import React, { useState, ChangeEvent } from 'react';
import { Rate, Button, Input } from 'antd';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
`;

interface RatingComponentProps {
    onSubmit: (data: {rating: number; review: string}) => void;
}

const RatingComponent: React.FC<RatingComponentProps> = ({ onSubmit }) => {
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState('');

  const handleRatingChange = (value: number) => {
    setRating(value);
  };

  const handleReviewChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setReview(e.target.value);
  };

  const handleSubmit = () => {
    onSubmit({ rating, review });
    setRating(0);
    setReview('');
  };

  return (
    <Container>
      <Rate onChange={handleRatingChange} value={rating} />
      <Input.TextArea 
        value={review} 
        onChange={handleReviewChange} 
        placeholder="Write a review..." 
        rows={4} 
      />
      <Button type="primary" onClick={handleSubmit}>
        Submit Review
      </Button>
    </Container>
  );
};

export default RatingComponent;
