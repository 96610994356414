import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
// import ContactImg from "images/landingpage/contactus/contactus.jpeg";
import { Form, Input, Divider, message, InputRef } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import SubmitButton from "ui/components/base/Buttons/SubmitButton";

const ContactSection = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 760px) {
    flex-direction: column;
  }
`;

const ContactLeft = styled.div`
  display: flex;
  flex-direction: row;
  width: 50%;
  @media (max-width: 760px) {
    overflow: hidden;
    width: 100%;
  }
`;

const ContactRigth = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-left: 5%;
  margin-right: 5%;
  align-content: center;
  justify-content: center;

  @media (max-width: 760px) {
    flex-direction: column;
    width: 90%;
  }
`;

const Header = styled.h4`
  font-size: 40px;
  font-weight: 600;
  line-height: 48px;
  text-align: left;
  color: #101828;
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 760px) {
    display: none;
  }
`;

const HeaderMobile = styled.h4`
  margin-top: 2rem;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
  text-align: left;
  color: #101828;
  /* margin-top: 0; */
  margin-bottom: 0;
  display: none;
  @media (max-width: 760px) {
    display: block;
  }
`;

const Text = styled.p`
  width: 73%;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  color: #475467;
  margin-top: 0;
  margin-bottom: 0;
`;

// const FormContainer = styled.div`
// `;

const Name = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 10px);
`;

const Email = styled.div`
  width: calc(100% - 10px);
`;

const PhoneNumber = styled.div`
  width: calc(100% - 10px);
`;

const PurpleText = styled.span`
  color: #6941c6;
`;

const Contact: React.FC = () => {
  const [form] = Form.useForm();
  const phoneInputRef = useRef<InputRef>(null);
  const recaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA;
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

  if (!recaptchaKey) {
    throw new Error("REACT_APP_GOOGLE_RECAPTCH is not defined");
  }
  const handleRecaptchaVerify = (token: string | null) => {
    setRecaptchaToken(token);
  };

  const onFinish = async (values: any) => {
    if (!recaptchaToken) {
      message.error("Please complete the reCAPTCHA verification.");
      return;
    }

    const { confirmpassword, phoneNumber, ...signupValues } = values;

    // Get the formatted phone number from the `input` property
    let phoneInput = phoneInputRef.current?.input?.value;

    if (phoneInput && phoneInput.length === 11 && phoneInput.startsWith("0")) {
      phoneInput = "234" + phoneInput.slice(1);
    }
  };

  return (
    <>
      <ContactSection>
        <ContactLeft>
          <img src="/Images/Contactus/contactus.jpeg" alt="contact image" width="720px" height="910px" />
        </ContactLeft>
        <ContactRigth>
          <Header>
            Let’s level up your brand,
            <br /> together
          </Header>
          <HeaderMobile>Let’s level up your brand, together</HeaderMobile>
          <Text>
            You can reach us anytime via <PurpleText>support@evendy.co</PurpleText>
          </Text>
          {/* <FormContainer>

          </FormContainer> */}
          <Form
            layout="vertical"
            form={form}
            name="register"
            scrollToFirstError
            requiredMark={false}
            onFinish={onFinish}
          >
            <Name>
              <Form.Item label="First Name" style={{ width: "47.5%" }} name="firstName" rules={[{ required: true }]}>
                <Input placeholder="e.g John" style={{ fontSize: "14px", color: "#9C9C9C", paddingLeft: "10px" }} />
              </Form.Item>
              <Form.Item label="Last Name" style={{ width: "47.5%" }} name="lastName" rules={[{ required: true }]}>
                <Input placeholder="e.g Doe" style={{ fontSize: "14px", color: "#9C9C9C", paddingLeft: "10px" }} />
              </Form.Item>
            </Name>
            <Email>
              <Form.Item
                label="Email Address"
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                  {
                    required: true,
                    message: "Please input your E-mail!",
                  },
                ]}
              >
                <Input
                  placeholder="e.g example@email.com"
                  style={{ fontSize: "14px", color: "#9C9C9C", paddingLeft: "10px" }}
                />
              </Form.Item>
            </Email>
            <PhoneNumber>
              <Form.Item
                label="Phone Number"
                name="phone"
                rules={[
                  { required: true, message: "Please input your phone number!" },
                  {
                    validator(_, value) {
                      if (!value) {
                        return Promise.reject(new Error("Phone number is required"));
                      }
                      if (!/^\d{11}$/.test(value)) {
                        return Promise.reject(new Error("Phone number must be exactly 11 digits"));
                      }
                      if (!/^0/.test(value)) {
                        return Promise.reject(new Error("Phone number must start with a 0"));
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                style={{ width: "100%" }}
              >
                <Input
                  ref={phoneInputRef}
                  placeholder="e.g 08061234567"
                  style={{ fontSize: "14px", color: "#9C9C9C", paddingLeft: "10px" }}
                />
              </Form.Item>
            </PhoneNumber>

            <Form.Item>
              <ReCAPTCHA sitekey={recaptchaKey} onChange={handleRecaptchaVerify} />
            </Form.Item>

            <Form.Item>
              <SubmitButton form={form}>Submit </SubmitButton>
            </Form.Item>
          </Form>
        </ContactRigth>
      </ContactSection>
    </>
  );
};
export default Contact;
