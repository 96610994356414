import { redirect } from "react-router-dom";
import { sessionStorageKeys } from "lib/constants";
import { getSession } from "lib/utils/utils";
import { routeLoaderKeys } from "./routeLoaderKeys";

type UserRole = "organizer" | "vendor" | "admin";

export async function authLoader(routeKey: keyof typeof routeLoaderKeys) {
  const session = getSession();

  if (!session) {
    return redirect("/auth/login");
  }

  try {
    const decodedToken = session;

    if (!decodedToken.exp || typeof decodedToken.exp !== "number") {
      throw new Error("Invalid or missing expiration time in JWT");
    }

    const tokenExpiration = decodedToken.exp;
    const currentTimestamp = Math.floor(Date.now() / 1000);

    if (tokenExpiration < currentTimestamp) {
      return redirect("/auth/login");
    }

    const userType = decodedToken.userType;

    if (!routeLoaderKeys[routeKey].includes(userType)) {
      return redirect("/auth/login");
    }

    return { user: decodedToken };
  } catch (error) {
    console.error("Error decoding or verifying JWT:", error);
    return redirect("/auth/login");
  }
}
