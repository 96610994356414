import React, { useState, useMemo } from "react";
import styled from "styled-components";
import { Form, Input, Select, Button, Upload, message, notification } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import SubmitButton from "ui/components/base/Buttons/SubmitButton";
import { useCreateEvent, useGetUser } from "lib/hooks";
import { getSession } from "lib/utils/utils";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { routeNames } from "lib/routes";
import { useGetEventCategories } from "lib/hooks/eventcategory";
import { useEventContext } from "ui/components/event/eventContext";
import { CreateEventResponse } from "lib/hooks";

const { TextArea } = Input;
const { Option } = Select;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: end;
  gap: 30px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
`;

const GhostButton = styled(Button)`
  width: 127px;
  height: 46px;
  border-radius: 8px;
  border: 1px solid rgba(226, 232, 240, 1);
  background-color: #fff;
`;

const ButtonWrapper = styled.div`
  width: 205px;
`;
const UploadContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: 1px dashed #d9d9d9;
  text-align: center;
  justify-content: space-between;
`;
const ImageUpload = styled(Upload)``;

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const initialEventValues = {
  title: "",
  banner: "https://placehold.co/600x400?text=No+Image",
  organizerEmail: "",
  category: "",
  about: "",
};

const CreateEventDetail = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { data: user } = useGetUser();
  const { mutate: createEvent } = useCreateEvent();
  const { data: categories, isLoading: isCategoriesLoading, error: categoriesError } = useGetEventCategories();
  const userEmail = user?.email;
  const { setEventId } = useEventContext();

  const organizerName = useMemo(() => {
    if (user) return `${capitalizeFirstLetter(user.firstName)} ${capitalizeFirstLetter(user.lastName)}`;
    return "";
  }, [user]);

  const organizerEmail = useMemo(() => {
    const session = getSession();
    return user?.email || session?.email;
  }, [user]);

  const handleFinish = async (values: any) => {
    setIsSubmitting(true);

    if (!organizerEmail) {
      notification.error({ message: "Unable to find organizer details, kindly try again" });
      return;
    }
    const payload = {
      title: values.title.trim(),
      banner: values.banner || initialEventValues.banner,
      organizerEmail,
      category: values.category.trim(),
      about: values.about.trim(),
    };
    createEvent(payload, {
      onSuccess: (data: CreateEventResponse) => {
        const newEventId = String(data.id); // Convert to string
        setEventId(newEventId);
        message.success("Event created successfully");
        form.resetFields();
        navigate(routeNames.eventType);
      },
      onError: (error: Error) => {
        message.error(
          axios.isAxiosError(error)
            ? error.response?.data?.message || "Failed to create event."
            : "An unexpected error occurred.",
        );
        setIsSubmitting(false);
      },
    });
  };

  return (
    <Form
      layout="vertical"
      style={{ width: "100%", margin: "0 auto", padding: "20px" }}
      form={form}
      onFinish={handleFinish}
      initialValues={initialEventValues}
      requiredMark={false}
    >
      <Container>
        <h2>Event Details</h2>
        <>
          <Form.Item label="Event Banner" name="banner" style={{ width: "100%" }}>
            <UploadContainer>
              <Upload
                name="banner"
                accept=".png,.jpg,.jpeg,.gif,.svg"
                listType="picture-card"
                maxCount={1}
                beforeUpload={() => false}
              >
                <Button icon={<UploadOutlined />} style={{ marginTop: 8, color: "rgba(19, 144, 230, 1)" }}>
                  Click to upload
                </Button>
                <p>SVG, PNG, JPG or GIF (max. 800x400px)</p>
              </Upload>
            </UploadContainer>
          </Form.Item>
        </>

        <Form.Item
          label="Event Title"
          name="title"
          rules={[{ required: true, message: "Please enter the event title" }]}
        >
          <Input placeholder="Enter the event title" style={{ paddingLeft: "14px" }} />
        </Form.Item>

        <Form.Item label="Organizer">
          <Input style={{ paddingLeft: "14px" }} value={organizerName} readOnly />
        </Form.Item>

        <Form.Item
          label="Event Category"
          name="category"
          rules={[{ required: true, message: "Please select a category" }]}
        >
          <Select
            placeholder="Select category"
            showSearch
            loading={isCategoriesLoading}
            filterOption={(input, option) =>
              typeof option?.children === "string"
                ? String(option.children).toLowerCase().includes(input.toLowerCase())
                : false
            }
          >
            {categoriesError && <Option disabled>Error fetching categories</Option>}
            {categories?.map(category => (
              <Option key={category.id} value={category.name}>
                {category.name}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="About Event" name="about" rules={[{ required: true, message: "Please describe the event" }]}>
          <TextArea
            rows={4}
            placeholder="Describe the event for attendees to know what's up"
            style={{ paddingLeft: "14px" }}
          />
        </Form.Item>

        <ButtonContainer>
          <Link to={routeNames.organizerHome}>
            <GhostButton>Cancel</GhostButton>
          </Link>
          <ButtonWrapper>
            <SubmitButton form={form} loading={isSubmitting}>
              Save and Continue
            </SubmitButton>
          </ButtonWrapper>
        </ButtonContainer>
      </Container>
    </Form>
  );
};

export default CreateEventDetail;
