import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { tqQueryKeys } from "lib/constants";
import { EvendyApi, getSession } from "lib/utils";
import { useMemo } from "react";
import { GetEventDto } from "ui/types";

type GetEventsResponse = {
  events: GetEventDto[];
  page: number;
  limit: number;
  totalCount: number;
};

type UseGetEventsResult = UseQueryResult<GetEventsResponse, Error>;

export function useGetOrganizerEvents(): UseGetEventsResult {
  const session = getSession();
  const organizerEmail = useMemo(() => {
    return session?.email || null;
  }, [session]);
  return useQuery({
    queryKey: [tqQueryKeys.event.getEvents, organizerEmail],
    queryFn: async () => {
      const res = await EvendyApi.get<Promise<GetEventsResponse>>(`event/${organizerEmail}`);
      return res.data ?? [];
    },
    enabled: !!organizerEmail,
  });
}
