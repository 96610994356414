import React from "react";
import styled from "styled-components";
import { ReactComponent as OrganizerMobile } from "images/landingpage/benefits/organizermobile.svg";
import ContentMobile from "images/landingpage/benefits/contentmobile.png";
import VendorMobile from "images/landingpage/benefits/vendormobile.png";
import { ReactComponent as Yellow } from "images/landingpage/benefits/yellow.svg";
import { ReactComponent as Logo1 } from "images/landingpage/benefits/1.svg";
import { ReactComponent as Logo2 } from "images/landingpage/benefits/2.svg";
import { ReactComponent as Logo3 } from "images/landingpage/benefits/3.svg";
import { ReactComponent as Logo4 } from "images/landingpage/benefits/4.svg";
import { ReactComponent as Logo5 } from "images/landingpage/benefits/5.svg";
import { ReactComponent as Eclipse1 } from "images/landingpage/benefits/Ellipse1.svg";
import { ReactComponent as Eclipse2 } from "images/landingpage/benefits/Ellipse2.svg";
import { ReactComponent as Eclipse3 } from "images/landingpage/benefits/Ellipse3.svg";
import { ReactComponent as Eclipse4 } from "images/landingpage/benefits/Ellipse4.svg";
import { ReactComponent as Header } from "images/landingpage/benefits/Header@3x.svg";
import { ReactComponent as Charges } from "images/landingpage/benefits/charges.svg";
import { ReactComponent as Package } from "images/landingpage/benefits/Package.svg";

const NewBenefitsContainer = styled.div`
  margin-top: 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;

  /* background-color: green; */

  @media (max-width: 768px) {
    gap: 3rem;
    margin-top: 1rem;
  }
`;

const BenefitTop = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 2rem;

  @media (max-width: 768px) {
    display: none;
  }
`;

const BenefitTopMobile = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
`;

const BenefitBottomRightMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    margin-left: 30%;
    margin-top: 1rem;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  opacity: 0;
  z-index: 1; /* Logos will be hidden behind Content2 */
  transition:
    opacity 0.5s ease-in-out,
    transform 0.5s ease-in-out;
`;

const LogoContainer1 = styled(LogoContainer)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const LogoContainer2 = styled(LogoContainer)`
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
`;

const LogoContainer3 = styled(LogoContainer)`
  bottom: 50%;
  left: 50%;
  transform: translate(-50%, 50%);
`;

const LogoContainer4 = styled(LogoContainer)`
  bottom: 50%;
  right: 50%;
  transform: translate(50%, 50%);
`;

const LogoContainer5 = styled(LogoContainer)`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const ContentContainer = styled.div`
  position: relative;
`;

const BenefitBottom = styled.div`
  background-color: #1390e6;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 6rem 8rem 6rem 4rem;

  @media (max-width: 768px) {
    flex-direction: column;
  }

  &:hover ${LogoContainer1} {
    opacity: 1;
    transform: translate(-290%, -185%);
  }

  &:hover ${LogoContainer2} {
    opacity: 1;
    transform: translate(140%, -210%);
  }

  &:hover ${LogoContainer3} {
    opacity: 1;
    transform: translate(195%, 25%);
  }

  &:hover ${LogoContainer4} {
    opacity: 1;
    transform: translate(45%, 220%);
  }

  &:hover ${LogoContainer5} {
    opacity: 1;
    transform: translate(-295%, 70%);
  }
`;

const BenefitBottomLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const BenefitBottomRight = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const Benefits = styled.button`
  background-color: #7ef88b99;
  color: white;
  border: none;
  padding: 8px 24px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 600;
  line-height: 15.08px;
  text-align: center;
  width: 20rem;
`;

const MediumText = styled.p`
  margin: 0;
  color: white;
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  text-align: left;
`;

const SmallText = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
  color: white;
`;

const BenefitList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

const Content2Container = styled.div`
  z-index: 10; /* Ensures the Content2 is above the logos initially */
  position: relative;
`;

const OrganizerDiv = styled.div`
  background-color: #1390e6;
  width: 559px;
  height: 534.69px;
  gap: 8px;
  border-radius: 24px 24px 0px 24px;
  opacity: 0px;
  position: relative;
  z-index: 5;
  overflow: hidden;
`;

const VendorDiv = styled.div`
  background-color: #121212;
  width: 559px;
  height: 534.69px;
  gap: 8px;
  border-radius: 24px 24px 24px 24px;
  opacity: 0px;
  position: relative;
  overflow: hidden;
  z-index: 5;
`;

const Organizerbtn = styled.button`
  background-color: #7ef88b99;
  color: #ffffff;
  border: none;
  padding: 8px 24px 8px 24px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 600;
  line-height: 15.08px;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 0;
  margin-left: 28px;
  width: Hug (121px) px;
  height: Hug (31px) px;
  gap: 0px;
  opacity: 0px;
  z-index: 10;
`;

const OrganizerText = styled.p`
  margin-bottom: 0;
  color: white;
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  margin-left: 28px;
  z-index: 10;
`;

const CreateEventContainer = styled.div`
  position: absolute;
  bottom: -0.3rem;
  right: 0;
  z-index: 1;
`;

const Eclipse1Container = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

const Eclipse2Container = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
`;

const AddPackageDiv = styled.div`
  z-index: 1;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 2rem;
  margin-right: 2rem;
  margin-top: 5rem;
  padding-top: 1rem;
  border-radius: 24px 24px 0px 0px;
  height: 300px; /* Set a height to allow scrolling */
  overflow-y: hidden;

  &:hover {
    overflow-y: auto; /* Enable scrolling when hovering */
  }

  /* Hide scrollbar */
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */

  &::-webkit-scrollbar {
    display: none; /* For Chrome, Safari, and Opera */
  }
`;

const Eclipse3Container = styled.div`
  position: absolute;
  z-index: -1;
  bottom: -1rem;
`;

const Eclipse4Container = styled.div`
  position: absolute;
  z-index: -1;
  right: 0;
  top: 0;
`;

const First = styled.div`
  position: sticky; /* Make the first div sticky */
  top: 0; /* Stick to the top of the parent container */
  background-color: white; /* Add a background so it's visible while scrolling */
  z-index: 10; /* Ensure it's above other content */
`;

const Second = styled.div`
  margin-top: 1rem;
`;

const Third = styled.div`
  margin-top: 1rem;
`;

const NewBenefits: React.FC = () => {
  return (
    <NewBenefitsContainer>
      <BenefitTop>
        <OrganizerDiv>
          <Organizerbtn>ORGANIZER</Organizerbtn>
          <OrganizerText>Seamless Event Creation</OrganizerText>
          <CreateEventContainer>
            <img src="/Images/benefits/createevent.svg" />
          </CreateEventContainer>
          <Eclipse1Container>
            <Eclipse1 />
          </Eclipse1Container>
          <Eclipse2Container>
            <Eclipse2 />
          </Eclipse2Container>
        </OrganizerDiv>
        <VendorDiv>
          <Organizerbtn>VENDORS</Organizerbtn>
          <OrganizerText>Provide Vendor Services</OrganizerText>
          <AddPackageDiv>
            <First>
              <Header />
            </First>
            <Second>
              <Charges />
            </Second>
            <Third>
              <Package />
            </Third>
          </AddPackageDiv>
          <Eclipse3Container>
            <Eclipse3 />
          </Eclipse3Container>
          <Eclipse4Container>
            <Eclipse4 />
          </Eclipse4Container>
        </VendorDiv>
      </BenefitTop>

      <BenefitTopMobile>
        <img src="/Images/benefits/organizermobile.svg" />
        <img src="/Images/benefits/vendormobile.svg" />
      </BenefitTopMobile>

      <BenefitBottom>
        <BenefitBottomLeft>
          <Benefits>BENEFITS - THE GREAT BENEFITS WE OFFER</Benefits>
          <MediumText>Great benefits you get with us</MediumText>
          <SmallText>Get Some insight into what you gain</SmallText>

          <BenefitList>
            <Row>
              <Yellow />
              <SmallText>Get trusted vendors for your event.</SmallText>
            </Row>
            <Row>
              <Yellow />
              <SmallText>All in one streamlined tools for planning & collaboration.</SmallText>
            </Row>
            <Row>
              <Yellow />
              <SmallText>Create great experience with ease.</SmallText>
            </Row>
          </BenefitList>
        </BenefitBottomLeft>

        <BenefitBottomRight>
          <ContentContainer>
            <Content2Container>
              <img src="/Images/benefits/screen.svg" />
            </Content2Container>

            <LogoContainer1>
              <Logo1 />
            </LogoContainer1>
            <LogoContainer2>
              <Logo2 />
            </LogoContainer2>
            <LogoContainer3>
              <Logo3 />
            </LogoContainer3>
            <LogoContainer4>
              <Logo4 />
            </LogoContainer4>
            <LogoContainer5>
              <Logo5 />
            </LogoContainer5>
          </ContentContainer>
        </BenefitBottomRight>
        <BenefitBottomRightMobile>
          {" "}
          <img src={ContentMobile} alt="Service Content" />
        </BenefitBottomRightMobile>
      </BenefitBottom>
    </NewBenefitsContainer>
  );
};

export default NewBenefits;
