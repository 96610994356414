import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { routeNames } from "lib/routes";

const CardWrapper = styled.div`
  width: calc(23.8% - 15px);
  /* padding: 6px; */
  /* border: 1px solid #ddd; */
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;

  @media (max-width: 1200px) {
    width: calc(31.8% - 15px);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const ImageContainer = styled.div`
  position: relative;
`;

const EventImage = styled.img`
  width: 100%;
  height: 150px;
  object-fit: cover;
  border-radius: 8px 8px 0px 0px;
  margin-bottom: 10px;
`;

const EventTitle = styled.h3`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-left: 1rem;
`;

const EventCategory = styled.p`
  margin: 5px 0;
  font-size: 14px;
  color: #666;
  margin-left: 1rem;
`;

const EventDate = styled.p`
  margin: 5px 0;
  font-size: 14px;
  color: #999;
  margin-left: 1rem;
  margin-bottom: 1rem;
`;

interface Event {
  id: number;
  title: string;
  banner: string;
  category: string;
  startDate: string;
  endDate: string;
}

interface CardProps {
  event: Event;
}

const Card: React.FC<CardProps> = ({ event }) => {
  return (
    <CardWrapper>
      <StyledLink to={`${routeNames.eventDetail}/${event.id}`}>
        <ImageContainer>
          <EventImage src="/Images/Events/cardimg.png" alt={event.title} />
        </ImageContainer>
        <EventTitle>{event.title}</EventTitle>
        <EventCategory>{event.category}</EventCategory>
        <EventDate>
          {new Date(event.startDate).toLocaleDateString()} - {new Date(event.endDate).toLocaleDateString()}
        </EventDate>
      </StyledLink>
    </CardWrapper>
  );
};

export default Card;
