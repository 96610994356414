import React from "react";
import styled from "styled-components";
import { devices } from "ui/theme";

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media ${devices.laptop} {
    max-width: 1208px;
    margin: 0px auto;
  }
`;

export const Container: React.FC<{
  children: React.ReactNode;
  className?: string;
}> = ({ children, className }) => {
  return <StyledContainer className={className}>{children}</StyledContainer>;
};
