import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Checkbox, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { routeNames } from "lib/routes";
import type { CheckboxProps } from "antd";
import { ReactComponent as Select } from "images/modal/Frameselect.svg";
import { sessionStorageKeys } from "lib/constants";

const SignUpContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

const Header = styled.h3`
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 0;
  margin-top: 0;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const LargeText = styled.p<{ selected: boolean }>`
  font-size: 13px;
  color: ${({ selected }) => (selected ? "#1C5B87" : "black")};
`;

const SmallText = styled.p<{ selected: boolean }>`
  font-size: 12px;
  color: ${({ selected }) => (selected ? "#1C5B87" : "#9c9c9c")};
  margin-top: 0;
  text-align: left;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
`;

const ButtonBase = styled.button<{ selected: boolean }>`
  background-color: ${({ selected }) => (selected ? "#FCFFFF" : "white")};
  color: black;
  border: 1px solid;
  border-color: ${({ selected }) => (selected ? "#1C5B87" : "#f1f1f1")};
  border-radius: 8px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 15px;
  padding-right: 15px;
  cursor: pointer;
`;

const Organizer = styled(ButtonBase)``;
const Vendor = styled(ButtonBase)``;

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const Right = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? "block" : "none")};
`;

const Terms = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const Linkto = styled.span`
  color: #1390e6;
`;

const Continue = styled.button<{ enabled: boolean }>`
  background-color: ${({ enabled }) => (enabled ? "#1390e6" : "#d9d9d9")};
  color: white;
  border: 1px solid;
  border-color: ${({ enabled }) => (enabled ? "#1390e6" : "#d9d9d9")};
  border-radius: 8px;
  padding: 12px 12px;
  font-size: 13px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
  cursor: ${({ enabled }) => (enabled ? "pointer" : "not-allowed")};
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const SignupPage: React.FC = () => {
  const [userType, setUserType] = useState<"organizer" | "vendor" | null>(null);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const [toast, context] = message.useMessage();
  const navigate = useNavigate();

  useEffect(() => {
    const savedUserType = sessionStorage.getItem(sessionStorageKeys.signupUserType) as "organizer" | "vendor" | null;
    if (savedUserType) {
      setUserType(savedUserType);
    }
  }, []);

  const handleSelectType = (type: "organizer" | "vendor") => {
    setUserType(type);
    sessionStorage.setItem(sessionStorageKeys.signupUserType, type);
  };

  const handleCheckboxChange: CheckboxProps["onChange"] = e => {
    setIsCheckboxChecked(e.target.checked);
  };

  const isContinueEnabled = userType !== null && isCheckboxChecked;

  const handleContinueClick = () => {
    if (isContinueEnabled) {
      sessionStorage.setItem(sessionStorageKeys.signupUserType, userType);
      navigate("/auth/signup/form");
    }
  };

  return (
    <>
      {context}
      <SignUpContainer>
        <Top>
          <Header>Sign up</Header>
          <SmallText selected={false}>Select your preferred account type to continue</SmallText>
        </Top>
        <Organizer selected={userType === "organizer"} onClick={() => handleSelectType("organizer")}>
          <Left>
            <LargeText selected={userType === "organizer"}>Sign up as an Organizer</LargeText>
            <SmallText selected={userType === "organizer"}>Create and manage events, finances, loans, etc</SmallText>
          </Left>
          <Right visible={userType === "organizer"}>
            <Select />
          </Right>
        </Organizer>
        <Vendor selected={userType === "vendor"} onClick={() => handleSelectType("vendor")}>
          <Left>
            <LargeText selected={userType === "vendor"}>Sign up as a Vendor</LargeText>
            <SmallText selected={userType === "vendor"}>
              Offer your services, and enhance your profile with customizable packages for delivery
            </SmallText>
          </Left>
          <Right visible={userType === "vendor"}>
            <Select />
          </Right>
        </Vendor>
        <Terms>
          <Checkbox onChange={handleCheckboxChange} style={{ fontSize: 13 }}>
            I have read and I agree with Evendy’s
          </Checkbox>
          <LargeText selected={false}>
            <StyledLink to={routeNames.terms}>Terms of use</StyledLink>
          </LargeText>
        </Terms>
        <Continue enabled={isContinueEnabled} disabled={!isContinueEnabled} onClick={handleContinueClick}>
          Continue
        </Continue>
        <Bottom>
          <LargeText selected={false}>
            Already have an account?
            <StyledLink to={routeNames.authLogin}>
              <Linkto> Login then!</Linkto>
            </StyledLink>
          </LargeText>
        </Bottom>
      </SignUpContainer>
    </>
  );
};

export default SignupPage;
