import React, { useState, useEffect } from "react";
import { routeNames } from "lib/routes";
import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";
import Spiral from "images/landingpage/hero/spiral.png";
import Line from "images/landingpage/hero/lineedge.png";
import LineMobile from "images/landingpage/hero/linemobile.png";
import SpiralMobile from "images/landingpage/hero/spiralmobile.png";
import { ReactComponent as Techpoint } from "images/landingpage/hero/Techpoint.svg";
import { ReactComponent as Altschool } from "images/landingpage/hero/altschool.svg";
import { ReactComponent as Thrive } from "images/landingpage/hero/thrive.svg";
import { ReactComponent as Giga } from "images/landingpage/hero/giga.svg";
import { ReactComponent as Sabi } from "images/landingpage/hero/sabi.svg";
import { ReactComponent as Os } from "images/landingpage/hero/os.svg";
import { ReactComponent as Leftshadow } from "images/landingpage/hero/leftshadow.svg";
import { ReactComponent as Rightshadow } from "images/landingpage/hero/rightshadow.svg";

const HeroContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  @media (max-width: 768px) {
    align-items: flex-start;
  }
`;

const TopSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  width: 100%;

  background-image: radial-gradient(circle, #dbdbdb 1.2px, transparent 1px);
  background-size: 20px 20px;

  @media (max-width: 768px) {
    align-items: flex-start;
    width: 100%;
  }
`;

const Allinone = styled.button`
  background-color: #7ef88b33;
  color: #168f43;
  border: none;
  padding: 8px 24px 8px 24px;
  border-radius: 16px;
  margin-bottom: 1rem;
  font-size: 12px;
  font-weight: 600;
  line-height: 15.08px;
  text-align: center;
  margin-top: 4.5rem;
  margin-bottom: 0;
  @media (max-width: 768px) {
    margin-left: 1rem;
  }
`;

const HeroHeader = styled.h1`
  font-family: BR Firma;
  font-size: 48px;
  font-weight: 600;
  line-height: 60.34px;
  letter-spacing: -0.02em;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 768px) {
    display: none;
  }
`;

const HeroHeaderMobile = styled.h1`
  display: none;
  @media (max-width: 768px) {
    margin-top: 0;
    margin-bottom: 0;
    display: block;
    font-size: 32px;
    font-weight: 600;
    line-height: 40.22px;
    letter-spacing: -0.02em;
    text-align: left;
    margin-left: 1rem;
  }
`;

const HeroText = styled.p`
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #595959;
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 768px) {
    display: none;
  }
`;

const HeroTextMobile = styled.p`
  display: none;
  @media (max-width: 768px) {
    display: block;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    color: #595959;
    margin-top: 0;
    margin-bottom: 0;
    margin-left: 1rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 4rem;
  @media (max-width: 768px) {
    justify-content: flex-start; /* Keep alignment left on mobile */
    margin-left: 1rem;
    margin-bottom: 0rem;
  }
`;

export const CreateAnEvent = styled(Link)`
  all: unset;
  height: 46px;
  border-radius: 8px;
  background-color: rgba(19, 144, 230, 1);
  color: #fff;
  width: 170px;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  /* Add transition for smooth shadow appearance */
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    background-color: #3897d8;
    /* Dropdown shadow effect */
    box-shadow: 0 8px 16px rgba(56, 151, 216, 0.3); /* Subtle shadow */
  }
`;

const ExploreEvents = styled(Link)`
  all: unset;
  height: 46px;
  border-radius: 8px;
  color: #121212;
  width: 170px;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  /* Add transition for smooth shadow appearance */
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    color: #1c5b87;
  }
`;

const TopContainer = styled.div`
  position: absolute;
  top: -2.6rem;
  left: 25%;
  transition: transform 0.3s ease; /* Add transition for smooth movement */

  @media (max-width: 768px) {
    top: -2rem;
  }
`;

const LeftContainer = styled.div`
  position: absolute;
  top: 41%;
  left: -3.9rem;
  transition: transform 0.3s ease; /* Add transition for smooth movement */
  z-index: 1;
  @media (max-width: 768px) {
    left: -2.9rem;
  }
`;

const RightContainer = styled.div`
  position: absolute;
  top: 23%;
  right: -3.5rem;
  transition: transform 0.3s ease; /* Add transition for smooth movement */

  @media (max-width: 768px) {
    right: -3rem;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  margin-top: 1rem;
  margin-bottom: 3rem;
  @media (max-width: 768px) {
    display: none;
  }

  /* On hover, move the TopContainer, LeftContainer, and RightContainer */
  &:hover ${TopContainer} {
    transform: translateY(-50px); /* Move the top container upwards */
  }

  &:hover ${LeftContainer} {
    transform: translateX(-85px) rotate(-15deg); /* Move the left container leftwards */
  }

  &:hover ${RightContainer} {
    transform: translateX(85px) rotate(15deg); /* Move the right container rightwards */
  }
`;

const ImageContainerMobile = styled.div`
  position: relative;
  margin-top: 3rem;
  margin-bottom: 3rem;
  display: none;
  @media (max-width: 768px) {
    display: block;
    margin-left: 4rem;
  }
`;

const CenterContainer = styled.div`
  position: relative;
  z-index: 10;
`;

const SpiralContainer = styled.div`
  position: absolute;
  top: 12%;
  left: -22rem;
`;

const LineContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 3;
  @media (max-width: 768px) {
    display: none;
  }
`;

const LineContainerMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
  }
`;

const SpiralContainerMobile = styled.div`
  position: absolute;
  top: 12%;
  left: -32%;
  z-index: -100;
`;

const Sponsors = styled.div`
  background-color: #121212;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  gap: 1.5rem;
`;

const SmallText = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: white;
  margin-top: 3.3rem;
  @media (max-width: 768px) {
    z-index: 1000;
  }
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 3rem;
  margin-bottom: 3.3rem;
  z-index: 1;
  @media (max-width: 768px) {
    z-index: 1;
    flex-wrap: wrap;
    gap: 2.5rem;
  }
`;

const LeftShadowContainer = styled.div`
  position: absolute;
  left: 0;
  top: 26%;
  @media (max-width: 768px) {
    top: 47%;
  }
`;

const RightShadowContainer = styled.div`
  position: absolute;
  right: 0;
  @media (max-width: 768px) {
    display: none;
  }
`;

// Define keyframes for sliding in and out
const slideInOut = keyframes`
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  10% {
    opacity: 1;
    transform: translateX(0);
  }
  90% {
    opacity: 1;
    transform: translateX(0);
  }
  100% {
    opacity: 0;
    transform: translateX(-100%);
  }
`;

const AnimatedText = styled.span`
  font-size: 48px;
  font-weight: 600;
  line-height: 60.34px;
  letter-spacing: -0.02em;
  color: #1390e6;
  animation: ${slideInOut} 7s ease-in-out forwards;
  display: inline-block;
  @media (max-width: 768px) {
    font-size: 28px;
  }
`;

const HeroTextWrapper = styled.div`
  height: 60px;
  overflow: hidden;
`;

const textOptions = ["AN INDIVIDUAL", "A BUSINESS", "AN ORGANIZATION"];

const NewHero: React.FC = () => {
  const [currentText, setCurrentText] = useState(0);

  useEffect(() => {
    // Change text every 3 seconds
    const interval = setInterval(() => {
      setCurrentText(prev => (prev + 1) % textOptions.length);
    }, 5000); // Match animation timing (3s)

    return () => clearInterval(interval);
  }, []);

  return (
    <HeroContainer>
      <TopSection>
        <LineContainer>
          <img src={Line} alt="Service Content" />
        </LineContainer>
        <LineContainerMobile>
          <img src={LineMobile} alt="Service Content" />
        </LineContainerMobile>
        <Allinone>ALL-IN-ONE EVENT TOOL AT YOUR FINGERTIPS 🔥</Allinone>
        <HeroHeader>
          SEAMLESSLY PLAN YOUR EVENT AS
          <HeroTextWrapper>
            <AnimatedText key={currentText}>{textOptions[currentText]}</AnimatedText>
          </HeroTextWrapper>
        </HeroHeader>

        <HeroHeaderMobile>
          SEAMLESSLY PLAN
          <br /> YOUR EVENT AS
          <br />{" "}
          <HeroTextWrapper>
            <AnimatedText key={currentText}>{textOptions[currentText]}</AnimatedText>
          </HeroTextWrapper>
        </HeroHeaderMobile>

        <HeroText>
          Effortlessly source top event vendors, streamline your event registration process, manage your
          <br /> team, and execute effective marketing strategies all from a unified platform.
        </HeroText>
        <HeroTextMobile>
          Effortlessly source top event vendors,
          <br /> streamline your event registration process,
          <br /> manage your team, and execute effective
          <br /> marketing strategies all from a unified,
          <br /> platform.
        </HeroTextMobile>
        <ButtonWrapper>
          <CreateAnEvent to={routeNames.authSignup}>Create an event</CreateAnEvent>

          <ExploreEvents to={routeNames.event}>Explore Events</ExploreEvents>
        </ButtonWrapper>
      </TopSection>

      <ImageContainer>
        <CenterContainer>
          <img src="/Images/LandingPage/center.svg" alt="" />
        </CenterContainer>
        <TopContainer>
          <img src="/Images/LandingPage/top.svg" alt="" />
        </TopContainer>
        <LeftContainer>
          <img src="/Images/LandingPage/left.svg" alt="" />
        </LeftContainer>
        <RightContainer>
          <img src="/Images/LandingPage/right.svg" alt="" />
        </RightContainer>
        <SpiralContainer>
          <img src={Spiral} alt="Service Content" />
        </SpiralContainer>
      </ImageContainer>
      <ImageContainerMobile>
        <CenterContainer>
          <img src="/Images/LandingPage/centermobile.svg" alt="" />
        </CenterContainer>
        <TopContainer>
          <img src="/Images/LandingPage/topmobile.svg" alt="" />
        </TopContainer>
        <LeftContainer>
          <img src="/Images/LandingPage/leftmobile.svg" alt="" />
        </LeftContainer>
        <RightContainer>
          <img src="/Images/LandingPage/rightmobile.svg" alt="" />
        </RightContainer>
        <SpiralContainerMobile>
          <img src={SpiralMobile} alt="Service Content" />
        </SpiralContainerMobile>
      </ImageContainerMobile>
      <Sponsors>
        <SmallText>Our Clients</SmallText>
        <LogoContainer>
          <Techpoint />
          <Altschool />
          <Thrive />
          <Giga />
          <Sabi />
          <Os />
        </LogoContainer>
        <LeftShadowContainer>
          <Leftshadow />
        </LeftShadowContainer>
        <RightShadowContainer>
          <Rightshadow />
        </RightShadowContainer>
      </Sponsors>
    </HeroContainer>
  );
};

export default NewHero;
