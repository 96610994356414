import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { tqQueryKeys } from "lib/constants";
import { EvendyApi } from "lib/utils";
import { ISODateString } from "ui/types";

type GetUserDto = {
  id: number;
  createdAt: ISODateString;
  updatedAt: ISODateString;
  firstName: string;
  lastName: string;
  email: string;
  verified: boolean;
  organizer: object;
};

type UseGetUsersResult = UseQueryResult<GetUserDto[], Error>;

export function useGetUsers(): UseGetUsersResult {
  return useQuery({
    queryKey: [tqQueryKeys.users.getUsers],
    queryFn: async () => {
      const res = await EvendyApi.get("users");
      return res.data;
    },
  });
}
