import React, { useState } from "react";
import { Button, Form } from "antd";
import type { FormInstance } from "antd";

interface SubmitButtonProps {
  form: FormInstance;
  loading?: boolean;
}

const SubmitButton: React.FC<React.PropsWithChildren<SubmitButtonProps>> = ({ form, loading = false, children }) => {
  const [submittable, setSubmittable] = React.useState<boolean>(false);
  // const [loading, setLoading] = React.useState<boolean>(false);

  const [loadings, setLoadings] = useState<boolean[]>([]);

  // const enterLoading = (index: number) => {
  //   setLoadings(prevLoadings => {
  //     const newLoadings = [...prevLoadings];
  //     newLoadings[index] = true;
  //     return newLoadings;
  //   });

  //   setTimeout(() => {
  //     setLoadings(prevLoadings => {
  //       const newLoadings = [...prevLoadings];
  //       newLoadings[index] = false;
  //       return newLoadings;
  //     });
  //   }, 2000);
  // };

  // Watch all values
  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  const handleClick = () => {
    if (loading || !submittable) {
      return;
    }
    form.submit();
  };

  return (
    <Button
      type="primary"
      htmlType="button"
      disabled={!submittable || loading}
      style={{
        width: "100%",
        color: "white",
        border: "none",
        fontSize: "13px",
        backgroundColor: loading ? "#1C5B87" : "",
      }}
      size="large"
      onClick={handleClick}
      loading={loadings[0]}
    >
      {loading ? "Please wait..." : children}
    </Button>
  );
};

export default SubmitButton;
