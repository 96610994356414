import React from "react";
import { Link, LinkProps } from "react-router-dom";

interface ApplinkProps extends LinkProps {}

export const AppLink: React.FC<ApplinkProps> = ({ children, to = "", className, ...restProps }) => {
  return (
    <Link {...restProps} to={to} className={className}>
      {children}
    </Link>
  );
};
