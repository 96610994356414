import { Popover } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { NotificationIcon, UserLogOut, UserProfile, UserSettings } from "ui/components/base";
import { IAdminProfile, IOrganizerProfile, IUser, IVendorProfile } from "ui/types";
import { IProfileMenuItem, UserProfileHeader, UserProfileMenu, UserProfileMenuItem } from "./UserProfileMenu";

const profileMenuConstants = {
  profileMenuKeys: {
    USER_INFO: "userInfo",
    SWITCH_PROFILE: "switchProfile",
    VIEW_PROFILE: "viewProfile",
    SETTINGS: "settings",
    MANAGE_PLAN: "managePlan",
    COMPANY_PROFILE: "companyProfile",
    TEAM: "team",
    TEAM_INVITE: "teamInvite",
    SUPPORT: "support",
    LOG_OUT: "logout",
  },
};

const Root = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  color: var(--gray-900, #101828);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
`;

const Icon = styled.div`
  cursor: pointer;
`;

const Avatar = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "BR Firma";
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;

  height: 44px;
  flex-shrink: 0;
  border-radius: 200px;

  img {
    width: 44px;
    height: 44px;
  }
  cursor: pointer;
`;

export interface RightComponentProps {
  user: IUser;
  profile: IOrganizerProfile | IVendorProfile | IAdminProfile;
}

export const RightComponent: React.FC<RightComponentProps> = ({ user, profile = {} }) => {
  const navigate = useNavigate();

  const userProfileMenuItems: IProfileMenuItem[] = [
    {
      title: "",
      actionKey: profileMenuConstants.profileMenuKeys.USER_INFO,
      component: <UserProfileHeader {...profile} {...user} />,
      hasDivider: true,
    },
    {
      title: "View Profile",
      actionKey: profileMenuConstants.profileMenuKeys.VIEW_PROFILE,
      icon: <UserProfile />,
    },
    {
      title: "Settings",
      actionKey: profileMenuConstants.profileMenuKeys.SETTINGS,
      icon: <UserSettings />,
      hasDivider: true,
    },
    { title: "Log out", actionKey: "logout", icon: <UserLogOut /> },
  ];

  const handleMenuItemSelect = (actionKey: string) => {
    switch (actionKey) {
      case profileMenuConstants.profileMenuKeys.USER_INFO: {
        return;
      }
      case profileMenuConstants.profileMenuKeys.VIEW_PROFILE: {
        // navigate(routeNames.profile);
        return;
      }
      case profileMenuConstants.profileMenuKeys.SETTINGS: {
        return;
      }
      case profileMenuConstants.profileMenuKeys.LOG_OUT: {
        document.location.href = "https://evendy.co";
        return;
      }
      default: {
        return;
      }
    }
  };

  return (
    <Root>
      <Icon>
        <NotificationIcon />
      </Icon>
      <Popover
        placement="bottomRight"
        arrow={false}
        content={
          <UserProfileMenu
            dataSource={userProfileMenuItems}
            rowKey={item => item.title}
            renderItem={item => {
              return <UserProfileMenuItem onSelect={handleMenuItemSelect} {...item} />;
            }}
          />
        }
        trigger="click"
      >
        <Avatar>
          <img src={""} alt="event manager" />
          Faith Ogarabe
        </Avatar>
      </Popover>
    </Root>
  );
};
