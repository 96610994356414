import React from "react";
import ReactDOM from "react-dom/client";
import { createBrowserRouter, redirect, RouterProvider } from "react-router-dom";
import Website from "./Website";
import reportWebVitals from "./reportWebVitals";
import Dashboard from "./Dashboard";
import AdminDashboard from "AdminDashboard";
import { ThemeProvider } from "styled-components";
import { evendyTheme } from "ui/theme";
import { NotFoundPage } from "app/pages/notFound/NotFound";
import LandingPage from "app/pages/website/landing/LandingPage";
import OrganizerHome from "app/pages/organizer/home/OrganizerHome";
import NewEvent from "app/pages/organizer/createevent";
import OrganizerProfile from "app/pages/organizer/profile/OrganizerProfile";
import AdminHome from "app/pages/admin/AdminHome";
import AdminOrganizers from "app/pages/admin/AdminOrganizers";
import AdminVendors from "app/pages/admin/AdminVendors";
import RequestAndPayments from "app/pages/admin/RequestAndPayments/index";
import AdminDisputes from "app/pages/admin/AdminDisputes";
import VendorHome from "app/pages/vendor/home/index";
import FinancesPage from "app/pages/finances";
import { routeNames } from "lib/routes";
import MarketingPage from "app/pages/marketing";
import OrganizerMarketPlacePage from "app/pages/organizer/marketplace";
import OrganizerEvents from "app/pages/organizer/events";
import OrganizerLoanPage from "app/pages/organizer/loanapplication";
import OrganizerReportsPage from "app/pages/organizer/reports";
import HospitalityPage from "app/pages/hospitality";
import HelpPage from "app/pages/help";
import ServicesHome from "app/pages/website/services/ServicesHomePage";
import TicketingPage from "app/pages/website/services/eventTicketing";
import EventAnalysis from "app/pages/website/services/eventAnalysis";
import EventManagement from "app/pages/website/services/eventManagement";
import EventMarketing from "app/pages/website/services/eventMarketing";
import VendorSourcing from "app/pages/website/services/eventVendorSourcing";
import OrganizerVendorProfile from "app/pages/organizer/vendorsprofile/VendorProfile";
import EventFinancingPage from "app/pages/website/services/eventFinancing";
import Event from "app/pages/website/explore/index";
import About from "app/pages/website/about/about";
import Contact from "app/pages/website/contact/contact";
import ContactForm from "app/pages/website/contact/contactForm";
import EventDetail from "app/pages/website/event/event-detail";
import Privacy from "app/pages/website/privacy/privacy";
import Terms from "app/pages/website/landing/terms/terms";
import VendorMyPackages from "app/pages/vendor/mypackages";
import VendorProfile from "app/pages/vendor/vendorprofile";
import VendorRequests from "app/pages/vendor/requests";
import VendorResources from "app/pages/vendor/resources";
import AdminEvents from "app/pages/admin/AdminEvents";
import Auth from "./auth";
import AuthSignupForm from "app/pages/authentication/RegisterForm";
import AuthVerifyEmail from "app/pages/authentication/VerifyEmail";
//email verification on the dashboard for signup without the need to verify
import VerifyEmail from "ui/components/Dashboard/verifyEmail/VerifyEmail";
import AuthSignupSuccessful from "app/pages/authentication/RegisterSuccessful";
import AuthSignupComplete from "app/pages/authentication/RegisterSuccessful/SignupComplete";
import AuthLogin from "app/pages/authentication/Login";
import AuthForgotPassword from "app/pages/authentication/ForgotPassword";
import AuthResetPassword from "app/pages/authentication/ResetPassword";
import AuthPasswordUpdated from "app/pages/authentication/PasswordUpdated";
import Admin from "./admin";

import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import SignupPage from "app/pages/authentication/Register";

import "ui/styles/fonts.css";
import WebFont from "webfontloader";

WebFont.load({
  custom: {
    families: ["'BR Firma"],
  },
});

import { appLoader, authLoader } from "lib/loaders";
import { AntDesignOverrideStyles, GlobalStyles } from "ui/styles/AppStyles";
import "ui/styles/normalize.css";
import ComingSoonPage from "app/pages/notFound/ComingSoon";
import { Logout } from "app/pages/website/logout";
import CreateEventDetail from "app/pages/organizer/createevent/eventdetail";
import EventType from "app/pages/organizer/createevent/eventtype";
import EventDate from "app/pages/organizer/createevent/eventdate";
import EventTickets from "app/pages/organizer/createevent/eventtickets";

const evendyQueryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: routeNames.homepage,
    element: <Website />,
    children: [
      {
        element: <LandingPage />,
        index: true,
      },
      {
        element: <Logout />,
        path: routeNames.logout,
      },
      {
        path: routeNames.servicesHomePage,
        element: <ServicesHome />,
        children: [
          { element: <EventFinancingPage />, path: routeNames.financialServices },
          { element: <TicketingPage />, path: routeNames.ticketingPage },
          { element: <EventAnalysis />, path: routeNames.analysisPage },
          { element: <EventManagement />, path: routeNames.eventManagement },
          { element: <EventMarketing />, path: routeNames.eventMarketing },
          { element: <VendorSourcing />, path: routeNames.eventVendorSourcing },
        ],
      },
      {
        path: routeNames.event,
        element: <Event />,
        // element: <ComingSoonPage />,
      },
      {
        path: routeNames.eventDetail,
        // element: <EventDetail />,
        element: <ComingSoonPage />,
      },
      {
        path: routeNames.about,
        element: <About />,
      },
      {
        path: routeNames.contact,
        element: <Contact />,
        children: [{ element: <ContactForm />, path: routeNames.contactForm }],
      },
      {
        path: routeNames.privacy,
        element: <Privacy />,
      },
      {
        path: routeNames.terms,
        element: <Terms />,
      },
    ],
  },
  {
    path: routeNames.dashboard,
    element: <Dashboard />,
    loader: appLoader,
    children: [
      // organizer pages
      {
        path: routeNames.organizerHome,
        element: <OrganizerHome />,
        loader: () => authLoader("organizer"),
      },
      {
        path: routeNames.organizerEvents,
        element: <OrganizerEvents />,
        loader: () => authLoader("organizer"),
      },
      {
        path: routeNames.organizermarketplace,
        element: <OrganizerMarketPlacePage />,
        loader: () => authLoader("organizer"),
      },
      {
        path: routeNames.marketing,
        // element: <MarketingPage />,
        element: <ComingSoonPage />,
        loader: () => authLoader("common"),
      },
      {
        path: routeNames.finances,
        // element: <FinancesPage />,
        element: <ComingSoonPage />,
        loader: () => authLoader("common"),
      },
      {
        path: routeNames.organizerLoan,
        // element: <OrganizerLoanPage />,
        element: <ComingSoonPage />,
        loader: () => authLoader("organizer"),
      },
      {
        path: routeNames.organizerReports,
        // element: <OrganizerReportsPage />,
        element: <ComingSoonPage />,
        loader: () => authLoader("organizer"),
      },
      {
        path: routeNames.hospitality,
        // element: <HospitalityPage />,
        element: <ComingSoonPage />,
        loader: () => authLoader("common"),
      },
      {
        path: routeNames.help,
        // element: <HelpPage />,
        element: <ComingSoonPage />,
        loader: () => authLoader("common"),
      },
      {
        path: routeNames.organizerProfile,
        element: <OrganizerProfile />,
        loader: () => authLoader("organizer"),
      },
      {
        path: routeNames.newEvent,
        element: <NewEvent />,
        loader: () => authLoader("organizer"),
        children: [
          {
            path: routeNames.eventDetails,
            element: <CreateEventDetail />,
          },
          {
            path: routeNames.eventType,
            element: <EventType />,
          },
          {
            path: routeNames.eventDate,
            element: <EventDate />,
          },
          {
            path: routeNames.eventTickets,
            element: <EventTickets />,
          },
        ],
      },
      // ==vendor pages==
      {
        path: routeNames.vendorHome,
        element: <VendorHome />,
        loader: () => authLoader("vendor"),
      },
      {
        path: `${routeNames.organizerVendorProfile}/:id`,
        element: <OrganizerVendorProfile />,
        loader: () => authLoader("organizer"),
      },
      {
        path: routeNames.VendorMyPackages,
        element: <VendorMyPackages />,
        loader: () => authLoader("vendor"),
      },
      {
        path: routeNames.VendorRequests,
        // element: <VendorRequests />,
        element: <ComingSoonPage />,
        loader: () => authLoader("vendor"),
      },
      {
        path: routeNames.VendorResources,
        // element: <VendorResources />,
        element: <ComingSoonPage />,
        loader: () => authLoader("vendor"),
      },
      {
        path: routeNames.vendorProfile,
        element: <VendorProfile />,
        loader: () => authLoader("vendor"),
      },
    ],
  },
  {
    path: routeNames.VerifyEmail,
    element: <VerifyEmail />,
  },

  {
    path: routeNames.auth,
    element: <Auth />,
    children: [
      {
        path: routeNames.authSignup,
        element: <SignupPage />,
      },
      {
        path: routeNames.authSignupForm,
        element: <AuthSignupForm />,
      },
      {
        path: routeNames.authVerifyEmail,
        element: <AuthVerifyEmail />,
      },
      {
        path: routeNames.authSignupSuccessful,
        element: <AuthSignupSuccessful />,
      },
      {
        path: routeNames.authSignupComplete,
        element: <AuthSignupComplete />,
      },
      {
        path: routeNames.authLogin,
        element: <AuthLogin />,
      },
      {
        path: routeNames.authForgotPassword,
        element: <AuthForgotPassword />,
      },
      {
        path: routeNames.authResetPassword,
        element: <AuthResetPassword />,
      },
      {
        path: routeNames.authPasswordUpdated,
        element: <AuthPasswordUpdated />,
      },
    ],
  },
  {
    path: routeNames.admin,
    element: <Admin />,
  },
  {
    path: routeNames.admin,
    element: <AdminDashboard />,
    children: [
      {
        path: routeNames.adminHome,
        element: <AdminHome />,
      },
      {
        path: routeNames.adminEvents,
        element: <AdminEvents />,
      },
      {
        path: routeNames.adminOrganizers,
        element: <AdminOrganizers />,
      },
      {
        path: routeNames.adminVendors,
        element: <AdminVendors />,
      },
      {
        path: routeNames.adminVendorRequest,
        element: <RequestAndPayments />,
      },
      {
        path: routeNames.adminDisputes,
        element: <AdminDisputes />,
      },
    ],
  },
  {
    path: "/*",
    element: <NotFoundPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);
root.render(
  <React.StrictMode>
    <ThemeProvider
      theme={{
        type: "light",
        ...evendyTheme,
      }}
    >
      <QueryClientProvider client={evendyQueryClient}>
        <RouterProvider router={router} />
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
      <GlobalStyles />
      <AntDesignOverrideStyles />
    </ThemeProvider>
  </React.StrictMode>,
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
