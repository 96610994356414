import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Add } from "images/organizerevents/Vectoradd.svg";
import { ReactComponent as Nopackage } from "images/vendor/nopackage.svg";
import AddPackageModal from "./AddPackageModal";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 2rem;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Text = styled.h3`
  font-size: 18px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
`;

const Addnew = styled.button`
  background-color: #1390e6;
  color: white;
  border: 1px solid;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  width: 13rem;
`;

interface NoPackageProps {
  onPackageAdded: () => void;
}

// const NoPackage: React.FC = () => {
const NoPackage: React.FC<NoPackageProps> = ({ onPackageAdded }) => {
  // Modal state
  const [custommodalOpen, setcustomModalOpen] = useState(false);

  const handlecustomModalOpen = () => {
    setcustomModalOpen(true);
  };

  const handlecustomModalClose = () => {
    setcustomModalOpen(false);
    onPackageAdded();
  };

  return (
    <Container>
      <Top>
        <Nopackage />
        <Text> No packages added yet</Text>
      </Top>
      <Addnew onClick={handlecustomModalOpen}>
        <Add />
        Add new package
      </Addnew>
      <AddPackageModal
        open={custommodalOpen}
        onCancel={handlecustomModalClose}
        onPackageAdded={handlecustomModalClose}
      />
    </Container>
  );
};

export default NoPackage;
