import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`

:root {
  --mobile-s: 320px;
  --mobile-m: 375px;
  --mobile-l: 425px;
  --tablet: 768px;
  --laptop: 1024px;
  --laptop-l: 1440px;
  --desktop: 2560px;
  
  font-family: 'BR Firma', Arial, Helvetica, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,html {
  margin: 0;
  padding: 0;

}
body * {
  font-family: 'BR Firma', Arial, Helvetica, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media (min-width: var(--tablet)) {
  body {
    font-size: 14px; /* basis for 1rem */   
  }
}
@media (min-width: var(--laptop)) {
  body {
    font-size: 16px; /* basis for 1rem */   
  }
}




`;

export const AntDesignOverrideStyles = createGlobalStyle`

.ant-popover-inner {
  padding: 0px !important;
}

.ant-form-item-label {
  margin-bottom: 6px;
}

.ant-form-item-label > label {
  color: var(--gray-700, #344054) !important;

  /* Text sm/Medium */
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important; /* 142.857% */
}

.ant-input {
  all: unset;
  color: var(--gray-500, #667085);

  /* Text md/Regular */
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
  display: flex !important;
  width: 100%;
  /* padding: 10px 14px; */
  align-items: center;
  gap: 8px;
  align-self: stretch;
  height: 44px;
  border-radius: 8px !important;
  border: 1px solid var(--gray-300, #d0d5dd) !important;
  background: var(--base-white, #fff) !important;

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05) !important;
}

.ant-select-single,
.form-control {
  height: 44px;
}

.ant-select-selector {
}

textarea.ant-input {
  max-width: 100%;
  height: auto;
  min-height: 32px;
  line-height: 1.5714285714285714;
  vertical-align: bottom;
  transition:
    all 0.3s,
    height 0s;
  resize: vertical;

  border-radius: 8px;
  border: 1px solid var(--gray-300, #d0d5dd);
  background: var(--base-white, #fff);

  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
}`;
