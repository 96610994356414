import React from "react";
import styled from "styled-components";
import { devices } from "ui/theme";

const StyledWebsiteContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100vw;

  @media ${devices.laptopL} {
    max-width: 1440px;
    margin: 0px auto;
  }
`;

export const WebsiteContainer: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <StyledWebsiteContainer>{children}</StyledWebsiteContainer>;
};
