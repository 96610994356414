import React, { useState, useEffect } from "react";
import { Tabs } from "antd";
import styled from "styled-components";
import General from "./general";
import BankDetails from "./bankdetails";
import Notification from "./notification";
import Password from "./password";
import CloseAccount from "./closeaccount";

const { TabPane } = Tabs;

const StyledTabs = styled(Tabs)`
  .ant-tabs-nav-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .ant-tabs-tab {
    margin-bottom: 8px;
  }

  .ant-tabs-content-holder {
    flex: 1;
  }

  &.ant-tabs-tab-active {
    .ant-tabs-tab-btn {
      color: #000;
    }
  }

  .ant-tabs-tab:nth-of-type(5) .ant-tabs-tab-btn {
    color: red;
  }

  @media (max-width: 768px) {
    .ant-tabs-nav-list {
      flex-direction: row;
      justify-content: space-around;
    }
  }
`;

const IndexSetting: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [activeTabKey, setActiveTabKey] = useState("1");
  const [tabPosition, setTabPosition] = useState<"left" | "top">("left");

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  useEffect(() => {
    if (activeTabKey === "5") {
      setIsModalOpen(true);
    }
  }, [activeTabKey]);

  const handleTabClick = (key: string) => {
    setActiveTabKey(key);
  };

  const handleResize = () => {
    if (window.innerWidth <= 768) {
      setTabPosition("top");
    } else {
      setTabPosition("left");
    }
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const items = [
    {
      key: "1",
      label: "General",
      children: <General />,
    },
    {
      key: "2",
      label: "Bank Details",
      children: <BankDetails />,
    },
    {
      key: "3",
      label: "Notification",
      children: <Notification />,
    },
    {
      key: "4",
      label: "Password",
      children: <Password />,
    },
    {
      key: "5",
      label: "Close account",
      children: <CloseAccount open={isModalOpen} onCancel={handleCancel} />,
    },
  ];

  return (
    <StyledTabs defaultActiveKey="1" tabPosition={tabPosition} onTabClick={handleTabClick}>
      {items.map(item => (
        <TabPane tab={item.label} key={item.key}>
          {item.children}
        </TabPane>
      ))}
    </StyledTabs>
  );
};

export default IndexSetting;
