import React from "react";
import styled from "styled-components";
import { ReactComponent as Facebook } from "images/vendor/Facebook.svg";
import { ReactComponent as Instagram } from "images/vendor/Instagram.svg";
import { ReactComponent as Linkedin } from "images/vendor/Linkedin.svg";
import { ReactComponent as Twitter } from "images/vendor/Twitter.svg";
import SubmitButton from "./Button";
import { SubmitContainer } from "./AccountDetails";
import { Form, Input } from "antd";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 867px;
  max-width: 100%;
  gap: 40px;
`;

const SocialContainer = styled.div`
  width: 100%;
  height: auto;
  border-radius: 8px;
  border: 1px solid rgba(226, 232, 240, 1);
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const LinkContainer = styled(Input)`
  width: 100%;
  height: 100%;
  border: 0;
  border-radius: 0px;
`;

const SocialName = styled.div`
  display: flex;
  background-color: rgba(226, 232, 240, 1);
  width: 157px;
  height: auto;
  align-items: center;
  justify-content: center;
  gap: 10px;
`;

const ParagraphText = styled.p`
  font-size: 0.8rem;
  font-weight: 400;
  color: rgba(89, 89, 89, 1);
  line-height: 1.5rem;
`;

const SocialAccounts: React.FC = () => {
  const [form] = Form.useForm();

  const onFinish = (values: any) => {};

  return (
    <Container>
      <Form layout="vertical" form={form} onFinish={onFinish} initialValues={{ remember: true }}>
        <ParagraphText>
          Link your business&apos;s social media handles for organizers to authenticate your services.
        </ParagraphText>

        <Form.Item name="twitter">
          <SocialContainer>
            <SocialName>
              <ParagraphText>X/Twitter</ParagraphText> <Twitter />
            </SocialName>
            <LinkContainer placeholder="Enter Twitter link" style={{ paddingLeft: "14px" }} />
          </SocialContainer>
        </Form.Item>

        <Form.Item name="facebook">
          <SocialContainer>
            <SocialName>
              <ParagraphText>Facebook</ParagraphText> <Facebook />
            </SocialName>
            <LinkContainer placeholder="Enter Facebook link" style={{ paddingLeft: "14px" }} />
          </SocialContainer>
        </Form.Item>
        <Form.Item name="linkedin">
          <SocialContainer>
            <SocialName>
              <ParagraphText>LinkedIn</ParagraphText> <Linkedin />
            </SocialName>
            <LinkContainer placeholder="Enter LinkedIn link" style={{ paddingLeft: "14px" }} />
          </SocialContainer>
        </Form.Item>

        <Form.Item name="instagram">
          <SocialContainer>
            <SocialName>
              <ParagraphText>Instagram</ParagraphText> <Instagram />
            </SocialName>

            <LinkContainer placeholder="Enter Instagram link" style={{ paddingLeft: "14px" }} />
          </SocialContainer>
        </Form.Item>
        <SubmitContainer>
          <SubmitButton form={form}> Save Changes</SubmitButton>
        </SubmitContainer>
      </Form>
    </Container>
  );
};

export default SocialAccounts;
