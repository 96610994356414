import React from "react";
import styled from "styled-components";
import { LeftOutlined } from "@ant-design/icons";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import { routeNames } from "lib/routes";
import type { FormInstance } from "antd";
import { Button, Form, Input } from "antd";
import { useForgotPassword } from "lib/hooks";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

const Back = styled.div`
  color: #1390e6;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Header = styled.h3`
  font-size: 21px;
  font-weight: 500;
  margin-bottom: 0;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
`;

const LargeText = styled.p<{ selected: boolean }>`
  font-size: 13px;
  color: ${({ selected }) => (selected ? "#1C5B87" : "black")};
`;

const SmallText = styled.p<{ selected: boolean }>`
  font-size: 12px;
  color: ${({ selected }) => (selected ? "#1C5B87" : "#9c9c9c")};
  margin-top: 0;
`;

interface SubmitButtonProps {
  form: FormInstance;
}

const SubmitButton: React.FC<React.PropsWithChildren<SubmitButtonProps>> = ({ form, children }) => {
  const [submittable, setSubmittable] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  const handleClick = () => {
    setLoading(true);
    form.submit();
  };

  return (
    <Button
      type="primary"
      htmlType="submit"
      disabled={!submittable || loading}
      style={{
        width: "100%",
        color: "white",
        border: "none",
        fontSize: "13px",
        backgroundColor: loading ? "#1C5B87" : "",
      }}
      size="large"
      onClick={handleClick}
    >
      {loading ? "Please wait..." : children}
    </Button>
  );
};

const ForgotPasswordPage: React.FC = () => {
  const [form] = Form.useForm();
  const { mutate: forgotPassword, isSuccess, isError, error } = useForgotPassword();
  const navigate = useNavigate();

  const onFinish = (values: any) => {
    forgotPassword(values, {
      onSuccess: () => {
        console.log("Form submitted successfully");
        navigate(routeNames.authResetPassword);
      },
    });
  };

  return (
    <Container>
      <StyledLink to={routeNames.authLogin}>
        <Back>
          <LeftOutlined /> Back
        </Back>
      </StyledLink>
      <Top>
        <Header>Forgot Password?</Header>
        <SmallText selected={false}>Oops, locked out? Reset your password in seconds!</SmallText>
      </Top>

      <Form
        layout="vertical"
        form={form}
        name="forgotPassword"
        scrollToFirstError
        requiredMark={false}
        onFinish={onFinish}
      >
        <Form.Item
          label="Email Address"
          name="email"
          rules={[
            {
              type: "email",
              message: "The input is not valid E-mail!",
            },
            {
              required: true,
              message: "Please input your E-mail!",
            },
          ]}
        >
          <Input placeholder="e.g example@email.com" style={{ fontSize: "14px", color: "#9C9C9C" }} />
        </Form.Item>
        <Form.Item>
          <SubmitButton form={form}>Send code to reset password</SubmitButton>
        </Form.Item>
        {isError && <p style={{ color: "red" }}>{(error as Error).message}</p>}
        {isSuccess && <p style={{ color: "green" }}>Request successful, please check your email.</p>}
      </Form>
    </Container>
  );
};

export default ForgotPasswordPage;
