import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { sessionStorageKeys, tqMutationKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";
import { ISODateString, UserType } from "ui/types";

type SignupPayload = {
  firstName: string;
  lastName: string;
  phone: string;
  email: string;
  password: string;
  userType: UserType;
};

type SignupResponse = {
  id: number;
  createdAt: string;
  updatedAt: string;
  firstName: string;
  lastName: string;
  email: string;
  verified: boolean;
  organizer?: object | null;
};

type UseSignupResult = UseMutationResult<SignupResponse, Error, SignupPayload>;

export function useSignup(): UseSignupResult {
  return useMutation({
    mutationFn: async (payload: SignupPayload): Promise<SignupResponse> => {
      const userType = sessionStorage.getItem(sessionStorageKeys.userType);
      const res = await EvendyApi.post<SignupResponse>("users", payload);
      return res.data;
    },
    mutationKey: [tqMutationKeys.users.signup],
    onError(signupError: Error) {
      console.log({ signupError });
    },
    onSuccess(data) {
      sessionStorage.setItem(sessionStorageKeys.userId, data.id.toString());
      sessionStorage.setItem(sessionStorageKeys.email, data.email);
    },
  });
}
