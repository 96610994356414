import React from "react";
import styled from "styled-components";
import { ReactComponent as Ticket } from "images/landingpage/services/ticketing.svg";
import { ReactComponent as EventF } from "images/landingpage/services/event.svg";
import { ReactComponent as EventM } from "images/landingpage/services/eventmarketing.svg";
import { ReactComponent as EventMan } from "images/landingpage/services/eventmanagement.svg";
import { ReactComponent as Analyticsimg } from "images/landingpage/services/analytics.svg";
import { ReactComponent as VendorS } from "images/landingpage/services/vendorsourcing.svg";
import { ReactComponent as Arrow } from "images/landingpage/services/arrow-right.svg";
import { ReactComponent as Overlay } from "images/landingpage/services/Management.svg";
import Hover from "images/landingpage/services/Vector4.png";
import Left from "images/landingpage/services/left.png";
import Right from "images/landingpage/services/right.png";
import { AppLink } from "ui/components/base";
import { routeNames } from "lib/routes";

const ServicesContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
`;

const ServicesTop = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;

const ServicesBottom = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Experience = styled.button`
  background-color: #7ef88b33;
  color: #168f43;
  border: none;
  padding: 8px 24px 8px 24px;
  border-radius: 16px;
  margin-bottom: 1rem;
  font-size: 14px;
  font-weight: 600;
  line-height: 15.08px;
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 0;
`;

const LargeText = styled.h3`
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

const SmallText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  color: #595959;
`;

const Services = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: 3rem;
  align-items: center;
  margin-bottom: 4rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ServiceImage = styled.div``;

const ServicesLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  background-color: #f9fafb80;
  padding: 2rem;
  border-radius: 16px;
`;

const ServiceRight = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
  background-color: #f9fafb80;
  padding: 2rem;
  border-radius: 16px;
`;

const Service = styled(AppLink)`
  text-decoration: none;
  outline: none;
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const MediumBold = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  z-index: 10;
`;

const Medium = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 17.6px;
  text-align: left;
  color: #595959;
  margin-top: 0;
  margin-bottom: 0;
`;

const Small = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.7rem;
`;

const RightSide = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
`;

const HoverContainer = styled.div`
  position: absolute;
  top: -0.5rem;
  left: 3rem;
  visibility: hidden;
  opacity: 0;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;

  ${Service}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;

const ImageContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 369px;
  height: 492px;
  border-radius: 16px;

  img {
    width: 100%;
    height: 100%;
    transition: transform 0.5s ease;
  }

  ${ServicesContainer}:hover & img {
    transform: scale(1.1);
  }
`;

const OverlayImage = styled.div`
  position: absolute;
  bottom: -2.5rem;
  right: 0;
  z-index: 1;
  height: auto;

  /* Add a transition effect for the bottom position */
  transition: bottom 0.5s ease;

  ${ServicesContainer}:hover & {
    bottom: -0.4rem; /* New position on hover */
  }
`;

const RightSpiralContainer = styled.div`
  position: absolute;
  right: 0;
  bottom: 0;
  @media (max-width: 768px) {
    display: none;
  }
`;

const LeftSpiralContainer = styled.div`
  position: absolute;
  @media (max-width: 768px) {
    display: none;
  }
`;

const NewLandingPageServices: React.FC = () => {
  return (
    <ServicesContainer>
      <ServicesTop>
        <Experience>SERVICES - CREATE A BETTER EVENT EXPERIENCE</Experience>
        <LargeText>Achieve more, stress less</LargeText>
        <SmallText>Improve the Experience of your Event</SmallText>
      </ServicesTop>
      <ServicesBottom>
        <Services>
          <ServicesLeft>
            <Service to={routeNames.ticketingPage}>
              <Ticket />
              <RightSide>
                <HoverContainer>
                  <img src={Hover} alt="Service Content" />
                </HoverContainer>
                <MediumBold>Ticketing</MediumBold>
                <Medium>
                  Manage your event ticketing
                  <br /> and registration with ease
                </Medium>
                <Small>
                  Learn More <Arrow />
                </Small>
              </RightSide>
            </Service>
            <Service to={routeNames.financialServices}>
              <EventF />
              <RightSide>
                <HoverContainer>
                  <img src={Hover} alt="Service Content" />
                </HoverContainer>
                <MediumBold>Event Financing</MediumBold>
                <Medium>
                  Finance and manage your
                  <br /> event with ease.
                </Medium>
                <Small>
                  Learn More <Arrow />
                </Small>
              </RightSide>
            </Service>
            <Service to={routeNames.eventMarketing}>
              <EventM />
              <RightSide>
                <HoverContainer>
                  <img src={Hover} alt="Service Content" />
                </HoverContainer>
                <MediumBold>Event Marketing</MediumBold>
                <Medium>
                  Get your event across to the
                  <br /> right audience
                </Medium>
                <Small>
                  Learn More <Arrow />
                </Small>
              </RightSide>
            </Service>
          </ServicesLeft>
          <ServiceImage>
            <ImageContainer>
              <img src="Images/Landingpage/landingpageservice.jpg" alt="Service Content" />
              <OverlayImage>
                <img src="/Images/Landingpage/Management.svg" />
              </OverlayImage>
            </ImageContainer>
          </ServiceImage>
          <ServiceRight>
            <Service to={routeNames.eventManagement}>
              <EventMan />
              <RightSide>
                <HoverContainer>
                  <img src={Hover} alt="Service Content" />
                </HoverContainer>
                <MediumBold>Event Management</MediumBold>
                <Medium>
                  Centralised tools keep your
                  <br /> entire event & team aligned
                </Medium>
                <Small>
                  Learn More <Arrow />
                </Small>
              </RightSide>
            </Service>
            <Service to={routeNames.analysisPage}>
              <Analyticsimg />
              <RightSide>
                <HoverContainer>
                  <img src={Hover} alt="Service Content" />
                </HoverContainer>
                <MediumBold>Analytics</MediumBold>
                <Medium>
                  Collect insights from your
                  <br /> guest and use them to make
                  <br /> data driven decisions
                </Medium>
                <Small>
                  Learn More <Arrow />
                </Small>
              </RightSide>
            </Service>
            <Service to={routeNames.eventVendorSourcing}>
              <VendorS />
              <RightSide>
                <HoverContainer>
                  <img src={Hover} alt="Service Content" />
                </HoverContainer>
                <MediumBold>Vendor Sourcing</MediumBold>
                <Medium>
                  Find verified vendors for all
                  <br /> your event needs.
                </Medium>
                <Small>
                  Learn More <Arrow />
                </Small>
              </RightSide>
            </Service>
          </ServiceRight>
        </Services>
      </ServicesBottom>
      <LeftSpiralContainer>
        {" "}
        <img src={Left} alt="Service Content" />
      </LeftSpiralContainer>
      <RightSpiralContainer>
        {" "}
        <img src={Right} alt="Service Content" />
      </RightSpiralContainer>
    </ServicesContainer>
  );
};

export default NewLandingPageServices;
