import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { tqMutationKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";

type ResetPasswordPayload = {
  token: string;
  password: string;
};

type ResetPasswordResult = UseMutationResult<void, Error, ResetPasswordPayload>;

export function useResetPassword(): ResetPasswordResult {
  return useMutation({
    mutationFn: async (payload: ResetPasswordPayload) => {
      const { token, password } = payload;
      const res = await EvendyApi.post<Promise<void>>("auth/reset-password", { token, password });
      return res.data;
    },
    mutationKey: [tqMutationKeys.auth.resetPassword],
    onError(resetPasswordError: Error) {
      console.log({ resetPasswordError });
    },
    onSuccess() {
      console.log("Password reset successful");
    },
  });
}
