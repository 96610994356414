import { Layout } from "antd";
import React, { useState } from "react";
import styled, { css } from "styled-components";
import { layoutHeight } from "ui/theme";
import { RightComponent, RightComponentProps } from "./RightComponent";
import { SearchOutlined } from "@ant-design/icons";

const { Header: AntHeader } = Layout;

const StyledHeader = styled(AntHeader)`
  height: ${layoutHeight.header}px;
  display: flex;
  padding: 24px 40px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  position: sticky;
  top: 0;
  z-index: 1;
  width: 100%;
  display: flex;
  align-items: center;

  opacity: 0.98;
  background: var(--LM-White, #fff);
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.03);
  backdrop-filter: blur(30px);
`;
// const Title = styled.div`
//   text-transform: capitalize;
//   color: var(--gray-950, #0c111d);
//   font-size: 18px;
//   font-style: normal;
//   font-weight: 500;
//   line-height: 28px; /* 155.556% */
// `;

const SearchContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  padding: 0px 24px;
  width: 545px;
  height: 44px;
`;

const SearchInput = styled.div`
  display: flex;
  padding: 12px 16px;
  align-items: center;
  flex: 1 0 0;
  align-self: stretch;
  width: 100%;
  background: #f5f7fb;
  justify-content: center;
  border-radius: 24px;
  border: 1px solid var(--LM-Grey-4, #ededed);
`;
const StyledInput = styled.input<{ hasContent: boolean }>`
  border: none;
  background: inherit;
  flex: ${props => (props.hasContent ? "1" : "none")};
  outline: none;
`;

const SearchIcon = styled(SearchOutlined)`
  margin-right: 8px;
`;
const Brand = styled.div<{ collapsed?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  align-self: stretch;
`;

export const BrandTop = styled.div``;
export const BrandBottom = styled.div`
  position: absolute;
`;

export const LogoImg = styled.img`
  width: fit-content;
  height: 32px;
`;

export const LogoText = styled.span`
  font-family: "BR Firma";
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0em;
  text-align: left;
  color: #1390e6;
`;

export const LogoSubtext = styled.span`
  //styleName: Caption;
  font-family: "BR Firma";
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: left;
`;
export interface AppHeaderProps extends RightComponentProps {
  title?: React.ReactNode;
  collapsed?: boolean;
}
export const AppHeader: React.FC<AppHeaderProps> = function ({ title, collapsed, ...props }) {
  const [inputContent, setInputContent] = useState("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputContent(e.target.value);
  };

  return (
    <StyledHeader>
      <Brand collapsed={collapsed}>
        <BrandTop>
          <LogoImg src="/assets/Logomark.svg" alt="event management" />
          <LogoText>Evendy</LogoText>
        </BrandTop>
        <BrandBottom>
          <LogoSubtext>Organizer</LogoSubtext>
        </BrandBottom>
      </Brand>
      {/* <Title>what</Title> */}
      <SearchContainer>
        <SearchInput>
          <SearchIcon />
          <StyledInput
            type="text"
            placeholder="Search here"
            value={inputContent}
            onChange={handleInputChange}
            hasContent={inputContent.length > 0}
          />
        </SearchInput>
      </SearchContainer>
      <RightComponent {...props} />
    </StyledHeader>
  );
};
