import React, { useEffect, useState } from "react";
import { Form, Input, message } from "antd";
import styled from "styled-components";
import SubmitButton from "../Button";
import { SubmitContainer } from "../AccountDetails";
import { ReactComponent as Checkbox } from "images/vendor/Checkbox.svg";
import { useChangePassword } from "lib/hooks/auth/useChangePassword";
import { sessionStorageKeys } from "lib/constants";
import { jwtDecode } from "jwt-decode";
import { JwtPayload } from "jwt-decode";
import { useGetUser } from "lib/hooks";

const Container = styled.div`
  width: 100%;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
`;

const PasswordInput = styled(Input.Password)`
  display: flex;
  align-items: center;
  width: 100%;
  height: 56px;
  .ant-input-password-icon {
    margin-left: auto;
  }
`;

const Note = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
  color: #666;
  font-size: 0.875rem;
`;

const CheckboxIcon = styled(Checkbox)`
  margin-right: 5px;
`;

const EmailInput = styled(Input)`
  background-color: #d9d9d9;
  color: #9c9c9c;
`;

interface PasswordFormValues {
  emailAddress: string;
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}

const Password: React.FC = () => {
  console.log("what");
  const [form] = Form.useForm();
  const [email, setEmail] = useState<string>(""); // Initialize with an empty string
  const { data } = useGetUser();
  const { mutate: changePassword } = useChangePassword();

  useEffect(() => {
    const accessToken = sessionStorage.getItem(sessionStorageKeys.accessToken);
    if (accessToken) {
      try {
        const decodedToken = jwtDecode<JwtPayload & { email: string }>(accessToken);
        setEmail(decodedToken.email || ""); // Fallback to empty string if email is undefined
      } catch (error) {
        console.error("Error decoding token:", error);
      }
    }
  }, []);

  const onFinish = (values: PasswordFormValues) => {
    console.log(values); // Log form values to debug

    const { oldPassword, newPassword, confirmPassword } = values;

    if (newPassword !== confirmPassword) {
      message.error("New password and confirm password do not match!");
      return;
    }

    changePassword(
      { password: oldPassword, newPassword, email: "afolabiadedeji110@yahoo.com" },
      {
        onSuccess: () => {
          message.success("Password changed successfully!");
          form.resetFields();
        },
        onError: error => {
          message.error(`Failed to change password: ${(error as Error).message}`);
        },
      },
    );
  };

  useEffect(() => {
    if (data?.email) {
      setEmail(data.email);
    }
  }, [data?.email]);

  return (
    <Container>
      <Form form={form} layout="vertical" onFinish={onFinish} requiredMark={false}>
        <Form.Item
          label={<Label>Old Password</Label>}
          name="oldPassword"
          //rules={[{ required: true, message: "Please input your old password!" }]}
        >
          <PasswordInput placeholder="Old Password" style={{ paddingLeft: "14px" }} />
        </Form.Item>
        <Form.Item
          label={<Label>New Password</Label>}
          name="newPassword"
          //rules={[{ required: true, message: "Please input your new password!" }]}
        >
          <PasswordInput placeholder="New Password" style={{ paddingLeft: "14px" }} />
        </Form.Item>
        <Form.Item
          label={<Label>Confirm Password</Label>}
          name="confirmPassword"
          // dependencies={["newPassword"]}
          rules={[
            { required: true, message: "Please confirm your new password!" },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || !getFieldValue("newPassword")) {
                  return Promise.resolve();
                }
                if (getFieldValue("newPassword") === value) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error("Passwords do not match!"));
              },
            }),
          ]}
        >
          <PasswordInput placeholder="Confirm Password" style={{ paddingLeft: "14px" }} />
        </Form.Item>
        <Form.Item>
          <SubmitContainer>
            <SubmitButton form={form}>Save Changes</SubmitButton>
          </SubmitContainer>
        </Form.Item>
      </Form>
    </Container>
  );
};

export default Password;
