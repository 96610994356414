import React, { useMemo } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { Checkbox, CheckboxProps, Select, Divider, Input, Button, message, notification } from "antd";
import { useEffect, useState } from "react";
import { ReactComponent as Unitprice } from "images/vendorprofile/Frameunitprice.svg";
import { ReactComponent as Upfront } from "images/vendorprofile/Frameupfront.svg";
import { ReactComponent as Notice } from "images/vendorprofile/Vectornotice.svg";
import { ReactComponent as Total } from "images/vendorprofile/Frametotal.svg";
import { ReactComponent as Upfrontamount } from "images/vendorprofile/Frame.svg";
import { AppModal } from "ui/components/base";
import { useGetVendorById, useGetVendorPackagesByEmail, useCreateVendorRequest } from "lib/hooks";
import { useGetUsers } from "lib/hooks";
import { getSession } from "lib/utils/utils";
import { useGetOrganizerEvents } from "lib/hooks/event/useGetOrganizerEvents";

export const Text = styled.p`
  font-size: 14px;
`;

export const ModalBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  margin-left: 1rem;
  margin-right: 1rem;
  width: 100%;
`;

export const NoticeDisplay = styled.button`
  background-color: #fefef9;
  color: black;
  border: 1px solid;
  border-color: #fccd33;
  border-radius: 8px;
  padding-left: 0.5rem;
  font-size: 13px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 0.5rem;
`;

export const NoticeText = styled.p`
  text-align: left;
  font-size: 12px;
  line-height: 20px;
  font-weight: 400;
`;

export const Label = styled.p`
  color: #595959;
`;

export const StyledButton = styled(Button)`
  margin-top: 1rem;
  background-color: #1390e6;
  color: white;
  border: 1px solid;
  border-color: #1390e6;
  border-radius: 8px;
  padding: 10px 10px;
  font-size: 13px;
  width: 100%;
`;

export const CustomRequestContainer = styled.div`
  border-top: 1px solid;
  border-left: 1px solid;
  border-right: 1px solid;
  border-color: #f1f1f1;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
`;

const PackagesContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SelectPackage = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  margin-top: -1rem;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: start;
    gap: 0;
    margin-bottom: 0.5rem;
  }
`;

const StyledLink = styled.span`
  color: #1390e6;
  cursor: pointer;

  &:hover {
    color: #1097f1;
  }
`;

const Package = styled.div`
  border: 1px solid;
  border-color: #f1f1f1;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  margin-bottom: 0.5rem;
`;

const Line1 = styled.div``;

const Line2 = styled.div`
  margin-top: 0.5rem;
`;

const Line3 = styled.div`
  margin-top: -0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const Line4 = styled.div`
  margin-top: -1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const Proceed = styled.button`
  background-color: #1390e6;
  color: white;
  border: 1px solid;
  border-color: #1390e6;
  border-radius: 8px;
  padding: 10px 10px;
  font-size: 13px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  cursor: pointer;
`;

const SelectedPackage = styled.div`
  border: 1px solid;
  border-color: #f1f1f1;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  margin-top: 0.5rem;
  padding-bottom: 0;
`;

const StyledDivider = styled(Divider)`
  margin-top: 0rem;
`;

const Line11 = styled.div`
  font-weight: 500;
`;

const Line12 = styled.div`
  color: #595959;
`;

const ItemContainer = styled.div`
  padding-bottom: 1rem;
`;

const TotalAmount = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const UpfrontAmount = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -1rem;
`;

const TotalLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

const TotalRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 5px;
`;

interface Props {}

interface Package {
  id: number;
  title: string;
  description: string;
  price: number;
  upfrontPayment: number;
}

interface Vendor {
  packages: Package[];
  vendorEmail: string;
}

interface PostVendorRequestPayload {
  eventId: number;
  organizerEmail: string;
  packageId: number;
  vendorEmail: string;
  quantity: number;
  status: "pending";
}

const Packages: React.FC<Props> = props => {
  const [userId, setUserId] = useState<number | null>(null);
  const [organizerId, setOrganizerId] = useState<number | null>(null);
  const [userEmail, setUserEmail] = useState<string>("");
  const [organizerEmail, setOrganizerEmail] = useState<string>("");
  const params = useParams<{ id?: string }>();
  const { data: vendor } = useGetVendorById({ id: userId || 0 });
  const { data: usersData, error: usersError } = useGetUsers();
  const { data } = useGetOrganizerEvents();
  const [selectedPackage, setSelectedPackage] = useState<number | null>(null);
  const [selectedPackageDetails, setSelectedPackageDetails] = useState<Package | null>(null);
  const [quantities, setQuantities] = useState<{ [key: number]: number }>({});
  const [requestmodalOpen, setrequestModalOpen] = useState(false);
  const [custommodalOpen, setcustomModalOpen] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedEventId, setSelectedEventId] = useState<number>(0);
  const { TextArea } = Input;

  const organizerEvents = data?.events || [];

  const options = useMemo(() => {
    const eventsOptions = organizerEvents?.map(event => ({
      value: event.id,
      label: event.title,
    }));
    return [{ value: 0, disabled: true, label: "Select an Event" }, ...eventsOptions];
  }, [organizerEvents]);

  useEffect(() => {
    const session = getSession();
    if (session) {
      setOrganizerId(session.userId);
    }
  }, []);

  useEffect(() => {
    if (params.id) {
      const id = parseInt(params.id);
      if (id) {
        setUserId(id);
      }
    }
  }, [params.id]);

  useEffect(() => {
    if (vendor) {
      const email = vendor.vendorEmail;
      console.log("this is the vendor email", email);
      setUserEmail(email);
    }
  }, [vendor]);

  useEffect(() => {
    if (organizerId && usersData) {
      const user = usersData.find(user => user.id === organizerId);
      if (user?.email) {
        setOrganizerEmail(user.email);
      }
    }
    if (usersError) {
      console.error("Error organizer email:", usersError);
    }
  }, [organizerId, usersData, usersError]);

  const { data: packagesData } = useGetVendorPackagesByEmail({ email: userEmail });

  const warningNoSelection = () => {
    messageApi.open({
      type: "warning",
      content: "Please select one package before proceeding",
    });
  };

  const warningMultipleSelection = () => {
    messageApi.open({
      type: "warning",
      content: "Please select only one package",
    });
  };

  const handlerequestModalOpen = () => {
    if (!selectedPackage) {
      warningNoSelection();
      return;
    }
    setrequestModalOpen(true);
    const selectedPackageDetail = packagesData?.find((pack: Package) => pack.id === selectedPackage) || null;
    setSelectedPackageDetails(selectedPackageDetail);
  };

  const handlerequestModalClose = () => {
    setrequestModalOpen(false);
  };

  const handlecustomModalOpen = () => {
    setcustomModalOpen(true);
  };

  const handlecustomModalClose = () => {
    setcustomModalOpen(false);
  };

  const handleCheckboxChange = (id: number) => {
    if (selectedPackage === id) {
      setSelectedPackage(null); // Unselect if the same package is clicked again
    } else if (selectedPackage === null) {
      setSelectedPackage(id); // Select if no package was selected
    } else {
      warningMultipleSelection(); // Show warning if another package is selected
    }
  };

  const handleEventSelect = (eventValue: number) => {
    setSelectedEventId(eventValue);
  };

  const handleQuantityChange = (e: React.ChangeEvent<HTMLInputElement>, packageItem: Package) => {
    const quantity = parseInt(e.target.value) || 0;
    setQuantities(prev => ({
      ...prev,
      [packageItem.id]: quantity,
    }));
  };

  const totalAmount = selectedPackageDetails
    ? (quantities[selectedPackageDetails.id] || 0) * selectedPackageDetails.price
    : 0;

  const totalUpfrontPayment = (totalAmount / 100) * (selectedPackageDetails?.upfrontPayment || 0);

  const { mutate: createRequest } = useCreateVendorRequest();

  const handleCreateRequest = () => {
    if (!selectedEventId) {
      messageApi.warning("Kindly select an event this reques is being made for");
      return;
    }
    if (selectedPackage && selectedPackageDetails && selectedEventId) {
      const payload: PostVendorRequestPayload = {
        eventId: selectedEventId,
        organizerEmail,
        packageId: selectedPackage,
        vendorEmail: userEmail,
        quantity: quantities[selectedPackage],
        status: "pending",
      };

      createRequest(payload, {
        onSuccess: () => {
          setrequestModalOpen(false);
          messageApi.success("Request sent successfully!");
        },
        onError: (error: any) => {
          console.error("Error creating package:", error);
          notification.error({ message: error?.response?.data?.message || error.message });
        },
      });
    }
  };

  return (
    <>
      {contextHolder}
      {vendor ? (
        <PackagesContainer>
          <SelectPackage>
            <Text>Select a package you want to request for or</Text>
            <StyledLink onClick={handlecustomModalOpen}>make a custom request</StyledLink>
          </SelectPackage>

          {packagesData?.map(packages => (
            <Package key={packages.id}>
              <Line1>
                <Checkbox checked={selectedPackage === packages.id} onChange={() => handleCheckboxChange(packages.id)}>
                  {packages.title}
                </Checkbox>
              </Line1>
              <Line2>{packages.description}</Line2>
              <Line3>
                <Unitprice />
                <Text>Unit price: NGN {packages.price}</Text>
              </Line3>
              <Line4>
                <Upfront />
                <Text>Upfront payment: {packages.upfrontPayment}%</Text>
              </Line4>
            </Package>
          ))}

          <Proceed onClick={handlerequestModalOpen}>Proceed</Proceed>

          <AppModal
            open={requestmodalOpen}
            onCancel={handlerequestModalClose}
            header={<span>Request for packages</span>}
            size="md"
          >
            <ModalBodyContainer>
              <NoticeDisplay>
                <Notice />
                <NoticeText>
                  Choose event and set package quantity based on unit price. For negotiations, create a custom request
                  with preferred price and quantity for vendor approval
                </NoticeText>
              </NoticeDisplay>
              <Label>Event</Label>
              <Select options={options} onChange={handleEventSelect} />

              <SelectedPackage>
                {selectedPackageDetails && (
                  <ItemContainer key={selectedPackageDetails.id}>
                    <Line11>{selectedPackageDetails.title}</Line11>
                    <Line12>{selectedPackageDetails.description}</Line12>
                    <Line3>
                      <Unitprice />
                      <Text>Unit price: NGN {selectedPackageDetails.price}</Text>
                    </Line3>
                    <StyledDivider />
                    <Label>Please specify desired quantity</Label>
                    <Input
                      placeholder="0"
                      type="number"
                      min="0"
                      value={quantities[selectedPackageDetails.id] || ""}
                      onChange={e => handleQuantityChange(e, selectedPackageDetails)}
                    />
                  </ItemContainer>
                )}

                <TotalAmount>
                  <TotalLeft>
                    <Total /> <NoticeText>Total amount:</NoticeText>
                  </TotalLeft>
                  <TotalRight>
                    <NoticeText>NGN {totalAmount}</NoticeText>
                  </TotalRight>
                </TotalAmount>
                <UpfrontAmount>
                  <TotalLeft>
                    <Upfrontamount /> <NoticeText>You’ll make an upfront payment of:</NoticeText>
                  </TotalLeft>
                  <TotalRight>
                    <NoticeText>NGN {totalUpfrontPayment}</NoticeText>
                  </TotalRight>
                </UpfrontAmount>
              </SelectedPackage>

              <StyledButton type="primary" size="large" onClick={handleCreateRequest}>
                Send request
              </StyledButton>
            </ModalBodyContainer>
          </AppModal>

          <AppModal
            open={custommodalOpen}
            onCancel={handlecustomModalClose}
            header={<span>Make a custom request</span>}
            size="md"
          >
            <ModalBodyContainer>
              <NoticeDisplay>
                <Notice />
                <NoticeText>
                  Choose event and set package quantity based on unit price. For negotiations, create a custom request
                  with preferred price and quantity for vendor approval
                </NoticeText>
              </NoticeDisplay>
              <Label>Event</Label>
              <Select defaultValue="Zhejiang" options={options} />
              <CustomRequestContainer>
                <Text>Description of Request</Text>
                <TextArea rows={4} placeholder="Briefly state details about your request" maxLength={10} />
                <Text>Desired quantity</Text>
                <Input placeholder="0" type="number" />
              </CustomRequestContainer>

              <StyledButton type="primary" size="large" disabled>
                Send request
              </StyledButton>
            </ModalBodyContainer>
          </AppModal>
        </PackagesContainer>
      ) : null}
    </>
  );
};

export default Packages;
