import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { tqMutationKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";

interface TicketDto {
  id: number;
  category: string;
  type: string;
  description: string;
  quantity: number;
  price: number;
  image: string;
  salesStartDate: string;
  salesEndDate: string;
  minQuantity: number;
  maxQuantity: number;
  eventId: number;
  createdAt: string;
  updatedAt: string;
}

interface PostTicketPayload {
  category: string;
  type: string;
  description: string;
  quantity: number;
  price: number;
  image: string;
  salesStartDate: string;
  salesEndDate: string;
  minQuantity: number;
  maxQuantity: number;
  eventId: number;
}

type TicketMutationResult = UseMutationResult<TicketDto, Error, PostTicketPayload>;

export function useCreateTicket(): TicketMutationResult {
  return useMutation({
    mutationKey: [tqMutationKeys.ticket.create],
    mutationFn: async (payload: PostTicketPayload): Promise<TicketDto> => {
      const res = await EvendyApi.post<TicketDto>("/ticket", payload);
      return res.data;
    },
  });
}
