import React from "react";
import styled from "styled-components";
import { ReactComponent as CloseAccountIcon } from "images/vendorprofile/CloseAccountIcon.svg";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { routeNames } from "lib/routes";
import { AppModal } from "ui/components/base";

const Section = styled.div`
  max-width: 443px;
  height: 345px;
  padding: 24px;
  gap: 24px;
  border-radius: 24px;
  border: 1px solid rgba(226, 232, 240, 1);
  flex-direction: column;
`;

type CloseEventModalProps = {
  open: boolean;
  onCancel: () => void;
  disabled?: boolean;
};

const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 20px;
`;

const Text = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 109px;
  margin-bottom: 20px;
`;

const HeaderText = styled.div`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  text-align: center;
  color: rgba(18, 18, 18, 1);
  margin-bottom: 20px;
`;

const Paragraph = styled.p`
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  text-align: center;
  color: rgba(89, 89, 89, 1);
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
`;

const CloseButton = styled(Button)`
  background-color: rgba(255, 250, 250, 1);
  color: rgba(176, 0, 0, 1);
  border-radius: 8px;
  border: none;
  height: 46px;
  margin-bottom: 10px;
`;

const StayButton = styled(Button)`
  border: 1px solid rgba(226, 232, 240, 1);
  color: rgba(89, 89, 89, 1);
  height: 46px;
`;

const CloseAccount: React.FC<CloseEventModalProps> = ({ open, onCancel }) => {
  return (
    <AppModal open={open} onCancel={onCancel} header={<span>Close Account</span>} size="md">
      <Section>
        <IconContainer>
          <CloseAccountIcon />
        </IconContainer>
        <Text>
          <HeaderText> Close Account?</HeaderText>
          <Paragraph>
            {" "}
            Sure you want to do this?😢 <br />
            Please be aware that deleting your account is irreversible, and recovery is impossible{" "}
          </Paragraph>
        </Text>
        <ButtonContainer>
          <CloseButton>Yes, close my account</CloseButton>
          <StayButton>
            <Link to={routeNames.organizerHome}> No, I am staying</Link>
          </StayButton>
        </ButtonContainer>
      </Section>
    </AppModal>
  );
};

export default CloseAccount;
