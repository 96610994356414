import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useGetVendorPackagesByEmail, useGetVendorById } from "lib/hooks";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { sessionStorageKeys } from "lib/constants";
import VendorPackageCard from "../mypackages/VendorPackageCard";

const Container = styled.div`
  margin: 1rem;
`;

const Packagecontainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 1rem;
`;

const CreatedPackages: React.FC = () => {
  const [userEmail, setUserEmail] = useState("");
  const [userId, setUserId] = useState(0);
  const accessToken = sessionStorage.getItem(sessionStorageKeys.accessToken);

  const { data: vendor } = useGetVendorById({ id: userId });

  useEffect(() => {
    if (accessToken) {
      const decodedToken = jwtDecode<JwtPayload & { userId: number }>(accessToken);
      const userId = decodedToken.userId;
      setUserId(userId);

      if (vendor) {
        const email = vendor.vendorEmail;
        setUserEmail(email);
      }
    } else {
      console.log("No access token found");
    }
  }, [accessToken, vendor]);

  const { data, isLoading, isError, error, refetch } = useGetVendorPackagesByEmail({ email: userEmail });

  useEffect(() => {
    if (isError) {
      console.error("Error fetching packages:", error);
    }
  }, [isError, error]);

  return (
    <Container>
      {isLoading ? (
        <p>Loading...</p>
      ) : isError || (data && data.length === 0) ? (
        <p>No packages found.</p>
      ) : (
        <Packagecontainer>
          {data?.map(pkg => <VendorPackageCard key={pkg.id} vendorpackage={pkg} onPackageAdded={refetch} />)}
        </Packagecontainer>
      )}
    </Container>
  );
};

export default CreatedPackages;
