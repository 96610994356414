import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Tabs } from "antd";
import { ReactComponent as Cam } from "images/vendor/Cam.svg";
import { ReactComponent as LocationSvg } from "images/vendor/Location.svg";
import AccountDetails from "./AccountDetails";
import PastEvents from "./PastEvent";
import Ratings from "./Ratings";
import SocialAccounts from "./SocialAccounts";
import Settings from "./Settings";
import Packages from "./Packages";
import { getSession } from "lib/utils/utils";
import { useGetVendorByEmail } from "lib/hooks/vendor/useGetVendorByEmail";

const Section = styled.div`
  margin-top: 1rem;
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-direction: column;
  @media (max-width: 768px) {
    margin-top: 3rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    width: 90%;
  }
`;

const TopContent = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const TopLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const CamContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: center;
  margin-bottom: -90px;
  margin-left: 85px;
`;

const LeftParagraph = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const BusinessName = styled.div`
  margin-top: 0;
  margin-bottom: 0;
`;

const LargText = styled.p`
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 768px) {
    font-size: 15px;
    line-height: 0;
  }
`;

const BusinessCategory = styled.div`
  background-color: #fafbff;
  color: #2943d6;
  border: 1px solid;
  border-color: #f1f1f1;
  border-radius: 8px;
  padding: 5px 10px;
  font-size: 10px;
  cursor: not-allowed;
  width: fit-content;
`;

const BusinessDescription = styled.div`
  font-size: 0.8rem;
  line-height: 1.2rem;
  font-weight: 400;
`;

const Circle = styled.div`
  width: 20vw;
  height: 20vw;
  max-width: 128px;
  max-height: 128px;
  border-radius: 50%;
  background-color: #1390e6;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.2rem;
  position: relative;

  @media (max-width: 768px) {
    font-size: 1rem;
  }

  @media (max-width: 480px) {
    width: 40vw;
    height: 40vw;
    font-size: 0.9rem;
  }
`;

const Location = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  gap: 10px;
`;

const TopRight = styled.div`
  display: flex;
  align-items: center;
`;

const Right = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const StyledButton = styled(Button)`
  width: 177px;
  height: 46px;
  border-radius: 8px;
  border: 1px solid rgba(226, 232, 240, 1);
  color: rgba(47, 47, 47, 1);
  font-weight: 500;
`;

const TabContainer = styled.div`
  display: flex;
  gap: 20px;
  color: rgba(89, 89, 89, 1);
  width: 100%;
  padding: 10px;
`;

const ScrollableTabContainer = styled.div`
  display: flex;
  overflow-x: auto;
  width: 100%;
  padding: 10px;

  /* Hide scrollbar */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Safari and Chrome */
  }
`;

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const VendorProfile: React.FC = () => {
  const [userId, setUserId] = useState<number | null>(null);
  const [buisnessname, setBuisnessname] = useState<string>("");
  const [location, setLocation] = useState<string>("");
  const [initials, setInitials] = useState<string>("");

  const { data: vendorData, error: vendorError, refetch: refetchVendor } = useGetVendorByEmail();

  useEffect(() => {
    if (vendorData) {
      setBuisnessname(vendorData.businessName);

      const city = vendorData.city || "";
      const state = vendorData.state || "";

      if (city && state) {
        setLocation(`${city}, ${state}.`);
      } else {
        setLocation(" _ _ ");
      }
    }
  }, [vendorData]);

  const items = [
    {
      key: "1",
      label: "Account Details",
      children: <AccountDetails refetchVendor={refetchVendor} />,
    },
    {
      key: "2",
      label: "Past Events",
      children: <PastEvents />,
    },
    {
      key: "3",
      label: "Packages",
      children: <Packages />,
    },
    {
      key: "4",
      label: "Ratings & Reviews",
      children: <Ratings />,
    },
    {
      key: "5",
      label: "Social Accounts",
      children: <SocialAccounts />,
    },
    {
      key: "6",
      label: "Settings",
      children: <Settings />,
    },
  ];

  return (
    <Section>
      <TopContent>
        <TopLeft>
          <Circle>
            {initials}
            <CamContainer>
              <Cam />
            </CamContainer>
          </Circle>
          <LeftParagraph>
            <BusinessName>
              <LargText>{buisnessname}</LargText>
            </BusinessName>
            <Location>
              <LocationSvg /> {location}
            </Location>
          </LeftParagraph>
        </TopLeft>
        <TopRight>
          <Right>
            <StyledButton>View public profile</StyledButton>
          </Right>
        </TopRight>
      </TopContent>
      <ScrollableTabContainer>
        <Tabs defaultActiveKey="1" items={items} style={{ width: "100%" }} />
      </ScrollableTabContainer>
    </Section>
  );
};

export default VendorProfile;
