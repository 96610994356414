import React, { useState, useEffect } from "react";
import IndexSetting from "./profilesettings/indexSetting";

const Settings: React.FC = () => {
  return (
    <>
      <IndexSetting />
    </>
  );
};

export default Settings;
