import React from "react";
import PhoneInput from "antd-phone-input";
import {
  Button,
  ButtonProps,
  DatePicker,
  DatePickerProps,
  Form as AntForm,
  Input,
  InputProps,
  Select,
  SelectProps,
  Switch,
  SwitchProps,
  TreeSelect,
  TreeSelectProps,
  Row,
  Col,
  ColProps,
  Checkbox,
  CheckboxProps,
  TimePicker,
  TimePickerProps,
} from "antd";
import type { FormInstance, FormItemProps, FormProps } from "antd/es/form";
import { TextAreaProps } from "antd/es/input";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { NamePath } from "antd/es/form/interface";
import { FileDragIn, FileDragProps } from "./Upload";
import { Link, LinkProps } from "react-router-dom";
import { TagInput, TagInputProps } from "../TagInput";
import TimezoneSelect, { allTimezones, Props as TimeZoneSelectProps } from "react-timezone-select";

const { Option } = Select;

export type FormLayoutType = Parameters<typeof AntForm>[0]["layout"];

type SelectField = {
  field: SelectProps;
  options: { value: string; label: string }[];
};
export enum FORM_FIELD_TYPES {
  TEXT,
  RADIO,
  CHECK,
  SELECT,
  FIELDS,
  BUTTON,
  DATE,
  TIME,
  TEXT_AREA,
  SWITCH,
  TREE_SELECT,
  HIDDEN,
  LIST,
  UPLOAD,
  PHONE,
  LINK,
  TAG_INPUT,
  SELECT_TIMEZONE,
}
type BtnProps = ButtonProps;
type PhoneInputProps = Parameters<typeof PhoneInput>[0];
export type IFieldProps =
  | InputProps
  | TextAreaProps
  | DatePickerProps
  | SelectProps
  | SelectField
  | BtnProps
  | SwitchProps
  | TreeSelectProps
  | FileDragProps
  | PhoneInputProps
  | CheckboxProps
  | LinkProps
  | TagInputProps
  | TimePickerProps
  | TimeZoneSelectProps;

export type IFieldInputProps = InputProps;
export type IFieldTextAreaProps = TextAreaProps;
export type IFieldDatePickerProps = DatePickerProps;
export type IFieldSelectProps = SelectProps;
export type IFieldSelectField = SelectField;
export type IFieldBtnProps = BtnProps;
export type IFieldSwitchProps = SwitchProps;
export type IFieldTreeSelectProps = TreeSelectProps;
export type IFieldFileDragProps = FileDragProps;
export type IFieldPhoneInputProps = PhoneInputProps;
export type IFieldCheckboxProps = CheckboxProps;
export type IFieldLinkProps = LinkProps;
export type IFieldTagInputProps = TagInputProps;
export type IFieldTimePickerProps = TimePickerProps;
export type IFieldTimeZoneSelectProps = TimeZoneSelectProps;

export interface IFieldsProps {
  fieldProps: IFieldProps;
  fieldType: FORM_FIELD_TYPES;
  itemProps?: FormItemProps;
  colProps?: ColProps;
}
export interface IFormItems {
  itemProps: FormItemProps;
  fieldProps?: IFieldProps | IFieldsProps[] | IFormItems;
  itemFunc?: (
    props?: Partial<FormInstance>,
    fieldForm?: React.FC<IFormItems>,
    fieldData?: IFormItems,
  ) => React.ReactNode | null;
  fieldType: FORM_FIELD_TYPES;
  form?: FormInstance;
  isEdit?: boolean;
}
export const FormFields: React.FC<IFormItems> = ({ fieldType, fieldProps, itemProps, itemFunc, form, isEdit }) => {
  const { getFieldValue } = form || {};
  switch (fieldType) {
    case FORM_FIELD_TYPES.TAG_INPUT:
      return (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 10,
            marginBottom: 20,
          }}
        >
          <label>{itemProps.label}</label>
          <TagInput {...(fieldProps as IFieldTagInputProps)} />
        </div>
        // <AntForm.Item noStyle {...itemProps} label="Perks">
        // </AntForm.Item>
      );
    case FORM_FIELD_TYPES.LINK:
      return (
        <AntForm.Item noStyle {...itemProps}>
          <Link {...(fieldProps as IFieldLinkProps)}>{(fieldProps as IFieldLinkProps).children}</Link>
        </AntForm.Item>
      );
    case FORM_FIELD_TYPES.CHECK:
      return (
        <AntForm.Item noStyle {...itemProps}>
          <Checkbox {...(fieldProps as IFieldCheckboxProps)} />
        </AntForm.Item>
      );
    case FORM_FIELD_TYPES.HIDDEN:
      return (
        <AntForm.Item noStyle {...itemProps}>
          {props => {
            return itemFunc?.(props, FormFields, fieldProps as IFormItems);
          }}
        </AntForm.Item>
      );
    case FORM_FIELD_TYPES.PHONE:
      return (
        <AntForm.Item {...itemProps}>
          <PhoneInput {...(fieldProps as IFieldPhoneInputProps)} />
        </AntForm.Item>
      );
    case FORM_FIELD_TYPES.TEXT_AREA:
      return (
        <AntForm.Item {...itemProps}>
          <Input.TextArea {...(fieldProps as IFieldTextAreaProps)} />
        </AntForm.Item>
      );
    case FORM_FIELD_TYPES.TEXT:
      return (
        <AntForm.Item {...itemProps}>
          <Input {...(fieldProps as IFieldInputProps)} />
        </AntForm.Item>
      );
    case FORM_FIELD_TYPES.UPLOAD:
      return (
        <AntForm.Item {...itemProps}>
          <FileDragIn {...(fieldProps as IFieldFileDragProps)} />
        </AntForm.Item>
      );
    // case FORM_FIELD_TYPES.TREE_SELECT:
    //   return (
    //     <AntForm.Item {...itemProps}>
    //       <TreeSelect {...(fieldProps as IFieldTreeSelectProps)} />
    //     </AntForm.Item>
    //   );
    // case FORM_FIELD_TYPES.SELECT_TIMEZONE:
    //   return (
    //     // <AntForm.Item {...itemProps}>
    //     <TimezoneSelect timezones={allTimezones} {...(fieldProps as IFieldTimeZoneSelectProps)} />
    //     // </AntForm.Item>
    //   );
    // case FORM_FIELD_TYPES.SWITCH:
    //   return (
    //     <AntForm.Item {...itemProps}>
    //       <Switch {...(fieldProps as IFieldSwitchProps)} checked={getFieldValue?.(itemProps?.name as NamePath)} />
    //     </AntForm.Item>
    //   );
    // case FORM_FIELD_TYPES.DATE:
    //   return (
    //     <AntForm.Item {...itemProps}>
    //       <DatePicker {...(fieldProps as IFieldDatePickerProps)} />
    //     </AntForm.Item>
    //   );
    // case FORM_FIELD_TYPES.TIME:
    //   return (
    //     <AntForm.Item {...itemProps}>
    //       <TimePicker {...(fieldProps as IFieldTimePickerProps)} />
    //     </AntForm.Item>
    //   );
    // case FORM_FIELD_TYPES.BUTTON:
    //   return <Button {...(fieldProps as BtnProps)}>{(fieldProps as BtnProps).children}</Button>;
    // case FORM_FIELD_TYPES.SELECT:
    //   return (
    //     <AntForm.Item {...itemProps}>
    //       <Select allowClear {...(fieldProps as SelectProps)} />
    //     </AntForm.Item>
    //   );
    // case FORM_FIELD_TYPES.FIELDS:
    //   return (
    //     <AntForm.Item {...itemProps}>
    //       <Row gutter={24} style={{ width: "auto", padding: 0, marginRight: 0 }}>
    //         {(fieldProps as IFieldsProps[])?.map((field, index) => {
    //           return (
    //             <Col key={`field-col-${index}`} {...field.colProps}>
    //               <FormFields
    //                 {...field}
    //                 fieldType={field.fieldType}
    //                 fieldProps={field.fieldProps}
    //                 itemProps={field.itemProps as FormItemProps}
    //               />
    //             </Col>
    //           );
    //         })}
    //       </Row>
    //     </AntForm.Item>
    //   );
    // case FORM_FIELD_TYPES.LIST:
    //   return (
    //     <AntForm.List name={`${itemProps.name}-list`}>
    //       {(fields, { add, remove }) => (
    //         <>
    //           {fields?.map(listField => {
    //             return (
    //               <div key={listField.key} style={{ display: "flex", justifyContent: "space-around" }}>
    //                 {(fieldProps as IFieldsProps[])?.map((field, index) => {
    //                   return (
    //                     <FormFields
    //                       key={index}
    //                       fieldType={field.fieldType}
    //                       fieldProps={{
    //                         ...field?.fieldProps,
    //                         style: { width: "200px" },
    //                       }}
    //                       itemProps={{
    //                         ...field.itemProps,
    //                         ...listField,
    //                         name: [listField.name, field.itemProps?.name as string],
    //                       }}
    //                     />
    //                   );
    //                 })}
    //                 {!isEdit && (
    //                   <AntForm.Item>
    //                     <MinusCircleOutlined onClick={() => remove(listField.name)} />
    //                   </AntForm.Item>
    //                 )}
    //               </div>
    //             );
    //           })}
    //           <AntForm.Item wrapperCol={{ offset: 20 }}>
    //             <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />} disabled={isEdit}>
    //               Add item
    //             </Button>
    //           </AntForm.Item>
    //         </>
    //       )}
    //     </AntForm.List>
    //   );
  }
  return null;
};
export interface IFormProps {
  formRef: React.Ref<FormInstance<any>>;
  formProps?: FormProps;
  items?: IFormItems[];
  getForm?: (form: FormInstance) => void;
  isEdit?: boolean;
  children?: React.ReactNode;
}

// renamed to app form to avoid conflict wit antd Form
export const AppForm: React.FC<IFormProps> = ({ formProps, formRef, items, getForm, isEdit, children }) => {
  const [form] = AntForm.useForm();
  if (getForm) {
    getForm(form);
  }

  return (
    <AntForm
      ref={formRef}
      style={{ justifyContent: "center", padding: 20 /* maxWidth: 600 */ }}
      // labelCol={{ span: 8 }}
      // wrapperCol={{ span: 14 }}
      {...formProps}
      form={form}
    >
      {(children && children) ||
        items?.map((item, index) => {
          return <FormFields key={index} {...item} form={form} isEdit={isEdit} />;
        })}
    </AntForm>
  );
};
