import { useMutation, UseMutationResult, useQuery, UseQueryResult } from "@tanstack/react-query";
import { tqQueryKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";
import { useState } from "react";
import { ISODateString } from "ui/types";

type RequestVerificationPayload = {
  email: string;
};

type User = {
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  password: string;
  verified: boolean;
  createdAt: string;
  updatedAt: string;
};

type RequestVerificationResponse = {
  id: number;
  expiryTimestamp: ISODateString;
  type: string;
  token: string;
  userEmail: string;
  user: User;
};

type RequestVerificationResult = UseMutationResult<RequestVerificationResponse, Error, RequestVerificationPayload>;

export function useRequestVerification(): RequestVerificationResult {
  return useMutation({
    mutationKey: [tqQueryKeys.auth.requestVerification],
    mutationFn: async (payload: RequestVerificationPayload): Promise<RequestVerificationResponse> => {
      const { email } = payload;
      const res = await EvendyApi.get<RequestVerificationResponse>("auth/request-verification", {
        params: { email },
      });
      return res.data;
    },
    retry: false,
  });
}

export function useRequestEmailVerify() {
  const [loading, setLoading] = useState(false);

  const requestVerifyEmail = async (email: string) => {
    try {
      setLoading(true);
      const res = await EvendyApi.get<RequestVerificationResponse>("auth/request-verification", {
        params: { email },
      });
      setLoading(false);
      return res.data;
    } catch (verifyEmailError) {
      console.log({ verifyEmailError });
      setLoading(false);
      return null;
    }
  };

  return { loading, requestVerifyEmail };
}
