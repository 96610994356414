import React, { useState } from "react";
import styled from "styled-components";
import SearchBar from "./Searchbar";
import Vendorcategory from "./Category";
import Ratings from "./Ratings";
import Others from "./Others";
import Location from "./Location";
import { Button } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import Icon from "@ant-design/icons";
import { ReactComponent as reset } from "images/filter/reset.svg";

const Filtercontainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 2rem;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1rem;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export interface IFiltersProps {}
//searchbar
export function Filters(props: IFiltersProps) {
  const [searchQuery, setSearchQuery] = useState("");

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    // Add logic to perform search with the query
  };

  //vendor category

  return (
    <Filtercontainer>
      <SearchBar onSearch={handleSearch} />
      {/* <p>Search Query: {searchQuery}</p> */}

      <Wrapper>
        <Vendorcategory />
        <Ratings />
        <Location />
        <Others />
        <Button type="default" icon={<Icon component={reset} style={{ fontSize: "20px", color: "white" }} />}>
          Reset filters
        </Button>
      </Wrapper>
    </Filtercontainer>
  );
}
