import { useQuery, UseQueryOptions, UseQueryResult } from "@tanstack/react-query";
import { tqQueryKeys } from "lib/constants";
import { EvendyApi } from "lib/utils";

type VendorCategory = {
  id: number;
  name: string;
  categorySlug: string;
};

type UseGetVendorCategoriesResult = UseQueryResult<VendorCategory[], Error>;

export function useGetVendorCategories(): UseGetVendorCategoriesResult {
  return useQuery<VendorCategory[], Error>({
    queryKey: [tqQueryKeys.eventCategory.getEventCategories],
    queryFn: async (): Promise<VendorCategory[]> => {
      const res = await EvendyApi.get<VendorCategory[]>("/vendor-category");
      return res.data;
    },
    onSuccess: (data: VendorCategory[]) => {
      if (data && data.length > 0) {
        const eventId = data[0].id;
        sessionStorage.setItem("generatedEventId", eventId.toString());
      }
    },
  } as UseQueryOptions<VendorCategory[], Error>);
}
