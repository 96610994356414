import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button } from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as reset } from "images/filter/reset.svg";
import SearchBar from "ui/components/Dashboard/Filter/Searchbar";
import axios from "axios";

const Container = styled.div``;

const Topdiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Left = styled.div`
  width: 60%;
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const TableContainer = styled.div`
  margin-top: 2rem;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  background-color: #f9faff;
  padding: 8px;
  &:first-child {
    border-top-left-radius: 8px;
  }
  &:nth-child(2) {
    padding-left: 4px;
    padding-right: 4px;
  }
  &:last-child {
    border-top-right-radius: 8px;
  }
`;

const Td = styled.td`
  padding: 8px;
  &:nth-child(2) {
    padding-left: 4px;
    padding-right: 4px;
  }
`;

const VendorRequests = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [data, setData] = useState([]);

  const handleSearch = (query: string) => {
    setSearchQuery(query);
    // Add logic to perform search with the query
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("/api/endpoint"); // Replace with your API endpoint
        setData(response.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <Container>
      <Topdiv>
        <Left>
          <SearchBar onSearch={handleSearch} />
        </Left>
        <Right>
          <Button type="default">Payment status</Button>
          <Button type="default" icon={<Icon component={reset} style={{ fontSize: "20px", color: "white" }} />}>
            Reset filters
          </Button>
        </Right>
      </Topdiv>
      <TableContainer>
        <Table>
          <thead>
            <tr>
              <Th>#</Th>
              <Th>Vendor</Th>
              <Th>Package</Th>
              <Th>Request Type</Th>
              <Th>Quantity</Th>
              <Th>Total Amount</Th>
              <Th>Request Status</Th>
            </tr>
          </thead>
          {/* <tbody>
            {data.map((item, index) => (
              <tr key={index}>
                <Td>{index + 1}</Td>
                <Td>{item.vendor}</Td>
                <Td>{item.package}</Td>
                <Td>{item.totalAmount}</Td>
                <Td>{item.totalPaid}</Td>
                <Td>{item.toBePaid}</Td>
                <Td>{item.paymentStatus}</Td>
              </tr>
            ))}
          </tbody> */}
        </Table>
      </TableContainer>
    </Container>
  );
};

export default VendorRequests;
