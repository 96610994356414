import { routeNames } from "lib/routes";
import { adminRoutes } from "lib/routes/admin";
import { organizerRoutes } from "lib/routes/organizer";
import { vendorRoutes } from "lib/routes/vendor";
import { MenuItem } from "ui/components/base";
import { EvendySiteOption, GetItemOptions, GetUserDto, RouteData, evendySites } from "ui/types";

export function resolveCurrentSite(): EvendySiteOption {
  return evendySites.ORGANIZER;
}

export function getSidebarItem(route: RouteData, options?: GetItemOptions): MenuItem | undefined {
  const { path, icon, children, label, isBottomSidebarItem } = route;

  const menuItem = {
    key: path,
    icon,
    children: children?.map(child => getSidebarItem(child)),
    label,
  } as MenuItem;

  if (options?.isBottomSidebarItem && isBottomSidebarItem) {
    return menuItem;
  }
  if (!options?.isBottomSidebarItem && !isBottomSidebarItem) {
    return menuItem;
  }
}

export function getSiteSidebarRoutes({ site = evendySites.ORGANIZER }: { site: EvendySiteOption }) {
  const routes: RouteData[] = [];
  const items: MenuItem[] = [];
  const bottomItems: MenuItem[] = [];

  routes.forEach(route => {
    if (route?.isInvisible) {
      return;
    }
    const item = getSidebarItem(route, { isBottomSidebarItem: false });
    if (item) {
      items.push(item);
    }
    const bottomItem = getSidebarItem(route, { isBottomSidebarItem: true });
    if (bottomItem) {
      bottomItems.push(bottomItem);
    }
  });

  return { items, bottomItems };
}

export function getSiteRoutes({ site = evendySites.ORGANIZER }: { site: EvendySiteOption }): RouteData[] {
  let siteRoutes: RouteData[] = [];

  switch (site) {
    case evendySites.ORGANIZER:
      siteRoutes = organizerRoutes;
      break;
    case evendySites.VENDOR:
      siteRoutes = vendorRoutes;
      break;
    case evendySites.ADMIN:
      siteRoutes = adminRoutes;
      break;
    default:
      break;
  }

  return siteRoutes;
}

export function getUserHomeRoute(user?: GetUserDto): string {
  switch (user?.userType || "") {
    case "organizer":
      return routeNames.organizerHome;
    case "vendor":
      return routeNames.vendorHome;
    // case "admin":
    // return routeNames.adminHome;
    default:
      return routeNames.homepage;
  }
}
