import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { AppModal } from "ui/components/base";
import { Button, Form, Input } from "antd";
import SubmitButton from "ui/components/base/Buttons/SubmitButton";
import { ReactComponent as Logoimg } from "images/admin/Logoevendylogo.svg";
import { useAdminLogin } from "lib/hooks/auth/useAdminLogin";

export const ModalBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
  width: 100%;
  padding: 1rem;
  gap: 1rem;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
`;

const Logo = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 1rem;
`;

const Header = styled.h3`
  font-size: 21px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
`;

const SmallText = styled.p`
  font-size: 12px;
  color: "#9c9c9c";
  margin-top: 0;
`;

const Email = styled.div`
  width: calc(100% - 10px);
`;

const Password = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
`;

const Admin: React.FC = () => {
  const [form] = Form.useForm();
  const [requestModalOpen, setRequestModalOpen] = useState(false);
  const { mutate: login, isError, error } = useAdminLogin();

  const handleRequestModalClose = () => {
    setRequestModalOpen(false);
  };

  const onFinish = (values: any) => {
    login(values, {
      onSuccess: () => {
        console.log("Login successful");
      },
      onError: loginError => {
        console.error("Login error: ", loginError);
      },
    });
  };

  useEffect(() => {
    setRequestModalOpen(true);
  }, []);

  return (
    <AppModal
      open={requestModalOpen}
      onCancel={handleRequestModalClose}
      header={<span></span>}
      size="md"
      hideHeader={true}
    >
      <ModalBodyContainer>
        <Logo>
          <Logoimg />
        </Logo>
        <Top>
          <Header>Login</Header>
          <SmallText>To access your account provide your details below</SmallText>
        </Top>
        <Form layout="vertical" form={form} name="login" onFinish={onFinish} scrollToFirstError requiredMark={false}>
          <Email>
            <Form.Item
              label="Email Address"
              name="email"
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please input your E-mail!",
                },
              ]}
            >
              <Input
                placeholder="e.g example@email.com"
                style={{ fontSize: "14px", color: "#9C9C9C", paddingLeft: "10px" }}
              />
            </Form.Item>
          </Email>
          <Password>
            <Form.Item
              label="Password"
              style={{ width: "100%" }}
              name="password"
              rules={[
                {
                  required: true,
                  message: "Please input your password!",
                },
              ]}
            >
              <Input.Password placeholder="Enter password" style={{ fontSize: "14px", color: "#9C9C9C" }} />
            </Form.Item>
          </Password>
          <Form.Item>
            <SubmitButton form={form}>Log in</SubmitButton>
          </Form.Item>
        </Form>
        {isError && <p style={{ color: "red" }}>{(error as Error).message}</p>} {/* Display error message */}
      </ModalBodyContainer>
    </AppModal>
  );
};

export default Admin;
