export const routeNames = {
  homepage: "/",
  servicesHomePage: "/services",
  financialServices: "/services/finance",
  ticketingPage: "/services/ticketingPage",
  analysisPage: "/services/EventAnalysisPage",
  eventManagement: "/services/EventManagementPage",
  eventMarketing: "/services/EventMarketingPage",
  eventVendorSourcing: "/services/EventVendorSourcing",

  //event page on the landing page
  event: "/event/event",
  eventDetail: "/event/eventDetail",
  //aboutus page
  about: "/about/about",
  //contact page
  contact: "/contact/contact",
  contactForm: "/contact/contact-form",
  // privacy
  privacy: "/privacy/privacy",
  // terms and conditions
  terms: "/terms/terms",
  dashboard: "/app",
  // vendors
  vendor: "/app/vendor",
  vendorHome: "/app/vendor/home",
  VendorMyPackages: "/app/vendor/mypackages",
  VendorRequests: "/app/vendor/requests",
  VendorResources: "/app/vendor/resources",
  vendorProfile: "/app/vendor/profile",

  // vendorDetails: "/app/vendors/:id",
  // vendorHistory: "/app/vendors/history",
  // vendorRequests: "/app/vendors/requests",

  //organizer
  organizer: "/app/organizer",
  organizerHome: "/app/organizer/home",
  organizerEvents: "/app/organizer/events",
  organizerApp: "/app/organizer",
  organizermarketplace: "/app/marketplace",
  organizerLoan: "/app/organizer/loan",
  organizerReports: "/app/organizer/reports",
  organizerProfile: "/app/organizer/profile",
  organizerVendorProfile: "/app/organizer/vendorprofile",

  //Organizer event page
  newEvent: "/app/organizer/createevent/index",
  eventType: "/app/organizer/createevent/index/eventtype",
  eventDate: "/app/organizer/createevent/index/eventdate",
  //event details on the organizer dashboard
  eventDetails: "/app/organizer/createevent/index/eventdetail",
  eventTickets: "/app/organizer/createevent/index/eventtickets",

  //organizer and vendor
  finances: "/app/finances",
  marketing: "/app/marketing",
  hospitality: "/app/hospitality",
  help: "/app/help",

  // authentication
  auth: "/auth",
  logout: "/logout",
  authSignup: "/auth/signup",
  authSignupForm: "/auth/signup/form",
  authVerifyEmail: "/auth/verifyemail",
  authSignupSuccessful: "/auth/signupsuccessful",
  authSignupComplete: "/auth/signupcomplete",
  authLogin: "/auth/login",
  authForgotPassword: "/auth/forgotpassword",
  authResetPassword: "/auth/resetpassword",
  authPasswordUpdated: "/auth/passwordupdated",

  //dasboard email verifiaction
  VerifyEmail: "/VerifyEmail",
  //admin login
  admin: "/admin",

  // admin
  adminHome: "/admin/home",
  adminEvents: "/admin/events",
  adminOrganizers: "/admin/organizers",
  adminVendors: "/admin/vendors",
  adminVendorRequest: "/admin/vendorrequest",
  adminDisputes: "/admin/disputes",
};
