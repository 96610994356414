import React, { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { AppButton } from "ui/components/base";

const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 100px 0;
  gap: 64px;
`;
const Container = styled.div`
  background: url("/assets/404.png") no-repeat center center / cover;
  width: 880px;
  height: 356.4px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const Title = styled.div`
  color: var(--gray-900, #101828);
  text-align: center;
  font-size: 60px;
  font-family: Inter;
  font-style: normal;
  font-weight: 600;
`;
const Description = styled.div`
  color: var(--gray-600, #475467);
  text-align: center;
  font-size: 20px;
  font-family: Inter;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-top: 32px;
`;

const ComingSoonPage = () => {
  const navigate = useNavigate();
  return (
    <Root>
      <Container>
        <Title>Watch This Space!</Title>
        <Description>Coming Soon: We&apos;re working on something amazing, watch this space.</Description>
        <AppButton
          onClick={() => {
            navigate("/");
          }}
        >
          Go home
        </AppButton>
      </Container>
    </Root>
  );
};

export default ComingSoonPage;
