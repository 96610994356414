import React, { useState } from "react";
import styled from "styled-components";
import { Switch } from "antd";
import SubmitButton from "../Button";
import { SubmitContainer } from "../AccountDetails";

const NotificationContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 600px;
`;

const NotificationItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #e0e0e0;
  height: 80px;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.p`
  font-size: 1rem;
  font-weight: 400;
  margin: 0;
`;

const Description = styled.p`
  font-size: 0.875rem;
  margin: 0;
  color: #666;
`;

const StyledSwitch = styled(Switch)``;

const Notification = () => {
  const [notifications, setNotifications] = useState([
    { id: 1, text: "All", paragraph: "Get all notifications", enabled: true },
    {
      id: 2,
      text: "News and Updates",
      paragraph: "Get notified via email on product and feature updates",
      enabled: false,
    },
    {
      id: 3,
      text: "Tips, tools and resources",
      paragraph: "Get notified via email on Vendor tips, tools and resources",
      enabled: true,
    },
    {
      id: 4,
      text: "Request notifications",
      paragraph: "Let us alert you about updates on requests via email",
      enabled: false,
    },
  ]);

  const handleToggle = (id: number) => {
    setNotifications(prevState =>
      prevState.map(notification =>
        notification.id === id ? { ...notification, enabled: !notification.enabled } : notification,
      ),
    );
  };

  return (
    <NotificationContainer>
      {notifications.map(notification => (
        <NotificationItem key={notification.id}>
          <Text>
            <Title>{notification.text}</Title>
            <Description>{notification.paragraph}</Description>
          </Text>
          <StyledSwitch checked={notification.enabled} onChange={() => handleToggle(notification.id)} />
        </NotificationItem>
      ))}
      <SubmitContainer>{/* <SubmitButton >Save Changes</SubmitButton> */}</SubmitContainer>
    </NotificationContainer>
  );
};

export default Notification;
