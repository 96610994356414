// import { UseMutationResult, useMutation } from "@tanstack/react-query";
// import { tqMutationKeys } from "lib/constants";
// import { getSession } from "lib/utils";
// import { EvendyApi } from "lib/utils/api-utils";

// // Define the payload type for editing a vendor
// type EditVendorPayload = {
//   businessName?: string;
//   businessCategoryName?: string;
//   description?: string;
//   phone?: string;
//   avatar?: string;
//   country?: string;
//   state?: string;
//   city?: string;
//   address?: string;
//   workAddress?: string;
//   kycLevel?: string;
//   role?: string;
//   accountStatus?: string;
//   user?: {
//     firstName: string;
//     lastName: string;
//     phone: string;
//     email: string;
//   };
// };

// // Define the response type
// interface EditVendorResponse {
//   id: number;
//   businessName: string;
//   businessCategoryName: string;
//   description: string;
//   phone: string;
//   avatar: string;
//   country: string;
//   state: string;
//   city: string;
//   address: string;
//   workAddress: string;
//   kycLevel: string;
//   role: string;
//   accountStatus: string;
// }

// // Define the mutation result type
// type UseEditVendorResult = UseMutationResult<EditVendorResponse, Error, EditVendorPayload>;

// // Define the useEditVendorById hook
// export function useEditVendorByEmail(): UseEditVendorResult {
//   return useMutation({
//     mutationFn: async (payload: EditVendorPayload) => {
//       const session = getSession();
//       if (!session?.email) {
//         throw new Error("unable to find user, kindly ensure you're signed in");
//       }
//       const res = await EvendyApi.patch<Promise<EditVendorResponse>>(`vendor/update-by-email/${session.email}`, payload);
//       return res.data;
//     },
//     mutationKey: [tqMutationKeys.vendor.edit],
//     onError(editError: Error) {
//       console.log({ editError });
//     },
//     onSuccess(data) {
//       console.log("Vendor updated successfully", data);
//     },
//   });
// }

import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { tqMutationKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";

type EditVendorPayload = {
  email: string;
  data: {
    businessName?: string;
    businessCategoryName?: string;
    description?: string;
    phone?: string;
    avatar?: string;
    country?: string;
    state?: string;
    city?: string;
    address?: string;
    workAddress?: string;
    kycLevel?: string;
    role?: string;
    accountStatus?: string;
    user?: {
      firstName: string;
      lastName: string;
      phone: string;
      email: string;
    };
  };
};

interface EditVendorResponse {
  avatar: string;
  country: string;
  state: string;
  city: string;
  address: string;
  workAddress: string;
  role: string;
  kycLevel: string;
  accountStatus: string;
  user: {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
  };
}

type UseEditVendorResult = UseMutationResult<EditVendorResponse, Error, EditVendorPayload>;

export function useEditVendorByEmail(): UseEditVendorResult {
  return useMutation({
    mutationFn: async (payload: EditVendorPayload) => {
      const { email, data } = payload;
      const res = await EvendyApi.patch<Promise<EditVendorResponse>>(`vendor/update-by-email/${email}`, data);
      return res.data;
    },
    mutationKey: [tqMutationKeys.vendor.edit],
    onError(editError: Error) {
      console.log({ editError });
    },
    onSuccess(data) {
      console.log("Vendor updated successfully", data);
    },
  });
}
