import React from "react";
import styled from "styled-components";
import { routeNames } from "lib/routes";
import { Link } from "react-router-dom";
import { ReactComponent as Ticket } from "images/landingpage/services/ticketing.svg";
import TicketImage from "images/landingpage/services/ticketimage.jpeg";
import { ReactComponent as CreateTicket } from "images/landingpage/services/CreateTicket.svg";
// import { ReactComponent as DepositMobile } from "images/landingpage/services/Depositmobile.svg";
import { ReactComponent as Arrow } from "images/landingpage/services/arrow-right.svg";
import Vector1 from "images/landingpage/services/Vector5.png";
import Vector2 from "images/landingpage/services/Vector6.png";
import Vector3 from "images/landingpage/services/Vector7.png";
import Vector4 from "images/landingpage/services/Vector8.png";
import { ReactComponent as Frame1 } from "images/landingpage/services/Frame4.svg";
import { ReactComponent as Frame2 } from "images/landingpage/services/Frame5.svg";
// import { ReactComponent as Frame3 } from "images/landingpage/services/Frame6.svg";
import { ReactComponent as BlueArrow } from "images/landingpage/services/bluearrow.svg";
import Contact from "../contact/contact";

const FinanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 768px) {
  }
`;

const Top = styled.div`
  position: relative;
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10rem;
  background-color: #fcfcfd;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
  }
`;
const Bottom = styled.div`
  margin-top: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
`;
const TopLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  @media (max-width: 768px) {
    margin-left: 0.5rem;
  }
`;

const TopRight = styled.div`
  position: relative;
  @media (max-width: 768px) {
    display: none;
  }
`;

const TopRightMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const ItemContainers = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 6rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Header = styled.p`
  font-size: 48px;
  font-weight: 600;
  line-height: 60.34px;
  letter-spacing: -0.02em;
  color: #2f2f2f;
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

const MediumHeader = styled.h5`
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  z-index: 2;
`;

const Text = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #595959;
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 768px) {
    display: none;
  }
`;

const TextMobile = styled.p`
  display: none;
  @media (max-width: 768px) {
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #595959;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const SmallText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 17.6px;
  text-align: left;

  color: #595959;
  margin-top: 0;
  margin-bottom: 0;
`;

const MediumText = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: #121212;
  margin-top: 0;
  margin-bottom: 0;
`;

const BoldText = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 4rem;
  @media (max-width: 768px) {
    justify-content: flex-start; /* Keep alignment left on mobile */
    margin-left: 1rem;
    margin-bottom: 0rem;
  }
`;

export const CreateAnEvent = styled(Link)`
  all: unset;
  height: 46px;
  border-radius: 8px;
  background-color: rgba(19, 144, 230, 1);
  color: #fff;
  width: 170px;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  /* Add transition for smooth shadow appearance */
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    background-color: #3897d8;
    /* Dropdown shadow effect */
    box-shadow: 0 8px 16px rgba(56, 151, 216, 0.3); /* Subtle shadow */
  }
`;

const ExploreEvents = styled(Link)`
  all: unset;
  height: 46px;
  border-radius: 8px;
  color: #121212;
  width: 170px;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  /* Add transition for smooth shadow appearance */
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    color: #1c5b87;
  }
`;

const Benefits = styled.button`
  background-color: #7ef88b33;
  color: #168f43;
  border: none;
  padding: 8px 24px 8px 24px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 600;
  line-height: 15.08px;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 0;
`;

const Services = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  z-index: 2;
`;

const ImageContainer = styled.div``;

const OverlayContainer = styled.div`
  position: absolute;
  bottom: -4.8rem;
  left: -3.5rem;
`;

const OverlayContainerMobile = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;

const SpiralContainer1 = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  @media (max-width: 768px) {
    display: none;
  }
`;
const SpiralContainer2 = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`;

const SpiralContainer3 = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
`;
const SpiralContainer4 = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`;

const ContactUs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const TicketingPage: React.FC = () => {
  return (
    <FinanceContainer>
      <Top>
        <TopLeft>
          <Ticket />
          <Header>EVENT TICKETING</Header>
          <Text>
            Kick things off on the right foot with a seamless registration
            <br />
            experience. Have all your attendees register for your event with no
            <br />
            hassle and complications using our platform.
          </Text>
          <TextMobile>
            Kick things off on the right foot with a seamless registration experience. Have all your attendees register
            for your event with no hassle and complications using our platform.
          </TextMobile>
          <ButtonWrapper>
            <CreateAnEvent to={routeNames.authSignup}>Create an event</CreateAnEvent>

            <ExploreEvents to={routeNames.event}>Explore Events</ExploreEvents>
          </ButtonWrapper>
        </TopLeft>
        <TopRight>
          <ImageContainer>
            <img
              src={TicketImage}
              alt="Event financing"
              width="430.71px"
              height="335px"
              style={{ borderRadius: "4.7px" }}
            />
          </ImageContainer>
          <OverlayContainer>
            <CreateTicket />
          </OverlayContainer>
        </TopRight>
        <TopRightMobile>
          <ImageContainer>
            <img
              src={TicketImage}
              alt="Event financing"
              width="323.03px"
              height="251.25px"
              style={{ borderRadius: "4.7px" }}
            />
          </ImageContainer>
          <OverlayContainerMobile>
            <img src="/Images/Services/Depositmobile.svg" />
          </OverlayContainerMobile>
        </TopRightMobile>
        <SpiralContainer1>
          <img src={Vector1} alt="" />
        </SpiralContainer1>
        <SpiralContainer2>
          {" "}
          <img src={Vector2} alt="" />
        </SpiralContainer2>
      </Top>
      <Bottom>
        <Benefits>HOW IT WORKS</Benefits>
        <MediumHeader>How Tickets Work</MediumHeader>
        <ItemContainers>
          <Services>
            <img src="/Images/Services/Frame6.svg" />
            <BoldText>Create Tickets</BoldText>
            <SmallText>
              Create all types of tickets for your
              <br /> event.
            </SmallText>
            <ContactUs>
              <MediumText>Contact us for more info</MediumText>
              <Arrow />
            </ContactUs>
          </Services>

          <BlueArrow />
          <Services>
            <Frame2 />
            <BoldText>Publish Tickets</BoldText>
            <SmallText>
              Make all ticket types available for
              <br /> your attendees to buy
            </SmallText>
            <ContactUs>
              <MediumText>Contact us for more info</MediumText>
              <Arrow />
            </ContactUs>
          </Services>
          <BlueArrow />
          <Services>
            <Frame1 />
            <BoldText>Manage Ticket Sales</BoldText>
            <SmallText>
              View ticket sales on your
              <br /> dashboard
            </SmallText>
            <ContactUs>
              <MediumText>Contact us for more info</MediumText>
              <Arrow />
            </ContactUs>
          </Services>
        </ItemContainers>
        <SpiralContainer3>
          <img src={Vector3} alt="" />
        </SpiralContainer3>
        <SpiralContainer4>
          <img src={Vector4} alt="" />
        </SpiralContainer4>
      </Bottom>
    </FinanceContainer>
  );
};

export default TicketingPage;
