import React from "react";
import { styled } from "styled-components";
import { useOutletContext, useNavigate } from "react-router-dom";
import { sessionStorageKeys } from "lib/constants";
import { routeNames } from "lib/routes";

const Container = styled.div`
  margin-top: 5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

const Header = styled.h3`
  font-size: 21px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
`;

const LargeText = styled.p`
  font-size: 13px;
  color: #595959;
`;

const Continue = styled.button`
  background-color: #1390e6;
  color: white;
  border: 1px solid;
  border-color: #d9d9d9;
  border-radius: 8px;
  padding: 12px 12px;
  font-size: 13px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 2rem;
  cursor: pointer;
`;

const SignupCompletePage: React.FC = () => {
  const navigate = useNavigate();

  const handleContinueClick = () => {
    const userType = sessionStorage.getItem(sessionStorageKeys.signupUserType); // Retrieve userType from session storage
    if (userType) {
      navigate(`/app/${userType}/home`);
    } else {
      console.error("User type not found in session storage");
    }
    navigate(routeNames.authLogin);

    //for the change to email veriication

    navigate(routeNames.dashboard);
  };

  return (
    <Container>
      <Header>Sign up successful</Header>
      <LargeText>Now you can start organizing and managing all your events</LargeText>
      <Continue onClick={handleContinueClick}>Go to my dashboard</Continue>
    </Container>
  );
};

export default SignupCompletePage;
