import React from "react";
import { useRouteError } from "react-router-dom";
import { PageErrorBoundary } from "ui/components/sections";

export async function loader() {
  return Promise.resolve({});
}

const AdminHome = () => {
  return <div>AdminHome</div>;
};

export default AdminHome;
