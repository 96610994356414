import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { Checkbox, Select, Button, Form, Tag, DatePicker, TimePicker, Row, Col, message } from "antd";
import moment from "moment-timezone";
import { routeNames } from "lib/routes";
import { Link, useNavigate } from "react-router-dom";
import SubmitButton from "ui/components/base/Buttons/SubmitButton";
import { useEditEventById } from "lib/hooks";
import { useEventContext } from "ui/components/event/eventContext";
import { EditEventPayload } from "lib/hooks";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 1.1rem;
  margin-bottom: 20px;
`;

const OccurrenceContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
`;
const OccurrenceCard = styled.div<{ disabled?: boolean; isSelected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  width: 45%;
  height: 78px;
  border: 1px solid ${props => (props.disabled ? "#d9d9d9" : props.isSelected ? "rgba(28, 91, 135, 1)" : "#1890ff")};
  border-radius: 8px;
  background-color: ${props => (props.disabled ? "#fafafa" : "#fff")};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${props => (props.disabled ? 0.7 : 1)};
`;

const OccurrenceContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const OccurrenceLabel = styled.span`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 4px;
  gap: 20px;
`;

const OccurrenceDescription = styled.span`
  font-size: 0.8rem;
  color: #888;
`;
const TimezoneContainer = styled.div`
  margin-bottom: 30px;
`;

const TimezoneLabel = styled.label`
  display: block;
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 8px;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: end;
  gap: 30px;
`;

const GhostButton = styled(Button)`
  width: 127px;
  height: 46px;
  border-radius: 8px;
  border: 1px solid rgba(226, 232, 240, 1);
  background-color: #fff;
`;

const ButtonWrapper = styled.div`
  width: 205px;
`;

const DateRow = styled(Row)`
  margin-bottom: 20px;
`;

const DateCol = styled(Col)`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const EventDate: React.FC = () => {
  const [isSingleEventSelected, setIsSingleEventSelected] = useState(false);
  const [timezones, setTimezones] = useState<string[]>([]);
  const [form] = Form.useForm();
  const [isUpdating, setIsUpdating] = useState(false);
  const { eventId } = useEventContext();
  const { mutate: updateEvent } = useEditEventById();
  const navigate = useNavigate();

  const handleSingleEventClick = () => {
    setIsSingleEventSelected(prev => !prev);
  };

  // Fetch timezones for the form
  useEffect(() => {
    const allTimezones = moment.tz.names();
    const timezonesWithOffsets = allTimezones.map(tz => {
      const offset = moment.tz(tz).utcOffset() / 60;
      const formattedOffset = `GMT ${offset >= 0 ? `+${offset}` : offset}`;
      return `${tz} (${formattedOffset})`;
    });
    setTimezones(timezonesWithOffsets);
  }, []);

  // Handle form submission
  const handleSubmit = async (values: any) => {
    if (!eventId) {
      message.error("Event ID not found.");
      return;
    }
    setIsUpdating(true);

    const timezoneISO = moment.tz(values.timeZone).format();

    const payload: EditEventPayload = {
      id: Number(eventId),
      data: {
        title: values.title,
        banner: values.banner,
        organizerEmail: values.organizerEmail,
        category: values.category,
        about: values.about,
        type: values.eventType || "physical",
        occurence: values.occurence || "single",
        timezone: timezoneISO,
        startDate: values.startDate.format("YYYY-MM-DD"),
        endDate: values.endDate.format("YYYY-MM-DD"),
      },
    };

    updateEvent(payload, {
      onSuccess: () => {
        message.success("Event updated successfully");
        navigate(routeNames.eventTickets);
      },
      onError: error => {
        message.error(`Error updating event: ${error.message}`);
      },
    });
  };

  return (
    <Container>
      <Title>Date & Time</Title>

      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        requiredMark={false}
        initialValues={{
          eventType: isSingleEventSelected ? "single" : "",
          timeZone: "",
        }}
      >
        {/* Occurrence Section */}
        <Form.Item name="occurence">
          <OccurrenceContainer>
            <OccurrenceCard onClick={handleSingleEventClick} isSelected={isSingleEventSelected}>
              <OccurrenceContent>
                <OccurrenceLabel>Single event</OccurrenceLabel>
                <OccurrenceDescription>Happens only once at a specified date and time</OccurrenceDescription>
              </OccurrenceContent>
              <Checkbox checked={isSingleEventSelected} />
            </OccurrenceCard>

            <OccurrenceCard disabled>
              <OccurrenceContent>
                <OccurrenceLabel>
                  Recurring event
                  <Tag color="gold" style={{ marginLeft: "10px" }}>
                    Coming Soon
                  </Tag>
                </OccurrenceLabel>
                <OccurrenceDescription>Happens repeatedly over a specified period</OccurrenceDescription>
              </OccurrenceContent>
            </OccurrenceCard>
          </OccurrenceContainer>
        </Form.Item>

        {/* Timezone Section */}
        <TimezoneContainer>
          <TimezoneLabel>Timezone</TimezoneLabel>
          <Form.Item name="timeZone" rules={[{ required: true, message: "Please select a timezone" }]}>
            <Select showSearch placeholder="Select Timezone" optionFilterProp="children">
              {timezones.map((tz, index) => (
                <Select.Option key={index} value={tz}>
                  {tz}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </TimezoneContainer>

        {/* Date and Time Section */}
        <DateRow gutter={16}>
          <DateCol span={12}>
            <Form.Item
              label="Start Date"
              name="startDate"
              rules={[{ required: true, message: "Please select a start date" }]}
            >
              <DatePicker style={{ width: "100%", height: "56px" }} />
            </Form.Item>
            <Form.Item
              label="End Date"
              name="endDate"
              rules={[{ required: true, message: "Please select an end date" }]}
            >
              <DatePicker style={{ width: "100%", height: "56px" }} />
            </Form.Item>
          </DateCol>

          <DateCol span={12}>
            <Form.Item
              label="Start Time"
              name="startTime"
              rules={[{ required: true, message: "Please select a start time" }]}
            >
              <TimePicker style={{ width: "100%", height: "56px" }} />
            </Form.Item>

            <Form.Item
              label="End Time"
              name="endTime"
              rules={[{ required: true, message: "Please select an end time" }]}
            >
              <TimePicker style={{ width: "100%", height: "56px" }} />
            </Form.Item>
          </DateCol>
        </DateRow>

        {/* Buttons */}
        <ButtonContainer>
          <Link to={routeNames.organizerHome}>
            <GhostButton>Cancel</GhostButton>
          </Link>
          {/* <ButtonWrapper>
            <SubmitButton form={form} loading={isUpdating}>
              Save and Continue
            </SubmitButton>
          </ButtonWrapper> */}
        </ButtonContainer>
      </Form>
    </Container>
  );
};

export default EventDate;

// //rgba(28, 91, 135, 1)

// // End date
// // End time
// // Select date
// // Select time
// // Cancel
// // Save and continue]
