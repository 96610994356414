import React, { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import styled from "styled-components";
import Sidebar, { SidebarItem } from "ui/components/Dashboard/Sidebar/Sidebar";
import { ReactComponent as HomeIcon } from "images/sidebar/Framehome.svg";
import { ReactComponent as EventIcon } from "images/sidebar/Frameevents.svg";
import { ReactComponent as VendorIcon } from "images/sidebar/Shop-Filledvendor.svg";
import { ReactComponent as Organizers } from "images/sidebar/organizer.svg";
import { ReactComponent as VendorRequest } from "images/sidebar/requests.svg";
import { ReactComponent as Disputes } from "images/sidebar/disputes.svg";
import Topbar from "ui/components/base/Topbar/Topbar";

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
`;

const ContentContainer = styled.div`
  margin-left: 18.5%;
  overflow-y: auto; /* Allow content to scroll vertically */
  width: 100%;
  max-width: 1200px;
`;

// Sidebar data for organizer user
const adminSidebarData: SidebarItem[] = [
  // Define sidebar items relevant to organizers
  {
    name: "Home",
    path: "/admin/home",
    icon: HomeIcon,
  },
  {
    name: "Events",
    path: "/admin/events",
    icon: EventIcon,
  },
  {
    name: "Organizers",
    path: "/admin/organizers",
    icon: Organizers,
  },
  {
    name: "Vendors",
    path: "/admin/vendors",
    icon: VendorIcon,
  },
  {
    name: "Request & Payments",
    path: "/admin/vendorrequest",
    icon: VendorRequest,
  },
  {
    name: "Disputes",
    path: "/admin/disputes",
    icon: Disputes,
  },
];

const AdminDashboard: React.FC = () => {
  return (
    <DashboardContainer>
      <Sidebar sidebarData={adminSidebarData} />
      <ContentContainer>
        <Topbar />
        <Outlet />
      </ContentContainer>
    </DashboardContainer>
  );
};

export default AdminDashboard;
