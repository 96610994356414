import { Button } from "antd";
import { CSSProperties } from "react";
import { css, styled } from "styled-components";
import React from "react";

export const AppButton = styled(Button)<{
  variant?: "primary" | "secondaryGray" | "dark" | "tertiary" | "error" | "unstyled";
  display?: CSSProperties["display"];
  width?: CSSProperties["width"];
}>`
  ${props => css`
    border-radius: 6px;
    box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
    height: 40px !important;
    padding: 10px 16px !important;
    display: ${props.display} !important;
    justify-content: center !important;
    align-items: center !important;
    text-align: center !important;

    color: var(--base-white, #fff);

    /* Text sm/Semibold */
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
  `}
  ${({ width }) =>
    width
      ? css`
          width: width;
        `
      : ""}
  ${({ variant }) => {
    switch (variant) {
      case "primary":
        return css`
          color: var(--base-white, #fff);

          /* Text sm/Bold */
          font-family: Satoshi;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 142.857% */

          border-radius: 6px;
          border: 1px solid var(--blue-600, #1570ef);
          background: var(--blue-600, #1570ef);

          /* Shadow/xs */
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        `;
      case "secondaryGray":
        return css`
          color: var(--gray-700, #344054);

          /* Text sm/Bold */
          font-family: Satoshi;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: 20px; /* 142.857% */

          border-radius: 6px;
          border: 1px solid var(--gray-300, #d0d5dd);
          background: var(--base-white, #fff);

          /* Shadow/xs */
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

          &:hover {
            color: var(--gray-800, #1d2939);

            /* Text sm/Bold */
            font-family: Satoshi;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: 20px; /* 142.857% */

            border: 1px solid var(--gray-300, #d0d5dd);
            background: var(--gray-50, #f9fafb);

            /* Shadow/xs */
            box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
          }
        `;
      case "dark":
        return css`
          border: 1px solid var(--gray-600, #475467);
          color: #fff;
          background: var(--gray-900, #101828);
        `;
      case "tertiary":
        return css`
          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border-radius: 6px;
          border-width: 0px;
          border-color: transparent;
          box-shadow: none;

          // While hovering
          // Change to: "sm, Tertiary gray, Default, Destructive=False, Hover";
          // Animate: Dissolve;
          animation-timing-function: linear;
          animation-duration: 200ms;
        `;
      case "error": {
        return css`
          color: #fff;

          border-radius: 8px;
          border: 1px solid var(--error-600, #d92d20);
          background: var(--error-600, #d92d20);

          /* Shadow/xs */
          box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
        `;
      }
      case "unstyled": {
        return css`
          all: unset !important;
          cursor: pointer;
          padding: 10px 16px !important;
          display: flex !important;
          justify-content: center !important;
          align-items: center !important;
          text-align: center !important;
        `;
      }
    }
  }}
`;

AppButton.defaultProps = {
  variant: "primary",
  display: "flex",
};
