import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Form, Input, notification, Select } from "antd";
import { ReactComponent as NotificationImg } from "images/vendor/Alert.svg";
import SubmitButton from "../Button";
import { useEditVendorBank } from "lib/hooks/vendor/useVendorBankById";
import { useGetVendorByEmail } from "lib/hooks/vendor/useGetVendorByEmail";
import { bankCodes } from "./BankCodes";
import { getSession } from "lib/utils/utils";

const { Option } = Select;

const Alert = styled.div`
  display: flex;
  padding: 8px 0;
  align-items: center;
  margin-bottom: 20px;

  p {
    margin-left: 8px;
  }
`;

const ResponsiveContainer = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;

  @media (max-width: 768px) {
    padding: 10px;
  }
`;

const ResponsiveForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 16px;

    @media (max-width: 576px) {
      margin-bottom: 12px;
    }
  }

  .ant-input,
  .ant-select-selector {
    font-size: 14px;
    padding: 10px;
    width: 100%;

    @media (max-width: 576px) {
      font-size: 12px;
      padding: 8px;
    }
  }

  .ant-form-item-label > label {
    font-size: 16px;

    @media (max-width: 576px) {
      font-size: 14px;
    }
  }

  .ant-btn {
    width: 100%;
    font-size: 16px;
    padding: 10px 15px;

    @media (max-width: 576px) {
      font-size: 14px;
      padding: 8px 10px;
    }
  }
`;

const BankDetails = () => {
  const [form] = Form.useForm();
  const { data: vendor, isLoading, isError } = useGetVendorByEmail();
  const { mutate: editVendorBank } = useEditVendorBank();
  const [banks, setBanks] = useState<string[]>([]);
  const [bankCode, setBankCode] = useState<string>("");
  const [vendorEmail, setVendorEmail] = useState<string | null>(null);

  useEffect(() => {
    const banksData = bankCodes.Banks;
    setBanks(Object.keys(banksData));
  }, []);

  useEffect(() => {
    const session = getSession();
    if (session) {
      setVendorEmail(session.email);
    }
  }, []);

  const handleBankChange = (bankName: keyof typeof bankCodes.Banks) => {
    const selectedBankCode = bankCodes.Banks[bankName];
    setBankCode(selectedBankCode || "");
    form.setFieldsValue({ bankCode: selectedBankCode });
  };

  const handleSubmit = (data: any) => {
    if (!vendor) {
      return;
    }

    const payload = {
      bankName: data.bankName,
      bankAccountName: data.bankAccountName,
      bankAccountNumber: data.bankAccountNumber,
      bankCode: Number(data.bankCode),
      vendorEmail: vendorEmail as string,
    };

    editVendorBank(payload, {
      onSuccess: () => {
        notification.success({
          message: "Success",
          description: "Bank details saved successfully.",
        });
        form.resetFields();
      },
      onError: error => {
        notification.error({
          message: "Error",
          description: `Failed to save bank details. Error: ${error.message || "Unknown error"}`,
        });
      },
    });
  };

  if (isLoading) return <p>Loading user data...</p>;
  if (isError || !vendor) return <p>Error fetching user data.</p>;

  return (
    <>
      <Alert>
        <NotificationImg /> <p>Please ensure all details are correct</p>
      </Alert>
      <ResponsiveContainer>
        <ResponsiveForm
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
          initialValues={{ remember: true }}
          requiredMark={false}
        >
          <Form.Item
            label="Bank Name"
            name="bankName"
            rules={[{ required: true, message: "Please select your bank!" }]}
          >
            <Select onChange={handleBankChange}>
              {banks.map(bankName => (
                <Option key={bankName} value={bankName}>
                  {bankName}
                </Option>
              ))}
            </Select>
          </Form.Item>

          <Form.Item
            label="Account Name"
            name="bankAccountName"
            rules={[{ required: true, message: "Please enter your bank account name" }]}
          >
            <Input placeholder="Enter your bank account name" />
          </Form.Item>

          <Form.Item
            label="Account Number"
            name="bankAccountNumber"
            rules={[{ required: true, message: "Please enter your bank account number" }]}
          >
            <Input placeholder="Enter your bank account number" />
          </Form.Item>

          <Form.Item>
            <SubmitButton form={form}>Save Changes</SubmitButton>
          </Form.Item>
        </ResponsiveForm>
      </ResponsiveContainer>
    </>
  );
};

export default BankDetails;
