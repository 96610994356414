import React from "react";
import styled, { css } from "styled-components";
import { screenSizes } from "ui/theme";

interface IAppBadgeProps {
  children?: React.ReactNode;
  size?: string;
  group?: string;
}

export const BadgeGroupImg = styled.img``;

export const BadgeGroup = styled.div<{ size: IAppBadgeProps["size"] }>`
  display: flex;
  border-radius: 6px;
  gap: 6px;
  border: 1px solid rgba(208, 213, 221, 1);
  align-items: center;

  /* size styles  */
  ${({ size }) => {
    switch (size) {
      case "sm": {
        return css``;
      }
      case "lg": {
        return css`
          height: 26px;
          padding: 0.125rem 0.5rem;
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 1.375rem;
          text-align: center;
        `;
      }
    }
  }}
`;

const Badge = styled.div<IAppBadgeProps>`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding: 0.25rem;
  width: fit-content;
  height: 100%;
  gap: 4px;

  border-radius: 10px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  p {
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 1.375rem;
    text-align: left;
    color: rgba(52, 64, 84, 1);
    margin: 0;
  }

  /* color styles */
  /* ${({ color }) => {
    switch (color) {
      case "primary": {
        return css`
          border-radius: 16px;
          border: 1px solid var(--blue-200, #b2ddff);
          background: var(--blue-50, #eff8ff);
          mix-blend-mode: multiply;
        `;
      }
    }
  }} */

  /* size styles  */
  ${({ size }) => {
    switch (size) {
      case "sm": {
        return css`
          padding: 0px 8px;
          align-items: center;
          @media (min-width: ${screenSizes.laptop};) {
            height: 22px;
          }
        `;
      }
      case "lg": {
        return css`
          @media (min-width: ${screenSizes.laptop};) {
            height: 34px;
            max-height: 34px;
            gap: 12px;
          }
        `;
      }
    }
  }}
`;

export const AppBadge: React.FC<IAppBadgeProps> = ({ children, group, size }) => {
  return (
    <Badge size={size}>
      {group ? (
        <BadgeGroup size={size}>
          <BadgeGroupImg src="/badge_group_img.png" />
          <p>{group}</p>
        </BadgeGroup>
      ) : null}
      {children}
    </Badge>
  );
};

AppBadge.defaultProps = {
  // color: "primary",
  size: "lg",
};
