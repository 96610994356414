import * as React from "react";
import { DownOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Dropdown, message, Space, Flex } from "antd";
import { Checkbox } from "antd";
import type { CheckboxProps } from "antd";

const onChange: CheckboxProps["onChange"] = e => {
  console.log(`checked = ${e.target.checked}`);
};

const items: MenuProps["items"] = [];

const menuProps = {
  items,
};

const Others: React.FC = () => {
  const menuStyle: React.CSSProperties = {
    boxShadow: "none",
  };
  return (
    <Dropdown
      menu={menuProps}
      dropdownRender={menu => (
        <div style={{ background: "#fff", padding: "10px", borderRadius: "8px" }}>
          {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}
          <Flex vertical>
            <Space>
              <Checkbox onChange={onChange}>All vendors</Checkbox>
            </Space>
            <Space>
              <Checkbox onChange={onChange}>Completed 0 requests</Checkbox>
            </Space>
            <Space>
              <Checkbox onChange={onChange}>Completed 1 - 10 requests</Checkbox>
            </Space>
            <Space>
              <Checkbox onChange={onChange}>Completed 10 - 50 requests</Checkbox>
            </Space>
            <Space>
              <Checkbox onChange={onChange}>Completed 50+ requests</Checkbox>
            </Space>
            <Space>
              <Checkbox onChange={onChange}>Awaiting approval</Checkbox>
            </Space>
            <Space>
              <Checkbox onChange={onChange}>My favourites</Checkbox>
            </Space>
          </Flex>

          <Space style={{ padding: 8 }}>
            <Button type="default">cancel</Button>
          </Space>
          <Space style={{ padding: 8 }}>
            <Button type="primary">apply</Button>
          </Space>
        </div>
      )}
    >
      <Button>
        <Space>
          Others
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};
export default Others;
