import { Table as AntTable, TableProps } from "antd";
import React from "react";
import styled from "styled-components";

interface AppTableProps extends TableProps {
  dataSource?: TableProps["dataSource"];
}

const Table = styled(AntTable)`
  .ant-table-thead > tr > th {
    color: rgba(18, 18, 18, 1);
    font-weight: 400;
    font-size: 1rem;
    background-color: rgba(249, 250, 255, 1);
  }
  .ant-table-thead > tr > th .anticon {
    margin-right: 8px;
    vertical-align: middle;
  }

  /* Responsive styles */
  @media (max-width: 768px) {
    .ant-table-thead > tr > th {
      font-size: 0.85rem;
    }
    .ant-table-thead > tr > th .anticon {
      width: 14px;
      height: 14px;
    }
    .ant-table {
      font-size: 0.75rem;
    }
  }

  @media (max-width: 480px) {
    .ant-table-thead {
      display: none;
    }
    .ant-table {
      font-size: 0.7rem;
    }
  }
`;

export const AppTable: React.FC<TableProps> = ({ children, ...props }: TableProps) => {
  return <Table {...props} dataSource={props?.dataSource || []} />;
};
