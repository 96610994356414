import React from "react";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { routeNames } from "lib/routes";
import styled from "styled-components";
import { LeftOutlined } from "@ant-design/icons";
import { Tabs, Select, Input } from "antd";
import { Rate } from "antd";
import type { TabsProps } from "antd";
import vendorprofile from "images/vendorprofile/Ellipse1023Vendorimage.png";
import { ReactComponent as Twitter } from "images/vendorprofile/Twitter.svg";
import { ReactComponent as Facebook } from "images/vendorprofile/Facebook.svg";
import { ReactComponent as Instagram } from "images/vendorprofile/Instagram.svg";
import { ReactComponent as Location } from "images/vendorprofile/location.svg";
import { ReactComponent as Completed } from "images/vendorprofile/completed.svg";
import { ReactComponent as Pending } from "images/vendorprofile/pending.svg";
import { ReactComponent as Lastseen } from "images/vendorprofile/lastseen.svg";
import { ReactComponent as Like } from "images/vendorcard/Favouritelike.svg";
import Packages from "./Packages";
import PastEvents from "./PastEvents";
import Reviews from "./Reviews";
import image1 from "images/vendorprofile/Imgimage1.png";
import image2 from "images/vendorprofile/Imgimage2.png";
import image3 from "images/vendorprofile/Imgimage3.png";
import image4 from "images/vendorprofile/Imgimage4.png";
import image5 from "images/vendorprofile/Imgimage5.png";
import image6 from "images/vendorprofile/Imgimage6.png";
import { ModalBodyContainer, NoticeDisplay, NoticeText, StyledButton, Label, CustomRequestContainer } from "./Packages";
import { ReactComponent as Notice } from "images/vendorprofile/Vectornotice.svg";
import { AppModal } from "ui/components/base";
import { useGetVendorById } from "lib/hooks";

const VendorProfileWrapper = styled.div`
  width: calc(100% - 2rem);
  margin: 0 1rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  @media (max-width: 768px) {
    margin-top: 3rem;
    width: calc(100% - 2rem);
  }
`;

const BackTomarketPlace = styled.div`
  color: #1390e6;
  margin-left: 1rem;
  margin-bottom: 3rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const ProfileContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: row;
  width: calc(100% - 2rem); // Adjust width to fit within the screen with margins
  justify-content: space-between;
  margin: 0 1rem; // Add margin on both sides
  box-sizing: border-box;
  @media (max-width: 768px) {
    flex-direction: column;
    width: calc(100% - 2rem);
  }
`;

const ProfileLeft = styled.div`
  display: flex;
  flex-direction: column;
  width: 45%;
  margin-left: 5%;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: 100%;
    margin-left: 0;
  }
`;

const ProfileRight = styled.div`
  margin-top: 1rem;
  width: 35%;
  margin-right: 5%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: 100%;
    margin-right: 0; // Reset margin on mobile view
  }
`;

const LeftTop = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const TabsContainer = styled.div`
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const VendorDescription = styled.p`
  color: #595959;
  font-size: 0.9rem;
  width: 100%;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const VendorImage = styled.img``;

const NameCategory = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0.5rem;
`;

const NameLike = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const LikeButton = styled(Like)``;

const VendorName = styled.div`
  font-size: 2rem;
  font-weight: 500;
`;

const VendorCategory = styled.button`
  background-color: #fafbff;
  color: #2943d6;
  border: 1px solid;
  border-color: #f1f1f1;
  border-radius: 8px;
  padding: 5px 10px;
  font-size: 10px;
  cursor: not-allowed;
  width: fit-content;
`;

const RightTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
  }
`;

const Text = styled.h5``;

const Socials = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const Address = styled.button`
  background-color: white;
  color: black;
  border: 1px solid;
  border-color: #f1f1f1;
  border-radius: 8px;
  padding: 10px 10px;
  font-size: 13px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const CompletedRequest = styled.button`
  background-color: white;
  color: #168f43;
  border: 1px solid;
  border-color: #f1f1f1;
  border-radius: 8px;
  padding: 10px 10px;
  font-size: 13px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const PendingRequest = styled.button`
  background-color: white;
  color: black;
  border: 1px solid;
  border-color: #f1f1f1;
  border-radius: 8px;
  padding: 10px 10px;
  font-size: 13px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const Ratingcontainer = styled.button`
  background-color: white;
  color: black;
  border: 1px solid;
  border-color: #f1f1f1;
  border-radius: 8px;
  padding: 10px 10px;
  font-size: 13px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const LastActive = styled.button`
  background-color: white;
  color: black;
  border: 1px solid;
  border-color: #f1f1f1;
  border-radius: 8px;
  padding: 10px 10px;
  font-size: 13px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const CustomRequest = styled.button`
  background-color: white;
  color: #1390e6;
  border: 1px solid;
  border-color: #1390e6;
  border-radius: 8px;
  padding: 10px 10px;
  font-size: 13px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

export interface Vendor {
  id: number;
  name: string;
  location: string;
  rating: number;
  image: string;
  category: string;
  description: string;
  completedrequest: string;
  pendingrequest: string;
  lastseen: number;
  events: Event[];
  reviews: Reviews[];
  // packages: Packages[];
}

export interface Packages {
  packageid: number;
  packagetitle: string;
  packagedescription: string;
  unitprice: string;
  upfrontpayment: number;
}

export interface Event {
  eventid: number;
  eventname: string;
  eventdate: string;
  eventorganizer: string;
  images: string[];
}

export interface Reviews {
  reviewid: number;
  reviewdate: string;
  reviewdesc: string;
  revieworganzer: string;
  reviewrating: number;
}

export const DummyData: Vendor[] = [
  {
    id: 1,
    name: "Swift Logistics",
    location: "2200 S 75th Ave, Benin, Edo, Nigeria",
    rating: 4.8,
    image: vendorprofile,
    category: "Logistics",
    description:
      "Create unforgettable moments with our expert stage setup services. We design and construct custom stages for all types of events. Trust us for professional, on-time delivery, and meticulous attention to detail. Your stage, your spotlight.",
    completedrequest: "Completed 4 requests",
    pendingrequest: "0 pending requests",
    lastseen: 1,

    events: [
      {
        eventid: 1,
        eventname: "BigBrother Naija Finale",
        eventdate: "23 Sep 2023",
        eventorganizer: "BBNaija",
        images: [image1, image2, image3], // Add images of the event
      },
      {
        eventid: 2,
        eventname: "Tecno Launch 2022",
        eventdate: "2024-04-25",
        eventorganizer: "Tecno",
        images: [image4, image5, image6], // Add images of the event
      },
      // Add more events as needed
    ],

    reviews: [
      {
        reviewid: 1,
        reviewdate: "23 Sep 2023",
        reviewrating: 5.0,
        reviewdesc:
          "It was nice working with Swift Logistics. Good and fast and swift deliveries as the name implies. Extremely good work.",
        revieworganzer: "BBNaija",
      },
      {
        reviewid: 2,
        reviewdate: "06 May 2022",
        reviewrating: 4.5,
        reviewdesc: "Nice job",
        revieworganzer: "Tecno",
      },
    ],
  },
];

const onChange = (key: string) => {
  console.log(key);
};

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "Packages",
    children: <Packages />,
  },
  {
    key: "2",
    label: "Past Events",
    children: <PastEvents />,
  },
  {
    key: "3",
    label: "Reviews",
    children: <Reviews />,
  },
];

const OrganizerVendorProfile: React.FC = () => {
  const [userId, setUserId] = useState(0);
  const params = useParams<{ id?: string }>();

  const { data: vendor, error: vendorError } = useGetVendorById({ id: userId });

  useEffect(() => {
    const id = params.id ? parseInt(params.id) : undefined;
    if (id) setUserId(id);
  }, [params.id]);

  //custommodal
  const [custommodalOpen, setcustomModalOpen] = useState(false);

  const handlecustomModalOpen = () => {
    setcustomModalOpen(true);
  };

  const handlecustomModalClose = () => {
    setcustomModalOpen(false);
  };

  //options for input in modal
  const options = [
    {
      value: "DevFest 2024",
      label: "DevFest 2024",
    },
    {
      value: "DevFest 2024",
      label: "DevFest 2024",
    },
  ];

  const { TextArea } = Input;

  return (
    <VendorProfileWrapper>
      <StyledLink to={routeNames.organizermarketplace}>
        <BackTomarketPlace>
          <LeftOutlined /> Back to MarketPlace
        </BackTomarketPlace>
      </StyledLink>
      <ProfileContainer>
        <ProfileLeft>
          <LeftTop>
            <VendorImage src={vendorprofile} alt={vendor?.businessName ?? "Vendor"} />
            <NameCategory>
              <NameLike>
                <VendorName>{vendor?.businessName} </VendorName>
                <LikeButton />
              </NameLike>

              <VendorCategory>{vendor?.businessCategoryName}</VendorCategory>
            </NameCategory>
          </LeftTop>
          <VendorDescription>{vendor?.description}</VendorDescription>
          <TabsContainer>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
          </TabsContainer>
        </ProfileLeft>
        <ProfileRight>
          <RightTop>
            <Text>Vendor Information</Text>
            <Socials>
              <Twitter />
              <Facebook />
              <Instagram />
            </Socials>
          </RightTop>
          <Address>
            <Location />
            {vendor?.address}
          </Address>
          <CompletedRequest>
            <Completed />
            Completed 0 requests
          </CompletedRequest>
          <PendingRequest>
            <Pending />0 pending requests
          </PendingRequest>
          <Ratingcontainer>
            <Rate allowHalf defaultValue={0} style={{ fontSize: 16 }} />
            {0} Rating
          </Ratingcontainer>
          <LastActive>
            <Lastseen />
            Last active 1 hour ago
          </LastActive>
          <CustomRequest onClick={handlecustomModalOpen}>Make a custom request</CustomRequest>
        </ProfileRight>
      </ProfileContainer>
      <AppModal
        open={custommodalOpen}
        onCancel={handlecustomModalClose}
        header={<span>Make a custom request</span>}
        size="md"
      >
        <ModalBodyContainer>
          <NoticeDisplay>
            <Notice />
            <NoticeText>
              Choose event and set package quantity based on unit price. For negotiations, create a custom request with
              preferred price and quantity for vendor approval
            </NoticeText>
          </NoticeDisplay>
          <Label>Event</Label>
          <Select defaultValue="Zhejiang" options={options} />
          <CustomRequestContainer>
            <Text>Description of Request</Text>
            <TextArea rows={4} placeholder="Briefly state details about your request" maxLength={10} />
            <Text>Desired quantity</Text>
            <Input placeholder="0" />
          </CustomRequestContainer>
          <StyledButton type="primary" size="large" disabled>
            Send request
          </StyledButton>
        </ModalBodyContainer>
      </AppModal>
    </VendorProfileWrapper>
  );
};

export default OrganizerVendorProfile;
