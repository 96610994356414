import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { tqQueryKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";
import { ISODateString } from "ui/types";

type VerifyPayload = {
  token: string;
};

type VerifyResponse = {
  message: string;
  user: {
    id: number;
    firstName: string;
    lastName: string;
    email: string;
    verified: boolean;
    createdAt: ISODateString;
    updatedAt: ISODateString;
  };
};

type VerifyResult = UseMutationResult<VerifyResponse, Error, VerifyPayload>;

export function useVerify(): VerifyResult {
  return useMutation({
    mutationKey: [tqQueryKeys.auth.verify],
    mutationFn: async (payload: VerifyPayload) => {
      const { token } = payload;
      const res = await EvendyApi.get<VerifyResponse>("auth/verify", {
        params: { token },
      });
      return res.data;
    },
    retry: false,
  });
}
