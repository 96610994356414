import { Layout, MenuProps } from "antd";
import React from "react";
import { css, styled } from "styled-components";
import { layoutHeight } from "ui/theme";
import { AppSidebarNav } from "./SidebarNav";
import { useNavigate } from "react-router-dom";
import { AppFlex } from "../Flex";
import { themeUtils } from "ui/utils";
import { CollapseType } from "antd/es/layout/Sider";

const { getColor } = themeUtils;

export type MenuItem = Required<MenuProps>["items"][number];
export interface AppSidebarProps {
  collapsed: boolean;
  setCollapsed?: (collapsed: boolean) => void;
  bottomItems?: MenuItem[];
  items?: MenuItem[];
}

const { Sider } = Layout;

const StyledSider = styled(Sider)`
  all: unset;
  ${props => css`
    background: ${getColor(props, "baseWhite")} !important; /** FIXME: !important should not be needed */
    max-width: 232px;
    width: 232px;
    /* justify-content: ${props?.collapsed ? "center" : "flex-start"}; */
    justify-content: center;
    box-shadow: 3px 0px 10px 0px #00000005;

    .ant-layout-sider-trigger {
      background: ${getColor(props, "baseWhite")};
      color: ${getColor(props, "gray500")};
    }
  `}
`;

export const AppSidebar: React.FC<AppSidebarProps> = function ({ collapsed, setCollapsed, items, bottomItems }) {
  const navigate = useNavigate();
  return (
    <StyledSider
      collapsible
      collapsed={collapsed}
      width="240px"
      breakpoint="lg"
      collapsedWidth="82px"
      onCollapse={(value: boolean, __type: CollapseType) => setCollapsed?.(value)}
    >
      <AppFlex justify="space-between" vertical height="100%">
        <AppFlex vertical>
          <AppSidebarNav
            mode="inline"
            items={items ?? []}
            onSelect={event => {
              navigate?.(event.key);
            }}
          />
        </AppFlex>

        <AppSidebarNav
          mode="vertical"
          items={bottomItems ?? []}
          onSelect={event => {
            navigate?.(event.key);
          }}
          position="bottom"
        />
      </AppFlex>
    </StyledSider>
  );
};
