import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import styled from "styled-components";

const StyledPhoneInput = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  .PhoneInputCountry {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    padding: 0 10px;
    border-right: 1px solid #d9d9d9;
    background-color: #f7f7f7;
    z-index: 1;
    display: flex;
    align-items: center;
    width: 70px;
  }

  .PhoneInputInput {
    padding-left: 10px;
    /* max-width: auto; */
    /* width: 430px; */
    height: 44px;
    border: 1px solid #d9d9d9;
    border-radius: 8px;
  }
`;

interface PhoneNumberValidationProps {
  value?: string;
  onChange?: (value: string | undefined) => void;
}

const PhoneNumberValidation: React.FC<PhoneNumberValidationProps> = ({ value, onChange }) => {
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>("");
  const [valid, setValid] = React.useState(true);

  const handleChange = (value: string | undefined) => {
    if (onChange) {
      onChange(value);
    }
    setValid(value ? validatePhoneNumber(value) : false);
  };

  const validatePhoneNumber = (phoneNumber: string) => {
    const phoneNumberPattern = /^\+\d{10,14}$/;
    return phoneNumberPattern.test(phoneNumber);
  };

  return (
    <>
      <StyledPhoneInput>
        <PhoneInput
          value={phoneNumber}
          onChange={handleChange}
          defaultCountry="NG"
          countries={["NG", "GH", "GB"]}
          international
        />
      </StyledPhoneInput>
    </>
  );
};

export default PhoneNumberValidation;
