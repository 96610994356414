import { uiUtils } from "ui/utils";

export const defaultBreakpointScale: number[] = [320, 375, 425, 768, 1024, 1440, 2560];
export const defaultBreakpointAliases: string[] = [
  "mobileS",
  "mobileM",
  "mobileL",
  "tablet",
  "laptop",
  "laptopL",
  "desktop",
];
export const defaultBreakpointUnit = "px";

export const breakpoints = defaultBreakpointScale.map(n => uiUtils.addUnit(n, defaultBreakpointUnit));

export const mediaQueries = breakpoints.map(uiUtils.createMediaQuery);
