import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { tqQueryKeys } from "lib/constants";
import { getSession } from "lib/utils";
import { EvendyApi } from "lib/utils/api-utils";
import { useMemo } from "react";

interface Vendor {
  id: number;
  vendorEmail: string;
  businessName: string;
  businessCategoryName: string | null;
  description: string | null;
  phone: string | null;
  avatar: string | null;
  country: string | null;
  state: string | null;
  city: string | null;
  address: string | null;
  workAddress: string | null;
  kycLevel: string;
  role: string;
  accountStatus: string;
  createdAt: string;
  user: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
  };
}

type VendorResult = UseQueryResult<Vendor, Error>;

// type GetVendorByIdPayload = {
//   id: number;
// };

export function useGetVendorByEmail(): VendorResult {
  const session = getSession();

  const email = useMemo(() => session?.email, [session]);

  return useQuery({
    queryKey: [tqQueryKeys.vendors.getVendorById, email],
    queryFn: async (): Promise<Vendor> => {
      const res = await EvendyApi.get<Vendor>(`vendor/get-by-email/${email}`);
      return res.data;
    },
    enabled: !!email,
    retry: false,
  });
}

// import { useQuery, UseQueryResult } from "@tanstack/react-query";
// import { tqQueryKeys } from "lib/constants";
// import { EvendyApi } from "lib/utils/api-utils";
// import { ISODateString } from "ui/types";

// type GetVendorByEmailResponse = {
//   id: number;
//   vendorEmail: string;
//   businessName: string;
//   businessCategoryName: string;
//   description: string;
//   phone: string;
//   avatar: string;
//   country: string;
//   state: string;
//   city: string;
//   address: string;
//   workAddress: string;
//   kycLevel: string;
//   role: string;
//   accountStatus: string;
//   createdAt: string;
//   user: {
//     firstName: string;
//     lastName: string;
//     email: string;
//     phone: string;
//   };
// };

// type UseGetVendorByEmailResult = UseQueryResult<GetVendorByEmailResponse, Error>;

// type GetVendorByEmailPayload = {
//   email: string;
// };

// export function useGetVendorByEmail(payload: GetVendorByEmailPayload): UseGetVendorByEmailResult {
//   const { email } = payload;

//   return useQuery({
//     queryKey: [tqQueryKeys.vendors.getVendorByEmail, email],
//     queryFn: async (): Promise<GetVendorByEmailResponse> => {
//       const res = await EvendyApi.get<GetVendorByEmailResponse>(`vendor/get-by-email/${email}`);
//       return res.data;
//     },
//     enabled: !!email,
//     retry: false,
//   });
// }
