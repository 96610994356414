import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Form, Input, Select } from "antd";
import { AppModal } from "ui/components/base";
import { ModalBodyContainer, NoticeDisplay, NoticeText, Text } from "app/pages/organizer/vendorsprofile/Packages";

const Event = styled.div``;
const Package = styled.div``;
const DesiredQuantity = styled.div``;

const Approve = styled.div``;

const Decline = styled.div`
  margin-top: 0.5rem;
`;

type RegularRequestModalProps = {
  open: boolean;
  onCancel: () => void;
};

const RegularRequestModal: React.FC<RegularRequestModalProps> = ({ open, onCancel }) => {
  return (
    <AppModal open={open} onCancel={onCancel} header={<span>View Request</span>} size="md">
      <ModalBodyContainer>
        <Event></Event>
        <Package></Package>
        <DesiredQuantity></DesiredQuantity>
        <Approve>
          <Button
            type="primary"
            htmlType="submit"
            style={{
              width: "100%",
              color: "white",
              border: "none",
              fontSize: "13px",
              backgroundColor: "#1390E6",
            }}
            size="large"
          >
            {" "}
            Approve
          </Button>
        </Approve>
        <Decline>
          <Button
            htmlType="submit"
            style={{
              width: "100%",

              fontSize: "13px",
            }}
            size="large"
          >
            {" "}
            Decline with a reason
          </Button>
        </Decline>
      </ModalBodyContainer>
    </AppModal>
  );
};

export default RegularRequestModal;
