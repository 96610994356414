import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { tqMutationKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";

interface VendorRequestDto {
  id: number;
  evendtId: number;
  organizerEmail: string;
  packageId: number;
  vendorEmail: string;
  quantity: number;
  status: string;
}

interface PostVendorRequestPayload {
  eventId: number;
  organizerEmail: string;
  packageId: number;
  vendorEmail: string;
  quantity: number;
  status: "pending";
}

type VendorRequestResult = UseMutationResult<VendorRequestDto, Error, PostVendorRequestPayload>;

export function useCreateVendorRequest(): VendorRequestResult {
  return useMutation({
    mutationKey: [tqMutationKeys.vendorRequest.create],
    mutationFn: async (payload: PostVendorRequestPayload): Promise<VendorRequestDto> => {
      const res = await EvendyApi.post<VendorRequestDto>("vendor-request", payload);
      return res.data;
    },
  });
}
