import React, { DependencyList, EffectCallback, useRef } from "react";
/**
 * Identical to React.useEffect, except that it never runs on mount. This is
 * the equivalent of the componentDidUpdate lifecycle function.
 *
 * @param {Function} func - A useEffect effect.
 * @param {Array} [deps] - useEffect dependency list.
 */
const useDidMountEffect = (func: EffectCallback, deps: DependencyList) => {
  const didMount = useRef(false);

  React.useEffect(() => {
    if (didMount.current) func();
    else didMount.current = true;
  }, deps);

  // Reset on unmount for the next mount.
  React.useEffect(() => {
    return () => {
      didMount.current = false;
    };
  }, []);
};

export { useDidMountEffect };
