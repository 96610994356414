import { Flex as AntFlex } from "antd";
import { SizeType } from "antd/es/config-provider/SizeContext";
import React from "react";
import styled from "styled-components";
import { BorderProps, LayoutProps, SpaceProps, border, layout, space } from "styled-system";

interface IAppFlexProps extends SpaceProps, LayoutProps, BorderProps {
  prefixCls?: string;
  rootClassName?: string;
  vertical?: boolean;
  wrap?: React.CSSProperties["flexWrap"];
  justify?: React.CSSProperties["justifyContent"];
  align?: React.CSSProperties["alignItems"];
  flex?: React.CSSProperties["flex"];
  gap?: React.CSSProperties["gap"] | SizeType;
  marginTop?: React.CSSProperties["marginTop"];
  children?: React.ReactNode;
  // component?: React.ComponentType<P> | string;
}

const StyledAntFlex = styled(AntFlex)`
  ${space}
  ${layout}
  ${border}
`;

export const AppFlex: React.FC<IAppFlexProps> = ({ children, ...flexProps }) => {
  return <StyledAntFlex {...flexProps}>{children}</StyledAntFlex>;
};
