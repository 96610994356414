import { UserOutlined } from "@ant-design/icons";
import { Avatar, AvatarProps } from "antd";
import React from "react";
import styled, { css } from "styled-components";
import { AppButton } from "../Buttons";

const AvatarContainer = styled.div`
  ${props => css`
    display: flex;
    align-items: center;
    gap: 20px;
  `}
`;

interface IAvatarProps extends AvatarProps {
  canEdit?: boolean;
}

export const AppAvatar: React.FC<IAvatarProps> = (avatarProps: IAvatarProps) => {
  return (
    <AvatarContainer>
      <Avatar {...avatarProps} />
      {avatarProps?.canEdit && <AppButton variant="secondaryGray">Edit</AppButton>}
    </AvatarContainer>
  );
};

AppAvatar.defaultProps = {
  alt: "",
  icon: <UserOutlined />,
  size: 112,
};
