import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useGetUsers, useGetVendorById } from "lib/hooks";
import { getSession } from "lib/utils/utils";
import { ReactComponent as Totalevent } from "images/organizerevents/Icontotalevents.svg";
import { ReactComponent as Eventconcluded } from "images/organizerevents/Vectoreventsconcluded.svg";
import { ReactComponent as Upcomingevents } from "images/organizerevents/Iconupcomingevents.svg";
import { ReactComponent as Totalrevenue } from "images/organizerevents/Frametotalrevenue.svg";
import { ReactComponent as Hide } from "images/organizerevents/Framehide.svg";
import { ReactComponent as Package } from "images/vendor/package.svg";
import { ReactComponent as Check } from "images/vendor/Check.svg";
import { ReactComponent as Step } from "images/vendor/stepping_up.svg";
import { ReactComponent as Logo } from "images/vendor/Logomark.svg";
import { ReactComponent as Left11 } from "images/vendor/Vector.svg";
import { ReactComponent as Right11 } from "images/vendor/Plant.svg";

import AddPackageModal from "../mypackages/AddPackageModal";

const Container = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  @media (max-width: 768px) {
    width: calc(100% - 2rem);
    margin: 0 1rem;
  }
`;

const Top = styled.div`
  margin-top: -1.4rem;
  @media (max-width: 768px) {
    margin-top: 3rem;

    width: calc(100% - 2rem);
  }
`;

const Header = styled.h3`
  font-size: 32px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 40px;
`;

const First = styled.div`
  margin-top: 1rem;

  @media (max-width: 768px) {
    width: calc(100% - 2rem);
  }
`;

const Second = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  gap: 0.5rem;
  @media (max-width: 768px) {
    flex-direction: column;

    width: calc(100% - 2rem);
  }
`;

const Third = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 1rem;
  gap: 0.5rem;
  @media (max-width: 768px) {
    flex-direction: column;

    width: calc(100% - 2rem);
  }
`;

const EventsInfo = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    gap: 1rem;
  }
`;

const LogoContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
`;

const LogoContainer2 = styled.div`
  position: absolute;
  opacity: 40%;
  bottom: 0;
  left: 0;
`;

const LogoContainer3 = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;

const TotalEvents = styled.div`
  display: flex;
  flex-direction: column;
  width: 21%;
  background-color: #f9faff;
  padding: 1rem;
  border-radius: 15px;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Cardline1 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;
const Cardline2 = styled.p`
  font-size: 20px;
  font-weight: 500;
`;
const Cardline3 = styled.p`
  font-size: 12px;
  color: #595959;
  margin-top: 0;
  margin-bottom: 0;
`;

const Cardline4 = styled.p`
  font-size: 12px;
  color: #595959;
  margin-top: 0;
  margin-bottom: 0;
  text-align: center;
  margin-bottom: 2rem;
`;

const EventsConcluded = styled.div`
  display: flex;
  flex-direction: column;
  width: 21%;
  background-color: #f9faff;
  padding: 1rem;
  border-radius: 15px;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const UpcomingEvents = styled.div`
  display: flex;
  flex-direction: column;
  width: 21%;
  background-color: #f9faff;
  padding: 1rem;
  border-radius: 15px;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const TotalRevenue = styled.div`
  display: flex;
  flex-direction: column;
  width: 21%;
  background-color: #f9faff;
  padding: 1rem;
  border-radius: 15px;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const SmallText = styled.p`
  font-size: 12px;
`;

const Totalamount = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Left = styled.div`
  background-color: #f9faff;
  width: 65%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Leftside = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-bottom: 2rem;
`;

const Largetext = styled.p`
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
`;

const Rightside = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const Right = styled.div`
  background-color: #f9faff;
  width: 35%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Line1 = styled.div``;

const Line2 = styled.div``;

const Line3 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const Line4 = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const Rightdiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const Bluetext = styled.p`
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  color: #1c5b87;
  margin-top: 0;
  margin-bottom: 0;
`;

const Left1 = styled.div`
  background-color: #f9faff;
  width: 40%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Notice = styled.button`
  background-color: #fffefa;
  color: #665000;
  border: 1px solid;
  border-color: #f1f1f1;
  border-radius: 8px;
  padding: 5px 10px;
  font-size: 10px;
  margin-left: 0.5rem;
  width: 8rem;
  margin-top: 1rem;
`;

const Marketing = styled.button`
  background-color: #fffefa;
  color: #1390e6;
  border: 1px solid;
  border-color: #1390e6;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 10px;
  margin-left: 0.5rem;
  width: 18rem;
  margin-top: 1rem;
  cursor: pointer;
`;

const Right1 = styled.div`
  background-color: #f9faff;
  width: 60%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const Addnew = styled.button`
  background-color: #1390e6;
  color: white;
  border: 1px solid;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 1.5rem;
  width: 18rem;
  cursor: pointer;
`;

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const VendorHome: React.FC = () => {
  const [vendorEmail, setVendorEmail] = useState<string | null>(null);
  const [name, setName] = useState<string>("");
  const [custommodalOpen, setcustomModalOpen] = useState(false);

  const handlecustomModalOpen = () => {
    setcustomModalOpen(true);
  };

  const handlecustomModalClose = () => {
    setcustomModalOpen(false);
  };

  useEffect(() => {
    const session = getSession();
    console.log(session);
    if (session) {
      setVendorEmail(session.email);
    }
  }, []);

  const { data: usersData, error: usersError } = useGetUsers();

  useEffect(() => {
    if (vendorEmail && usersData) {
      const user = usersData.find(user => user.email === vendorEmail);
      if (user) {
        const capitalizedFirstName = capitalizeFirstLetter(user.firstName);
        setName(`${capitalizedFirstName}!`);
        console.log("Matching User:", user);
      }
    }
    if (usersError) {
      console.error("Error fetching users data:", usersError);
    }
  }, [vendorEmail, usersData, usersError]);

  return (
    <Container>
      <Top>
        <Header>Welcome, {name}</Header>
      </Top>
      <First>
        <EventsInfo>
          <TotalEvents>
            <Cardline1>
              <Totalevent />
              Total packages added
            </Cardline1>
            <Cardline2>0</Cardline2>
            <Cardline3>0 added this month</Cardline3>
            <LogoContainer>
              <Logo />
            </LogoContainer>
          </TotalEvents>
          <EventsConcluded>
            <Cardline1>
              <Eventconcluded />
              Requests completed
            </Cardline1>
            <Cardline2>0</Cardline2>
            <Cardline3>0 completed this month</Cardline3>
            <LogoContainer>
              <Logo />
            </LogoContainer>
          </EventsConcluded>
          <UpcomingEvents>
            <Cardline1>
              <Upcomingevents />
              Pending requests
            </Cardline1>
            <Cardline2>0</Cardline2>
            <Cardline3>0 pending this month</Cardline3>
            <LogoContainer>
              <Logo />
            </LogoContainer>
          </UpcomingEvents>
          <TotalRevenue>
            <Cardline1>
              <Totalrevenue />
              Total revenue
              <Hide style={{ marginLeft: "1rem" }} />
            </Cardline1>
            <Totalamount>
              <SmallText>NGN</SmallText>
              <Cardline2>0.00</Cardline2>
            </Totalamount>
            <Cardline3>NGN 0 made this month</Cardline3>
            <LogoContainer>
              <Logo />
            </LogoContainer>
          </TotalRevenue>
        </EventsInfo>
      </First>
      <Second>
        <Left>
          <Leftside>
            <Largetext>No packages added yet</Largetext>
            <Cardline3>
              Provide package details, set prices based on unit quantities,
              <br /> and more for event organizers to request
            </Cardline3>
            <Addnew onClick={handlecustomModalOpen}>Add a package</Addnew>
          </Leftside>
          <Rightside>
            <Package />
          </Rightside>
        </Left>
        <Right>
          <Line1>
            {" "}
            <Largetext>To-do</Largetext>
          </Line1>
          <Line2>
            <Cardline3>Begin your success journey with our user-friendly guide</Cardline3>
          </Line2>
          <Line3>
            <Check />
            <Rightdiv>
              <Bluetext>Finish setting up profile</Bluetext>
              <Cardline3>Title, profile image, business name, etc</Cardline3>
            </Rightdiv>
          </Line3>
          <Line4>
            <Check />
            <Rightdiv>
              <Bluetext>Add a package</Bluetext>
              <Cardline3>For visibility, add services / packages as a vendor</Cardline3>
            </Rightdiv>
          </Line4>
        </Right>
      </Second>
      <Third>
        <Left1>
          <Notice>information</Notice>
          <Largetext>Easily manage your finances</Largetext>
          <Cardline4>
            Streamline your revenue and finances with our intuitive <br />
            platform as vendors
          </Cardline4>
          <LogoContainer2>
            <Left11 />
          </LogoContainer2>
        </Left1>
        <Right1>
          <Leftside>
            <Largetext>Want to increase sales?</Largetext>
            <Cardline3>
              Elevate sales through diverse marketing strategies and
              <br /> campaigns for an enhanced business experience
            </Cardline3>
            <Marketing>See marketing tools</Marketing>
          </Leftside>
          <Rightside>
            <Step />
          </Rightside>
          <LogoContainer3>
            <Right11 />
          </LogoContainer3>
        </Right1>
      </Third>

      {/* <AddPackageModal open={custommodalOpen} onCancel={handlecustomModalClose} /> */}
    </Container>
  );
};

export default VendorHome;
