import React from "react";
import { Outlet } from "react-router-dom";
import styled from "styled-components";
import { WebsiteContainer } from "ui/components/base";
import { Button } from "antd";
import { devices } from "ui/theme";
import { Link } from "react-router-dom";
import { routeNames } from "lib/routes";

export const ServiceContainer = styled.div`
  width: 100%;
  height: 302px;
  padding: 10px;
  background-color: rgba(249, 250, 251, 1);
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
const MobileDisplay = styled.div`
  @media ${devices.laptop} {
  }
`;
export const Image = styled.img`
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;
export const Text = styled.div`
  align-items: center;
  justify-content: center;
`;
export const HeaderText = styled.h2`
  font-weight: 600;
  font-size: 1.5rem;
  text-align: center;
`;
export const HeaderParagraph = styled.p`
  font-weight: 400;
  font-size: 1rem;
  text-align: center;
  color: rgba(47, 47, 47, 1);
`;
export const StyledButton = styled(Button)`
  height: 46px;
  border-radius: 8px;
  padding: 12px 40px;
  background-color: rgba(19, 144, 230, 1);
  color: #fff;
  width: 190px;
`;
const ServicesHome = () => {
  return (
    <>
      <WebsiteContainer>
        <Outlet />
        {/* <MobileDisplay>
          <ServiceContainer>
            <Image src="/LandingPage/FaqAvatar.png" />
            <Text>
              <HeaderText>Still have questions?</HeaderText>
              <HeaderParagraph>
                Can&apos;t find the answer you are looking for? Please chat with our friendly team
              </HeaderParagraph>
            </Text>
            <Link to={routeNames.contact}>
              <StyledButton>Get in touch</StyledButton>
            </Link>
          </ServiceContainer>
        </MobileDisplay> */}
      </WebsiteContainer>
    </>
  );
};

export default ServicesHome;
