import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { tqMutationKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";

type EditPackagePayload = {
  id: number;
  data: {
    id: number;
    title?: string;
    description?: string;
    currency?: string;
    price?: number;
    revenue?: number;
    upfrontPayment?: number;
  };
};

interface EditPackageResponse {
  id: number;
  title: string;
  description: string;
  currency: string;
  price: number;
  revenue: number;
  upfrontPayment: number;
  vendorEmail: string;
}

// Define the mutation result type
type UseEditPackageResult = UseMutationResult<EditPackageResponse, Error, EditPackagePayload>;

// Define the useEditPackage hook
export function useEditPackage(): UseEditPackageResult {
  return useMutation({
    mutationFn: async (payload: EditPackagePayload) => {
      const { id, data } = payload;
      const res = await EvendyApi.patch<Promise<EditPackageResponse>>(`vendor-packages/${id}`, data);
      return res.data;
    },
    mutationKey: [tqMutationKeys.packages.edit],
    onError(editError: Error) {
      console.log({ editError });
    },
    onSuccess(data) {
      console.log("Package updated successfully", data);
    },
  });
}
