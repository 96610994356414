import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { LeftOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { Form, Input, Divider, message, InputRef } from "antd";
import { routeNames } from "lib/routes";
import { ReactComponent as Google } from "images/modal/google.svg";
import { ReactComponent as Apple } from "images/modal/apple.svg";
import { useSignup } from "lib/hooks";
import SubmitButton from "ui/components/base/Buttons/SubmitButton";
import { sessionStorageKeys } from "lib/constants";
import ReCAPTCHA from "react-google-recaptcha";
import { appGet } from "lib/utils";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

const Back = styled.div`
  color: #1390e6;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.h3`
  font-size: 21px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
`;

const LargeText = styled.p`
  font-size: 13px;
`;

const SmallText = styled.p<{ selected: boolean }>`
  font-size: 12px;
  color: ${({ selected }) => (selected ? "#1C5B87" : "#9c9c9c")};
  margin-top: 0;
`;

const Name = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: calc(100% - 10px);
`;

const Email = styled.div`
  width: calc(100% - 10px);
`;

const PhoneNumber = styled.div`
  width: 100%;
`;

const Password = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
`;

const Linkto = styled.span`
  color: #1390e6;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const Line1 = styled.div`
  margin-top: -2rem;
  width: 100%;
`;

const Line2 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
`;

const Line3 = styled.div``;

const SignupSocial = styled.button`
  background-color: white;
  color: black;
  border: 1px solid;
  border-color: #e2e8f0;
  border-radius: 8px;
  padding: 10px 10px;
  font-size: 12px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
`;

const SignupFormPage: React.FC = () => {
  const [userType, setUserType] = useState<"organizer" | "vendor" | null>(null);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();
  const { mutate: signup, isError, error } = useSignup();
  const recaptchaKey = process.env.REACT_APP_GOOGLE_RECAPTCHA;
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);

  const phoneInputRef = useRef<InputRef>(null);

  // useEffect(() => {
  //   const input = phoneInputRef.current?.input;

  //   if (input) {
  //     intlTelInput(input, {
  //       initialCountry: "auto",
  //       geoIpLookup: callback => {
  //         fetch("https://ipapi.co/json")
  //           .then(res => res.json())
  //           .then(data => callback(data.country_code))
  //           .catch(() => callback("us"));
  //       },
  //       utilsScript: "intl-tel-input/build/js/utils.js", // for formatting and validating phone numbers
  //     });
  //   }
  // }, [phoneInputRef]);

  if (!recaptchaKey) {
    throw new Error("REACT_APP_GOOGLE_RECAPTCH is not defined");
  }
  const handleRecaptchaVerify = (token: string | null) => {
    setRecaptchaToken(token);
  };

  useEffect(() => {
    const storedUserType = sessionStorage.getItem(sessionStorageKeys.signupUserType);
    if (storedUserType === "organizer" || storedUserType === "vendor") {
      setUserType(storedUserType);
    }
    return () => {
      sessionStorage.removeItem(sessionStorageKeys.signupUserType);
    };
  }, []);

  const onFinish = async (values: any) => {
    if (!recaptchaToken) {
      message.error("Please complete the reCAPTCHA verification.");
      return;
    }

    const { confirmpassword, phoneNumber, ...signupValues } = values;

    // Get the formatted phone number from the `input` property
    let phoneInput = phoneInputRef.current?.input?.value;

    if (phoneInput && phoneInput.length === 11 && phoneInput.startsWith("0")) {
      phoneInput = "234" + phoneInput.slice(1);
    }

    signup(
      { ...signupValues, phone: phoneInput, userType },
      {
        onSuccess: () => {
          console.log("Form submitted successfully");
          sessionStorage.setItem(sessionStorageKeys.signupEmail, values.email);
          navigate(routeNames.authSignupComplete);
        },
        onError: error => {
          console.error("Form submission error: ", error);
          setLoading(false);
        },
      },
    );
  };

  return (
    <Container>
      <StyledLink to={routeNames.authSignup}>
        <Back>
          <LeftOutlined /> Back
        </Back>
      </StyledLink>
      <Top>
        <Header>Sign up</Header>
        <SmallText selected={false}>Create an account as an {userType}</SmallText>
      </Top>
      <Form layout="vertical" form={form} name="register" scrollToFirstError requiredMark={false} onFinish={onFinish}>
        <Name>
          <Form.Item label="First Name" style={{ width: "47.5%" }} name="firstName" rules={[{ required: true }]}>
            <Input placeholder="e.g John" style={{ fontSize: "14px", color: "#9C9C9C", paddingLeft: "10px" }} />
          </Form.Item>
          <Form.Item label="Last Name" style={{ width: "47.5%" }} name="lastName" rules={[{ required: true }]}>
            <Input placeholder="e.g Doe" style={{ fontSize: "14px", color: "#9C9C9C", paddingLeft: "10px" }} />
          </Form.Item>
        </Name>
        <Email>
          <Form.Item
            label="Email Address"
            name="email"
            rules={[
              {
                type: "email",
                message: "The input is not valid E-mail!",
              },
              {
                required: true,
                message: "Please input your E-mail!",
              },
            ]}
          >
            <Input
              placeholder="e.g example@email.com"
              style={{ fontSize: "14px", color: "#9C9C9C", paddingLeft: "10px" }}
            />
          </Form.Item>
        </Email>
        <PhoneNumber>
          <Form.Item
            label="Phone Number"
            name="phone"
            rules={[
              { required: true, message: "Please input your phone number!" },
              {
                validator(_, value) {
                  if (!value) {
                    return Promise.reject(new Error("Phone number is required"));
                  }
                  if (!/^\d{11}$/.test(value)) {
                    return Promise.reject(new Error("Phone number must be exactly 11 digits"));
                  }
                  if (!/^0/.test(value)) {
                    return Promise.reject(new Error("Phone number must start with a 0"));
                  }
                  return Promise.resolve();
                },
              },
            ]}
            style={{ width: "100%" }}
          >
            <Input
              ref={phoneInputRef}
              placeholder="e.g 08061234567"
              style={{ fontSize: "14px", color: "#9C9C9C", width: "100%" }}
            />
          </Form.Item>
        </PhoneNumber>
        <Password>
          <Form.Item
            label="Password"
            style={{ width: "50%" }}
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password placeholder="Enter password" style={{ fontSize: "14px", color: "#9C9C9C" }} />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            style={{ width: "50%" }}
            name="confirmpassword"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("The new password that you entered do not match!"));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Enter password" style={{ fontSize: "14px", color: "#9C9C9C" }} />
          </Form.Item>
        </Password>
        <Form.Item>
          <ReCAPTCHA sitekey={recaptchaKey} onChange={handleRecaptchaVerify} />
        </Form.Item>
        {isError && <p style={{ color: "red" }}>{error && appGet(error, "response.data.message", error.message)}</p>}{" "}
        {/* Display error message */}
        <Form.Item>
          <SubmitButton form={form}>Start {userType === "organizer" ? "organizing" : "vending"}</SubmitButton>
        </Form.Item>
      </Form>
      <Bottom>
        <Line1>
          <Divider plain>or</Divider>
        </Line1>
        <Line2>
          <SignupSocial>
            <Google />
            Continue with Google
          </SignupSocial>
          <SignupSocial>
            <Apple />
            Continue with Apple
          </SignupSocial>
        </Line2>
        <Line3>
          <LargeText>
            Already have an account?
            <StyledLink to={routeNames.authLogin}>
              <Linkto> Login then!</Linkto>
            </StyledLink>
          </LargeText>
        </Line3>
      </Bottom>
    </Container>
  );
};

export default SignupFormPage;
