import React from "react";
import styled from "styled-components";
import { WebsiteContainer, AppBadge } from "ui/components/base";

const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  div {
    width: 100%;
    max-width: 1200px;
    padding: 10px;
  }
  a {
    text-decoration: none;
  }
`;
const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
`;
const HeaderText = styled.h2`
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5rem;
`;
const Paragraph = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.5rem;
`;
const List = styled.ol``;

const ListItem = styled.li``;

const AlphabeticalList = styled.ol`
  list-style-type: lower-alpha;
`;
const RomanList = styled.ol`
  list-style-type: lower-roman;
`;
const Terms = () => {
  return (
    <>
      <WebsiteContainer>
        <Badge>
          <AppBadge group="Terms and Condition"></AppBadge>
        </Badge>
        <Section>
          <div>
            <Paragraph>
              <div>
                <HeaderText>TERMS AND CONDITIONS </HeaderText>
                Welcome to Evendy, an online platform that provides a marketplace that enables Users to search for,
                connect, and book vendors, and their services, for their event needs (the “Service”). Evendy.com is a
                product of Evendy Tech-Services Limited (“Evendy”) which can be assessed through the website (
                <a href="www.getevendy.com" target="_blank" rel="noopener noreferrer">
                  www.getevendy.com
                </a>{" "}
                or{" "}
                <a href="www.evendy.co" target="_blank" rel="noopener noreferrer">
                  evendy.co
                </a>
                ), mobile, tablet, or other applications made available by Evendy. By accessing, downloading,
                installing, or otherwise using{" "}
                <a href="www.evendy.co" target="_blank" rel="noopener noreferrer">
                  https://evendy.co
                </a>{" "}
                (the “Platform”), you agree to comply with these Terms and Conditions. If you do not agree, you must
                cease use of the Platform immediately. For the avoidance of doubt, ‘User’, ‘You’, ‘Your’ refers to the
                person making the booking a vendor (“Customer”); and the person accepting the booking requests
                (“Vendor”) through the Platform. The Terms and Conditions (as amended from time to time by Evendy in its
                sole discretion), along with our privacy policy (
                <a href="www.evendy.co/policy/policy" target="_blank" rel="noopener noreferrer">
                  www.evendy.co/evendy-policy
                </a>
                ), and any other agreement that you have executed with us, form a legally binding contract between you
                and Evendy regarding your use of this Platform. <br /> Please read the Terms and Conditions carefully
                before accessing or using this Platform.
              </div>
              <div>
                <List>
                  <div>
                    <ListItem>
                      <HeaderText>Nature and Applicability of Terms</HeaderText>
                      <Paragraph>
                        Each time you access or use this Platform, you, and if you are acting on behalf of a third
                        party, such third party, agrees to be bound by this Terms and Conditions and our Privacy Policy
                        whether or not you register with us.
                      </Paragraph>
                      <AlphabeticalList>
                        <ListItem>
                          Please review our Privacy Policy, which explains how Evendy handles your personal data when
                          you use this Platform. If you agree to each of the Terms and Conditions and our Privacy
                          Policy, check the “I ACCEPT” or “I AGREE” button at the bottom of this Terms and Conditions.
                        </ListItem>
                        <ListItem>
                          Any modifications and new features added to the Platform are also subject to this Terms and
                          Conditions.
                        </ListItem>
                        <ListItem>
                          All rights, title, and interest in and to the Platform and its components (including all
                          software, technology, copyrights, and other intellectual property rights) will remain with and
                          belong exclusively to Evendy.
                        </ListItem>
                      </AlphabeticalList>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Eligibility for Our Service</HeaderText>
                      <Paragraph>
                        By using this Platform, you represent and warrant that you or your authorized representative
                        have attained the age of majority where you reside (18 years of age in most jurisdictions) and
                        are otherwise capable of entering into binding contracts including this Terms and Conditions. If
                        you are using this Platform on behalf of another party, you represent and warrant that you have
                        authority to act on behalf of that party and to bind that party to this Terms and Conditions.
                      </Paragraph>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Terms and Conditions Applicable to Vendors</HeaderText>
                      <AlphabeticalList>
                        <ListItem>
                          Evendy would review every vendor’s work after every delivery. A vendor can be taken off the
                          Platform after three bad reviews.
                        </ListItem>
                        <ListItem>
                          {" "}
                          Evendy can also ask for an improvement meeting with a vendor that has had three bad reviews.
                        </ListItem>
                        <ListItem>Payment is made 50% before starting and 50% after delivery to vendors.</ListItem>
                        <ListItem>
                          The more good reviews a vendor gets, the more jobs the vendor will get through the Platform.
                        </ListItem>
                        <ListItem>
                          A vendor who doesn’t deliver service after being paid without proper communication will be
                          arrested and prosecuted.
                        </ListItem>
                        <ListItem>
                          Vendors should communicate changes in the date, menu, etc, with Evendy three days before any
                          event and wait for Evendy’s response.
                        </ListItem>
                      </AlphabeticalList>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Accounts and Registration</HeaderText>
                      <AlphabeticalList>
                        <ListItem>
                          To access the features of this Platform, you will be required to register for an account and
                          provide us with some information about yourself (such as your name, date of birth, e-mail
                          address, physical address, phone number, company name, or other personal information). Some of
                          this information may be of a confidential nature and may include personally identifiable
                          information or “PII” (all “Your Information”).
                        </ListItem>
                        <ListItem>
                          If you provide your Information to us then you agree to provide true, current, complete, and
                          accurate information, and not to misrepresent your identity. You also agree to keep Your
                          Information current and to update Your Information if any of Your Information changes.
                        </ListItem>
                        <ListItem>
                          Our collection, use, and disclosure of Your Information are governed by this Terms and
                          Conditions, our Privacy Policy, and any applicable law.
                        </ListItem>
                      </AlphabeticalList>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Account Management</HeaderText>
                      <AlphabeticalList>
                        <ListItem>
                          Keep Your Password Secure <br />
                          If you have been issued an account by Evendy in connection with your use of the Platform, you
                          are responsible for safeguarding your password and any other credentials used to access that
                          account, even if you authorize other parties in your organization to access your account. You,
                          and not Evendy, are responsible for any activity occurring on your account, including, but not
                          limited to, incidences where your password has been compromised. If you become aware of any
                          unauthorized access to your account, you should notify Evendy immediately. You shall also
                          conduct internal investigations and where it is determined that such an account has been
                          compromised at your instance and shall be solely responsible for the direct losses incurred by
                          Evendy and others due to any unauthorized use of your account.
                        </ListItem>
                        <ListItem>
                          Keep Your Details Accurate <br /> Evendy may send notices to the email address or text
                          messages to your mobile phone registered with your account. You must keep your email address,
                          mobile phone number, and, where applicable, your contact details and payment details
                          associated with your account current and accurate.
                        </ListItem>
                      </AlphabeticalList>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Breach Notification Policy</HeaderText>
                      <Paragraph>
                        If you suspect or learn that the security of the Service and the data contained therein has been
                        breached or compromised, you agree to immediately notify us at <a>support@evendy.com</a>. You
                        agree that the User, and not Evendy, shall be responsible for the legal consequences of failing
                        to comply with Evendy’s breach notification policy, and you shall hold Evendy free from any
                        liability arising from such failure to provide notification following the breach or compromise
                        of the Service data.
                      </Paragraph>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Unacceptable Use of our Platform</HeaderText>
                      <AlphabeticalList>
                        <ListItem>
                          {" "}
                          You shall not and shall not permit any person using your device or account to:
                          <RomanList>
                            <ListItem>
                              use this Platform in a way that causes or could cause damage or restrict the availability
                              or accessibility of the Platform;
                            </ListItem>
                            <ListItem>
                              extract, mine, scrap proprietary and personal data from this Platform without our express
                              and written permission;
                            </ListItem>
                            <ListItem>
                              store, copy, transmit, publish or distribute any computer virus, spyware, Trojan horse,
                              worm, keystroke logger or other malicious software via this Platform;
                            </ListItem>
                            <ListItem>conduct any fraudulent or illegal activities via the Platform.</ListItem>
                          </RomanList>
                        </ListItem>
                        <ListItem>
                          You understand and agree that if any fraudulent activity is associated with the operation of
                          your account, we shall have the right to apply restrictions to your account and report it to
                          the appropriate law enforcement agencies.
                        </ListItem>
                      </AlphabeticalList>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Information Accuracy</HeaderText>
                      <AlphabeticalList>
                        <ListItem>
                          We attempt to ensure that information on this Platform is accurate and current. Despite our
                          best efforts, the information on our Platform may occasionally be inaccurate, incomplete or
                          out of date. We make no representation, warranty, or guarantee regarding the reliability,
                          completeness, accuracy, or currency of any information on the Platform.
                        </ListItem>
                        <ListItem>
                          Furthermore, information on the Platform may contain typographical errors, inaccuracies, or
                          omissions. We reserve the right to correct or make changes in such information at any time
                          without notice. Provided that such changes shall not affect the user’s ability to access or
                          use the Platform.
                        </ListItem>
                      </AlphabeticalList>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Interruption of Service</HeaderText>
                      <Paragraph>
                        Unless otherwise stated in the applicable Service Level Agreement, your access and use of our
                        Platform may be interrupted for any of several reasons, including, without limitation, the
                        malfunction of equipment, periodic updating, maintenance or repair of our Services, Software, or
                        other actions that we may elect to take. Evendy shall have no liability for any damage or loss
                        caused as a result of such interruptions.
                      </Paragraph>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Third Party Links, Services and Content</HeaderText>
                      <Paragraph>
                        The Platform may contain features, services and functionalities linking you to, or providing you
                        with access to third party services and content, websites, directories, servers, networks,
                        systems, information, databases, applications, software, programs, courses, services, and the
                        Internet as a whole. Because we have no control over such sites and resources, we are not
                        responsible for the availability of such external sites or resources, and do not endorse and are
                        not responsible or liable for any content, advertising, courses or other materials on or
                        available from such sites or resources. When you visit or use a third party’s website you agree
                        to read and consent to the third party’s Terms and Conditions and Privacy Policy and you release
                        us from any liability.
                      </Paragraph>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Rights and Obligations Relating to User Content</HeaderText>
                      <Paragraph>
                        “User Content”, means, without limitation, any messages, texts, digital files, images, photos,
                        personal profile, artwork, videos, audio, comments, feedback, suggestions, and documents, or any
                        other content you upload, transmit or otherwise make available to Evendy and its Users via the
                        Platform.
                      </Paragraph>
                      <AlphabeticalList>
                        <ListItem>
                          <Paragraph>
                            Evendy hereby informs Users that they are not permitted to host, display, upload, modify,
                            publish, transmit, update or share any information that:
                          </Paragraph>
                          <RomanList>
                            <ListItem>
                              belongs to another person and to which the User does not have any right to;
                            </ListItem>
                            <ListItem>
                              is grossly harmful, harassing, blasphemous, defamatory, obscene, pornographic, pedophilic,
                              libelous, invasive of another’s privacy, hateful, or racially, ethnically objectionable,
                              disparaging, relating or encouraging money laundering or gambling, or otherwise unlawful
                              in any manner whatever;
                            </ListItem>
                            <ListItem>harm minors in any way;</ListItem>
                            <ListItem>infringes any patent, trademark, copyright or other proprietary rights;</ListItem>
                            <ListItem>violates any law for the time being in force;</ListItem>
                            <ListItem>
                              deceives or misleads the addressee about the origin of such messages or communicates any
                              information which is grossly offensive or menacing in nature;
                            </ListItem>
                            <ListItem>impersonates another person;</ListItem>
                            <ListItem>
                              contains software viruses or any other computer code, files or programs designed to
                              interrupt, destroy or limit the functionality of any computer resource;
                            </ListItem>
                            <ListItem>
                              threatens the unity, integrity, defense, security or sovereignty of India, friendly
                              relations with foreign states, or public order or causes incitement to the commission of
                              any cognizable offense or prevents investigation of any offense or is insulting any other
                              nation.
                            </ListItem>
                          </RomanList>
                        </ListItem>
                        <ListItem>
                          <Paragraph>Users are also prohibited from:</Paragraph>
                          <RomanList>
                            <ListItem>
                              violating or attempting to violate the integrity or security of the Website or Application
                              or any Evendy Content;
                            </ListItem>
                            <ListItem>
                              transmitting any information (including job posts, messages and hyperlinks) on or through
                              the Website or Application that is disruptive or competitive to the provision of Services
                              by Evendy;
                            </ListItem>
                            <ListItem>
                              intentionally submitting on the Website or Application any incomplete, false or inaccurate
                              information;
                            </ListItem>
                            <ListItem>making any unsolicited communications to other Users;</ListItem>
                            <ListItem>
                              {" "}
                              using any engine, software, tool, agent or other device or mechanism (such as spiders,
                              robots, avatars or intelligent agents) to navigate or search the Website or Application;
                            </ListItem>
                            <ListItem>
                              attempting to decipher, decompile, disassemble or reverse engineer any part of the Website
                              or Application;
                            </ListItem>
                            <ListItem>
                              copying or duplicating in any manner any of the Evendy Content or other information
                              available from the Website or Application;
                            </ListItem>
                            <ListItem>framing or hot linking or deep linking any Evendy Content.</ListItem>
                            <ListItem>
                              circumventing or disabling any digital rights management, usage rules, or other security
                              features of the Application.
                            </ListItem>
                          </RomanList>
                        </ListItem>
                        <ListItem>
                          Evendy, upon obtaining knowledge by itself or being brought to actual knowledge by an affected
                          person in writing or through email signed with electronic signature about any such information
                          as mentioned above, shall be entitled to disable such information that is in contravention of
                          Clauses 11(a). Evendy shall also be entitled to preserve such information and associated
                          records for at least 90 (ninety) days for production to governmental authorities for
                          investigation purposes.
                        </ListItem>
                        <ListItem>
                          In case of non-compliance with any applicable laws, rules or regulations, or the Agreement
                          (including the Privacy Policy) by a User, Evendy has the right to immediately terminate the
                          access or usage rights of the User to the Application and Services and to remove non-compliant
                          information from the Application or Website.
                        </ListItem>
                        <ListItem>
                          Evendy may disclose or transfer User-generated information to its affiliates or governmental
                          authorities in such manner as permitted or required by applicable law, and you hereby consent
                          to such transfer.
                        </ListItem>
                        <ListItem>
                          Evendy respects the intellectual property rights of others and we do not hold any
                          responsibility for any violations of any intellectual property rights.
                        </ListItem>
                      </AlphabeticalList>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Termination</HeaderText>
                      <AlphabeticalList>
                        <ListItem>
                          <Paragraph>
                            Evendy reserves the right to suspend or terminate a User’s access to the Application or
                            Website and the Services, with or without notice and to exercise any other remedy available
                            under law, in cases where,
                          </Paragraph>
                          <RomanList>
                            <ListItem>Such User breaches any terms and conditions of the Agreement;</ListItem>
                            <ListItem>
                              A third-party reports violation of any of its right as a result of your use of the
                              Platform;
                            </ListItem>
                            <ListItem>
                              Evendy is unable to verify or authenticate any information provided to Evendy by a User;
                            </ListItem>
                            <ListItem>
                              Evendy has reasonable grounds for suspecting any illegal, fraudulent or abusive activity
                              on the part of such User; or
                            </ListItem>
                            <ListItem>
                              Evendy believes, in its sole discretion, that User’s actions may cause legal liability for
                              such User, other Users, or for Evendy or are contrary to the interests of the Application
                              or Website.
                            </ListItem>
                          </RomanList>
                        </ListItem>
                        <ListItem>
                          Once temporarily suspended, indefinitely suspended, or terminated, the User may not continue
                          to use the Application or Website under the same account, a different account, or re-register
                          under a new account. On termination of an account due to the reasons mentioned herein, such
                          User shall no longer have access to data, messages, files and other material kept on the
                          Application or Website by such User. The User shall ensure that he/she/it has continuous
                          backup of any medical services the User has rendered in order to comply with the User’s record
                          keeping process and practices.
                        </ListItem>
                      </AlphabeticalList>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Limitation of Liability</HeaderText>
                      <Paragraph>
                        In no event, including but not limited to negligence, shall Evendy, or any of its directors,
                        officers, employees, agents or content or service providers (collectively, the “Protected
                        Entities”) be liable for any direct, indirect, special, incidental, consequential, exemplary or
                        punitive damages arising from, or directly or indirectly related to, the use of, or the
                        inability to use, the Application or the Content, materials, and functions related thereto,
                        User’s provision of information via the Application, lost business or lost Users. In no event
                        shall the Protected Entities be liable for:
                      </Paragraph>
                      <AlphabeticalList>
                        <ListItem>
                          any content posted, transmitted, exchanged or received by or on behalf of any User or other
                          person on or through the Application;
                        </ListItem>
                        <ListItem>any unauthorized access to or alteration of your transmissions or data; or</ListItem>
                        <ListItem>
                          any other matter relating to the Application. <br /> In no event shall the total aggregate
                          liability of the Protected Entities to a User for all damages, losses and causes of action
                          (whether in contract or tort, including, but not limited to, negligence or otherwise) arising
                          from this Agreement or a User’s use of the Website or the Services or the Platform exceed the
                          cost of the relevant Service
                        </ListItem>
                      </AlphabeticalList>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Disclaimers; No Warranties</HeaderText>
                      <AlphabeticalList>
                        <ListItem>
                          SAVE AS OTHERWISE AGREED IN WRITING BY PARTIES, THE EVENDY PARTIES DO NOT WARRANT THAT THE
                          SERVICES OR THE SERVERS THAT MAKE THE PLATFORM AVAILABLE WILL BE FREE OF VIRUSES OR OTHER
                          HARMFUL COMPONENTS, OR THAT ANY PRODUCT DESCRIPTION OR OTHER CONTENT OFFERED AS PART OF THE
                          SERVICES ARE ACCURATE, RELIABLE, CURRENT OR COMPLETE.
                        </ListItem>
                        <ListItem>
                          YOU EXPRESSLY AGREE THAT YOUR USE OF THE PLATFORM IS AT YOUR SOLE RISK. IF YOU DOWNLOAD ANY
                          CONTENT ON THE PLATFORM, YOU DO SO AT YOUR OWN DISCRETION AND RISK. YOU WILL BE SOLELY
                          RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE
                          DOWNLOAD OF SUCH CONTENT THROUGH THE PLATFORM.
                        </ListItem>
                        <ListItem>
                          YOU AGREE TO DEFEND AND HOLD EVENDY AND ANY OF ITS PROTECTED ENTITIES HARMLESS AGAINST ANY AND
                          ALL LEGAL CLAIMS AND DEMANDS, INCLUDING REASONABLE ATTORNEY’S FEES, WHICH MAY ARISE FROM OR
                          RELATE TO YOUR USE OR MISUSE OF THE PLATFORM, YOUR BREACH OF THIS ‘TERMS OF USE OR YOUR
                          ACTIONS. YOU AGREE THAT EVENDY SHALL BE FREE TO ELECT ITS OWN LEGAL COUNSEL AND PARTICIPATE IN
                          ITS OWN DEFENSE IF IT WISHES.
                        </ListItem>
                      </AlphabeticalList>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>APPLICABLE LAW AND DISPUTE SETTLEMENT</HeaderText>
                      <Paragraph>
                        You agree that this Terms and Conditions and any contractual obligation between Evendy and User
                        will be governed by the laws of the Federal Republic of Nigeria.
                      </Paragraph>
                      <Paragraph>
                        If a dispute or difference arises as to the validity, interpretation, effects, or rights and
                        obligations of the Parties under this Terms and Conditions, the Parties shall use their best
                        endeavor to reach an amicable settlement of the dispute.
                      </Paragraph>
                      <Paragraph>
                        If any such dispute is not settled between the Parties within fourteen (14) days, the Parties
                        agree to submit such dispute to Mediation at the Lagos State Multi-Door Courthouse (LMDC) for
                        resolution under the provisions of Lagos State Multi-Door Courthouse (LMDC) Law 2007.
                      </Paragraph>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>CONTACT INFORMATION</HeaderText>
                      <Paragraph>
                        If a User has any questions concerning Evendy, the Website or application, the Platform, this
                        Terms and Conditions, or anything related to any of the foregoing, Evendy User support can be
                        reached at the following email address:{" "}
                        <a href="mailto:support@evendy.co">support@evendy.com</a>.
                      </Paragraph>
                    </ListItem>
                  </div>
                </List>
              </div>
            </Paragraph>
          </div>
        </Section>
      </WebsiteContainer>
    </>
  );
};
export default Terms;
