import React from "react";
import styled from "styled-components";
import { Tabs, Select, Input } from "antd";
import type { TabsProps } from "antd";
import Payments from "./Payments";
import VendorRequests from "./VendorRequests";

const Container = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: column;
`;

const Header = styled.h3`
  font-size: 21px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
`;

const onChange = (key: string) => {
  console.log(key);
};

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "Vendor Requests",
    children: <VendorRequests />,
  },
  {
    key: "2",
    label: "Payments",
    children: <Payments />,
  },
];

const RequestAndPayments = () => {
  return (
    <Container>
      <Header>Requests & Payments</Header>
      <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
    </Container>
  );
};

export default RequestAndPayments;
