import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { tqQueryKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";

interface Vendor {
  id: number;
  vendorEmail: string;
  businessName: string | null;
  businessCategoryName: string | null;
  description: string | null;
  phone: string | null;
  avatar: string | null;
  country: string | null;
  state: string | null;
  city: string | null;
  address: string | null;
  workAddress: string | null;
  kycLevel: string;
  role: string;
  accountStatus: string;
  createdAt: string;
}

type VendorResult = UseQueryResult<Vendor, Error>;

type GetVendorByIdPayload = {
  id: number;
};

export function useGetVendorById(payload: GetVendorByIdPayload): VendorResult {
  const { id } = payload;

  return useQuery({
    queryKey: [tqQueryKeys.vendors.getVendorById, id],
    queryFn: async (): Promise<Vendor> => {
      const res = await EvendyApi.get<Vendor>(`vendor/${id}`);
      return res.data;
    },
    enabled: !!id,
    retry: false,
  });
}
