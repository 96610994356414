import { TextTransformMap } from "./types";

// const aliases = ["xs", "sm", "md", "lg", "xl"];
export const fonts = {
  DEFAULT: ["BR Firma"],
  mono: [
    "ui-monospace",
    "SFMono-Regular",
    "Menlo",
    "Monaco",
    "Consolas",
    '"Liberation Mono"',
    '"Courier New"',
    "monospace",
  ],
  evendy: ["BR Firma"],
  sans: [
    "ui-sans-serif",
    "system-ui",
    "-apple-system",
    "BlinkMacSystemFont",
    '"Segoe UI"',
    "Roboto",
    '"Helvetica Neue"',
    "Arial",
    '"Noto Sans"',
    "sans-serif",
    '"Apple Color Emoji"',
    '"Segoe UI Emoji"',
    '"Segoe UI Symbol"',
    '"Noto Color Emoji"',
  ],
  serif: ["ui-serif", "Georgia", "Cambria", '"Times New Roman"', "Times", "serif"],
};
export const fontSizes = {
  xs: "0.75rem",
  sm: "0.875rem",
  md: "1rem",
  lg: "1.125rem",
  xl: "1.25rem",
  displayXs: "1.5rem",
  displaySm: "1.875rem",
  displayMd: "2.25rem",
  displayLg: "3rem",
  displayXl: "3.75rem",
  display2xl: "4.5rem",
};
export const lineHeights = {
  xs: "1.125rem",
  sm: "1.25rem",
  md: "1.5rem",
  lg: "1.75rem",
  xl: "1.875rem",
  displayXs: "2rem",
  displaySm: "2.375rem",
  displayMd: "2.75rem",
  displayLg: "3.75rem",
  displayXl: "4.5rem",
  display2xl: "5.625rem",
};
export const letterSpacings = {
  xs: "normal",
  sm: "normal",
  md: "normal",
  lg: "normal",
  xl: "normal",
  displayXs: "normal",
  displaySm: "normal",
  displayMd: "-2%",
  displayLg: "-2%",
  displayXl: "-2%",
  display2xl: "-2%",
};
export const fontWeights = {
  regular: "400",
  medium: "500",
  semibold: "600",
  bold: "700",
};

export const textTransforms: TextTransformMap = {
  capitalize: "capitalize",
  lowercase: "lowercase",
  uppercase: "uppercase",
  none: "none",
  inherit: "inherit",
  initial: "initial",
  unset: "unset",
};
