import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { tqQueryKeys } from "lib/constants";
import { EvendyApi, getSession } from "lib/utils";
import { useMemo } from "react";

import { GetUserDto } from "ui/types";

type UseGetUserResult = UseQueryResult<GetUserDto, Error>;

export function useGetUser(): UseGetUserResult {
  const session = getSession();

  const id = useMemo(() => session?.userId, [session]);

  return useQuery({
    queryKey: [tqQueryKeys.users.getUserById, id],
    queryFn: async () => {
      // if (!id) throw new Error("user id is required to fetch user profile");
      const res = await EvendyApi.get<GetUserDto>(`users/${id}`);
      return res.data;
    },
    enabled: !!id,
  });
}
