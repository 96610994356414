import React, { useState } from "react";
import {
  FAQHeader,
  FAQSubHeader,
  FAQContainer,
  ItemContainer,
  TextHeader,
  CircleIcon,
  TextParagraph,
  Faq,
  LeftSpiralContainer,
  RightSpiralContainer,
  SeeMore,
} from "./faq.style";
import Left from "images/landingpage/Faq/left.png";
import Right from "images/landingpage/Faq/right.png";

interface FAQItemProps {
  question: string;
  answer: string;
}

const FAQItem: React.FC<FAQItemProps> = ({ question, answer }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  return (
    <ItemContainer onClick={toggleVisibility}>
      <TextHeader>
        <div>{question}</div>
        <CircleIcon>{isVisible ? "-" : "+"}</CircleIcon>
      </TextHeader>
      {isVisible && <TextParagraph>{answer}</TextParagraph>}
    </ItemContainer>
  );
};

const FAQSection: React.FC = () => {
  const [showAll, setShowAll] = useState(false);

  // All FAQ items
  const faqItems = [
    {
      question: "What is Evendy?",
      answer:
        "Evendy is a comprehensive event management platform designed to simplify and enhance the event handling process...",
    },
    {
      question: "How can I get started with Evendy?",
      answer:
        "Getting started with Evendy is easy! Simply visit our website at www.evendy.co and sign up for an account...",
    },
    {
      question: "What types of events can I organize using Evendy?",
      answer:
        "Evendy is versatile and can be used for a wide range of events, including conferences, seminars, weddings, parties...",
    },
    {
      question: "What features does Evendy offer?",
      answer:
        "Evendy offers a suite of features, including registrations and ticketing, vendor sourcing and management...",
    },
    {
      question: "Can I customize the registration process for my event?",
      answer:
        "Absolutely! Evendy allows you to create customized registration forms and set up different ticket types...",
    },
    {
      question: "How can Evendy help me with vendor management?",
      answer: "Our platform facilitates vendor sourcing and management by connecting you with trusted vendors...",
    },
    {
      question: "Can I communicate with vendors directly on your platform?",
      answer: "No, all communication is done on the evendy platform and a representative is available to assist you...",
    },
    {
      question: "Is Evendy suitable for both small and large-scale events?",
      answer: "Yes, Evendy is designed to cater to events of all sizes...",
    },
    {
      question: "How can I promote my event using Evendy?",
      answer:
        "Evendy's marketing feature provides you with tools and partners to help you amplify your event's reach...",
    },
    {
      question: "Is my data secure on the Evendy platform?",
      answer: "Yes, we take data security seriously...",
    },
    {
      question: "How can I get support if I encounter issues?",
      answer:
        "If you have any questions, concerns, or issues, our dedicated customer support team is here to assist you...",
    },
  ];

  // Show first 5 items by default
  const initialFAQCount = 6;

  return (
    <FAQContainer>
      <Faq>FAQ</Faq>
      <FAQHeader>Frequently Asked Questions</FAQHeader>
      <FAQSubHeader>Everything you need to know about Evendy</FAQSubHeader>

      {/* Display only limited FAQ items by default */}
      {faqItems.slice(0, showAll ? faqItems.length : initialFAQCount).map((item, index) => (
        <FAQItem key={index} question={item.question} answer={item.answer} />
      ))}

      {/* See more/less button */}
      {/* <div style={{ textAlign: "center", marginTop: "20px" }}>
        <button onClick={() => setShowAll(!showAll)}>{showAll ? "See Less" : "See More"}</button>
      </div> */}

      <SeeMore onClick={() => setShowAll(!showAll)}>{showAll ? "See Less" : "Load More"}</SeeMore>

      <LeftSpiralContainer>
        <img src={Left} alt="Service Content" />
      </LeftSpiralContainer>
      <RightSpiralContainer>
        <img src={Right} alt="Service Content" />
      </RightSpiralContainer>
    </FAQContainer>
  );
};

export default FAQSection;
