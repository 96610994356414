import React, { useState } from "react";
import RatingComponent from "./component/RatingComponet";

//Rating Component
interface ReviewData {
  rating: number;
  review: string;
}
const Ratings: React.FC = () => {
  const [reviews, setReviews] = useState<ReviewData[]>([]);

  const handleReviewSubmit = (reviewData: ReviewData) => {
    setReviews(prevReviews => [...prevReviews, reviewData]);
  };

  return (
    <>
      <div>
        <h1>Rating and Review</h1>
        <RatingComponent onSubmit={handleReviewSubmit} />
        <div>
          {reviews.map((review, index) => (
            <div key={index}>
              <p>Rating: {review.rating}</p>
              <p>Review: {review.review}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Ratings;
