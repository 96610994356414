import React from "react";
import styled from "styled-components";
import { ReactComponent as Noevent } from "images/organizerevents/cuatenoevents.svg";
import { ReactComponent as Create } from "images/organizerevents/Vectoradd.svg";
import { Link } from "react-router-dom";
import { routeNames } from "lib/routes";

const ListOfEvents = styled.div`
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
`;

const CenteredWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* To stack the elements vertically */
  gap: 1rem; /* Adjust gap as needed */
`;

const Text = styled.h4`
  font-size: 20px;
  font-weight: 500;
  text-align: left;
`;
const CreateEvent = styled(Link)`
  all: unset;
  cursor: pointer;
  background-color: #1390e6;
  color: white;
  border: 1px solid;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  width: 9rem;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const NoEvent = () => {
  return (
    <>
      <CenteredWrapper>
        <ListOfEvents>
          <Noevent />
          <Text>No created events yet</Text>
        </ListOfEvents>
        <CreateEvent to={routeNames.newEvent}>
          <Create />
          create event
        </CreateEvent>
      </CenteredWrapper>
    </>
  );
};

export default NoEvent;
