import { useMutation, UseMutationResult } from "@tanstack/react-query";
import { tqMutationKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";

type ForgotPasswordPayload = {
  email: string;
};

type ForgotPasswordResponse = {
  message: string;
};

type ForgotPasswordResult = UseMutationResult<ForgotPasswordResponse, Error, ForgotPasswordPayload>;

export function useForgotPassword(): ForgotPasswordResult {
  return useMutation({
    mutationKey: [tqMutationKeys.auth.forgotPassword],
    mutationFn: async (payload: ForgotPasswordPayload) => {
      const { email } = payload;
      const res = await EvendyApi.get<ForgotPasswordResponse>("auth/forgot-password", {
        params: { email },
      });
      return res.data;
    },
    retry: false,
  });
}
