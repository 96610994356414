import React, { useState, useEffect } from "react";
import styled from "styled-components";
// import { ReactComponent as Star } from "images/landingpage/services/Stars.svg";

interface ActiveProp {
  active: boolean;
}

const TestimonialsContainer = styled.div`
  background-color: #f9fafb;
  margin: 4rem;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 3rem;
  @media (max-width: 768px) {
    margin: 1rem;
  }
`;

const CarouselContainer = styled.div`
  width: 50%;
  margin: 0 auto;
  text-align: center;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const TestimonialSlide = styled.div<ActiveProp>`
  display: ${props => (props.active ? "block" : "none")};
  font-size: 18px;
  padding: 20px;
  transition: opacity 0.5s ease-in-out;

  p {
    font-size: 18px;
    line-height: 1.5;
    color: #333;
  }
`;

const Testimony = styled.p`
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: center;
  color: #101828;
  margin-top: 0;
  margin-bottom: 0;
`;

const Author = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #101828;
  margin-top: 0;
  margin-bottom: 0;
`;

const Company = styled.p`
  color: #101828;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
`;

const Dots = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 10px;
`;

const Dot = styled.div<ActiveProp>`
  height: 10px;
  width: 10px;
  background-color: ${props => (props.active ? "#007bff" : "#ddd")};
  border-radius: 50%;
  margin: 0 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
`;

const Testimonial = styled.button`
  background-color: #7ef88b33;
  color: #168f43;
  border: none;
  padding: 8px 24px 8px 24px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 600;
  line-height: 15.08px;
  text-align: center;
  margin-top: 3rem;
  margin-bottom: 0;
`;

const testimonials = [
  {
    text: "The service was good and vendor easy to  work with. Would recommend them",
    author: "Adedamola Oni",
    company: "",
  },
  {
    text: "Their service was really amazing and Professional. We will always recommend them",
    author: "Samirah Bello",
    company: "Thrive Agric",
  },
  {
    text: "We've had consistent exceptional experience with their vendors and services which has enhanced our events. Their vendors are reliable, professional, and deliver high-quality services.",
    author: "Titi",
    company: "Alt School",
  },
];

const NewTestimonial: React.FC = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide(prevSlide => (prevSlide + 1) % testimonials.length);
    }, 5000);

    return () => clearInterval(interval); // Cleanup the interval on component unmount
  }, []);

  return (
    <TestimonialsContainer>
      <Testimonial>TESTIMONIALS</Testimonial>
      <CarouselContainer>
        {testimonials.map((testimonial, index) => (
          <TestimonialSlide key={index} active={index === currentSlide}>
            <Testimony>{testimonial.text}</Testimony>
            {/* <Star /> */}
            <Author>{testimonial.author}</Author>
            <Company>{testimonial.company}</Company>
          </TestimonialSlide>
        ))}
        <Dots>
          {testimonials.map((_, index) => (
            <Dot key={index} active={index === currentSlide} />
          ))}
        </Dots>
      </CarouselContainer>
    </TestimonialsContainer>
  );
};

export default NewTestimonial;
