import React from "react";
import styled from "styled-components";
import { routeNames } from "lib/routes";
import { Link } from "react-router-dom";
import { ReactComponent as Evendy } from "images/footer/evendy.svg";
import { ReactComponent as Twitter } from "images/footer/twitter.svg";
import { ReactComponent as Linkedin } from "images/footer/linkedin.svg";
import { ReactComponent as Facebook } from "images/footer/facebook.svg";

const FooterContainer = styled.div`
  background-color: #0c111d;
  padding-top: 3rem;
  padding-bottom: 2rem;
  padding-left: 6rem;
  padding-right: 6rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  z-index: 100;
  @media (max-width: 768px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
`;

const FoooterTop = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const FooterBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 2rem;
  }
`;

const TopLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const TopRight = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10rem;

  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 3rem;
    div {
      width: 35%; /* This will place two divs in one row and two in the next row */
    }
  }
`;

const Company = styled.div`
  display: flex;
  flex-direction: column;
`;
const Resources = styled.div`
  display: flex;
  flex-direction: column;
`;
const Social = styled.div`
  display: flex;
  flex-direction: column;
`;
const Legal = styled.div`
  display: flex;
  flex-direction: column;
`;

const BottomLeft = styled.div``;

const BottomRight = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
`;

const SmallText = styled.p`
  color: #eaecf0;
  font-size: 14px;
  font-weight: 400;
  line-height: 32px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
`;

const SmallTextBold = styled.p`
  color: #eaecf0;
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
`;

const FooterLink = styled.a`
  text-decoration: none;
  cursor: pointer;
  color: #eaecf0;
  font-size: 14px;
  font-weight: 600;
  line-height: 32px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  opacity: 1;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 0.5;
  }
`;

const NewFooter: React.FC = () => {
  return (
    <FooterContainer>
      <FoooterTop>
        <TopLeft>
          <Evendy />
          {/* <SmallText>
            Design amazing digital experiences that
            <br /> create more happy in the world.
          </SmallText> */}
        </TopLeft>
        <TopRight>
          <Company>
            <SmallText>Company</SmallText>
            <FooterLink href="/about/about">About us</FooterLink>
            <FooterLink href="/">Features</FooterLink>
            <FooterLink href="/">Services</FooterLink>
            <FooterLink href="/">Tutorials</FooterLink>
            <FooterLink href="/contact/contact">Contact</FooterLink>
          </Company>
          <Resources>
            <SmallText>Resources</SmallText>
            <FooterLink href="/">Blog</FooterLink>
            <FooterLink href="/">Newsletter</FooterLink>
            <FooterLink href="/">Events</FooterLink>
          </Resources>
          <Social>
            <SmallText>Social</SmallText>
            <FooterLink href="https://x.com/evendyservices?s=21">Twitter</FooterLink>
            <FooterLink href="https://www.linkedin.com/company/evendy">LinkedIn</FooterLink>
            <FooterLink href="https://web.facebook.com/Evendyservices">Facebook</FooterLink>
          </Social>
          <Legal>
            <SmallText>Legal</SmallText>
            <FooterLink href="/terms/terms">Terms</FooterLink>
            <FooterLink href="/privacy/privacy">Privacy</FooterLink>
            <FooterLink href="/">Cookies</FooterLink>
          </Legal>
        </TopRight>
      </FoooterTop>
      <FooterBottom>
        <BottomLeft>
          <SmallText> 2024 Evendy. All rights reserved.</SmallText>
        </BottomLeft>
        <BottomRight>
          <FooterLink href="https://x.com/evendyservices?s=21" target="_blank" rel="noopener noreferrer">
            <Twitter />
          </FooterLink>
          <FooterLink href="https://www.linkedin.com/company/evendy">
            <Linkedin />
          </FooterLink>
          <FooterLink href="https://web.facebook.com/Evendyservices">
            {" "}
            <Facebook />
          </FooterLink>
        </BottomRight>
      </FooterBottom>
    </FooterContainer>
  );
};

export default NewFooter;
