export const tqQueryKeys = {
  auth: {
    requestVerification: "auth/request-verification",
    verify: "auth/verify",
  },
  users: {
    getUsers: "/users",
    getUserById: "/users/:id",
  },

  packages: {
    getVendorPackages: "vendorpackage/vendor-packages",
    getVendorPackageById: "vendorpackage/vendor-packages:id",
    getVendorPackagesByEmail: "vendorpackage/vendor-packages:email",
  },
  vendorRequest: {
    getVendorRequest: "vendor-request",
    getVendorRequestById: "vendor-request/:id",
  },
  event: {
    getEvents: "events",
    getEventById: "events/:id",
  },
  vendors: {
    getVendors: "vendors",
    getVendorById: "vendors/:id",
    getVendorByEmail: "vendors/get-by-email/:email",
  },
  organizer: {
    getOrganizer: "organizer",
    getOrganizerbyId: "organizer/:id",
    getOrganizerbyEmail: "organizer/get-by-email/:email",
    getByEmail: "organizer/get-organization/:email",
    getOrganiserProfile: "organizer",
  },
  eventCategory: {
    getEventCategories: "event-category",
  },
};

export const tqMutationKeys = {
  auth: {
    login: "auth/login",
    resetPassword: "auth/reset-password",
    requestVerification: "auth/request-verification",
    forgotPassword: "auth/forgot-password",
  },
  adminauth: {
    login: "admin-auth/login",
  },
  users: {
    signup: "users/signup",
    createProfile: "userType/create",
  },
  packages: {
    create: "vendorpackage/vendor-packages",
    edit: "vendorpackage/vendor-packages:id",
    delete: "vendorpackage/vendor-packages:id",
  },
  vendorRequest: {
    create: "vendor-request/create",
    edit: "vendor-request/:id",
    delete: "vendor-request/:id",
  },
  event: {
    create: "events/create",
    edit: "events/edit/:id",
    delete: "events/delete/:id",
  },
  vendor: {
    edit: "vendor/edit/:id",
    delete: "vendor/delete/:id",
    bankDetails: {
      edit: "vendor/bank-details/edit",
    },
  },

  organizer: {
    edit: "organizer/edit/:id",
    update: "organizer/update-by-email/:email",
    delete: "organizer/delete/:id",
  },
  organization: {
    create: "organization/create",
  },
  ticket: {
    create: "ticket.create",
  },
};

export const sessionStorageKeys = {
  userId: "user",
  accessToken: "accessToken",
  refreshToken: "refreshToken",
  email: "email",
  userType: "userType",
  signupUserType: "signupUserType",
  signupEmail: "signupEmail",
  requestedVerification: "requestedVerification",
  eventId: "eventId",
};
