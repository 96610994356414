import React, { useState } from "react";
import {
  Form,
  Button,
  Input,
  InputNumber,
  Select,
  Upload,
  DatePicker,
  TimePicker,
  Checkbox,
  Switch,
  message,
} from "antd";
import { PlusOutlined, UploadOutlined, DownOutlined, UpOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { useCreateTicket } from "lib/hooks/ticketing/useCreateEventTicketing";

const { Option } = Select;

const Container = styled.div`
  padding: 20px;
`;

const CategoryToggle = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const TicketTypeButton = styled(Button)<{ selected: boolean }>`
  width: 48%;
  height: auto;
  padding: 16px;
  border-color: ${({ selected }) => (selected ? "rgba(28, 91, 135, 1)" : "black")};
  color: ${({ selected }) => (selected ? "rgba(28, 91, 135, 1)" : "black")};
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  flex-direction: row;
  gap: 10px;

  &:hover {
    border-color: rgba(28, 91, 135, 1);
    color: rgba(28, 91, 135, 1);
  }

  p {
    margin: 0;
    flex: 1;
  }
`;

const HiddenCheckbox = styled(Checkbox)`
  margin-top: 8px;
  .ant-checkbox-inner {
    border-color: rgba(28, 91, 135, 1);
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgba(28, 91, 135, 1);
    border-color: rgba(28, 91, 135, 1);
  }
`;

const AdditionalSettings = styled.div`
  margin-top: 16px;
  cursor: pointer;
  color: #1890ff;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DateTimeContainer = styled.div`
  display: flex;
  gap: 16px;
  margin-bottom: 16px;
  width: 100%;
`;
const DateTimeFormItem = styled(Form.Item)`
  flex: 1;
  height: 50px;
`;
const Paragraph = styled.p`
  font-size: 0.5rem;
  line-height: 1rem;
`;
const MinMaxQuantityContainer = styled.div`
  display: flex;
  gap: 16px;
`;
const TicketStatus = styled.div`
  display: flex;
  align-items: center;
  background-color: rgba(249, 251, 255, 1);
  justify-content: space-between;
  height: 60px;
  margin-bottom: 50px;
`;
const StatusLeft = styled.div`
  text-align: left;
  h2 {
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: rgba(18, 18, 18, 1);
  }
  p {
    font-size: 0.9rem;
    font-weight: 400;
    line-height: 1.3rem;
    color: rgba(89, 89, 89, 1);
  }
`;
const StatusRight = styled.div``;

const StyledButton = styled(Button)`
  width: 100%;
  height: 46px;
  color: #fff;
  background-color: rgba(19, 144, 230, 1);
`;

const CreateTicketModal: React.FC = () => {
  const [category, setCategory] = useState<string>("none");
  const [showAdditionalSettings, setShowAdditionalSettings] = useState<boolean>(true);
  const [form] = Form.useForm();
  const [type, setType] = useState<string>("general");
  const [description, setDescription] = useState<string>("");
  const [quantity, setQuantity] = useState<number>(0);
  const [price, setPrice] = useState<number>(0);
  const [salesStartDate, setSalesStartDate] = useState<string>("");
  const [salesEndDate, setSalesEndDate] = useState<string>("");
  const [minQuantity, setMinQuantity] = useState<number>(1);
  const [maxQuantity, setMaxQuantity] = useState<number>(10);
  const [eventId, setEventId] = useState<number | null>(null);

  const [isCheckboxVisible, setIsCheckboxVisible] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const { mutate: createTicket } = useCreateTicket();

  const handleCategoryChange = (value: string) => {
    setCategory(value);
    setIsCheckboxVisible(true);
  };

  const handleAdditionalSettingsToggle = () => {
    setShowAdditionalSettings(prevState => !prevState);
  };

  const onFinish = (values: any) => {
    setIsSubmitting(true);
    createTicket(values, {
      onSuccess: () => {
        form.resetFields();
        setIsSubmitting(false);
      },
      onError: () => {
        setIsSubmitting(false);
      },
    });
  };

  return (
    <Container>
      <Form form={form} layout="vertical" onFinish={onFinish} requiredMark={false}>
        <Form.Item name="ticketCategory" label="Ticket Category">
          <CategoryToggle>
            <TicketTypeButton selected={category === "free"} onClick={() => handleCategoryChange("free")}>
              <p>
                Free ticket
                <Paragraph>Attendees don’t pay for Ticket</Paragraph>
              </p>
              {category === "free" && <HiddenCheckbox defaultChecked />}
            </TicketTypeButton>
            <TicketTypeButton selected={category === "paid"} onClick={() => handleCategoryChange("paid")}>
              <p>
                Paid ticket <br />
                <Paragraph>Attendees pay for tickets</Paragraph>
              </p>
              {category === "paid" && <HiddenCheckbox defaultChecked />}
            </TicketTypeButton>
          </CategoryToggle>
        </Form.Item>

        <Form.Item
          name="eventName"
          label="Event Name"
          rules={[{ required: true, message: "Please input the event name!" }]}
        >
          <Input placeholder="e.g. Regular, VIP etc" />
        </Form.Item>

        <Form.Item
          name="ticketDescription"
          label="Description"
          rules={[{ required: true, message: "Please input the ticket description!" }]}
        >
          <Input.TextArea rows={4} placeholder="Describe the purpose of this ticket briefly." />
        </Form.Item>

        <Form.Item name="ticketQuantity" label="Ticket Quantity">
          <InputNumber min={1} defaultValue={100} style={{ width: "100%" }} />
        </Form.Item>

        {category === "paid" && (
          <Form.Item name="ticketPrice" label="Ticket Price">
            <Input.Group compact>
              <Select defaultValue="NGN" style={{ width: "20%", padding: "16px", height: "50px" }}>
                <Option value="NGN">NGN</Option>
                <Option value="USD">USD</Option>
                <Option value="EUR">EUR</Option>
              </Select>
              <InputNumber min={0} style={{ width: "80%" }} placeholder="Set a price for this ticket" />
            </Input.Group>
          </Form.Item>
        )}
        {category === "free" && (
          <Form.Item name="ticketPrice" label="Ticket Price">
            <Input.Group compact>
              <Select defaultValue="NGN" style={{ width: "20%", padding: "16px" }}>
                <Option value="NGN">NGN</Option>
                <Option value="USD">USD</Option>
                <Option value="EUR">EUR</Option>
              </Select>
              <InputNumber min={0} style={{ width: "80%" }} placeholder="Free" disabled />
            </Input.Group>
          </Form.Item>
        )}

        <AdditionalSettings onClick={handleAdditionalSettingsToggle}>
          <span>Additional Settings</span>
          {showAdditionalSettings ? <UpOutlined /> : <DownOutlined />}
        </AdditionalSettings>

        {showAdditionalSettings && (
          <>
            <Form.Item name="ticketImage" label="Ticket Image">
              <div
                style={{
                  border: "1px dashed #d9d9d9",
                  padding: "20px",
                  textAlign: "center",
                }}
              >
                <Upload>
                  <Button icon={<UploadOutlined />}>Click to upload</Button>
                </Upload>
                <div>SVG, PNG, JPG or GIF (max. 800x400px)</div>
              </div>
            </Form.Item>

            <TicketStatus>
              <StatusLeft>
                <h2>Ticket Status: Available</h2>
                <p>will be displayed on the published event</p>
              </StatusLeft>
              <StatusRight>
                <Switch />
              </StatusRight>
            </TicketStatus>

            <DateTimeContainer>
              <DateTimeFormItem name="ticketSalesStartDate" label="Ticket Sales Start Date">
                <DatePicker style={{ width: "100%" }} />
              </DateTimeFormItem>
              <DateTimeFormItem name="ticketSalesStartTime" label="Ticket Sales Start Time">
                <TimePicker style={{ width: "100%" }} />
              </DateTimeFormItem>
            </DateTimeContainer>

            <DateTimeContainer>
              <DateTimeFormItem name="ticketSalesEndDate" label="Ticket Sales End Date">
                <DatePicker style={{ width: "100%" }} />
              </DateTimeFormItem>
              <DateTimeFormItem name="ticketSalesEndTime" label="Ticket Sales End Time">
                <TimePicker style={{ width: "100%" }} />
              </DateTimeFormItem>
            </DateTimeContainer>

            <MinMaxQuantityContainer>
              <DateTimeFormItem name="minQuantityPerOrder" label="Minimum Quantity Per Order">
                <InputNumber min={0} defaultValue={1} style={{ width: "100%" }} />
              </DateTimeFormItem>
              <DateTimeFormItem name="maxQuantityPerOrder" label="Maximum Quantity Per Order">
                <InputNumber min={0} defaultValue={10} style={{ width: "100%" }} />
              </DateTimeFormItem>
            </MinMaxQuantityContainer>
          </>
        )}

        <Form.Item>
          <StyledButton type="primary" htmlType="submit" loading={isSubmitting} disabled={true}>
            Create Ticket
          </StyledButton>
        </Form.Item>
      </Form>
    </Container>
  );
};

export default CreateTicketModal;
