import React from "react";
import styled from "styled-components";
import { Text } from "../Text";

interface IPageHeaderProps {
  title: string;
  subtitle?: string;
  rightAddon?: React.ReactNode;
}

const PageHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const PageHeaderText = styled.div`
  display: flex;
  flex-direction: column;
`;

const PageHeaderTextTitle = styled.div`
  font-family: "BR Firma";
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
`;
const PageHeaderTextSubtitle = styled.div`
  //styleName: Subtitle 2/Semibold;
  font-family: "BR Firma";
  font-size: 32px;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0px;
  text-align: left;
`;

const PageHeaderAction = styled.div``;

export const PageHeader: React.FC<IPageHeaderProps> = ({ title, subtitle = "", rightAddon }) => {
  return (
    <PageHeaderContainer>
      <PageHeaderText>
        <PageHeaderTextTitle>{title}</PageHeaderTextTitle>
        <PageHeaderTextSubtitle>{subtitle || null}</PageHeaderTextSubtitle>
      </PageHeaderText>
      {/* <PageHeaderAction>{rightAddon || null}</PageHeaderAction> */}
    </PageHeaderContainer>
  );
};
