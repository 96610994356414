import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Add } from "images/organizerevents/Vectoradd.svg";
import { ReactComponent as Noevent } from "images/vendor/NoEvent.svg";
import PastEventModal from "./PastEventModal";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  margin-top: 2rem;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Text = styled.h3`
  font-size: 18px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
`;

const Addnew = styled.button`
  background-color: #1390e6;
  color: white;
  border: 1px solid;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  width: 13rem;
`;

const EventCard = styled.div`
  border: 1px solid #d9d9d9;
  border-radius: 8px;
  padding: 1rem;
  width: 100%;
  max-width: 500px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
`;

const EventTitle = styled.h4`
  margin: 0;
  font-size: 16px;
  font-weight: 500;
`;

const EventDetails = styled.p`
  margin: 0.5rem 0 0 0;
  font-size: 14px;
`;

const PastEvents: React.FC = () => {
  // Modal state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [events, setEvents] = useState<any[]>([]);

  const handleOpen = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const handleAddEvent = (newEvent: any) => {
    setEvents([...events, newEvent]);
  };

  return (
    <Container>
      <Top>
        {events.length === 0 ? (
          <>
            <Noevent />
            <Text> No past Event Added yet</Text>
            <p>Share past events where you provided services. They aid organizers in vendor selection</p>
          </>
        ) : (
          events.map((event, index) => (
            <EventCard key={index}>
              <EventTitle>{event.title}</EventTitle>
              <EventDetails>Organized by: {event.organizers}</EventDetails>
              <EventDetails>Date: {event.date}</EventDetails>
            </EventCard>
          ))
        )}
      </Top>
      <Addnew onClick={handleOpen}>
        <Add />
        Add Event
      </Addnew>
      <PastEventModal open={isModalOpen} onCancel={handleCancel} onAddEvent={handleAddEvent} />
    </Container>
  );
};

export default PastEvents;
