import React from "react";
import styled from "styled-components";
import { routeNames } from "lib/routes";
import { Link } from "react-router-dom";
import { ReactComponent as Analysis } from "images/landingpage/services/analytics.svg";
import Vector1 from "images/landingpage/services/Vector5.png";
import Vector2 from "images/landingpage/services/Vector6.png";
import Left from "images/landingpage/Faq/left.png";

const FinanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 768px) {
  }
`;

const Top = styled.div`
  position: relative;
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10rem;
  background-color: #fcfcfd;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    gap: 3rem;
    padding-bottom: 1rem;
  }
`;
const Bottom = styled.div`
  margin-top: 2rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding-bottom: 2rem;
  @media (max-width: 768px) {
    margin-top: 0;
  }
`;
const TopLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  @media (max-width: 768px) {
    margin-left: 0.5rem;
  }
`;

const TopRight = styled.div`
  position: relative;
  @media (max-width: 768px) {
    display: none;
  }
`;

const TopRightMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const Header = styled.p`
  font-size: 48px;
  font-weight: 600;
  line-height: 60.34px;
  letter-spacing: -0.02em;
  color: #2f2f2f;
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

const Text = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #595959;
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 768px) {
    display: none;
  }
`;

const TextMobile = styled.p`
  display: none;
  @media (max-width: 768px) {
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #595959;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 4rem;
  @media (max-width: 768px) {
    justify-content: flex-start; /* Keep alignment left on mobile */
    margin-left: 1rem;
    margin-bottom: 0rem;
  }
`;

export const CreateAnEvent = styled(Link)`
  all: unset;
  height: 46px;
  border-radius: 8px;
  background-color: rgba(19, 144, 230, 1);
  color: #fff;
  width: 170px;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  /* Add transition for smooth shadow appearance */
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    background-color: #3897d8;
    /* Dropdown shadow effect */
    box-shadow: 0 8px 16px rgba(56, 151, 216, 0.3); /* Subtle shadow */
  }
`;

const ExploreEvents = styled(Link)`
  all: unset;
  height: 46px;
  border-radius: 8px;
  color: #121212;
  width: 170px;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  /* Add transition for smooth shadow appearance */
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    color: #1c5b87;
  }
`;

const ImageContainer = styled.div``;

const SpiralContainer1 = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  @media (max-width: 768px) {
    display: none;
  }
`;
const SpiralContainer2 = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`;

const SpiralContainer3 = styled.div`
  position: absolute;
  bottom: -7rem;
  left: 0;
  z-index: -1;
`;

const CenterContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const CenterContainerMobile = styled.div`
  display: none;
  z-index: 2;
  @media (max-width: 768px) {
    display: Block;
  }
`;

const EventAnalysis: React.FC = () => {
  return (
    <FinanceContainer>
      <Top>
        <TopLeft>
          <Analysis />
          <Header>EVENT ANALYTICS</Header>
          <Text>
            Empower your event planning process with Evendy&apos;s data-driven
            <br />
            insights, enabling you to make informed and strategic decisions. With
            <br />
            Evendy, harness the power of data to optimize every aspect of your
            <br />
            event and ensure its success.
          </Text>
          <TextMobile>
            Empower your event planning process with Evendy&apos;s data-driven insights, enabling you to make informed
            and strategic decisions. With Evendy, harness the power of data to optimize every aspect of your event and
            ensure its success.
          </TextMobile>
          <ButtonWrapper>
            <CreateAnEvent to={routeNames.authSignup}>Create an event</CreateAnEvent>

            <ExploreEvents to={routeNames.event}>Explore Events</ExploreEvents>
          </ButtonWrapper>
        </TopLeft>
        <TopRight>
          <ImageContainer>
            <img
              src="/Images/Services/eventanalytics.svg"
              alt="Event financing"
              width="430.71px"
              height="335px"
              style={{ borderRadius: "4.7px" }}
            />
          </ImageContainer>
        </TopRight>
        <TopRightMobile>
          <ImageContainer>
            <img
              src="/Images/Services/eventanalytics.svg"
              alt="Event financing"
              width="323.03px"
              height="251.25px"
              style={{ borderRadius: "4.7px" }}
            />
          </ImageContainer>
        </TopRightMobile>
        <SpiralContainer1>
          <img src={Vector1} alt="" />
        </SpiralContainer1>
        <SpiralContainer2>
          {" "}
          <img src={Vector2} alt="" />
        </SpiralContainer2>
      </Top>
      <Bottom>
        <CenterContainer>
          <img src="/Images/Services/center.svg" alt="" />
        </CenterContainer>
        <CenterContainerMobile>
          <img src="/Images/LandingPage/centermobile.svg" alt="" />
        </CenterContainerMobile>
        <SpiralContainer3>
          <img src={Left} alt="Service Content" />
        </SpiralContainer3>
      </Bottom>
    </FinanceContainer>
  );
};
export default EventAnalysis;
