import React from "react";
import styled from "styled-components";
import { useGetEvents } from "lib/hooks";
import Card from "./EventCard";

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 19px;
  justify-content: space-between;
`;

const EventList: React.FC = () => {
  const { data: EventResponse, isLoading, isError } = useGetEvents();

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching events</div>;

  return <CardContainer>{EventResponse?.map(event => <Card key={event.id} event={event} />)}</CardContainer>;
};

export default EventList;
