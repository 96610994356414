import React from "react";
import styled from "styled-components";
import { Typography, Button } from "antd";
import { screenSizes } from "ui/theme";

const { Title, Text } = Typography;

export const FAQContainer = styled.div`
  text-align: center;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  margin-bottom: 2rem;
`;

export const FAQHeader = styled.p`
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
`;
export const FAQSubHeader = styled.p`
  color: #595959;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 1rem;
`;
export const ItemContainer = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-bottom: 0.5px solid rgba(152, 162, 179, 1);
  cursor: pointer;
  @media (max-width: 768px) {
    max-width: 80%;
  }
`;
export const TextHeader = styled.div`
  width: 100%;
  /* max-width: 295px; */
  color: #101828;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.5rem;
  text-align: left;

  /* @media (max-width: 768px) {
    max-width: 80%;
      } */

  /* @media screen and (min-width: ${screenSizes.laptop}) {
    max-width: 720px;
  } */
`;

export const TextParagraph = styled.p`
  font-size: 0.8rem;
  font-weight: 400;
  line-height: 1.5rem;
  text-align: left;
  max-width: 295px;

  @media screen and (min-width: ${screenSizes.laptop}) {
    max-width: 720px;
  }
`;

export const CircleIcon = styled.div`
  width: 20px;
  height: 20px;
  top: 10px;
  left: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  color: rgba(152, 162, 179, 1);
  border: 2px solid rgba(152, 162, 179, 1);
  border-radius: 50px;
`;
export const BottomContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 1264px;
  border-radius: 16px;
  height: 302px;
  padding: 10px;
  gap: 20px;
  background-color: #f9fafb;
`;

export const Image = styled.img`
  width: 120px;
  height: 56px;
`;

export const HeaderText = styled.div`
  width: 100%;
  max-width: 768px;
  color: #101828;

  font-size: 1.5rem;
  font-weight: 500;
  line-height: 2rem;
  text-align: center;
`;
export const SubHeaderText = styled.div`
  width: 100%;
  max-width: 768px;
  color: #101828;

  //styleName: Body 1/Regular;
  font-family: BR Firma;
  font-size: 16px;
  font-weight: 400;
  line-height: 32px;
  text-align: center;
`;
export const StyledButton = styled(Button)`
  height: 46px;
  border-radius: 8px;
  padding: 12px 40px;
  background-color: rgba(19, 144, 230, 1);
  color: #fff;
  width: 190px;
`;
// Badge group section of the landing page
export const Badge = styled.div`
  display: flex;
  width: 500px;
  height: 40px;
  border-radius: 10px;
  border: 1px solid rgba(208, 213, 221, 1);
  gap: 12px;
  padding: 4px 10px;
  align-items: center;
  justify-content: center;
  p {
    font-weight: 1.5rem;
    font-size: 1.5rem;
    line-height: 1rem;
    color: rgba(52, 64, 84, 1);

    @media (max-width: 650px) {
      font-size: 1.2rem;
      font-weight: 1.2rem;
      line-height: 0;
    }
  }
  @media (max-width: 650px) {
    width: 370px;
  }
`;

export const Faq = styled.button`
  background-color: #7ef88b33;
  color: #168f43;
  border: none;
  padding: 8px 24px 8px 24px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 600;
  line-height: 15.08px;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 0;
  width: 4rem;
`;

export const LeftSpiralContainer = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const RightSpiralContainer = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const SeeMore = styled.button`
  margin-top: 2rem;
  padding: 12px 40px 12px 40px;
  gap: 10px;
  border-radius: 8px;
  border: none;
  opacity: 0px;
`;
