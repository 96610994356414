import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { DummyData, Vendor } from "./VendorProfile";

const EventsContainer = styled.div``;

const EventItem = styled.div`
  border: 1px solid;
  border-color: #f1f1f1;
  border-radius: 15px;
  padding: 1rem;
  margin-bottom: 1rem;
`;

const Line1 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  align-items: center;
  margin-top: -1rem;
`;

const Line2 = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  margin-top: -1rem;
`;

const Eventname = styled.p`
  font-size: 1rem;
  font-weight: 500;
`;

const Eventdate = styled.p`
  color: #595959;
`;

const Text1 = styled.p``;
const Text2 = styled.p``;
const Line3 = styled.p`
  margin-top: -0rem;
  color: #595959;
`;

const Images = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
`;

interface Props {}

const PastEvents: React.FC<Props> = props => {
  const [vendor, setVendor] = useState<Vendor | null>(null);
  // const params = useParams<{ id?: string }>();

  useEffect(() => {
    // const id = params.id ? parseInt(params.id) : undefined;
    const id = 1;
    const selectedVendor = id ? DummyData.find(vendor => vendor.id === id) : null;
    setVendor(selectedVendor || null);
  }, []);

  return (
    <>
      {vendor ? (
        <EventsContainer>
          {vendor.events.map(event => (
            <EventItem key={event.eventid}>
              <Line1>
                <Eventname>{event.eventname}</Eventname>
                <Eventdate>{event.eventdate}</Eventdate>
              </Line1>
              <Line2>
                <Text1>Event Organizers:</Text1>
                <Text2> {event.eventorganizer}</Text2>
              </Line2>
              <Line3>Images from event:</Line3>
              {/* Render images */}
              <Images>
                {event.images.map((image, index) => (
                  <img key={index} src={image} alt={`Event ${event.eventid}`} />
                ))}
              </Images>
            </EventItem>
          ))}
        </EventsContainer>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default PastEvents;
