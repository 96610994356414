import React, { useEffect, useState, useContext } from "react";
import styled from "styled-components";
import { Button, Badge, Form, Input, message } from "antd";
import SubmitButton from "ui/components/base/Buttons/SubmitButton";
import { Link, useNavigate } from "react-router-dom";
import { routeNames } from "lib/routes";
import { useUpdateEvent } from "lib/hooks/eventcategory/useUpdateEventById";
import { useEventContext } from "ui/components/event/eventContext";
import { useEditEventById } from "lib/hooks";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
`;

const Title = styled.h2`
  font-size: 1.1rem;
  margin-bottom: 20px;
`;

const EventTypeContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  gap: 20px;

  @media (max-width: 768px) {
    flex-direction: column;
    padding: 20px;
  }
`;

const EventTypeCard = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  width: 30%;
  height: 60px;
  border: 1px solid ${props => (props.disabled ? "#d9d9d9" : "#1890ff")};
  border-radius: 8px;
  background-color: ${props => (props.disabled ? "#fafafa" : "#fff")};
  cursor: ${props => (props.disabled ? "not-allowed" : "pointer")};
  opacity: ${props => (props.disabled ? 0.7 : 1)};

  @media (max-width: 768px) {
    width: 100%;
    padding: 20px;
  }
`;

const EventTypeLabel = styled.span`
  font-size: 1rem;
  font-weight: 600;
  margin-bottom: 4px;
`;

const EventTypeDescription = styled.span`
  font-size: 0.8rem;
  color: #888;
`;

const ButtonContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: flex-end;
  justify-content: end;
  gap: 30px;
`;

const ButtonWrapper = styled.div`
  width: 205px;
`;

const GhostButton = styled(Button)`
  width: 127px;
  height: 46px;
  border-radius: 8px;
  border: 1px solid rgba(226, 232, 240, 1);
  background-color: #fff;
`;

const EventType: React.FC = () => {
  const [form] = Form.useForm();
  const { eventId } = useEventContext();
  const { mutate: updateEvent } = useEditEventById();
  const [isUpdating, setIsUpdating] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (values: any) => {
    setIsUpdating(true);

    // Ensure the updated event includes the correct ID
    const updatedEvent = { ...values, id: eventId };

    await updateEvent(updatedEvent, {
      onSuccess: () => {
        setIsUpdating(false);
        navigate(routeNames.eventDate);
      },
      onError: error => {
        setIsUpdating(false);
        message.error("Failed to update the event.");
        console.error("Update Error:", error);
      },
    });
  };

  return (
    <Form form={form} onFinish={handleSubmit}>
      <Container>
        <Title>Type & Location</Title>
        <EventTypeContainer>
          <EventTypeCard>
            <EventTypeLabel>Physical event</EventTypeLabel>
            <EventTypeDescription>At a venue or physical location</EventTypeDescription>
            <Form.Item name="type" valuePropName="checked">
              <Input type="radio" value="physical" />
            </Form.Item>
          </EventTypeCard>

          <EventTypeCard disabled>
            <EventTypeLabel>
              Online event <Badge count="Coming soon" style={{ backgroundColor: "#f0c14b", color: "#000" }} />
            </EventTypeLabel>
            <EventTypeDescription>Virtual live stream hosting</EventTypeDescription>
            <Form.Item name="type" valuePropName="checked">
              <Input type="radio" value="online" disabled />
            </Form.Item>
          </EventTypeCard>

          <EventTypeCard disabled>
            <EventTypeLabel>
              Hybrid event <Badge count="Coming soon" style={{ backgroundColor: "#f0c14b", color: "#000" }} />
            </EventTypeLabel>
            <EventTypeDescription>Host physically & virtually</EventTypeDescription>
            <Form.Item name="type" valuePropName="checked">
              <Input type="radio" value="hybrid" disabled />
            </Form.Item>
          </EventTypeCard>
        </EventTypeContainer>

        <ButtonContainer>
          <Link to={routeNames.organizerHome}>
            <GhostButton>Cancel</GhostButton>
          </Link>
          <ButtonWrapper>
            <SubmitButton form={form} loading={isUpdating}>
              Save and Continue
            </SubmitButton>
          </ButtonWrapper>
        </ButtonContainer>
      </Container>
    </Form>
  );
};

export default EventType;
