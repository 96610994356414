import { redirect } from "react-router-dom";
import { getSession } from "lib/utils/utils";
import { sessionStorageKeys } from "lib/constants";

type UserRole = "organizer" | "vendor" | "admin";

export async function appLoader() {
  const session = getSession();

  if (!session) {
    sessionStorage.removeItem(sessionStorageKeys.accessToken);
    return redirect("/auth/login");
  }

  const userType = session.userType;

  if (!userType) {
    return redirect("/auth/login");
  }

  return { userType };
}
