import React from "react";
import styled, { css } from "styled-components";
import { IMetricsCardProps } from "ui/types";
import { InfoCircle } from "../svg";
import { uiUtils } from "ui/utils";

const doNotForwardProps = ["colorScheme"];

const MetricsCardRoot = styled.div.withConfig({
  shouldForwardProp: prop => !doNotForwardProps.includes(prop),
})`
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 6px;
  width: 100%;
  height: 46px;
`;
const MetricsCardHeader = styled.div`
  display: flex;
  gap: 4px;
`;

const MetricsCardContent = styled.div`
  display: flex;
  align-items: center;
`;

const MetricsCardTitle = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  color: var(--gray-500, #667085);
  white-space: nowrap;

  /* Text xs/Medium */
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px; /* 150% */
`;

const MetricsCardSubTitle = styled.div`
  color: var(--gray-600, #475467);
  font-size: 8px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px; /* 175% */
`;

const MetricsCardValue = styled.div`
  color: var(--gray-800, #1d2939);

  /* Text md/Semibold */
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px; /* 150% */

  color: var(--gray-700, #344054);

  /* Text sm / Bold */
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
`;

const MetricsCardTotal = styled.div`
  color: var(--gray-700, #344054);

  /* Text xs/Regular */
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
`;

export const MetricsCard: React.FC<IMetricsCardProps> = ({ title, subtitle, value, hint, total }) => {
  return (
    <MetricsCardRoot>
      <MetricsCardHeader>
        <MetricsCardTitle>
          {title} {uiUtils.isNotEmptyStr(hint) ? <InfoCircle /> : null}
        </MetricsCardTitle>
      </MetricsCardHeader>
      <MetricsCardContent>
        <MetricsCardValue>{value}</MetricsCardValue>
        {total || <MetricsCardTotal> &frasl; {total}</MetricsCardTotal>}
      </MetricsCardContent>
      <MetricsCardSubTitle>{subtitle}</MetricsCardSubTitle>
    </MetricsCardRoot>
  );
};
