import dayjs from "dayjs";
import numeral from "numeral";
import { NumberOrString } from "ui/types";

type DateOrDateString = Date | string;
type CssMeasurementUnit = "px" | "%" | "em" | "rem" | "vh" | "vw";

export const uiUtils = {
  hyphenate(...args: NumberOrString[]): string {
    return Array.from(args).join("-");
  },
  formatDate(date: DateOrDateString, formatString: string = "DD MMM YYYY"): string {
    if (!date) {
      return dayjs().format(formatString);
    }
    if (typeof date === "string") {
      date = new Date(date);
    }
    return dayjs(date).format(formatString);
  },
  randomNumber(max: number, min: number) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  },
  /** add a unit to to a numeric value
   * TODO: add validation of css units
   */
  addUnit(value: NumberOrString, unit: CssMeasurementUnit = "px"): string {
    if (
      typeof value === "string" &&
      (value as string).toLowerCase().includes("px" || "%" || "em" || "rem" || "vh" || "vw")
    ) {
      return value;
    }
    return `${value}${unit || ""}`;
  },
  /** create a mobile first media query string */
  createMediaQuery(n: string) {
    return `@media screen and (min-width: ${n})`;
  },
  isFunction(func: any) {
    return typeof func === "function";
  },
  formatCurrency(value: number | null, currency = "₦") {
    return numeral(value).format(`${currency} 0,0.00`);
  },
  formatNumber(value: number | null, format = "0,0") {
    return numeral(value).format(`${format}`);
  },
  formatEventDate(date: string | Date): string {
    return uiUtils.formatDate(date, "MMM d, YYYY, h:mm A");
  },
  isNotEmptyStr(value: any): boolean {
    return typeof value === "string" && value.trim() !== "";
  },
  // Function to generate a random color
  getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  },
};
