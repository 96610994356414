import * as React from "react";
import styled from "styled-components";
import { Filters } from "ui/components/Dashboard/Filter/Filters";
import VendorList from "app/pages/organizer/marketplace/VendorList";

const Marketplace = styled.div`
  margin: 1rem;
`;

const Header = styled.h2`
  font-size: 32px;
  font-weight: 600;
  text-align: left;
  margin-top: -1.4rem;
  @media (max-width: 768px) {
    margin-top: 0;
    margin-bottom: 0;
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    margin-top: 2rem;
    margin-bottom: 1rem;
  }
`;

interface ImarketplaceProps {}

const marketplace: React.FunctionComponent<ImarketplaceProps> = props => {
  return (
    <Marketplace>
      <Header>Vendor Marketplace</Header>
      <Filters />
      <VendorList />
    </Marketplace>
  );
};

export default marketplace;
