import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { tqMutationKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";

type DeletePackagePayload = {
  id: number;
};

interface DeletePackageResponse {
  success: boolean;
  message: string;
}

type UseDeletePackageResult = UseMutationResult<DeletePackageResponse, Error, DeletePackagePayload>;

export function useDeletePackage(): UseDeletePackageResult {
  return useMutation({
    mutationFn: async (payload: DeletePackagePayload) => {
      const { id } = payload;
      const res = await EvendyApi.delete<Promise<DeletePackageResponse>>(`vendor-packages/${id}`);
      return res.data;
    },
    mutationKey: [tqMutationKeys.packages.delete],
    onError(deleteError: Error) {
      console.log({ deleteError });
    },
    onSuccess(data) {
      console.log("Package deleted successfully", data);
    },
  });
}
