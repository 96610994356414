import React, { useState } from "react";
import styled from "styled-components";
import { EllipsisOutlined } from "@ant-design/icons";
import { ReactComponent as Price } from "images/vendorprofile/Frametotal.svg";
import { ReactComponent as Completed } from "images/vendor/Framecompleted.svg";
import { ReactComponent as Edit } from "images/vendor/Iconsedit.svg";
import { ReactComponent as Delete } from "images/vendor/Iconsdelete.svg";
import { Popover, ConfigProvider } from "antd";
import EditPackageModal from "./EditPackageModal";
import DeletePackageModal from "./DeletePackageModal";

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 16px;
  background-color: #f9faff;
  width: calc(31.3% - 30px);
  gap: 1rem;
  @media (max-width: 768px) {
    width: 90%;
  }
`;

const Line1 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Title = styled.p`
  font-size: 16px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
`;

const Description = styled.p`
  color: #595959;
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
`;

const Line2 = styled.div``;

const Line3 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Line4 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Line5 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;
const Line6 = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
`;

const Text1 = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
`;

const Text2 = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  color: #595959;
`;

const Text3 = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
`;

const MenuOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 1rem;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-bottom: 1rem;
  padding-right: 2rem;
  background-color: #f9fbff;
  border-radius: 8px;
`;
const Top = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;
const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

interface VendorPackageCardProps {
  vendorpackage: {
    id: number;
    title: string;
    description: string;
    currency: string;
    price: number;
    revenue: number;
    upfrontPayment: number;
    vendorEmail: string;
  };
  //to refresh
  onPackageAdded: () => void;
}

const arrow = false;

const VendorPackageCard: React.FC<VendorPackageCardProps> = ({ vendorpackage, onPackageAdded }) => {
  //edit modal
  const [custommodalOpen, setcustomModalOpen] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const handlecustomModalOpen = () => {
    setcustomModalOpen(true);

    setPopoverVisible(false); // Close popover
  };

  const handlecustomModalClose = () => {
    setcustomModalOpen(false);
    onPackageAdded();
  };

  //delete modal
  const [deletemodalOpen, setdeleteModalOpen] = useState(false);

  const handledeleteModalOpen = () => {
    setdeleteModalOpen(true);
    setPopoverVisible(false); // Close popover
  };

  const handledeleteModalClose = () => {
    setdeleteModalOpen(false);
    onPackageAdded();
  };

  const content = (
    <MenuOption>
      <Top onClick={handlecustomModalOpen}>
        <Edit />
        <Text1>Edit details</Text1>
      </Top>
      <Bottom onClick={handledeleteModalOpen}>
        <Delete />
        <Text1>Delete package</Text1>
      </Bottom>
    </MenuOption>
  );

  return (
    <CardContainer>
      <Line1>
        <Title>{vendorpackage.title}</Title>
        <ConfigProvider>
          <Popover
            placement="bottom"
            content={content}
            trigger="click"
            arrow={arrow}
            visible={popoverVisible}
            onVisibleChange={setPopoverVisible}
          >
            <EllipsisOutlined />
          </Popover>
        </ConfigProvider>
      </Line1>
      <Line2>
        <Description>{vendorpackage.description}</Description>
      </Line2>
      <Line3>
        <Left>
          <Price />
          <Text1>Unit Price:</Text1>
        </Left>
        <Right>
          <Text2>{vendorpackage.currency}</Text2>
          <Text3>{vendorpackage.price}</Text3>
        </Right>
      </Line3>
      <Line4>
        <Left>
          <Completed />
          <Text1>Completed Requests</Text1>
        </Left>
        <Right>
          <Text3>{vendorpackage.upfrontPayment}</Text3>
          <Text2></Text2>
        </Right>
      </Line4>
      <Line5>
        <Left>
          <Price />
          <Text1>Revenue:</Text1>
        </Left>
        <Right>
          <Text2>{vendorpackage.currency}</Text2>
          <Text3>{vendorpackage.revenue}</Text3>
        </Right>
      </Line5>
      <Line6>
        <Left>
          <Price />
          <Text1>Upfront Payment:</Text1>
        </Left>
        <Right>
          <Text3>{vendorpackage.upfrontPayment}</Text3>
          <Text2>%</Text2>
        </Right>
      </Line6>
      <EditPackageModal
        open={custommodalOpen}
        onCancel={handlecustomModalClose}
        packageId={vendorpackage.id}
        initialValues={vendorpackage}
      />
      <DeletePackageModal open={deletemodalOpen} onCancel={handledeleteModalClose} packageId={vendorpackage.id} />
    </CardContainer>
  );
};

export default VendorPackageCard;
