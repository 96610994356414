import React from "react";
import styled, { css } from "styled-components";
import { IThemeColors, ITypographyMixins, TextTransformValues, typography } from "ui/theme";
import { IAppTheme } from "ui/theme";

const doNotForwardProps = ["color", "font", "textTransform"];

const StyledText = styled.p.withConfig({
  shouldForwardProp: prop => !doNotForwardProps.includes(prop),
})<{
  font: keyof ITypographyMixins;
  color: keyof IThemeColors | "gray500";
  textTransform: TextTransformValues | "none";
  theme: IAppTheme;
}>`
  all: unset;
  ${props => {
    if (Object.keys(typography).includes(props?.font)) {
      return typography[props.font];
    }
    return "";
  }}

  ${props => {
    if (Object.keys(props.theme.colors).includes(props?.color)) {
      return css`
        color: ${props.theme.colors[props.color]};
      `;
    } else if (props?.color) {
      return css`
            color: {props.color};
        `;
    }
  }}

  ${props => {
    if (Object.keys(props.theme.textTransforms).includes(props?.textTransform)) {
      return css`
        text-transform: ${props.textTransform};
      `;
    }
  }}
`;

export const Text: React.FC<{
  children?: React.ReactNode;
  font?: keyof ITypographyMixins | number | symbol;
  color?: keyof IThemeColors;
  as?: React.ReactNode;
  textTransform?: TextTransformValues;
}> = ({ children, font, color, as, textTransform }) => {
  return (
    <StyledText
      font={font ?? "mdRegular"}
      as={as ?? ("p" as any)}
      color={color ?? "gray500"}
      textTransform={textTransform}
    >
      {children ?? ""}
    </StyledText>
  );
};
