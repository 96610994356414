import React, { useState } from "react";
import { Button, TableColumnsType, TableProps, ConfigProvider, Popover } from "antd";
import { EllipsisOutlined } from "@ant-design/icons";
import { AppTable } from "ui/components/base/Table";
import { GetVendorRequestPayload, useGetVendorRequest } from "lib/hooks/VendorRequest";
import styled from "styled-components";
import SearchBar from "ui/components/Dashboard/Filter/Searchbar";
import Icon from "@ant-design/icons";
import { ReactComponent as reset } from "images/filter/reset.svg";
import { ReactComponent as Go } from "images/admin/go.svg";
import { ReactComponent as View } from "images/admin/view.svg";
import { ReactComponent as Pending } from "images/admin/pending.svg";
import RegularRequestModal from "./RegularRequest";
import CustomRequestModal from "./CustomRequest";
import OrganiserDetailsModal from "./OrganiserDetails";

const Container = styled.div``;

const Topdiv = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const Left = styled.div`
  width: 60%;
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const MenuOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 1rem;
  padding-top: 1rem;
  padding-left: 1rem;
  padding-bottom: 1rem;
  padding-right: 2rem;
  background-color: #f9fbff;
  border-radius: 8px;
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

const Center = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
  cursor: pointer;
`;

const Text1 = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
`;

interface DataType {
  key: string;
  id: string;
  vendorname: string;
  packagetype: string;
  requesttype: string;
  requestquantity: string;
  totalamount: string;
  requeststatus: string;
}

const VendorRequests: React.FC = () => {
  // RegularRequest modal
  const [regularmodalOpen, setregularModalOpen] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);

  const handleregularModalOpen = () => {
    setregularModalOpen(true);
    setPopoverVisible(false); // Close popover
  };

  const handleregularModalClose = () => {
    setregularModalOpen(false);
  };

  // CustomRequest modal
  const [custommodalOpen, setcustomModalOpen] = useState(false);

  const handlecustomModalOpen = () => {
    setcustomModalOpen(true);
    setPopoverVisible(false); // Close popover
  };

  const handlecustomModalClose = () => {
    setcustomModalOpen(false);
  };

  // Organiser Details modal
  const [organizermodalOpen, setorganizerModalOpen] = useState(false);

  const handleorganizerModalOpen = () => {
    setorganizerModalOpen(true);
    setPopoverVisible(false); // Close popover
  };

  const handleorganizerModalClose = () => {
    setorganizerModalOpen(false);
  };

  const content = (
    <MenuOption>
      <Top>
        <Go />
        <Text1>Go to vendor profile</Text1>
      </Top>
      <Center onClick={handleorganizerModalOpen}>
        <Go />
        <Text1>View organiser details</Text1>
      </Center>
      <Bottom onClick={handleregularModalOpen}>
        <View />
        <Text1>View request</Text1>
      </Bottom>
    </MenuOption>
  );

  const arrow = false;

  const columns: TableColumnsType<DataType> = [
    { title: "#", dataIndex: "id", key: "id" },
    { title: "Vendor", dataIndex: "vendorname", key: "vendorname" },
    { title: "Package", dataIndex: "packagetype", key: "packagetype" },
    { title: "Request Type", dataIndex: "requesttype", key: "requesttype" },
    { title: "Quantity", dataIndex: "requestquantity", key: "requestquantity" },
    { title: "Total Amount", dataIndex: "totalamount", key: "totalamount" },
    { title: "Request Status", dataIndex: "requeststatus", key: "requeststatus", render: () => <Pending /> },
    {
      title: "",
      dataIndex: "other",
      key: "other",
      render: () => (
        <ConfigProvider>
          <Popover
            placement="bottom"
            content={content}
            arrow={arrow}
            trigger="click"
            onVisibleChange={setPopoverVisible}
          >
            <EllipsisOutlined />
          </Popover>
        </ConfigProvider>
      ),
    },
  ];

  const data: DataType[] = [
    {
      key: "1",
      id: "1",
      vendorname: "Swift Logistics",
      packagetype: "Hire for a day",
      requesttype: "Regular",
      requestquantity: "50",
      totalamount: "NGN225,000",
      requeststatus: "",
    },
    {
      key: "2",
      id: "2",
      vendorname: "Chowdeck",
      packagetype: "Main Dish",
      requesttype: "Custom",
      requestquantity: "2,000",
      totalamount: "NGN225,000",
      requeststatus: "",
    },
    {
      key: "3",
      id: "3",
      vendorname: "Chowdeck",
      packagetype: "Main Dish",
      requesttype: "Custom",
      requestquantity: "2,000",
      totalamount: "NGN225,000",
      requeststatus: "",
    },
  ];

  const [searchQuery, setSearchQuery] = useState<GetVendorRequestPayload>({ page: 1, limit: 20, status: "pending" });

  const { data: requests } = useGetVendorRequest(searchQuery);

  const handleSearch = (query: string) => {
    setSearchQuery(prevQuery => ({ ...prevQuery, search: query }));
  };

  const handleTableChange = (pagination: TableProps["pagination"]) => {
    setSearchQuery(prevQuery => ({ ...prevQuery }));
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await axios.get("/api/endpoint"); // Replace with your API endpoint
  //       setData(response.data);
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, []);

  return (
    <Container>
      <Topdiv>
        <Left>
          <SearchBar onSearch={handleSearch} />
        </Left>
        <Right>
          <Button type="default">Payment status</Button>
          <Button type="default" icon={<Icon component={reset} style={{ fontSize: "20px", color: "white" }} />}>
            Reset filters
          </Button>
        </Right>
      </Topdiv>
      <AppTable
        pagination={{ position: ["bottomRight"], pageSizeOptions: [20, 50, 100, 1000] }}
        dataSource={data}
        columns={columns}
        onChange={handleTableChange}
      />
      <RegularRequestModal open={regularmodalOpen} onCancel={handleregularModalClose} />
      <CustomRequestModal open={custommodalOpen} onCancel={handlecustomModalClose} />
      <OrganiserDetailsModal open={organizermodalOpen} onCancel={handleorganizerModalClose} />
    </Container>
  );
};

export default VendorRequests;
