import React from "react";
import styled from "styled-components";
import { ReactComponent as SearchIcon } from "images/filter/Vectorsearchlogo.svg";

const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 4px;
`;

const SearchInput = styled.input`
  font-size: 0%.6;
  border: none;
  outline: none;
  background: none;
  flex: 1;
  width: 20rem;
`;

const IconWrapper = styled.div`
  padding: 0.3rem;
`;

interface SearchBarProps {
  placeholder?: string;
  onSearch: (query: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ placeholder = "Search", onSearch }) => {
  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const query = event.target.value;
    onSearch(query);
  };

  return (
    <SearchContainer>
      <IconWrapper>
        <SearchIcon />
      </IconWrapper>
      <SearchInput type="text" placeholder={placeholder} onChange={handleInputChange} />
    </SearchContainer>
  );
};

export default SearchBar;
