import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { sessionStorageKeys, tqMutationKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";

type LoginPayload = {
  email: string;
  password: string;
};
interface ILoginDto {
  accessToken: string;
  id?: string;
}

type LoginResult = UseMutationResult<ILoginDto, Error, LoginPayload>;

export function useAdminLogin(): LoginResult {
  return useMutation({
    mutationFn: async (payload: LoginPayload) => {
      const { email, password } = payload; // not compulsory
      const res = await EvendyApi.post<Promise<ILoginDto>>("admin-auth/login", { email, password });
      return res.data;
    },
    mutationKey: [tqMutationKeys.adminauth.login],
    onError(loginError: Error) {
      console.log({ loginError });
    },
    onSuccess(data) {
      sessionStorage.setItem(sessionStorageKeys.accessToken, data.accessToken);
      sessionStorage.setItem(sessionStorageKeys.userId, data?.id ?? "");
    },
  });
}
