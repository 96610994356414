import * as React from "react";
import { DownOutlined } from "@ant-design/icons";
import type { MenuProps } from "antd";
import { Button, Dropdown, message, Space, Flex } from "antd";
import { Checkbox } from "antd";
import type { CheckboxProps } from "antd";

const onChange: CheckboxProps["onChange"] = e => {
  console.log(`checked = ${e.target.checked}`);
};

const items: MenuProps["items"] = [];

const menuProps = {
  items,
};

const Vendorcategory: React.FC = () => {
  const menuStyle: React.CSSProperties = {
    boxShadow: "none",
  };
  return (
    <Dropdown
      menu={menuProps}
      dropdownRender={menu => (
        <div style={{ background: "#fff", padding: "10px", borderRadius: "8px" }}>
          {React.cloneElement(menu as React.ReactElement, { style: menuStyle })}
          <Flex vertical>
            <Space>
              <Checkbox onChange={onChange}>catering</Checkbox>
            </Space>
            <Space>
              <Checkbox onChange={onChange}>decoration</Checkbox>
            </Space>
            <Space>
              <Checkbox onChange={onChange}>drinks</Checkbox>
            </Space>
            <Space>
              <Checkbox onChange={onChange}>entertainment</Checkbox>
            </Space>
            <Space>
              <Checkbox onChange={onChange}>Furniture rentals</Checkbox>
            </Space>
            <Space>
              <Checkbox onChange={onChange}>Lodgings</Checkbox>
            </Space>
            <Space>
              <Checkbox onChange={onChange}>logistics</Checkbox>
            </Space>
            <Space>
              <Checkbox onChange={onChange}>photography</Checkbox>
            </Space>
            <Space>
              <Checkbox onChange={onChange}>venue</Checkbox>
            </Space>
          </Flex>

          <Space style={{ padding: 8 }}>
            <Button type="default">cancel</Button>
          </Space>
          <Space style={{ padding: 8 }}>
            <Button type="primary">apply</Button>
          </Space>
        </div>
      )}
    >
      <Button>
        <Space>
          Vendor Category
          <DownOutlined />
        </Space>
      </Button>
    </Dropdown>
  );
};
export default Vendorcategory;
