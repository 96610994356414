import React from "react";
import styled from "styled-components";
import { LayoutProps, SpaceProps, layout, space } from "styled-system";

interface IAppCardProps extends SpaceProps, LayoutProps {
  children?: React.ReactNode;
  title?: string;
  headerRight?: React.ReactNode;
  footerElement?: React.ReactNode;
  headerElement?: React.ReactNode;
  width?: string;
  gap?: string;
  horizontal?: boolean;
}

const CardContainer = styled.div`
  display: flex;
  padding: 16px 24px;
  flex-direction: column;
  align-items: flex-start;
  align-self: stretch;

  border-radius: 12px;
  border: 1px solid var(--gray-200, #eaecf0);
  background: #F9FBFF;


  /* Shadow/xs */
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
  ${layout}
  ${space}
`;
const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-self: stretch;
`;
const CardHeaderTitle = styled.div`
  color: var(--gray-700, #344054);
  font-family: Inter;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px; /* 142.857% */
`;
const CardHeaderRight = styled.div``;

const CardContent = styled.div<{
  gap?: string;
  orientation?: "horizontal" | "vertical";
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  ${props => {
    if (props.gap) {
      return `gap: ${props.gap};`;
    }

    if (props.orientation === "horizontal") {
      return `flex-direction: row;`;
    }

    if (props.orientation === "vertical") {
      return `flex-direction: column;`;
    }
  }}
`;

const CardFooter = styled.div`
  /* margin-top: 8px; */
`;

export const AppCard: React.FC<IAppCardProps> = ({
  children,
  title,
  headerRight,
  footerElement,
  headerElement,
  gap,
  horizontal,
  ...restCardProps
}) => {
  return (
    <CardContainer {...restCardProps}>
      <CardHeader>
        {headerElement ?? (
          <>
            <CardHeaderTitle>{title}</CardHeaderTitle>
            <CardHeaderRight>{headerRight}</CardHeaderRight>
          </>
        )}
      </CardHeader>
      <CardContent gap={gap} orientation={horizontal ? "horizontal" : "vertical"}>
        {children}
      </CardContent>
      <CardFooter>{footerElement}</CardFooter>
    </CardContainer>
  );
};

AppCard.defaultProps = {
  horizontal: false,
};
