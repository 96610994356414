import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { routeNames } from "lib/routes";
import styled, { RuleSet, css } from "styled-components";
import { ReactComponent as Notification } from "images/topbar/Bellnotification.svg";
import { ReactComponent as Alert } from "images/topbar/Notice.svg";
import { ReactComponent as Dropdown } from "images/topbar/Arrow.svg";
import { ReactComponent as ProfileIcon } from "images/vendorprofile/Profile.svg";
import { ReactComponent as SettingIcon } from "images/vendorprofile/SettingsIcon.svg";
import { ReactComponent as LogoutIcon } from "images/vendorprofile/LogoutIcon.svg";
import { useGetOrganizerByEmail, useGetVendorByEmail } from "lib/hooks";
import { getSession } from "lib/utils/utils";
import { Popover, ConfigProvider, Button } from "antd";
import { ReactComponent as Logoimg } from "images/topbar/mobileLogomark.svg";
import { ReactComponent as Open } from "images/topbar/hamburger.svg";
import { ReactComponent as Close } from "images/topbar/close.svg";

const Logo = styled.div`
  display: flex;
  flex-direction: column;
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #1390e6;
  gap: 0.3rem;
  cursor: pointer;
`;

const LogoName = styled.p`
  font-size: 17.54px;
  font-weight: 700;
  line-height: 22.05px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
`;

const Bottom = styled.p`
  display: flex;
  flex-direction: row;
  color: #595959;
  margin-left: 2.3rem;
  font-size: 8.77px;
  font-weight: 400;
  line-height: 14.62px;
  text-align: left;
  margin-top: -0.5rem;
  margin-bottom: 0;
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  margin: 1rem;
  margin-bottom: 0.7rem;
`;

const Right = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 0.7rem;
`;

const Circle = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: #1390e6;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
  font-size: 0.8rem;
`;

//styling for the profile pop-up
const MenuOption = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.5rem;
  background-color: #f9fbff;
  border-radius: 8px;
  width: 210px;
  padding: 1rem;
`;

const PopCircle = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #1390e6;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.2rem;
  position: relative;
`;
const NoticeDisplay = styled.div`
  border: 1px solid rgba(196, 152, 5, 1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0.2rem;
  padding: 0.2rem;
  margin-bottom: 0.5rem;
  font-size: 11px;
  font-weight: 400;
  line-height: 19px;
  text-align: left;
`;

const MenuBottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
  gap: 0.5rem;
`;

const UserName = styled.p`
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  margin-top: 0;
  margin-bottom: 0;
`;
const StyledLink = styled(Link)`
  color: rgba(18, 18, 18, 1);
  display: flex;
  gap: 0.5rem;
  align-items: center;

  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
`;
const Line = styled.div`
  width: 100%;
  border: 0.5px solid rgba(226, 232, 240, 1);
`;

const StyledButton = styled(Button)`
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: inherit;
  cursor: pointer;
  padding: 0;
  gap: 0.5rem;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  text-align: left;
`;

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const Menu = styled.div`
  margin: 0;
`;

interface MobileTopBarProps {
  toggleSidebar: () => void;
  isSidebarOpen: boolean;
}

const capitalizeFirstLetter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const MobileTopBar: React.FC<MobileTopBarProps> = ({ toggleSidebar, isSidebarOpen }) => {
  const [userId, setUserId] = useState<number | null>(null);
  const [name, setName] = useState<string>("");
  const [regularmodalOpen, setregularModalOpen] = useState(false);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [route, setRoute] = useState<string>("");
  const [initials, setInitials] = useState<string>("");
  const [userType, setUserType] = useState<string>("");
  const navigate = useNavigate();

  const { data: vendorData, error: vendorError, refetch: refetchVendor } = useGetVendorByEmail();
  const { data: organizerData, error: organizerError, refetch: refetchOrganizer } = useGetOrganizerByEmail();

  useEffect(() => {
    const session = getSession();
    if (session) {
      setUserId(session.userId);
      setUserType(session.userType);
    }
  }, []);

  useEffect(() => {
    if (userType === "vendor") {
      setRoute(routeNames.vendorProfile);
      if (vendorData) {
        const fullName = `${capitalizeFirstLetter(vendorData.user.firstName)} ${capitalizeFirstLetter(vendorData.user.lastName)}`;
        setName(fullName);
        setInitials(
          `${vendorData.user.firstName.charAt(0).toUpperCase()}${vendorData.user.lastName.charAt(0).toUpperCase()}`,
        );
      }
    } else if (userType === "organizer") {
      setRoute(routeNames.organizerProfile);
      if (organizerData) {
        const fullName = `${capitalizeFirstLetter(organizerData.user.firstName)} ${capitalizeFirstLetter(organizerData.user.lastName)}`;
        setName(fullName);
        setInitials(
          `${organizerData.user.firstName.charAt(0).toUpperCase()}${organizerData.user.lastName.charAt(0).toUpperCase()}`,
        );
      }
    }
  }, [userType, vendorData, organizerData]);

  //popover props
  const handleregularModalOpen = () => {
    setregularModalOpen(true);
    setPopoverVisible(false);
  };
  const handleLogout = () => {
    sessionStorage.clear();
    navigate(routeNames.homepage);
  };

  const content = (
    <MenuOption>
      <PopCircle>{initials}</PopCircle>
      <UserName>{name}</UserName>
      <NoticeDisplay>
        <Alert /> complete setting up {userType} profile
      </NoticeDisplay>
      <MenuBottom>
        <Line />

        <StyledLink to={route}>
          <ProfileIcon />
          <div>View Profile</div>
        </StyledLink>

        <StyledLink to={routeNames.vendorProfile}>
          <SettingIcon />
          Settings
        </StyledLink>

        <Line />
        <StyledLink to={"/"}>
          <LogoutIcon />
          Log Out
        </StyledLink>
      </MenuBottom>
    </MenuOption>
  );
  return (
    <Container>
      <Logo>
        <Top>
          <Logoimg /> <LogoName>Evendy</LogoName>
        </Top>
        <Bottom>{userType}</Bottom>
      </Logo>
      <Right>
        <Notification />
        <Circle>{initials}</Circle>
        <ConfigProvider>
          <Popover placement="bottom" trigger="click" onVisibleChange={setPopoverVisible} content={content}>
            <FlexContainer>
              <Dropdown />
            </FlexContainer>
          </Popover>
        </ConfigProvider>
        <Menu onClick={toggleSidebar}>{isSidebarOpen ? <Close /> : <Open />}</Menu>
      </Right>
    </Container>
  );
};

export default MobileTopBar;
