import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { ReactComponent as Add } from "images/organizerevents/Vectoradd.svg";
import SearchBar from "ui/components/Dashboard/Filter/Searchbar";
import AddPackageModal from "./AddPackageModal";
import NoPackage from "./nopackage";
import VendorPackageCard from "./VendorPackageCard";
import { useGetVendorPackagesByEmail, useGetVendorById } from "lib/hooks";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { sessionStorageKeys } from "lib/constants";
import { getSession } from "lib/utils/utils";

const Container = styled.div`
  margin: 1rem;
  display: flex;
  flex-direction: column;
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: -1.3rem;
  @media (max-width: 768px) {
    flex-direction: column;
    margin-top: 1rem;
  }
`;

const Header = styled.h3`
  font-size: 32px;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 768px) {
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
    text-align: left;
    margin-bottom: 1rem;
  }
`;

const Addnew = styled.button`
  background-color: #1390e6;
  color: white;
  border: 1px solid;
  border-radius: 8px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 13px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  width: 10rem;
`;

const Packagecontainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  margin-top: 1rem;
  @media (max-width: 768px) {
    flex-direction: column;
    gap: 20px; /* Adjust the gap as needed */
    width: 100%;
  }
`;

const VendorMyPackages: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState("");
  const [custommodalOpen, setcustomModalOpen] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  const handleSearch = (query: string) => {
    setSearchQuery(query);
  };

  const handlecustomModalOpen = () => {
    setcustomModalOpen(true);
  };

  const handlecustomModalClose = () => {
    setcustomModalOpen(false);
  };

  useEffect(() => {
    const session = getSession();

    if (session) {
      setUserEmail(session.email);
    }
  }, []);

  const { data, isLoading, isError, error, refetch } = useGetVendorPackagesByEmail({ email: userEmail });

  useEffect(() => {
    if (isError) {
      console.error("Error fetching packages:", error);
    }
  }, [isError, error]);

  return (
    <Container>
      <Top>
        <Header>My Packages</Header>
        <Addnew onClick={handlecustomModalOpen}>
          <Add />
          Add new package
        </Addnew>
      </Top>
      <SearchBar onSearch={handleSearch} />
      {isLoading ? (
        <p>Loading...</p>
      ) : isError || (data && data.length === 0) ? (
        <NoPackage onPackageAdded={refetch} />
      ) : (
        <Packagecontainer>
          {data?.map(pkg => <VendorPackageCard key={pkg.id} vendorpackage={pkg} onPackageAdded={refetch} />)}
        </Packagecontainer>
      )}
      <AddPackageModal open={custommodalOpen} onCancel={handlecustomModalClose} onPackageAdded={refetch} />
    </Container>
  );
};

export default VendorMyPackages;
