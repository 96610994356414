import React from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { DummyData, Vendor } from "./VendorProfile";
import { Rate } from "antd";

const ReviewsContainer = styled.div``;

const ReviewItem = styled.div`
  border: 1px solid;
  border-color: #f1f1f1;
  border-radius: 15px;
  padding: 1rem;
  margin-bottom: 1rem;
  background-color: #f9faff;
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: -1rem;
`;

const Left = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.5rem;
`;
const Right = styled.p``;

const Ratenum = styled.p``;

const Center = styled.p`
  margin-top: 0rem;
`;

const Bottom = styled.h4``;

interface Props {}

const Reviews: React.FC<Props> = props => {
  const [vendor, setVendor] = useState<Vendor | null>(null);
  const params = useParams<{ id?: string }>();

  useEffect(() => {
    // const id = params.id ? parseInt(params.id) : undefined;
    // const id = 1;
    // const selectedVendor = id ? DummyData.find(vendor => vendor.id === id) : null;
    // setVendor(selectedVendor || null);
  }, [params.id]);

  return (
    <>
      {vendor ? (
        <ReviewsContainer>
          {vendor.reviews.map(review => (
            <ReviewItem key={review.reviewid}>
              <Top>
                <Left>
                  <Rate allowHalf defaultValue={review.reviewrating} style={{ fontSize: 16 }} />
                  <Ratenum>{review.reviewrating}</Ratenum>
                </Left>
                <Right>{review.reviewdate}</Right>
              </Top>
              <Center>{review.reviewdesc}</Center>
              <Bottom>{review.revieworganzer}</Bottom>
            </ReviewItem>
          ))}
        </ReviewsContainer>
      ) : (
        <div>Loading...</div>
      )}
    </>
  );
};

export default Reviews;
