import React, { useState } from "react";
import styled from "styled-components";
import { LeftOutlined } from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import { routeNames } from "lib/routes";
import type { OTPProps } from "antd/es/input/OTP";
import type { FormInstance } from "antd";
import { Button, Form, Input } from "antd";
import { useResetPassword } from "lib/hooks";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
`;

const Back = styled.div`
  color: #1390e6;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.h3`
  font-size: 21px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 0;
`;

const LargeText = styled.p`
  font-size: 13px;
`;

const SmallText = styled.p`
  font-size: 12px;
  color: #9c9c9c;
  margin-top: 0;
`;

const Password = styled.div`
  display: flex;
  flex-direction: row;
  gap: 1rem;
  width: 100%;
`;

interface SubmitButtonProps {
  form: FormInstance;
}

const SubmitButton: React.FC<React.PropsWithChildren<SubmitButtonProps>> = ({ form, children }) => {
  const [submittable, setSubmittable] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState<boolean>(false);

  const values = Form.useWatch([], form);

  React.useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setSubmittable(true))
      .catch(() => setSubmittable(false));
  }, [form, values]);

  const handleClick = () => {
    setLoading(true);
    form.submit();
  };

  return (
    <Button
      type="primary"
      htmlType="submit"
      disabled={!submittable || loading}
      style={{
        width: "100%",
        color: "white",
        border: "none",
        fontSize: "13px",
        backgroundColor: loading ? "#1C5B87" : "",
      }}
      size="large"
      onClick={handleClick}
    >
      {loading ? "Please wait..." : children}
    </Button>
  );
};

const ResetPasswordPage: React.FC = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [isContinueEnabled, setIsContinueEnabled] = useState(false);
  const [otp, setOtp] = useState("");
  const { mutate: resetPassword, isError, error } = useResetPassword();

  const onChange = (text: string) => {
    setOtp(text);
    setIsContinueEnabled(text.length === 6);
  };

  const sharedProps: OTPProps = {
    onChange,
    length: 6,
  };

  const onFinish = (values: any) => {
    resetPassword(
      {
        token: otp,
        password: values.password,
      },
      {
        onSuccess: () => {
          console.log("Password reset successfully");
          navigate(routeNames.authPasswordUpdated);
        },
        onError: error => {
          console.error("Password reset error: ", error);
        },
      },
    );
  };

  return (
    <Container>
      <StyledLink to={routeNames.authForgotPassword}>
        <Back>
          <LeftOutlined /> Back
        </Back>
      </StyledLink>
      <Top>
        <Header>Reset Password</Header>
        <SmallText>Input the 6-digit number we sent before you can reset your password</SmallText>
      </Top>
      <LargeText>Input OTP Code</LargeText>
      <Input.OTP {...sharedProps} />
      <Form layout="vertical" form={form} name="register" scrollToFirstError requiredMark={false} onFinish={onFinish}>
        <Password>
          <Form.Item
            label="Password"
            style={{ width: "50%" }}
            name="password"
            rules={[
              {
                required: true,
                message: "Please input your password!",
              },
            ]}
          >
            <Input.Password placeholder="Enter password" style={{ fontSize: "14px", color: "#9C9C9C" }} />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            style={{ width: "50%" }}
            name="confirmpassword"
            dependencies={["password"]}
            hasFeedback
            rules={[
              {
                required: true,
                message: "Please confirm your password!",
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("password") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error("The new password that you entered do not match!"));
                },
              }),
            ]}
          >
            <Input.Password placeholder="Enter password" style={{ fontSize: "14px", color: "#9C9C9C" }} />
          </Form.Item>
        </Password>
        {isError && <p style={{ color: "red" }}>{(error as Error).message}</p>}
        <Form.Item>
          <SubmitButton form={form}>Update Password</SubmitButton>
        </Form.Item>
      </Form>
    </Container>
  );
};

export default ResetPasswordPage;
