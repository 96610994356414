import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { tqQueryKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";
import { ISODateString } from "ui/types";

interface Vendor {
  id: number;
  businessName: string | null;
  businessCategoryName: string | null;
  description: string | null;
  phone: string | null;
  avatar: string | null;
  country: string | null;
  state: string | null;
  city: string | null;
  address: string | null;
  workAddress: string | null;
  kycLevel: string;
  role: string;
  accountStatus: string;
  createdAt: ISODateString;
  vendorEmail: string;
  requests: object[];
  reviews: object[];
}

interface VendorResponse {
  data: Vendor[];
  page: number;
  limit: number;
  totalCount: number;
}

type VendorResult = UseQueryResult<VendorResponse, Error>;

export type GetVendorPayload = {
  page: number;
  limit: number;
  location: string;
  minCompletedRequests: number;
  minRating: number;
  category: string;
};

export function useGetVendor(payload: GetVendorPayload): VendorResult {
  const { page, limit, location, minCompletedRequests, minRating, category } = payload;

  // Filter out undefined or null params
  const params = {
    page,
    limit,
    ...(location && { location }),
    ...(minCompletedRequests && { minCompletedRequests }),
    ...(minRating && { minRating }),
    ...(category && { category }),
  };

  return useQuery({
    queryKey: [
      tqQueryKeys.vendors.getVendors,
      ...Object.values(params),
      page,
      limit,
      location,
      minCompletedRequests,
      minRating,
      category,
    ],
    queryFn: async (): Promise<VendorResponse> => {
      const res = await EvendyApi.get<VendorResponse>("vendor", { params });
      console.log("API Response:", res.data);
      return res.data;
    },
    enabled: !!page && !!limit,
    retry: false,
  });
}
