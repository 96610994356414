import React from "react";
import { WebsiteContainer, AppBadge } from "ui/components/base";
import styled from "styled-components";

const Section = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  div {
    width: 100%;
    max-width: 1200px;
    padding: 10px;
  }
  a {
    text-decoration: none;
  }
`;
const Badge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 3rem;
`;
const HeaderText = styled.h2`
  font-size: 1.5rem;
  font-weight: 500;
  line-height: 1.5rem;
`;
const Paragraph = styled.p`
  font-size: 1rem;
  font-weight: 400;
  line-height: 2.5rem;
`;
const List = styled.ol``;

const ListItem = styled.li``;

const AlphabeticalList = styled.ol`
  list-style-type: lower-alpha;
`;
const RomanList = styled.ol`
  list-style-type: lower-roman;
`;
const Privacy = () => {
  return (
    <>
      <WebsiteContainer>
        <Badge>
          <AppBadge group="Privacy Policy"></AppBadge>
        </Badge>
        <Section>
          <div>
            <Paragraph>
              <div>
                Evendy Tech-Services Limited (“Evendy”) is the owner and operator of www.evendy.co (the “Website”) and
                any other software, online platform, website, mobile or tablet application or domains used to provide
                our services (together with the Website, referred to as the “Services”). Evendy respects the privacy of
                your personal information, and makes every effort to ensure your information is protected and remains
                private. We have provided this Privacy Policy to explain how we collect, use, share, disclose and
                protect personal information about the users of our Website and Services (vendors, customers) hereafter
                referred to as “user”, “you” or “your”. This Privacy Policy also demonstrates our commitment to the
                protection of your privacy and your personal information. Your use of and access to the Services is
                subject to this Privacy Policy and our Terms of Use. Any capitalized term used but not defined in this
                Privacy Policy shall have the meaning attributed to it in the applicable Terms of Use for a particular
                Service. This Privacy Policy will inform you about the types of personal data we collect when you use
                our Services, the purposes for which we use the data, the ways in which the data is handled and your
                rights with regards to your personal data. For the purpose of this Privacy Policy the ‘Data Controller’
                means the natural or legal person who (either alone or jointly or in common with other persons)
                determines the purposes for which and the manner in which any personal information are, or will be,
                collected and processed.
              </div>
              <div>
                For purposes of this Privacy Policy, “Your Information” or “Personal Data” means information about you,
                which may be of a confidential or sensitive nature and may include personally identifiable information
                (“PII”) and/or financial information. PII means individually identifiable information that would allow
                us to determine the actual identity of a specific living person, while sensitive data may include
                information, comments, content and other information that you voluntarily provide. We may change this
                Privacy Policy from time to time. If we decide to change this Privacy Policy, we will inform you by
                sending a notice to your registered email address and by posting the revised Privacy Policy on the
                Website. By continuing to use the Website or Services after any change is effected, you consent to the
                revised Privacy Policy. BY USING THE SERVICES OR BY OTHERWISE GIVING US YOUR INFORMATION, YOU WILL BE
                DEEMED TO HAVE READ, UNDERSTOOD AND AGREED TO THE PRACTICES AND POLICIES OUTLINED IN THIS PRIVACY POLICY
                AND AGREE TO BE BOUND BY THE PRIVACY POLICY. YOU HEREBY CONSENT TO OUR COLLECTION, USE, TRANSFER,
                STORAGE AND DISCLOSURE OF YOUR INFORMATION (COLLECTIVELY, “USE OF YOUR INFORMATION”) AS DESCRIBED IN
                THIS PRIVACY POLICY. IF YOU DO NOT, OR NO LONGER, AGREE WITH OR CONSENT TO THIS PRIVACY POLICY AND OUR
                TERMS OF SERVICE DO NOT ACCESS ANY OF OUR SERVICES OR GIVE US YOUR INFORMATION.
              </div>
              <div>
                <List>
                  <div>
                    <ListItem>
                      {" "}
                      <HeaderText>Information Provided Directly By You</HeaderText>
                    </ListItem>
                    <Paragraph>
                      We may collect certain information about you provided directly to us, such as when you request
                      information, request Services, complete an Evendy transaction form, survey, questionnaire or
                      application, contact customer support, enter into a service provider agreement, make a
                      transaction, join or enroll for an event or otherwise communicate with us in any manner. This
                      information may include, without limitation: name, date of birth, e-mail address, physical
                      address, business address, phone number, photograph, means of identification type with its
                      identification number, credit card number, debit card number or other method of payment
                      information, or any other personal information you choose to provide.
                    </Paragraph>
                  </div>
                  <div>
                    <ListItem>
                      {" "}
                      <HeaderText>Information Collected Through Your Use of Our Services</HeaderText>
                    </ListItem>
                    <Paragraph>
                      We may collect certain information about you, your activity on our Website and your computer or
                      device when using our Services, for instance how you use them (e.g., date and time you logged in,
                      features you’ve been using, searches, clicks and pages which have been shown to you, referring web
                      page address, advertising that you click on) and how you interact with other users and us. The
                      following are situations in which you may provide Your Information to us:
                    </Paragraph>
                    <AlphabeticalList>
                      <ListItem>When you fill out forms or fields through our Services.</ListItem>
                      <ListItem>When you register for an account with our Service.</ListItem>
                      <ListItem>When you interact with us through Customer Support.</ListItem>
                      <ListItem>When you order products or services from, or through our Service.</ListItem>
                      <ListItem>When you provide responses to a survey.</ListItem>
                      <ListItem>When you answer questions on a questionnaire.</ListItem>
                      <ListItem>When you join or enroll in an event through our Services.</ListItem>
                      <ListItem>
                        {" "}
                        When you sign up for any newsletters or other materials through our Services.
                      </ListItem>
                      <ListItem>
                        When you provide information to us through a third-party application, service or Website.
                      </ListItem>
                      <ListItem>
                        When you communicate with us or request information about us or our products or Services,
                        whether via email or other means.
                      </ListItem>
                      <ListItem>
                        When you participate in any of our marketing initiatives, events or promotions; and
                      </ListItem>
                      <ListItem>
                        When you participate in forums, reviews or provide or post user generated content or other
                        submissions.
                      </ListItem>
                    </AlphabeticalList>
                    <Paragraph>
                      We also automatically collect information via the Website or Service using various technologies,
                      including, but not limited to Cookies and Web Beacons (explained below). We may collect your IP
                      address, browsing behavior and device IDs. This information is used by us in order to enable us to
                      better understand how our Services are being used by visitors and allows us to administer and
                      customize the Services to improve your overall experience.
                    </Paragraph>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Information Collected from Third-Party Sources</HeaderText>
                      <Paragraph>
                        We may also receive information about you from publicly and commercially available sources, as
                        permitted by applicable law which we may combine with other information we collect through our
                        Services. For example, if you choose to link, create, or log in to your Evendy account with a
                        social media service, or a payment provider, or if you engage with a separate App or Website
                        that uses our API, or whose API we use, we may receive information about you or your connections
                        from that Website or App. This includes, without limitation, profile information, profile
                        picture, gender, user name, user ID associated with your social media account, age range,
                        language, country, friends list, your contact names, e-mail addresses, phone numbers, and other
                        identifiers and any other information you permit the social network to share with third parties.
                        The data we receive is solely dependent upon your privacy settings with the social network.
                      </Paragraph>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Cookies, Log Files and Anonymous Identifiers</HeaderText>
                      <AlphabeticalList>
                        <ListItem>
                          When you visit our Services, we may send one or more Cookies – small data files – to your
                          computer to uniquely identify your browser and let us help you log in faster and enhance your
                          navigation through the Sites.
                          <br /> &apos;Cookies are small text files that websites and other online services use to store
                          information about users on the user&apos;s own computers. A Cookie may convey anonymous
                          information about how you browse the Services to us so we can provide you with a more
                          personalized experience but does not collect personal information about you. Cookies allow the
                          Sites to remember important information that will make your use of the site more convenient.
                          For example, cookies can be used to store your sign-in credentials so that you do not have to
                          enter them each time you return to a website. Cookies also may be used to store a unique
                          identification number tied to your computer so that a website can recognize you as the same
                          user across different visits to the website. A Cookie will typically contain the name of the
                          domain from which the Cookie has come, the &apos;lifetime&apos; of the Cookie, and a randomly
                          generated unique number or other value. Certain Cookies may be used on the Sites regardless of
                          whether you are logged in to your account or not.
                        </ListItem>
                        <ListItem>
                          Session Cookies are temporary Cookies that remain in the Cookie file of your browser until you
                          leave the Website.
                        </ListItem>
                        <ListItem>
                          Persistent Cookies remain in the Cookie file of your browser for much longer (though how long
                          will depend on the lifetime of the specific Cookie).
                        </ListItem>
                        <ListItem>
                          We may use session Cookies to track the total number of visitors to our Website, this is done
                          on an anonymous aggregate basis (as Cookies do not in themselves carry any personal data).
                        </ListItem>
                        <ListItem>
                          We may also employ Cookies so that we remember your computer when it is used to return to the
                          Website to help customize your Evendy web experience. We may associate personal information
                          with a Cookie file in those instances.
                        </ListItem>
                        <ListItem>
                          We may use Cookies to help us know that you are logged on, provide you with features based on
                          your preferences, understand when you are interacting with our Services, and compile other
                          information regarding use of our Services.
                        </ListItem>
                        <ListItem>
                          Third parties with whom we partner to provide certain features on our Website or to display
                          advertising based upon your Web browsing activity may use Cookies to collect and store
                          information about your browsing activities, in order to provide you with more relevant content
                          and ads, on and off the Services.
                        </ListItem>
                        <ListItem>
                          Our Website may use remarketing services, to serve ads on our behalf across the internet on
                          third party websites to previous visitors to our Sites. It could mean that we advertise to
                          previous visitors who haven’t completed a task on our site. This could be in the form of an
                          advertisement on the Google search results page or a site in the Google Display Network.
                          Third-party vendors, including Google, use Cookies to serve ads based on your past visits to
                          our Website. Any data collected will be used in accordance with our own privacy policy, as
                          well as Google’s privacy policies. To learn more, or to opt-out of receiving advertisements
                          tailored to your interests by our third parties, visit the Network Advertising Initiative at{" "}
                          <a href="www.networkadvertising.org/choices" target="_blank" rel="noopener noreferrer">
                            www.networkadvertising.org/choices
                          </a>
                          .
                        </ListItem>
                        <ListItem>
                          Evendy may use third-party services such as Google Analytics to help understand use of the
                          Services. These services typically collect the information sent by your browser as part of a
                          web page request, including Cookies and your IP address. They receive this information and
                          their use of it is governed by their respective privacy policies. You may opt-out of Google
                          Analytics for Display Advertisers including AdWords and opt-out of customized Google Display
                          Network ads by visiting the Google Ads Preferences Manager here{" "}
                          <a
                            href="https://adssettings.google.com/authenticated?hl=en"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            https://adssettings.google.com/authenticated?hl=en
                          </a>
                          . To provide website visitors more choice on how their data is collected by Google Analytics,
                          Google has developed an Opt-out Browser add-on, which is available by visiting Google
                          Analytics Opt-out Browser Add-on here <a>https://tools.google.com/dlpage/gaoptout</a>. For
                          additional information on how Google uses data when you use Google partners’ sites or apps, go
                          here:
                          <a>https://policies.google.com/technologies/partner-sites</a>.
                        </ListItem>
                        <ListItem>
                          {" "}
                          You can control the use of Cookies at the individual browser level. Use the options in your
                          web browser if you do not wish to receive a Cookie or if you wish to set your browser to
                          notify you when you receive a Cookie. You can easily delete and manage any Cookies that have
                          been installed in the Cookie folder of your browser by following the instructions provided by
                          your browser manufacturer. Consult the documentation that your browser manufacturer provides.
                          You may also consult your mobile device documentation for information on how to disable
                          Cookies on your mobile device. If you reject Cookies, you may still use our Website or
                          Service, but your ability to use some features or areas of our Service may be limited
                        </ListItem>
                        <ListItem>
                          Evendy cannot control the use of Cookies by third parties (or the resulting information), and
                          the use of third-party Cookies is not covered by this Privacy Policy.
                        </ListItem>
                        <ListItem>
                          We automatically collect information about how you interact with our Services, preferences
                          expressed, and settings chosen and store it in Log Files. This information may include
                          internet protocol (IP) addresses, browser type, internet service provider (ISP),
                          referring/exit pages, operating system, date/time stamp, and/or clickstream data. We may
                          combine this automatically collected log information with other information we collect about
                          you. We do this to improve services we offer you, to improve marketing, analytics, or
                          Service/Website functionality, and to document your consent to receiving products, services or
                          communications from us or our partners. If we link such information with personally
                          identifiable information in a manner that identifies an individual, then we will treat all
                          such information as PII for purposes of this Privacy Policy.
                        </ListItem>
                        <ListItem>
                          When you use our Services, we may employ Web Beacons (also known as clear GIFs or tracking
                          pixels) to anonymously track online usage patterns. No Personally Identifiable Information
                          from your account is collected using these Web Beacons.
                        </ListItem>
                      </AlphabeticalList>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Device Information</HeaderText>
                      <Paragraph>
                        When you use our Services through your computer, mobile phone or other device, we may collect
                        information regarding and related to your device, such as hardware models and IDs, IP address,
                        other unique identifiers, device type, operating system version, browser type, the request type,
                        the content of your request, settings of the device and basic usage information about your use
                        of our Services, such as date and time. In addition, we may collect information regarding
                        application-level events and associate that with your account to provide customer service. We
                        may also collect and store information locally on your device using mechanisms such as browser
                        web storage and application data caches.
                      </Paragraph>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Location Information</HeaderText>
                      <Paragraph>
                        When you use the Services, depending on your device settings, we may collect information about
                        your precise location. We may also derive your approximate location from your IP address.
                        Disabling the geolocation function may prevent you from using certain features of Services that
                        require location information.
                      </Paragraph>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Call and SMS Data</HeaderText>
                      <Paragraph>
                        In connection with providing Service, we may receive call and SMS data, including the date and
                        time of the call or SMS message, the parties phone numbers, and the content of the SMS message.
                        You consent to the receipt, collection and storage of this information.
                      </Paragraph>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Children’s Privacy</HeaderText>
                      <Paragraph>
                        Our Services are intended for users who have attained the age of majority where they reside (18
                        years of age or older in most jurisdictions). Furthermore, we do not knowingly collect
                        personally identifiable information (PII) from persons under the age of 13 in the United States
                        (“U.S.”), or under the age of 16 in the European Union (“EU”), the “Protected Age” without
                        parental consent unless permitted by law. If you are under the Protected Age, please do not
                        provide us with information of any kind whatsoever. If you have reason to believe that we may
                        have accidentally received information from a child under the Protected Age, please contact us
                        immediately. If we become aware that we have inadvertently received Personal Information from a
                        person under the Protected Age, we will delete such information from our records.
                      </Paragraph>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Protective Measures We Use</HeaderText>
                      <Paragraph>
                        We have put in place physical, technical and administrative measures that are intended to help
                        protect your information in our possession against loss, theft, misuse, unauthorized access,
                        disclosure and alteration. Some of the safeguards we use are firewalls and data encryption,
                        physical access controls to our data centers, and information access authorization controls.
                        Although we take measures to secure your information, we do not promise, and you should not
                        expect, that your personal information, or searches, or other information will always remain
                        secure. We cannot guarantee the security of our information storage, nor can we guarantee that
                        the information you supply will not be intercepted while being transmitted to and from us over
                        the Internet including, without limitation, email and text transmissions. In the event that any
                        information under our control is compromised as a result of a breach of security, we will take
                        reasonable steps to investigate the situation and notify those individuals whose information may
                        have been compromised and take other steps, in accordance with any applicable jurisdictional
                        laws and regulations.
                      </Paragraph>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Payment Information</HeaderText>
                      <Paragraph>
                        Evendy will accept payments on our Website or electronically. Payments will be accepted using a
                        secure third-party payment processing gateway to collect, store and process payment information.
                        That information will be provided directly to our third-party payment processors and use of your
                        personal information will be governed by their own Privacy Policy. Evendy does not store this
                        information on our Website or servers.
                      </Paragraph>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>
                        The Legal Basis and Reasons for Collection and Processing Your Personal Information
                      </HeaderText>
                      <Paragraph>
                        The legal basis upon which we rely for the collection and processing of your Personal
                        Information is the following:
                        <AlphabeticalList>
                          <ListItem>
                            When signing up to use our Services, you have given us explicit consent allowing Evendy to
                            provide you with our Services and generally to process your information, in accordance with
                            this Privacy Policy; and the transfer of your data to other jurisdictions as may be required
                            in accordance with applicable law;
                          </ListItem>
                          <ListItem>
                            It is necessary for entering into, or performing, the obligations of a contract between you
                            and Evendy, including, without limitation, the Terms of Use agreement;
                          </ListItem>
                          <ListItem> It is necessary to process your requests, transactions and payments</ListItem>
                          <ListItem>
                            {" "}
                            It is necessary to register you as a user, manage your account and profile, and authenticate
                            you when you log in.
                          </ListItem>
                          <ListItem>
                            It is necessary for our legitimate interests in the proper administration of our Website,
                            our Service and our business; analyzing the use of the website and our Services; assuring
                            the security of our website and Services; maintaining back-ups of our databases; and
                            communicating with you;
                          </ListItem>
                          <ListItem>
                            {" "}
                            To resolve technical issues you encounter, to respond to your requests for assistance,
                            comments and questions, to analyze crash information, to repair and improve the Services and
                            provide other customer support.
                          </ListItem>
                          <ListItem>
                            To send communications via email and within the Services, including, for example, responding
                            to your comments, questions and requests, providing customer support, and sending you
                            technical notices, product updates, security alerts, and administrative, billing and account
                            management related messages.
                          </ListItem>
                          <ListItem>
                            {" "}
                            To send promotional communications that you have consented to receive or have expressed a
                            legitimate interest in receiving.
                          </ListItem>
                          <ListItem>
                            It is necessary for our legitimate interests in the protection and assertion of our legal
                            rights, and the legal rights of others, including you.
                          </ListItem>
                          <ListItem>
                            It is necessary for our compliance with certain legal provisions which may require us to
                            process your personal data. By way of example, and without limitation, we may be required by
                            law to disclose your personal data to law enforcement or a regulatory agency.
                          </ListItem>
                        </AlphabeticalList>
                      </Paragraph>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText> How We Use Information We Collect</HeaderText>
                      <Paragraph>
                        Our primary purpose in collecting, holding, using and disclosing your Information is for our
                        legitimate business purposes and to provide you with a safe, smooth, efficient, and customized
                        experience while using the Service. We will not share, rent, sell, lease or barter your
                        information, to anyone unless you grant us explicit permission for this information to be
                        shared.
                        <br />
                        We will use this information in order to:
                        <AlphabeticalList>
                          <ListItem>
                            Provide users with our Services and Customer Support including, but not limited to verifying
                            your account and for informational and operational purposes, such as account management or
                            system maintenance.
                          </ListItem>
                          <ListItem>To process your requests, transactions and payments</ListItem>
                          <ListItem>
                            Track your billing and payment history and to contact you for account and promotional
                            purposes.
                          </ListItem>
                          <ListItem>Contact you and provide you with important notices.</ListItem>
                          <ListItem>Analyze, improve and manage our Services and operations.</ListItem>
                          <ListItem>
                            Resolve problems and disputes and engage in other legal and security matters.
                          </ListItem>
                          <ListItem> Detect and prevent fraud or abuse.</ListItem>
                          <ListItem>
                            Enforce our Terms of Service and any terms and conditions of any other agreements for our
                            Services.{" "}
                          </ListItem>
                          <ListItem>
                            Build new services and other purposes as deemed necessary by Evendy upon notification to
                            you.
                          </ListItem>
                        </AlphabeticalList>
                        <Paragraph>Additionally, we may use the information we collect about you to:</Paragraph>
                        <AlphabeticalList>
                          <ListItem>
                            Send you communications we think will be of interest to you, including information about
                            products, services, promotions, news, and events of Evendy and other companies, where
                            permissible and according to local applicable laws.
                          </ListItem>
                          <ListItem>
                            Display advertising, including advertising that is targeted to you or other users based on
                            your location, interests, as well as your activities on our Services.
                          </ListItem>
                          <ListItem>
                            Verify your identity and prevent impersonation, spam or other unauthorized or illegal
                            activity including, without limitation, infringement of intellectual property rights of
                            third parties.
                          </ListItem>
                        </AlphabeticalList>
                      </Paragraph>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>How We Share Information We Collect</HeaderText>
                      <AlphabeticalList>
                        <ListItem>
                          <Paragraph>
                            We may share the information we collect about you as described in this Privacy Policy or as
                            described at the time of collection or sharing, including as follows:
                          </Paragraph>
                          <RomanList>
                            <ListItem>
                              {" "}
                              With third party Service Providers that provide a variety of services on our behalf. For
                              example, we may rely on service providers to provide web analytics, data processing,
                              advertising, email distribution and other services. These service providers are expected
                              to protect the confidentiality, integrity and security of the information we share with
                              them. However, we are not responsible for their privacy practices.
                            </ListItem>
                            <ListItem>
                              With the general public if you submit content to a part of our Services that is viewable
                              by the general public, such as a forum or community area.
                            </ListItem>
                            <ListItem>
                              With third parties with whom you choose to let us share information, for example other
                              websites or apps that integrate with our API or Services, or those with an API or Service
                              with which we integrate.
                            </ListItem>
                            <ListItem>
                              {" "}
                              With current and future Evendy subsidiaries and affiliated entities that provide services,
                              including payment processing services or conduct data processing on our behalf, or for
                              data verification, data centralization and/or logistics purposes.
                            </ListItem>
                            <ListItem>
                              With affiliated entities that provide payment processing services on our behalf.
                            </ListItem>
                            <ListItem>
                              With vendors, consultants, marketing partners, and other service providers who need access
                              to such information to carry out work on our behalf.
                            </ListItem>
                            <ListItem>
                              In response to a request for information by a competent authority if we believe disclosure
                              is in accordance with, or is otherwise required by, any applicable law, regulation, or
                              legal process.
                            </ListItem>
                            <ListItem>
                              With law enforcement officials, government authorities, or other third parties if we
                              believe your actions are inconsistent with our user agreements, Terms of Use, or policies,
                              or to protect the rights, property, or safety of Evendy or others.
                            </ListItem>
                            <ListItem>
                              {" "}
                              In connection with, or during negotiations of, any merger, sale of company assets,
                              consolidation or restructuring, financing, or acquisition of all or a portion of our
                              business by or into another company.
                            </ListItem>
                            <ListItem>If we otherwise notify you and you consent to the sharing; and</ListItem>
                            <ListItem>
                              In an aggregated and/or de-identified form which cannot reasonably be used to identify
                              you.
                            </ListItem>
                          </RomanList>
                        </ListItem>
                        <Paragraph>
                          We only use such data in the aggregate form and our analytical services do not record any
                          personal information.
                        </Paragraph>
                        <ListItem>
                          <Paragraph>We may disclose Your Information:</Paragraph>
                          <RomanList>
                            <ListItem>
                              To any person who, in our reasonable judgment, is authorized to receive Your Information
                              as your agent, including as a result of your business dealings with that person (for
                              example, your attorney or accountant).
                            </ListItem>
                            <ListItem>
                              To our third-party vendors and service providers so that they may provide support for our
                              internal and business operations, including for the processing of payments, handling of
                              data processing, data verification, data storage, surveys, research, internal marketing,
                              delivery of promotional, marketing and transaction materials, and our Services maintenance
                              and security. These companies are authorized to use Your Information only as necessary to
                              provide these services to us and are contractually obligated to keep Your Information
                              confidential.
                            </ListItem>
                            <ListItem>
                              To third parties when you engage in certain activities through our Services that are
                              sponsored by them, such as purchasing products or services offered by a third party,
                              electing to receive information or communications from a third party, or electing to
                              participate in other programs sponsored, in whole or in part, by a third party. When we
                              disclose Your Information to these third parties, Your Information will become subject to
                              the information use and sharing practices of the third party, and the third party will not
                              be restricted by this Privacy Policy with respect to its use and further sharing of Your
                              Information.
                            </ListItem>
                            <ListItem>
                              As required by law or ordered by a court, regulatory, or administrative agency.
                            </ListItem>
                            <ListItem>
                              As we deem necessary, in our sole discretion, if we believe that you are violating any
                              applicable law, rule or regulation, or are otherwise interfering with another’s rights or
                              property, including, without limitation, our rights or property.
                            </ListItem>
                            <ListItem>
                              To enforce our Terms of Use, this Privacy Policy, and any other applicable agreements, or
                              protect our legal rights.
                            </ListItem>
                          </RomanList>
                        </ListItem>
                      </AlphabeticalList>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Aggregated Data</HeaderText>
                      <Paragraph>
                        Aggregated Data is de-identified or anonymized and does not constitute Personal Data as this
                        data does not directly or indirectly reveal your identity. We may collect, use and share
                        Aggregated Data such as statistical or demographic data for any purpose including, without
                        limitation, research (including medical research), education, analyzing usage trends and
                        patterns, improving our Services and business operations, evaluating the performance of Evendy
                        Service and measuring the effectiveness of our content, and Service components. We may receive
                        compensation in exchange for sharing de-identified or anonymized Aggregated Data which shall be
                        for the account of Evendy. If we ever combine Aggregated Data with your Personal Data so that it
                        can directly or indirectly identify you, we treat the combined data as PII which will only be
                        used in accordance with this Privacy Policy and will not be shared without your consent.
                      </Paragraph>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText> Sharing Information with Law Enforcement</HeaderText>
                      <Paragraph>
                        Evendy is committed to cooperating with law enforcement while respecting everyone’s right to
                        privacy. If Evendy receives a request for user account information from a government agency
                        investigating criminal activity, we will review the request to be certain that it satisfies all
                        legal requirements before releasing information to the requesting agency.
                      </Paragraph>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Social Media Sharing</HeaderText>
                      <AlphabeticalList>
                        <ListItem>
                          Our Services may now or in the future integrate with social sharing features and other related
                          tools which let you share actions you take on our Services with other Apps, sites, or media,
                          and vice versa, depending on the settings you establish with the social sharing service.
                          Please refer to the privacy policies of those social sharing services for more information
                          about how they handle the data you provide to or share through them.
                        </ListItem>
                        <ListItem>
                          {" "}
                          Any information or content that you voluntarily disclose for posting publicly to a social
                          sharing service becomes available to the public, as controlled by any applicable privacy
                          settings that you set with the social sharing service. Once you have shared User Content or
                          made it public, that User Content may be re-shared by others. If you remove information that
                          you posted to the social sharing service, copies may still remain viewable in cached and
                          archived pages, or if other users or third parties, using the social sharing service, have
                          re-shared, copied or saved that User Content.
                        </ListItem>
                      </AlphabeticalList>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>User Content and Public Sharing</HeaderText>
                      <AlphabeticalList>
                        <ListItem>
                          If you share User Content or post a comment or message that contains personally identifiable
                          information to a page on our Services that is accessible to the public, third parties may use
                          that information, or share it with other parties. As a result, you may receive unsolicited
                          messages from other parties. Such User Content, comments or messages are not subject to this
                          Privacy Policy and you share this information at your own risk.
                        </ListItem>
                        <ListItem>
                          You will need to contact Evendy if you wish to have this personally identifiable information
                          removed from our Service. Please note that responding to your request may not ensure complete
                          or comprehensive removal from the internet if the content or information has been reposted by
                          another party. To request removal of content or information, please contact us at
                          <a href="mailto:support@evendy.co"> support@evendy.com</a>
                        </ListItem>
                      </AlphabeticalList>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Advertising and Analytic Services Provided by Others</HeaderText>
                      <Paragraph>
                        We and third parties that provide content or functionality on the Services or provide us
                        analytics and advertising services may collect or receive information about your use of the
                        Services and other websites or mobile applications, including through the use of Cookies, Web
                        Beacons, software development kits (SDKs), and other technologies to identify your device when
                        you visit our Website and use our Services, as well as when you visit other online sites and
                        services. This information may include, for example, your IP address, browser, device
                        information, pages viewed, time spent on pages, links clicked and conversion information. This
                        information may be combined with information collected across different websites, online
                        services, and linked or associated devices to, among other things, analyze and track data,
                        determine the popularity of certain content, deliver advertising and content targeted to your
                        interests, and better understand your online activity. For more information about Internet-based
                        ads, or to opt out of receiving targeted advertising from participating companies, please visit
                        <a href="www.aboutads.info/choices" target="_blank" rel="noopener noreferrer">
                          {" "}
                          www.aboutads.info/choices.
                        </a>
                      </Paragraph>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Links to Third-Party Websites</HeaderText>
                      <AlphabeticalList>
                        <ListItem>
                          Our Services, as well as the email messages sent with respect to our Services, may contain
                          links or access to websites and services operated by third parties that are beyond our
                          control. Links or access to third parties from our Services are not an endorsement by us of
                          such third parties, or their websites, applications, products, services, or practices. We are
                          not responsible for the security or privacy policy, terms and conditions, practices or the
                          content of such third parties. These third parties may send their own Cookies to you and
                          independently collect data.
                        </ListItem>
                        <ListItem>
                          If you visit or access a third-party Website, application or other property that is linked or
                          accessed from our Services, we encourage you to read any privacy policies and terms and
                          conditions of that third party before providing any personally identifiable information. If
                          you have a question about the terms and conditions, privacy policy, practices or contents of a
                          third party, please contact the third party directly.
                        </ListItem>
                      </AlphabeticalList>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Do Not Track Settings</HeaderText>
                      <Paragraph>
                        Some web browsers may transmit or give you the ability to enable a “do not track” feature that
                        sends signals to the websites you visit or other online services with which the browser
                        communicates, indicating that you do not want your online activities tracked. This is different
                        from blocking or deleting Cookies, as browsers with a “do not track” feature enabled may still
                        accept Cookies. There currently is no accepted standard for how to respond to this signal, and
                        we do not take any action in response to this signal. If a standard for responding is
                        established, we may revisit our Policy on responding to these signals.
                      </Paragraph>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>International Privacy Practices</HeaderText>
                      <Paragraph>
                        In order to provide our Services to you, we may send and store your personal information outside
                        of the country where you reside or are located, including countries that may not, or do not,
                        provide the same level of protection for your personal information as your resident country. We
                        are committed to protecting the privacy and confidentiality of personal information when it is
                        transferred and take appropriate steps to provide the same level of protection for the
                        processing carried out in the country in which you reside to the extent feasible under
                        applicable law. By checking the box acknowledging that you have read and agree to this Privacy
                        Policy, and providing explicit consent at the time of your registration for your account, you
                        agree and consent to the transfer to, and processing of, personal information on servers located
                        outside of the country where they reside, and assume the risk that the protection of such
                        information may be different and may be less protective than those required under the laws of
                        their residence or location.
                      </Paragraph>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Account Information</HeaderText>
                    </ListItem>
                    <Paragraph>
                      You may access or modify your account information at any time by logging into your online account.
                      If you wish to cancel your account, please email us at{" "}
                      <a href="mailto:support@evendy.co">support@evendy.com</a>. Please note that in some cases we may
                      retain certain information about you as required by law, or for legitimate business purposes to
                      the extent permitted by law. For instance, if you have a standing credit or debit on your account,
                      or if we believe you have committed fraud or violated our Terms of Use, we may seek to resolve the
                      issue before deleting your information.
                    </Paragraph>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Promotional Information opt-out</HeaderText>
                      <Paragraph>
                        You may opt out of receiving promotional messages from us at any time by following the
                        instructions in those messages sent to you or by contacting us at any time using the Contact Us
                        information at the end of this Privacy Policy. If you opt out, we may still send you
                        non-promotional communications, such as those related to your account, or use of our products
                        and Services.
                      </Paragraph>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Your Access and Rights to Your Personal Information</HeaderText>
                      <Paragraph>
                        You have certain rights in relation to Personal Information we hold about you. You can exercise
                        any of the following rights by contacting us using any of the methods in the Contact section
                        below. We may need to request specific information from you to help us confirm your identity and
                        ensure your right to access your Personal Data (or to exercise any of your other rights). This
                        is a security measure to ensure that Personal Data is not disclosed to any person who has no
                        right to receive it. We try to respond to all legitimate requests within 45 days. Occasionally
                        it may take us longer than 45 days if your request is particularly complex or you have made
                        several requests. In this case, we will notify you and keep you updated.
                      </Paragraph>
                      <AlphabeticalList>
                        <ListItem>
                          Right to Access Your Personal Data. You have the right to access information held about you
                          for the purpose of viewing and in certain cases updating or deleting such information.
                          Furthermore, if you prefer that Evendy does not share certain information as described in this
                          Privacy Policy, you can direct Evendy not to share that information. We will comply with an
                          individual’s requests regarding access, correction, sharing and/or deletion of the personal
                          data we store in accordance with applicable law. To make changes to your account affecting
                          your personal information contact us at the email address in our Contact section below. For
                          any deletion, non-sharing or update request, we will make the changes as soon as practicable,
                          however this information may stay in our backup files. If we cannot make the changes you want,
                          we will let you know and explain why.
                        </ListItem>
                        <ListItem>
                          Right of Correction or Completion of Your Personal Data. If personal information we hold about
                          you is not accurate, out of date or incomplete, you have a right to have the data corrected or
                          completed. To make corrections to your account please contact us at the email address in our
                          Contact section below.
                        </ListItem>
                        <ListItem>
                          Right of Erasure or Deletion of Your Personal Data. In certain circumstances, you have the
                          right to request that personal information we hold about you is deleted. If we cannot delete
                          the information you want, we will let you know and explain why. To request information
                          deletion please contact us at the email address in our Contact section below.
                        </ListItem>
                        <ListItem>
                          Right to Object to or Restrict Processing of Your Personal Data. In certain circumstances, you
                          have the right to object to our processing of your personal information. For example, you have
                          the right to object to use of your personal information for direct marketing purposes.
                          Similarly, you have the right to object to use of your personal information if we are
                          processing your information based on legitimate interests and there are no compelling
                          legitimate grounds for our processing which supersede your rights and interests. You may also
                          have the right to restrict our use of your personal information, such as in circumstances
                          where you have challenged the accuracy of the information and during the period where we are
                          verifying its accuracy. To object to or restrict processing please contact us at the email
                          address in our Contact section below.
                        </ListItem>
                        <ListItem>
                          Right to Data Portability or Transfer of Your Personal Data. You have the right to be provided
                          with a copy of the information we maintain about you in a structured, machine-readable and
                          commonly used format. To receive a copy of the information we maintain about you please
                          contact us at the email address in our Contact section below. This request may only be made
                          twice over a 12-months period.
                        </ListItem>
                        <ListItem>
                          Right to Withdrawal of Consent. If you have given your consent to us to process and share your
                          Personal Information after we have requested it, you have the right to withdraw your consent
                          at any time. To withdraw your consent please contact us at the email address in our Contact
                          section below.
                        </ListItem>
                      </AlphabeticalList>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Our Information Retention Policy</HeaderText>
                      <Paragraph>
                        Unless you request that we delete certain information, we retain the information we collect for
                        as long as your account is active or as needed to provide you services. Following termination or
                        deactivation of your account, we will retain information for at least 1 year or for as long as
                        needed for our legal purposes in accordance with applicable law. We will only retain your
                        Personal Data for so long as we reasonably need to unless a longer retention period is required
                        by law (for example for regulatory purposes).
                      </Paragraph>
                    </ListItem>
                  </div>
                  <div>
                    <ListItem>
                      <HeaderText>Contact Us</HeaderText>
                      <Paragraph>
                        If you have any questions or if you would like to contact us about our processing of your
                        personal information, including exercising your rights as outlined above, please contact us
                        through any of the methods below. When you contact us, we will ask you to verify your identity.
                      </Paragraph>
                      <Paragraph>Contact name: Data Protection Officer</Paragraph>
                      <Paragraph>
                        Email: <a href="mailto:support@evendy.co">support@evendy.com</a>
                      </Paragraph>
                      <Paragraph>Our office address:</Paragraph>
                      <Paragraph>Phone number: +2349039504875</Paragraph>
                      <Paragraph>Last updated: March 6th, 2022</Paragraph>
                    </ListItem>
                  </div>
                </List>
              </div>
            </Paragraph>
          </div>
        </Section>
      </WebsiteContainer>
    </>
  );
};
export default Privacy;
