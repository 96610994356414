import { UseQueryResult, useQuery } from "@tanstack/react-query";
import { tqQueryKeys } from "lib/constants";
import { EvendyApi } from "lib/utils";
import { ISODateString } from "ui/types";

type GetEventsResponse = {
  id: number;
  title: string;
  banner: string;
  organizerEmail: string;
  category: string;
  type: string;
  about: string;
  occurence: string;
  timezone: string;
  startDate: ISODateString;
  endDate: ISODateString;
  createdAt: ISODateString;
  updatedAt: ISODateString;
}[];

type UseGetEventsResult = UseQueryResult<GetEventsResponse, Error>;

export function useGetEvents(): UseGetEventsResult {
  return useQuery({
    queryKey: [tqQueryKeys.event.getEvents],
    queryFn: async () => {
      const res = await EvendyApi.get<GetEventsResponse>("event");
      return res.data;
    },
  });
}
