import React from "react";
import { Form, Input } from "antd";
import styled from "styled-components";
import SubmitButton from "../Button";
import { SubmitContainer } from "../AccountDetails";

export const Container = styled.div`
  width: 641px;
  height: 370px;
  top: 435px;
  left: 633px;
  gap: 40px;
`;
export const Label = styled.div`
  font-weight: 400;
  font-size: 0.8rem;
  line-height: 1.5rem;
  color: rgba(89, 89, 89, 1);
  height: 24px;
`;

const General = () => {
  const [form] = Form.useForm();

  const handleSubmit = (data: any) => {
    console.log("Form data:", data);
  };
  return (
    <>
      <Container>
        <Form layout="vertical" form={form} onFinish={handleSubmit} initialValues={{ remember: true }}>
          <Form.Item rules={[{ message: "Please input your profile url!" }]}>
            <Label>My Url</Label>
            <Input style={{ width: "100%", fontSize: "14px", color: "#9C9C9C", paddingLeft: "10px" }} />
          </Form.Item>
          <Form.Item rules={[{ message: "Please input your email address!" }]}>
            <Label>Email Address</Label>
            <Input style={{ width: "100%", fontSize: "14px", color: "#9C9C9C", paddingLeft: "10px" }} />
          </Form.Item>
          <Form.Item rules={[{ message: "Incorrect Password!" }]}>
            <Label>Enter password to confirm email change</Label>
            <Input style={{ width: "100%", fontSize: "14px", color: "#9C9C9C", paddingLeft: "10px" }} />
          </Form.Item>
        </Form>
        <SubmitContainer>
          <SubmitButton form={form}>Save Changes</SubmitButton>{" "}
        </SubmitContainer>
      </Container>
    </>
  );
};
export default General;
