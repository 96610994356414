import { useQuery, UseQueryResult } from "@tanstack/react-query";
import { tqQueryKeys } from "lib/constants";
import { EvendyApi } from "lib/utils";
import { ISODateString } from "ui/types";
import { getSession } from "lib/utils";
import { useMemo } from "react";

type GetOrganizerByEmailResponse = {
  id: number;
  userEmail: string;
  avatar: string;
  country: string;
  state: string;
  city: string;
  address: string;
  workAddress: string;
  kycLevel: string;
  role: string;
  accountStatus: string;
  createdAt: ISODateString;
  organizationName: string;
  user: {
    id: number;
    createdAt: ISODateString;
    updatedAt: ISODateString;
    firstName: string;
    lastName: string;
    email: string;
    verified: boolean;
    organizer: object;
  };
};

type UseGetOrganizerByEmailResult = UseQueryResult<GetOrganizerByEmailResponse, Error>;

export function useGetOrganizerByEmail(): UseGetOrganizerByEmailResult {
  const session = getSession();

  const email = useMemo(() => session?.email, [session]);

  return useQuery({
    queryKey: [tqQueryKeys.organizer.getOrganizerbyEmail, email],
    queryFn: async (): Promise<GetOrganizerByEmailResponse> => {
      const res = await EvendyApi.get<GetOrganizerByEmailResponse>(`organizer/get-by-email/${email}`);
      return res.data;
    },
    enabled: !!email,
    retry: false,
  });
}
