import React, { useState } from "react";
import styled from "styled-components";
import { Button } from "antd";
import { SettingOutlined, PlusCircleOutlined } from "@ant-design/icons";
import CreateTicketModal from "./ticketModal";
import { Link, useNavigate } from "react-router-dom";
import { AppModal } from "ui/components/base";

// Styled Components
const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px;
  max-width: 900px;
  width: 100%;
  margin: 0 auto;
`;

const TopRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
`;

const Title = styled.h2`
  font-size: 18px;
`;

const CreateTicketButton = styled(Button)`
  display: flex;
  align-items: center;
  border-color: #1890ff;
  color: #1890ff;
  height: 46px;
`;

const SettingsLink = styled.a`
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #1890ff;
  text-decoration: none;
  margin-top: 20px;

  &:hover {
    color: #40a9ff;
  }
`;

const BottomRow = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 16px;
`;

const CancelButton = styled(Button)`
  border-color: #d9d9d9;
  color: #000;
`;

const SaveButton = styled(Button)`
  background-color: #1890ff;
  color: #fff;
  border-color: #1890ff;

  &:hover {
    background-color: #40a9ff;
    border-color: #40a9ff;
  }
`;

// EventTickets Component
const EventTickets = () => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  // Open the modal
  const handleModalOpen = () => {
    setIsModalVisible(true);
  };

  // Close the modal
  const handleModalClose = () => {
    setIsModalVisible(false);
  };

  return (
    <Container>
      <TopRow>
        {/* Left side - Title and Create Ticket Button */}
        <div>
          <Title>Event Tickets</Title>
          <CreateTicketButton icon={<PlusCircleOutlined />} onClick={handleModalOpen}>
            Create new ticket
          </CreateTicketButton>
        </div>

        {/* Right side - General Settings */}
        <SettingsLink href="#">
          <SettingOutlined style={{ marginRight: 8 }} />
          General Settings
        </SettingsLink>
      </TopRow>

      {/* Modal for Creating Ticket */}
      <AppModal open={isModalVisible} header="Create Ticket" onCancel={handleModalClose} size="lg">
        <CreateTicketModal />
      </AppModal>

      {/* Bottom Row - Cancel and Save buttons */}
      <BottomRow>
        <CancelButton>Cancel</CancelButton>
        <SaveButton type="primary">Save and continue</SaveButton>
      </BottomRow>
    </Container>
  );
};

export default EventTickets;
