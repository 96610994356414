import { routeNames } from "lib/routes";
import React from "react";
import { useNavigate } from "react-router-dom";

export const Logout = () => {
  const navigate = useNavigate();
  React.useEffect(() => {
    sessionStorage.clear();
    navigate(routeNames.homepage);
  }, []);
  return <div>Redirecting...</div>;
};
