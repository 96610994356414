import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { AppModal } from "ui/components/base";
import { ModalBodyContainer, NoticeDisplay, NoticeText, Text } from "app/pages/organizer/vendorsprofile/Packages";

type OrganiserDetailsModalProps = {
  open: boolean;
  onCancel: () => void;
};

const OrganiserDetailsModal: React.FC<OrganiserDetailsModalProps> = ({ open, onCancel }) => {
  return (
    <AppModal open={open} onCancel={onCancel} header={<span>Organiser Details</span>} size="md">
      <ModalBodyContainer></ModalBodyContainer>
    </AppModal>
  );
};

export default OrganiserDetailsModal;
