import { IAppTheme } from "ui/theme";
import { colorSchemes } from "./colors";
import { breakpoints, mediaQueries } from "./breakpoints";
import { fontWeights, fontSizes, letterSpacings, lineHeights, textTransforms } from "./fonts";

export * from "./mixins";
export * from "./types";

export const evendyTheme: IAppTheme = {
  colorMode: "default",
  colorSchemes,
  // should be set based on color mode selected, default to colorSchemes.default
  colors: colorSchemes.default,
  zIndices: {
    auto: "auto",
    0: "0",
    10: "10",
    20: "20",
    30: "30",
    40: "40",
    50: "50",
    60: "60",
    70: "70",
    80: "80",
    90: "90",
  },
  breakpoints,
  mediaQueries,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  //   WIP
  container: ["40rem", "48rem", "64rem", "80rem", "96rem"],
  icons: {
    iconXs: "24px",
    iconSm: "32px",
    iconMd: "40px",
    iconLg: "48px",
    iconXl: "56px",
  },
  textTransforms: textTransforms,
};

export const layoutHeight = {
  header: 73,
};

export const badgeColors = [];
export const screenSizes = {
  mobileS: "320px",
  mobileM: "375px",
  mobileL: "425px",
  tablet: "768px",
  laptop: "1024px",
  laptopL: "1440px",
  desktop: "2560px",
};
export const devices = {
  mobileS: `(min-width: ${screenSizes.mobileS})`,
  mobileM: `(min-width: ${screenSizes.mobileM})`,
  mobileL: `(min-width: ${screenSizes.mobileL})`,
  tablet: `(min-width: ${screenSizes.tablet})`,
  laptop: `(min-width: ${screenSizes.laptop})`,
  laptopL: `(min-width: ${screenSizes.laptopL})`,
  desktop: `(min-width: ${screenSizes.desktop})`,
  desktopL: `(min-width: ${screenSizes.desktop})`,
};
