import React from "react";
import { Divider as AntDivider, DividerProps } from "antd";
import styled, { css } from "styled-components";

interface IAppDividerProps extends DividerProps {
  margin?: React.CSSProperties["margin"];
}

const StyledDivider = styled(AntDivider)<IAppDividerProps>`
  background-color: var(--gray-200, #eaecf0);
  ${({ type, margin }) => {
    if (type === "vertical") {
      return css`
        margin: 0px ${margin} !important;
        height: 100%;
      `;
    }
    if (type === "horizontal") {
      return css`
        margin: ${margin} 0px !important;
      `;
    }
  }}
`;

export const AppDivider: React.FC<IAppDividerProps> = ({ children, ...restDividerProps }) => {
  return <StyledDivider {...restDividerProps}>{children}</StyledDivider>;
};

AppDivider.defaultProps = {
  margin: "16px",
  type: "horizontal",
};
