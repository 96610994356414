import { useMutation } from "@tanstack/react-query";
import { EvendyApi } from "lib/utils/api-utils";

type ChangePasswordPayload = {
  email: string;
  password: string;
  newPassword: string;
};

export function useChangePassword() {
  return useMutation({
    mutationFn: async (payload: ChangePasswordPayload) => {
      const endpoint = "/auth/change-password";
      const res = await EvendyApi.post(endpoint, payload);
      return res.data;
    },
    onError(error: Error) {
      console.log("Error changing password:", error);
    },
    onSuccess() {
      console.log("Password change successful");
    },
  });
}
