import { Input, Tag } from "antd";
import React, { useState } from "react";
import { styled } from "styled-components";

const TagContainer = styled.div`
  display: flex;
  border: solid 1px #d9d9d9;
  border-radius: 8px;
  padding: 10px;
  flex-flow: wrap;
`;
export interface TagInputProps {
  tags: string[];
  onTagsChange: (tags: string[]) => void;
}

export const TagInput: React.FC<TagInputProps> = ({ tags, onTagsChange }) => {
  const [inputValue, setInputValue] = useState<string>("");

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputValue(e.target.value);
  };

  const handleInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter" && inputValue.trim() !== "") {
      // Prevent duplicate tags
      if (!tags.includes(inputValue.trim())) {
        const updatedTags = [...tags, inputValue.trim()];
        onTagsChange(updatedTags);
      }
      setInputValue("");
    }
  };

  const handleTagRemove = (tag: string) => {
    const updatedTags = tags.filter(t => t !== tag);
    onTagsChange(updatedTags);
  };

  return (
    <div>
      <TagContainer className="tag-list">
        {tags.map((tag, index) => (
          <Tag
            closeIcon
            closable
            key={index}
            title={tag}
            style={{ display: "flex", alignItems: "center" }}
            onClose={() => handleTagRemove(tag)}
          >
            {tag}
          </Tag>
        ))}
        <Input
          style={{ border: "none" }}
          type="text"
          placeholder="Type and press Enter to add tags"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleInputKeyPress}
        />
      </TagContainer>
    </div>
  );
};
