import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ReactComponent as Logoutimg } from "images/sidebar/Framelogout.svg";
import { ReactComponent as Logoimg } from "images/sidebar/Logo.svg";
import { sessionStorageKeys } from "lib/constants";
import { routeNames } from "lib/routes";
import { jwtDecode, JwtPayload } from "jwt-decode";

const SidebarContainer = styled.div`
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
  position: fixed;
  width: 18.5%;
  height: 100%;
  margin-top: -1.5rem;

  @media (max-width: 768px) {
    width: 100%;
    margin-top: -2.5rem;
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.05);
  }
`;

const Logo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: left;
  width: 100%;
  margin-top: 1rem;
  border-bottom: 0.05px solid #d4d8dd;
`;

const Top = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #1390e6;
  font-size: 20px;
  font-weight: 600;
  gap: 0.4rem;
  margin-left: 1rem;
  cursor: pointer;
`;

const LogoName = styled.p`
  font-size: 24px;
  font-weight: 700;
  line-height: 30.17px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  color: #595959;
  margin-left: 4.1rem;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 0.55rem;
`;

const Itemlist = styled.ul`
  margin-left: -2.5rem;
  list-style: none;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: left;
  gap: 1rem;
`;

const ItemContainer = styled.li`
  font-family: "BR Firma";
  margin-top: 0;
`;

const ItemWrapper = styled.div<{ isActive: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1rem;
  color: ${({ isActive }) => (isActive ? "#1390E6" : "#606060")};
  background-color: ${({ isActive }) => (isActive ? "#FCFDFD" : "white")};
  padding-left: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 14px;
  font-weight: ${({ isActive }) => (isActive ? "600" : "400")};
  border-right: ${({ isActive }) => (isActive ? "4px solid #1390E6" : "none")};
`;

const Name = styled.div``;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const ComingSoonButton = styled.div`
  padding: 2px 5px;
  background-color: #fffefa;
  color: #665000;
  border: 1px solid;
  border-color: #f1f1f1;
  border-radius: 8px;
  font-size: 9px;
  cursor: not-allowed;
  margin-left: auto;
  margin-right: 0.5rem;
`;

const Logout = styled.button`
  background-color: #1390e6;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 15px 15px;
  font-size: 13px;
  font-weight: 600;
  width: 80%;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 0.5rem;
  margin-left: 10%;
  margin-top: 5rem;
  cursor: pointer;
`;

export interface SidebarItem {
  name: string;
  path: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  comingSoon?: boolean;
}

interface SidebarProps {
  sidebarData: SidebarItem[];
}

const Sidebar: React.FC<SidebarProps> = ({ sidebarData }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogo = () => {
    navigate(routeNames.homepage);
  };

  const handleLogout = () => {
    sessionStorage.clear();
    navigate(routeNames.homepage);
  };

  return (
    <SidebarContainer>
      <Itemlist>
        {sidebarData.map((item, index) => {
          const isActive = location.pathname === item.path;
          return (
            <ItemContainer key={index}>
              <StyledLink to={item.path}>
                <ItemWrapper isActive={isActive}>
                  <item.icon />
                  <Name>{item.name}</Name>
                  {item.comingSoon && <ComingSoonButton>Coming Soon</ComingSoonButton>}
                </ItemWrapper>
              </StyledLink>
            </ItemContainer>
          );
        })}
      </Itemlist>
      <Logout onClick={handleLogout}>
        <Logoutimg />
        Log out
      </Logout>
    </SidebarContainer>
  );
};

export default Sidebar;
