import { Layout as AntLayout } from "antd";
import React, { useState } from "react";
import styled from "styled-components";
import { AppSidebar, AppSidebarProps, MenuItem } from "./Sidebar/Sidebar";
import { AppHeader, AppHeaderProps } from "./Header";
import { layoutHeight } from "ui/theme";
import { Container } from "./Container";
import { Outlet } from "react-router-dom";
import { getSiteSidebarRoutes, resolveCurrentSite } from "lib/utils/route-utils";
import { IOrganizerProfile, IUser } from "ui/types";

const userId = "";

export const singleUser: IUser = {
  id: userId,
  lastName: "Ogarabe",
  firstName: "Faith",
  email: "faitho@gmail.com",
  createdAt: "34-345-345T456:34:3456.3456Z",
  updatedAt: "34-345-345T456:34:3456.3456Z",
};

export const userProfile: IOrganizerProfile = {
  id: "",
  userId,
  avatar: "/assets/dummy/avatar.png",
  phone: "090541941969",
  country: "Nigeria",
  state: "Federal Capital Territory",
  city: "Abuja",
  address: "",
  workAddress: "",
  kycLevel: 0,
  account_status: "active",
  role: "organizer",
  createdAt: "34-345-345T456:34:3456.3456Z",
  settings: {
    profilePublicUrl: "https://evendy.co/organizer/faith-ogarabe",
  },
  socials: [],
};

const { Content } = AntLayout;

const StyledLayout = styled(AntLayout)<{ collapsed: boolean }>`
  box-shadow: 3px 0px 10px 0px #00000005;
`;

const StyledContent = styled(Content)`
  background: #fff;
  height: calc(100vh - ${layoutHeight.header}px);
  padding: 24px;
  overflow-y: auto;
  width: 100%;
`;
export interface AppLayoutProps {
  sidebarMenuItems?: MenuItem[];
  sidebarBottomMenuItems?: MenuItem[];
  sidebarProps?: Omit<AppSidebarProps, "setCollapsed" | "collapsed" | "items" | "teamItems">;
  headerProps: Omit<AppHeaderProps, "theme">;
}

const site = resolveCurrentSite();

const { items, bottomItems } = getSiteSidebarRoutes({ site });

interface PropTypes {
  children?: React.ReactNode;
}

export const DashboardLayout: React.FC<PropTypes> = () => {
  const [collapsed, setCollapsed] = useState<boolean>(false);

  return (
    <AntLayout style={{ minHeight: "100vh" }}>
      <AppHeader collapsed={collapsed} user={singleUser} profile={userProfile} />
      <StyledLayout hasSider collapsed={collapsed}>
        <AppSidebar collapsed={collapsed} setCollapsed={setCollapsed} items={items} bottomItems={bottomItems} />
        <StyledContent>
          <Container>
            <Outlet />
          </Container>
        </StyledContent>
      </StyledLayout>
    </AntLayout>
  );
};
