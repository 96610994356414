import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { tqMutationKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";

type BankDetailsPayload = {
  bankName: string;
  bankAccountName: string;
  bankAccountNumber: string;
  bankCode: number;
  vendorEmail: string;
};

interface BankDetailsResponse {
  id: number;
  bankAccountName: string;
  bankName: string;
  bankAccountNumber: string;
  bankCode: number;
  vendorEmail: string;
  vendorSettings: {
    id: number;
    publicProfileUrl: string;
    passwordLastChanged: string;
    emailLastChanged: string;
    vendor: {
      id: number;
      businessName: string;
      businessCategoryName: string;
      description: string;
      phone: string;
      avatar: string;
      coverImage: string;
      country: string;
      state: string;
      city: string;
      address: string;
      workAddress: string;
      kycLevel: string;
      role: string;
      accountStatus: string;
      createdAt: string;
      user: {
        id: number;
        createdAt: string;
        updatedAt: string;
        firstName: string;
        lastName: string;
        email: string;
        verified: boolean;
        phone: string;
        userType: string;
      };
      vendorEmail: string;
    };
    vendorEmail: string;
  };
}

type UseEditVendorBankResult = UseMutationResult<BankDetailsResponse, Error, BankDetailsPayload>;

export function useEditVendorBank(): UseEditVendorBankResult {
  return useMutation({
    mutationFn: async (payload: BankDetailsPayload) => {
      const res = await EvendyApi.post<Promise<BankDetailsResponse>>("/vendor-settings/bank-details", payload);
      return res.data;
    },
    mutationKey: [tqMutationKeys.vendor.bankDetails.edit],
    onError(error: Error) {
      console.error("Error editing vendor bank details:", error);
    },
    onSuccess(data) {
      console.log("Vendor bank details edited successfully:", data);
    },
  });
}
