import { jwtDecode, JwtPayload } from "jwt-decode";
import { sessionStorageKeys } from "lib/constants";
import get from "lodash.get";

interface DecodedToken extends JwtPayload {
  userId: number;
  userType: string;
  email: string;
  exp: number;
  iat: number;
}

interface Session extends DecodedToken {
  isValid: () => boolean;
}

export const getSession = (): Session | null => {
  const accessToken = sessionStorage.getItem(sessionStorageKeys.accessToken);
  if (accessToken) {
    const decodedToken = jwtDecode<DecodedToken>(accessToken);

    if (!decodedToken.exp || typeof decodedToken.exp !== "number") {
      return null;
    }

    const tokenExpiration = decodedToken.exp;
    const currentTimestamp = Math.floor(Date.now() / 1000);
    const isValid = () => {
      if (tokenExpiration < currentTimestamp) {
        return false;
      }
      return true;
    };

    return { ...decodedToken, isValid };
  }
  return null;
};

export const appGet = (object: unknown, path: string, defaultValue: any) => {
  return get(object, path, defaultValue);
};
