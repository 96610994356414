import styled, { RuleSet, css } from "styled-components";
import { Button } from "antd";
import { screenSizes } from "ui/theme";
import { AppLink } from "ui/components/base";
import { Link } from "react-router-dom";

export const HEADER_HEIGHT = "83px";

const nonMobileOnlyFlex = (desktopStyles: RuleSet<object>) => `
  display: none;
  @media (min-width: ${screenSizes.laptop}) {
    display: flex;
    ${desktopStyles}
  }
`;
const mobileOnlyFlex = (mobileStyles: RuleSet<object>) => `
  display: flex;
  ${mobileStyles}
  @media (min-width: ${screenSizes.laptop}) {
    display: none;
  }
`;

const hiddenOnMobile = css`
  visibility: hidden;
  @media (min-width: ${screenSizes.laptop}) {
    visibility: visible;
  }
`;

const hiddenOnNonMobile = css`
  visibility: visible;
  @media (min-width: ${screenSizes.laptop}) {
    visibility: hidden;
  }
`;
const hidden = `
  visibility: hidden;
`;

export const SiteHeader = styled.header`
  position: fixed;
  background-color: white;
  top: 0px;
  left: 0px;
  right: 0px;
  height: ${HEADER_HEIGHT};
  max-width: 100vw;
  z-index: 999;
  min-height: 83px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const NavText = styled.p`
  margin-top: 0;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: center;
  color: #121212;
  z-index: 10;
`;

export const LogoContainer = styled.div`
  position: relative;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

export const StyledServiceLink = styled(AppLink)`
  text-decoration: none;
  outline: none;
  color: #121212;
  border: none;
  padding: 10px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  /* gap: 2rem; */
`;

export const HoverContainer = styled.div`
  position: absolute;
  top: -0.5rem;
  left: 2rem;
  visibility: hidden;
  opacity: 0;
  transition:
    opacity 0.3s ease,
    visibility 0.3s ease;

  /* Show HoverContainer when hovering over LogoContainer */
  ${StyledServiceLink}:hover & {
    visibility: visible;
    opacity: 1;
  }
`;

export const ArrowContainer = styled.div`
  justify-content: flex-end;
  margin-top: 0;
  margin-bottom: 0;
  padding: 0;
  align-items: center;
  display: flex;
  flex-direction: row;
`;

export const MenuColoumn = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderPlaceholder = styled.div`
  height: ${HEADER_HEIGHT};
`;

export const LogoSection = styled.div`
  display: flex;
  /* margin-left: 1rem; */
  @media (max-width: 768px) {
    margin-left: 0;
  }
`;

export const MobileNav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${HEADER_HEIGHT};
  padding: 0px 88px;
  z-index: 999;
  @media (min-width: ${screenSizes.laptop}) {
    display: none;
  }
`;

export const Nav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 17%;
  margin-left: 2%;
  align-items: center;
  height: ${HEADER_HEIGHT};
  @media (max-width: 768px) {
    gap: 0;
    margin: 0;
    justify-content: space-between;
  }
  @media (max-width: 1024px) {
    gap: 6%;
    margin: 0;
    justify-content: space-between;
  }
  @media (max-width: 1280px) {
    gap: 10%;
    margin-left: 5%;
    justify-content: space-between;
  }
`;

export const NavMenu = styled.ul`
  ${nonMobileOnlyFlex(css`
    display: flex;
    flex-direction: row;
    align-items: center;
    list-style-type: none;
    gap: 1rem;
  `)}
`;
export const MobileNavMenu = styled.ul`
  ${mobileOnlyFlex(css`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0;
    margin-top: 6rem;
    padding: 0;
    list-style-type: none;
    gap: rem;
    /* flex: 1; */
  `)}
`;

export const StyledLi = styled.li`
  text-decoration: none;
  outline: none;
  list-style-type: none;
  border-radius: 5px;
  padding-top: 4px;
  padding-bottom: 4px;
  /* Apply transition to background-color and color for a gradual fade */
  transition:
    background-color 0.5s ease-in-out,
    color 0.4s ease-in-out;

  &:hover {
    background-color: #ededed; /* Ash color on hover */
    border-radius: 5px;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  a {
    color: inherit; /* Ensure the link inherits the color from the li */
    text-decoration: none; /* Remove underline */
    transition: color 0.7s ease-in-out; /* Smooth color transition */

    &:hover {
      color: #1390e6;
    }
  }
`;

export const Login = styled.button`
  height: 46px;
  border: none;
  color: #1390e6;
  background-color: #fff;
  cursor: pointer;
  padding: 12px 30px;
  width: 170px;
`;

export const SignUpLink = styled(Link)`
  all: unset;
  height: 46px;
  border-radius: 8px;
  color: #121212;
  width: 170px;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  /* Add transition for smooth shadow appearance */
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    color: #1c5b87;
  }
`;

export const StyledButton = styled(Button)`
  height: 46px;
  border-radius: 8px;
  padding: 12px 30px;
  background-color: rgba(19, 144, 230, 1);
  color: #fff;
  width: 190px;
`;

export const LoginLink = styled(Link)`
  all: unset;
  height: 46px;
  border-radius: 8px;
  background-color: rgba(19, 144, 230, 1);
  color: #fff;
  width: 170px;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  /* Add transition for smooth shadow appearance */
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    background-color: #3897d8;
    /* Dropdown shadow effect */
    box-shadow: 0 8px 16px rgba(56, 151, 216, 0.3); /* Subtle shadow */
  }
`;

export const StyledHeaderLink = styled(AppLink)`
  text-decoration: none;
  outline: none;
  color: #121212;
  border: none;
  padding: 10px;
  font-size: 14px;
`;

export const RightAlign = styled.div`
  display: flex;
`;
export const CollaspeButton = styled.button`
  ${mobileOnlyFlex(css`
    display: block;
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    margin-right: 1.1rem;
  `)}
`;

export const AuthSection = styled.div`
  ${nonMobileOnlyFlex(css`
    flex: 1;
    ${hiddenOnMobile}
    ${hidden}
  `)}
`;
export const MobileAuthSection = styled.div`
  flex-direction: column;
`;
