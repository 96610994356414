import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { AxiosError } from "axios";
import { sessionStorageKeys, tqMutationKeys } from "lib/constants";
import { routeNames } from "lib/routes";
import { EvendyApi } from "lib/utils/api-utils";

type LoginPayload = {
  email: string;
  password: string;
};
interface ILoginDto {
  accessToken: string;
}

interface ILoginErrorDto {
  error: string;
  message: string;
  statusCode: number;
}
// const LoginErrorDtoSample = {
//   data: {
//     error: "UnknownError",
//     message: "Unknown Error has occured, Kindly try again",
//     statusCode: 500,
//   }
// }

type LoginResult = UseMutationResult<ILoginDto, AxiosError<ILoginErrorDto>, LoginPayload>;

export function useLogin(): LoginResult {
  return useMutation({
    mutationFn: async (payload: LoginPayload) => {
      const { email, password } = payload; // not compulsory
      const res = await EvendyApi.post<Promise<ILoginDto>>("auth/login", { email, password });
      return res.data;
    },
    mutationKey: [tqMutationKeys.auth.login],
    onError(loginError) {
      console.log({ loginError });

      return loginError;
    },
    onSuccess(data) {
      sessionStorage.setItem(sessionStorageKeys.accessToken, data.accessToken);
    },
    retry: false,
  });
}
