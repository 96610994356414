const colors1 = {
  primarySingle: "",
  primary: "#1570ef",
  primaryLight: "",
  primaryHighlight: "",
};

export const colorSchemes = {
  default: {
    // neutral
    baseWhite: "#FFFFFF",
    baseBlack: "#000000",
    // blueLight
    blueLight25: "#F5FBFF",
    blueLight50: "#F0F9FF",
    blueLight100: "#E0F2FE",
    blueLight200: "#B9E6FE",
    blueLight300: "#7CD4FD",
    blueLight400: "#36BFFA",
    blueLight500: "#0BA5EC",
    blueLight600: "#0086C9",
    blueLight700: "#026AA2",
    blueLight800: "#065986",
    blueLight900: "#0B4A6F",
    blueLight950: "#062C41",
    // blue
    blue25: "#F5FAFF",
    blue50: "#EFF8FF",
    blue100: "#D1E9FF",
    blue200: "#B2DDFF",
    blue300: "#84CAFF",
    blue400: "#53B1FD",
    blue500: "#2E90FA",
    blue600: "#1570EF",
    blue700: "#175CD3",
    blue800: "#1849A9",
    blue900: "#194185",
    blue950: "#102A56",
    // error
    error25: "#FFFBFA",
    error50: "#FEF3F2",
    error100: "#FEE4E2",
    error200: "#FECDCA",
    error300: "#FDA29B",
    error400: "#F97066",
    error500: "#F04438",
    error600: "#D92D20",
    error700: "#B42318",
    error800: "#912018",
    error900: "#7A271A",
    error950: "#55160C",
    // gray
    gray100: "#F2F4F7",
    gray200: "#EAECF0",
    gray300: "#D0D5DD",
    gray400: "#98A2B3",
    gray50: "#F9FAFB",
    gray500: "#667085",
    gray600: "#475467",
    gray700: "#344054",
    gray800: "#1D2939",
    gray900: "#101828",
    gray950: "#0C111D",
    // green
    green100: "#D3F8DF",
    green25: "#F6FEF9",
    green50: "#EDFCF2",
    green600: "#099250",
    // orange
    orange50: "#FEF6EE",
    // primary
    primary25: "#FCFAFF",
    primary50: "#F9F5FF",
    primary100: "#F4EBFF",
    primary200: "#E9D7FE",
    primary300: "#D6BBFB",
    primary400: "#B692F6",
    primary500: "#9E77ED",
    primary600: "#7F56D9",
    primary700: "#6941C6",
    primary800: "#53389E",
    primary900: "#42307D",
    primary950: "#2C1C5F",
    // success
    success600: "#079455",
    // warning
    warning400: "#FDB022",
    // yellow
    yellow50: "#FEFBE8",

    // antd customizations
    primaryHighlight: colors1.primaryHighlight,
    background: "#fff",
    bgSecondary: colors1.primaryLight,
    highlight: colors1.primaryLight,
    boxBg: "#dfdfdf",
    textColor: "black",
    textSecondary: "#a1a1aa", // Zinc/400"
    textTertiary: "#9A9DA3",
    borderLine: "",
    primary: colors1.primary,
    bgPrimary: "#FFF4F7",
    secondary: "",
    accent: "",
    success: "",
    fail: "",
    colorBgContainer: "#f4f4f4",
    logoColor: "",
  },
  light: {
    // neutral
    baseWhite: "#FFFFFF",
    baseBlack: "#000000",
    // blueLight
    blueLight25: "#F5FBFF",
    blueLight50: "#F0F9FF",
    blueLight100: "#E0F2FE",
    blueLight200: "#B9E6FE",
    blueLight300: "#7CD4FD",
    blueLight400: "#36BFFA",
    blueLight500: "#0BA5EC",
    blueLight600: "#0086C9",
    blueLight700: "#026AA2",
    blueLight800: "#065986",
    blueLight900: "#0B4A6F",
    blueLight950: "#062C41",
    // blue
    blue25: "#F5FAFF",
    blue50: "#EFF8FF",
    blue100: "#D1E9FF",
    blue200: "#B2DDFF",
    blue300: "#84CAFF",
    blue400: "#53B1FD",
    blue500: "#2E90FA",
    blue600: "#1570EF",
    blue700: "#175CD3",
    blue800: "#1849A9",
    blue900: "#194185",
    blue950: "#102A56",
    // error
    error25: "#FFFBFA",
    error50: "#FEF3F2",
    error100: "#FEE4E2",
    error200: "#FECDCA",
    error300: "#FDA29B",
    error400: "#F97066",
    error500: "#F04438",
    error600: "#D92D20",
    error700: "#B42318",
    error800: "#912018",
    error900: "#7A271A",
    error950: "#55160C",
    // gray
    gray100: "#F2F4F7",
    gray200: "#EAECF0",
    gray300: "#D0D5DD",
    gray400: "#98A2B3",
    gray50: "#F9FAFB",
    gray500: "#667085",
    gray600: "#475467",
    gray700: "#344054",
    gray800: "#1D2939",
    gray900: "#101828",
    gray950: "#0C111D",
    // green
    green100: "#D3F8DF",
    green25: "#F6FEF9",
    green50: "#EDFCF2",
    green600: "#099250",
    // orange
    orange50: "#FEF6EE",
    // primary
    primary25: "#FCFAFF",
    primary50: "#F9F5FF",
    primary100: "#F4EBFF",
    primary200: "#E9D7FE",
    primary300: "#D6BBFB",
    primary400: "#B692F6",
    primary500: "#9E77ED",
    primary600: "#7F56D9",
    primary700: "#6941C6",
    primary800: "#53389E",
    primary900: "#42307D",
    primary950: "#2C1C5F",
    // success
    success600: "#079455",
    // warning
    warning400: "#FDB022",
    // yellow
    yellow50: "#FEFBE8",

    // antd customizations
    primaryHighlight: colors1.primaryHighlight,
    background: "#fff",
    bgSecondary: colors1.primaryLight,
    highlight: colors1.primaryLight,
    boxBg: "#dfdfdf",
    textColor: "black",
    textSecondary: "#a1a1aa", // Zinc/400"
    textTertiary: "#9A9DA3",
    borderLine: "",
    primary: colors1.primary,
    bgPrimary: "#FFF4F7",
    secondary: "",
    accent: "",
    success: "",
    fail: "",
    colorBgContainer: "#f4f4f4",
    logoColor: "",
  },
  dark: {
    background: "black",
    bgSecondary: colors1.primaryLight,
    highlight: "",
    boxBg: "",
    textColor: "",
    textSecondary: "",
    textTertiary: "",
    borderLine: "",
    primary: colors1.primary,
    bgPrimary: "",
    secondary: "",
    accent: "",
    success: "#22C55E",
    fail: "#DC2626",
    colorBgContainer: "#141616",
    logoColor: "",
    primaryHighlight: colors1.primaryHighlight,
  },
};
