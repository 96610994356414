import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { routeNames } from "lib/routes";
import { ReactComponent as Location } from "images/vendorcard/Framelocation.svg";
import { ReactComponent as Request } from "images/vendorcard/Framerequests.svg";
import { ReactComponent as Rating } from "images/vendorcard/star-01rating.svg";
import { ReactComponent as Like } from "images/vendorcard/Favouritelike.svg";
import vendorImage1 from "images/vendorcard/Rectangle3292dfxdtudio.png";
import vendorImage2 from "images/vendorcard/Rectangle3292chowdeck.png";
import vendorImage3 from "images/vendorcard/Rectangle3292swiftlogistic.png";
import vendorImage4 from "images/vendorcard/Rectangle3292mamakitchen.png";
import vendorImage5 from "images/vendorcard/Rectangle3292essawine.png";
import vendorImage6 from "images/vendorcard/Rectangle3292ogelogistic.png";
import vendorImage7 from "images/vendorcard/Rectangle3292capturegraphy.png";
import vendorImage8 from "images/vendorcard/Rectangle3292rjprental.png";
import vendorImage9 from "images/vendorcard/Rectangle3292maybelphoto.png";
import vendorImage10 from "images/vendorcard/Rectangle3292desixdecor.png";
import vendorImage11 from "images/vendorcard/Rectangle3292beerpalour.png";
import vendorImage12 from "images/vendorcard/Rectangle3292premierrental.png";

const CardContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 19px;
`;

const CardWrapper = styled.div`
  width: calc(23.8% - 15px);
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 20px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 1200px) {
    width: calc(31.8% - 15px);
  }

  @media (max-width: 768px) {
    width: 100%;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const ImageContainer = styled.div`
  position: relative;
`;

const ButtonContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7rem;
  top: 10%;
  left: 5%;
`;

const ApproveButton = styled.button`
  background-color: #665000;
  color: #faff19;
  border: 1px solid;
  border-color: #faff19;
  padding: 8px;
  border-radius: 10px;
  font-size: 9px;
  z-index: 1;
`;

const LikeButton = styled(Like)`
  z-index: 1;
`;

const VendorImage = styled.img`
  width: 100%;
  max-height: 8rem;
  border-radius: 8px;
  margin-bottom: 10px;
`;

const VendorName = styled.h3`
  margin: 0;
  margin-left: 0.5rem;
  font-size: 14px;
  margin-bottom: 0.5rem;
  font-weight: 500;
  color: #333;
`;

const VendorLocation = styled.p`
  margin: 5px 0;
  font-size: 14px;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  color: #666;
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const VendorRequest = styled.p`
  margin: 5px 0;
  font-size: 14px;
  margin-left: 0.5rem;
  margin-bottom: 0.5rem;
  color: #666;
  display: flex;
  flex-direction: row;
  gap: 1rem;
`;

const VendorRating = styled.div`
  margin-left: 0.5rem;
  margin-bottom: 10px;
  font-size: 14px;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  color: #666;
`;

const LikeIcon = styled.span`
  margin-right: 5px;
  color: #ff6347;
`;

const VendorCategory = styled.button`
  background-color: #fafbff;
  color: #2943d6;
  border: 1px solid;
  border-color: #f1f1f1;
  border-radius: 8px;
  padding: 5px 10px;
  font-size: 10px;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
`;

const AwaitingApprovalButton = styled.button`
  background-color: #f0ad4e;
  color: #fff;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  font-size: 12px;
  cursor: not-allowed;
`;

interface Vendor {
  id: number;
  avatar: string | null;
  businessName: string | null;
  businessCategoryName: string | null;
  address: string | null;
  country: string | null;
}

interface CardProps {
  vendor: Vendor;
}

const Card: React.FC<CardProps> = ({ vendor }) => {
  return (
    <CardWrapper>
      <StyledLink to={`${routeNames.organizerVendorProfile}/${vendor.id}`}>
        <ImageContainer>
          <VendorImage src={vendorImage1} />
          <ButtonContainer>
            <ApproveButton>Awaiting approval</ApproveButton>
            <LikeButton>Like</LikeButton>
          </ButtonContainer>
        </ImageContainer>
        <VendorName>{vendor.businessName}</VendorName>
        <VendorLocation>
          <Location />
          {vendor.address}
        </VendorLocation>
        <VendorRequest>
          <Request />
          {/* {vendor.completedrequest} */}
        </VendorRequest>
        <VendorRating>
          <Rating />
          {/* {vendor.rating} */}
        </VendorRating>
        <VendorCategory>{vendor.businessCategoryName}</VendorCategory>
        {/* {vendor.awaitingApproval && (
        <AwaitingApprovalButton>Awaiting Approval</AwaitingApprovalButton>
      )} */}
      </StyledLink>
    </CardWrapper>
  );
};

export default Card;
