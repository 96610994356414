import * as React from "react";
import { useState } from "react";
import styled from "styled-components";
import EventList from "./EventList";

const Explore = styled.div`
  margin-left: 3rem;
  margin-right: 3rem;
  margin-bottom: 2rem;
`;

const Header = styled.h2`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

// Styled search input
const SearchInput = styled.input`
  width: 100%;
  padding: 5px 15px;
  font-size: 16px;
  border: none;
  outline: none;
  background-color: white;
  border-left: 2px solid black;
  margin-bottom: 2rem;

  &:focus {
    background-color: white;
  }

  &::placeholder {
    color: #9c9c9c;
    font-size: 18px;
  }
`;

// Styled component for categories row

const CategoriesRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2.5rem;
  margin-bottom: 15px;

  @media (max-width: 768px) {
    gap: 0;
    justify-content: space-between;
  }
`;

// Styled component for each category button
const Category = styled.p`
  cursor: pointer;
  color: #9c9c9c;
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
`;

interface IexploreProps {}

const categories = [
  "Music",
  "Nightlife",
  "Performing & Visual Arts",
  "Holidays",
  "Health",
  "Hobbies",
  "Business",
  "Food & Drink",
  "Comedy",
  "Education",
  "Mental health",
  "Travel",
];

const ExploreComponent: React.FunctionComponent<IexploreProps> = props => {
  const [searchTerm, setSearchTerm] = useState("");

  // Handle clicking on a category
  const handleCategoryClick = (category: string) => {
    setSearchTerm(category); // Set category as the search term
  };

  // Handle typing in the search bar
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(e.target.value); // Update the search term
  };

  return (
    <Explore>
      <Header>Browse or search for events</Header>

      <SearchInput
        type="text"
        placeholder="Search event name or location"
        value={searchTerm} // The search term is set as the input value
        onChange={handleSearchChange} // Update the value when typing
      />
      <CategoriesRow>
        {categories.map(category => (
          <Category key={category} onClick={() => handleCategoryClick(category)}>
            {category}
          </Category>
        ))}
      </CategoriesRow>

      <EventList />
    </Explore>
  );
};

export default ExploreComponent;
