import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, AxiosError } from "axios";
import { sessionStorageKeys } from "lib/constants";

const BASE_URL = process.env.REACT_APP_BACKEND_BASEURL;

const EvendyAPI: AxiosInstance = axios.create({
  baseURL: BASE_URL,
});

EvendyAPI.interceptors.request.use(
  (config: AxiosRequestConfig): any => {
    const accessToken = sessionStorage.getItem(sessionStorageKeys.accessToken);
    if (accessToken) {
      if (config.headers) {
        config.headers["Authorization"] = `Bearer ${accessToken}`;
      } else {
        config.headers = { Authorization: `Bearer ${accessToken}` };
      }
    }
    return config;
  },
  (error: AxiosError) => {
    console.error(error);
    return Promise.reject(error);
  },
);

// EvendyAPI.interceptors.response.use(
//   (response: AxiosResponse) => {
//     return response;
//   },
//   (error: AxiosError) => {
//     console.error(error);
//     if (error.response) {
//       console.error(error.response.status);
//       console.error(error.response.data);
//     }
//     return Promise.reject(error);
//   },
// );

export { EvendyAPI as EvendyApi };
