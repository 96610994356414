import React from "react";
import NewBenefit from "./Benefits/NewBenefits";
import FAQSection from "./faq/faq";
import NewHero from "./hero/NewHero";
import NewLandingPageServices from "./serviceSection/NewLandingPageServices";
import NewTestimonial from "./Testimonial/NewTestimonial";

const LandingPage: React.FC = () => {
  return (
    <>
      <NewHero />
      <NewLandingPageServices />
      <NewBenefit />
      <NewTestimonial />
      <FAQSection />
    </>
  );
};

export default LandingPage;
