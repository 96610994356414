import { Menu, MenuProps } from "antd";
import React from "react";
import styled, { css } from "styled-components";
import { themeUtils } from "ui/utils";

interface ISidebarNavProps extends MenuProps {
  position?: "bottom" | "top";
}

const { getColor } = themeUtils;

const StyledMenu = styled(Menu)<ISidebarNavProps>`
  ${props => css`
    background: ${getColor(props, "baseWhite")};
    padding: 0px 16px;
    margin-top: 24px;
    gap: 16px;
    display: flex;
    flex-direction: column;

    .ant-menu-item {
      .ant-menu-item-title-content {
        color: var(--Dark-2, #2f2f2f);

        /* Body 2/Regular */
        font-family: "'BR Firma'";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px; /* 171.429% */
      }
      .ant-menu-item-icon {
        width: 24px !important;
        height: 24px !important;
        /* stroke: ${getColor(props, "gray500")} !important; */
        path {
          stroke: ${getColor(props, "gray500")} !important;
        }
      }

      &:hover {
        color: ${getColor(props, "blue700")} !important;
        .ant-menu-item-title-content {
        }
        background-color: ${getColor(props, "blueLight50")} !important;
        .ant-menu-item-icon {
          path {
            stroke: ${getColor(props, "blue600")} !important;
          }
          stroke: ${getColor(props, "blue600")} !important;
        }
      }
    }

    .ant-menu-active,
    .ant-menu-item-selected {
      background-color: ${getColor(props, "blueLight50")} !important;
      .ant-menu-item-title-content {
        color: ${getColor(props, "blue700")} !important;

        /* Text sm/Medium */
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /*142.857%*/
      }
      .ant-menu-item-icon {
        path {
          stroke: ${getColor(props, "blue600")} !important;
          fill: ${getColor(props, "blue100")} !important;
        }
      }
    }

    .ant-menu-item-selected {
    }

    .ant-menu-item > .ant-menu-title-content {
    }

    .ant-menu-inline-collapsed > .ant-menu-item {
      /* display: flex;
      align-items: center;
      outline: 1px red solid;
      svg {
        width: 32px;
        height: 32px;
      } */
      .ant-menu-title-content {
        /* Text sm/Regular */
        /* font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /*142.857% */
        /* color: ${getColor(props, "blue700")} !important; */
      }
    }

    .ant-menu-inline-collapsed > .ant-menu-item .ant-menu-item {
      /* display: flex;
      align-items: center;
      justify-content: center;
      padding: 0px;
      outline: 1px red solid; */
      .ant-menu-title-content {
        /* Text sm/Regular */
        /* font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px; /* 142.857% */
        /* color: ${getColor(props, "blue700")} !important;  */
      }
    }

    .ant-menu-submenu {
      .ant-menu-item-icon {
      }
      .ant-menu-submenu-title {
      }
      .ant-menu-submenu-arrow {
      }

      &:hover {
        .ant-menu-item-icon {
          path {
            stroke: ${getColor(props, "blue600")} !important;
          }
        }
        .ant-menu-submenu-title {
          background-color: ${getColor(props, "blueLight50")} !important;
          color: ${getColor(props, "blue700")} !important;
        }
        .ant-menu-submenu-arrow {
          stroke: ${getColor(props, "blue600")} !important;
          path {
            stroke: ${getColor(props, "blue600")} !important;
          }
        }
      }
    }

    .ant-menu-submenu-selected {
      .ant-menu-item-icon {
        path {
          stroke: ${getColor(props, "blue600")} !important;
        }
      }
      .ant-menu-submenu-title {
        background-color: ${getColor(props, "blueLight50")} !important;
        color: ${getColor(props, "blue700")} !important;
      }
      .ant-menu-submenu-arrow {
        stroke: ${getColor(props, "blue600")} !important;
        path {
          stroke: ${getColor(props, "blue600")} !important;
          fill: ${getColor(props, "blue100")} !important;
        }
      }
    }
  `}
`;

export const AppSidebarNav: React.FC<ISidebarNavProps> = props => {
  return <StyledMenu {...props} />;
};

AppSidebarNav.defaultProps = {
  position: "top",
};
