import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { tqMutationKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";

type CreatePackagePayload = {
  title: string;
  description: string;
  currency: string;
  vendorEmail: string;
  price: number;
  revenue: number;
  upfrontPayment: number;
};

interface CreatePackageResponse {
  id: number;
  title: string;
  description: string;
  currency: string;
  price: number;
  revenue: number;
  upfrontPayment: number;
  vendorEmail: string;
}

type UseCreatePackageResult = UseMutationResult<CreatePackageResponse, Error, CreatePackagePayload>;

export function useCreatePackage(): UseCreatePackageResult {
  return useMutation({
    mutationFn: async (payload: CreatePackagePayload) => {
      const res = await EvendyApi.post<Promise<CreatePackageResponse>>("vendor-packages", payload);
      return res.data;
    },
    mutationKey: [tqMutationKeys.packages.create],
    onError(createPackageError: Error) {
      console.error("Error creating package:", createPackageError);
    },
    onSuccess(data) {
      console.log("Package created successfully:", data);
    },
  });
}
