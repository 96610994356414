import React from "react";
import styled from "styled-components";
import { routeNames } from "lib/routes";
import { Link } from "react-router-dom";
import { ReactComponent as Marketing2 } from "images/landingpage/services/findvendorsicon.svg";
import { ReactComponent as Analysis } from "images/landingpage/services/managevendorsicon.svg";
import Vector1 from "images/landingpage/services/Vector5.png";
import Vector2 from "images/landingpage/services/Vector6.png";

const FinanceContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  @media (max-width: 768px) {
  }
`;

const Top = styled.div`
  position: relative;
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10rem;
  background-color: #fcfcfd;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    gap: 5rem;
  }
`;
const Bottom = styled.div`
  position: relative;
  width: 100%;
  padding-top: 5rem;
  padding-bottom: 5rem;
  justify-content: center;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  gap: 10rem;
  background-color: #fcfcfd;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 100%;
    gap: 5rem;
  }
`;
const TopLeft = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1rem;
  @media (max-width: 768px) {
    margin-left: 0.5rem;
  }
`;

const TopRight = styled.div`
  position: relative;
  @media (max-width: 768px) {
    display: none;
  }
`;

const TopRightMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
  }
`;

const ItemContainers = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  margin-bottom: 6rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const Header = styled.p`
  font-size: 48px;
  font-weight: 600;
  line-height: 60.34px;
  letter-spacing: -0.02em;
  color: #2f2f2f;
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

const MediumHeader = styled.h5`
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
  z-index: 2;
`;

const Text = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #595959;
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 768px) {
    display: none;
  }
`;

const TextMobile = styled.p`
  display: none;
  @media (max-width: 768px) {
    display: block;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    text-align: left;
    color: #595959;
    margin-top: 0;
    margin-bottom: 0;
  }
`;

const SmallText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 17.6px;
  text-align: left;

  color: #595959;
  margin-top: 0;
  margin-bottom: 0;
`;

const MediumText = styled.p`
  font-size: 14px;
  font-weight: 500;
  line-height: 22px;
  text-align: left;
  color: #121212;
  margin-top: 0;
  margin-bottom: 0;
`;

const BoldText = styled.p`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 4rem;
  @media (max-width: 768px) {
    justify-content: flex-start; /* Keep alignment left on mobile */
    margin-left: 1rem;
    margin-bottom: 0rem;
  }
`;

export const CreateAnEvent = styled(Link)`
  all: unset;
  height: 46px;
  border-radius: 8px;
  background-color: rgba(19, 144, 230, 1);
  color: #fff;
  width: 170px;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  /* Add transition for smooth shadow appearance */
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    background-color: #3897d8;
    /* Dropdown shadow effect */
    box-shadow: 0 8px 16px rgba(56, 151, 216, 0.3); /* Subtle shadow */
  }
`;

const ExploreEvents = styled(Link)`
  all: unset;
  height: 46px;
  border-radius: 8px;
  color: #121212;
  width: 170px;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  /* Add transition for smooth shadow appearance */
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    color: #1c5b87;
  }
`;

const Benefits = styled.button`
  background-color: #7ef88b33;
  color: #168f43;
  border: none;
  padding: 8px 24px 8px 24px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 600;
  line-height: 15.08px;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 0;
`;

const Services = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  align-items: flex-start;
  z-index: 2;
`;

const ImageContainer = styled.div``;

const OverlayContainer = styled.div`
  position: absolute;
  bottom: -4.8rem;
  left: -3.5rem;
`;

const OverlayContainerMobile = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
`;

const SpiralContainer1 = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
  @media (max-width: 768px) {
    display: none;
  }
`;
const SpiralContainer2 = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`;

const SpiralContainer3 = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
`;
const SpiralContainer4 = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
`;

const ContactUs = styled.div`
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
`;

const VendorSourcing: React.FC = () => {
  return (
    <FinanceContainer>
      <Top>
        <TopLeft>
          <Marketing2 />
          <Header>FIND VENDORS</Header>
          <Text>
            Make use of our marketing tools on the platform to reach a wider
            <br />
            audience with your events. With Evendy&apos;s built-in marketing tools, you
            <br />
            can promote your event and reach the perfect attendees without the
            <br />
            usual hassles. Whether you&apos;re hosting a local meetup or global
            <br />
            convention, Evendy ensures you get your event across to the right
            <br />
            audience through smart, streamlined marketing capabilities.
          </Text>
          <TextMobile>
            Make use of our marketing tools on the platform to reach a wider audience with your events. With
            Evendy&apos;s built-in marketing tools, you can promote your event and reach the perfect attendees without
            the usual hassles. Whether you&apos;re hosting a local meetup or global convention, Evendy ensures you get
            your event across to the right audience through smart, streamlined marketing capabilities.
          </TextMobile>
          <ButtonWrapper>
            <CreateAnEvent to={routeNames.authSignup}>Create an event</CreateAnEvent>

            <ExploreEvents to={routeNames.event}>Explore Events</ExploreEvents>
          </ButtonWrapper>
        </TopLeft>
        <TopRight>
          <ImageContainer>
            <img
              src="/Images/Services/findvendors.jpeg"
              alt="Event financing"
              width="430.71px"
              height="335px"
              style={{ borderRadius: "4.7px" }}
            />
          </ImageContainer>
        </TopRight>
        <TopRightMobile>
          <ImageContainer>
            <img
              src="/Images/Services/findvendors.jpeg"
              alt="Event financing"
              width="323.03px"
              height="251.25px"
              style={{ borderRadius: "4.7px" }}
            />
          </ImageContainer>
        </TopRightMobile>
        <SpiralContainer1>
          <img src={Vector1} alt="" />
        </SpiralContainer1>
        <SpiralContainer2>
          {" "}
          <img src={Vector2} alt="" />
        </SpiralContainer2>
      </Top>
      <Bottom>
        <TopLeft>
          <Analysis />
          <Header>MANAGING VENDORS</Header>
          <Text>
            We can help you partner with various marketing partners on our
            <br />
            platform to help you achieve the best for your upcoming event. Our
            <br />
            partners are sourced strictly and well vetted. Promoting an event is a<br />
            massive undertaking, you can tap into our pre-vetted network of
            <br />
            marketing partners to amplify your reach. From influencers and
            <br />
            bloggers to ad platforms, we&apos;ve hand-picked diverse channels to
            <br />
            exponentially increase awareness.
            <br />
            Filter through partners based on your criteria like location and
            <br /> budget. We&apos;ll coordinate everything - briefs, materials, pricing, and
            <br /> real-time performance tracking - all in one place.
          </Text>
          <TextMobile>
            We can help you partner with various marketing partners on our platform to help you achieve the best for
            your upcoming event. Our partners are sourced strictly and well vetted. Promoting an event is a massive
            undertaking, you can tap into our pre-vetted network of marketing partners to amplify your reach. From
            influencers and bloggers to ad platforms, we&apos;ve hand-picked diverse channels to exponentially increase
            awareness. Filter through partners based on your criteria like location and budget. We&apos;ll coordinate
            everything - briefs, materials, pricing, and real-time performance tracking - all in one place.
          </TextMobile>
          <ButtonWrapper>
            <CreateAnEvent to={routeNames.authSignup}>Create an event</CreateAnEvent>

            <ExploreEvents to={routeNames.event}>Explore Events</ExploreEvents>
          </ButtonWrapper>
        </TopLeft>
        <TopRight>
          <ImageContainer>
            <img
              src="/Images/Services/eventpatners.jpeg"
              alt="Event financing"
              width="430.71px"
              height="335px"
              style={{ borderRadius: "4.7px" }}
            />
          </ImageContainer>
        </TopRight>
        <TopRightMobile>
          <ImageContainer>
            <img
              src="/Images/Services/eventpatners.jpeg"
              alt="Event financing"
              width="323.03px"
              height="251.25px"
              style={{ borderRadius: "4.7px" }}
            />
          </ImageContainer>
        </TopRightMobile>
        <SpiralContainer1>
          <img src={Vector1} alt="" />
        </SpiralContainer1>
      </Bottom>
    </FinanceContainer>
  );
};

export default VendorSourcing;
