import { Avatar, ListProps } from "antd";
import List from "antd/es/list";
import React, { ReactComponentElement, ReactNode, SVGFactory } from "react";
import styled, { css } from "styled-components";
import { Text } from "ui/components/base/Text";
import { useCallbackRef } from "lib/hooks/shared/useCallbackRef";
import { IProfile, IUser } from "ui/types";
import { themeUtils } from "ui/utils";
import { AppDivider } from "../../Divider";
import { AppFlex } from "../../Flex";

const { getColor } = themeUtils;

export interface IProfileMenuItem {
  icon?: ReactComponentElement<SVGFactory>;
  title: string;
  actionKey: string;
  component?: ReactNode;
  hasDivider?: boolean;
}

interface IProfileMenuItemProps extends IProfileMenuItem {
  onSelect: (actionKey: string) => void;
}

const UserProfileMenuRoot = styled.div`
  width: 240px;
`;
const UserProlfileMenuList = styled(List)<ListProps<IProfileMenuItem>>`
  padding-bottom: 4px;
  margin: 0px;
`;

const StyledItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2px 6px;
`;
const StyledItem = styled.button`
  ${props => css`
    all: unset;
    display: flex;
    padding: 9px 10px;
    align-items: center;
    gap: 12px;
    flex: 1 0 0;
    cursor: pointer;
    border-radius: 6px;

    &:hover {
      background-color: ${getColor(props, "blueLight50")};
    }
  `}
`;
const StyledAvatar = styled(Avatar)``;

export const UserProfileMenu: React.FC<ListProps<IProfileMenuItem>> = props => {
  return (
    <UserProfileMenuRoot>
      <UserProlfileMenuList {...props} />
    </UserProfileMenuRoot>
  );
};

export const UserProfileMenuItem: React.FC<IProfileMenuItemProps> = ({
  title,
  icon,
  actionKey,
  component,
  hasDivider,
  onSelect,
}) => {
  const onSelectCallback = useCallbackRef(() => onSelect(actionKey), []);
  console.log({ actionKey });
  return (
    <>
      {component || (
        <StyledItemContainer>
          <StyledItem type="button" role="menuitem" onClick={onSelectCallback}>
            {icon || null}
            <Text font="smMedium" color="gray700">
              {title}
            </Text>
          </StyledItem>
        </StyledItemContainer>
      )}
      {hasDivider ? <AppDivider margin={0} /> : null}
    </>
  );
};

export const UserProfileHeader: React.FC<Partial<IProfile & IUser>> = ({ firstName, lastName, avatar }) => {
  return (
    <AppFlex gap="12px" px="16px" py="12px" vertical align="center">
      <StyledAvatar size={80} alt={`${firstName || "User"}'s Profile Pic`} src={avatar}>
        {`${firstName?.charAt(0) || "N"} ${lastName?.charAt(0) || "A"}`}
      </StyledAvatar>
      <AppFlex vertical>
        <Text font="smSemibold" color="gray700">
          {firstName || "No firstname"} {lastName || "No lastname"}
        </Text>
        {/* <Text font={"smRegular"} color="gray600">
          {email}
        </Text> */}
      </AppFlex>
    </AppFlex>
  );
};
