import { RouteObject } from "react-router-dom";

type ValuesOf<T> = T[keyof T];
export type NumberOrString = number | string;
export type ISODateString = `${number}-${number}-${number}T${number}:${number}:${number}.${number}Z`;

export type SocialAccountType = "facebook" | "twitter" | "instagram" | "linkedin" | "youtube";

export interface ISocialAccount {
  type: SocialAccountType;
  url: string;
}

export interface IBankDetails {
  bankName: string;
  bankAccountName: string;
  bankAccountNumber: string;
  bankCode: number;
}

export type UserType = "vendor" | "organizer";

export type NotificationSetting = {
  id: string;
  title: string;
  subtitle?: string;
  type: "text" | "radio" | "checkbox" | "switch";
  status: "active" | "inactive";
};

export type IOrganization = {
  id: string;
  orgName: string;
  orgAddress: string;
  orgCategory: string;
  orgCountry?: string;
  orgState?: string;
  orgCity?: string;
  orgDescription?: string;
  createdAt: string;
  updatedAt: string;
};

export interface IOrganizerSettings {
  profilePublicUrl: string;
  passwordLastChanged?: ISODateString;
  emailLastChanged?: ISODateString;
  bankDetails?: IBankDetails;
  notificationSettings?: NotificationSetting[]; // default to all notifications allowed
}

export interface IVendorSettings {}

export interface IOrganizerProfile {
  id: string;
  userId: string;
  avatar?: string;
  phone: string;
  country: string;
  state: string;
  city: string;
  address: string;
  workAddress: string;
  organization?: IOrganization;
  settings: IOrganizerSettings;
  socials?: ISocialAccount[] | [];
  kycLevel?: 1 | 0;
  account_status: "active" | "inactive"; // inactive means a closed account
  role: "organizer" | "member";
  createdAt: ISODateString;
}
export interface IVendorProfile {
  id: string;
  userId: string;
  role: "vendor" | "member";
  avatar?: string;
  phone: string;
  country: string;
  state: string;
  city: string;
  address: string;
  workAddress: string;
  organiaztion: IOrganization;
  socials?: ISocialAccount[] | [];
  settings: IVendorSettings;
  kycLevel?: 1 | 0;
  createdAt: ISODateString;
}
export interface IAdminProfile {
  id: string;
  userId: string;
  avatar?: string;
  phone: string;
  country?: string;
  state?: string;
  city?: string;
  createdAt: ISODateString;
  role: "admin" | "superadmin";
}

export interface IUser {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  createdAt: ISODateString;
  updatedAt: ISODateString;
  // password is  included here but never sent to the frontend
}

export type IProfile = IOrganizerProfile | IVendorProfile | IAdminProfile;

export type IColorMode = "dark" | "light";

export interface IMetricsCardProps {
  title: string;
  // icon: React.ReactNode;
  value: string;
  total?: string;
  subtitle?: string;
  hint?: string;
}

// extracted from website repo
export interface ISector {
  id?: number;
  createAt?: string;
  updateAt?: string;
  name: string;
}
export type IPackageType = "Unit";
export interface IVendorPackage {
  name: string;
  price: number;
  type: IPackageType;
  description?: string;
  image?: string;
}

export interface IVendor extends Required<Omit<ILocation, "geoloaction">> {
  id?: number;
  createAt?: Date;
  updateAt?: Date;
  name: string;
  email: string;
  phone: string;
  avatar: string;
  sector_id: string;
  business_name: string;

  user_id?: number;
  user?: any;
  sector?: any;
  packages: IVendorPackage[];
}
export interface ILocation {
  street?: string;
  city?: string;
  state?: string;
  country?: string;
  geoloaction?: number[];
}
export interface IEventTag {
  _id: string;
  name: string;
  description?: string;
  color: string;
}
export interface IEventLocation extends ILocation {
  center: string;
}
export interface IEventTicket {
  _id: string;
  name: string;
  description?: string;
  amount: number;
}
export enum EVENT_TYPE {
  virtual = "virtual",
  live = "live",
}
export enum EVENT_TICKET_TYPE {
  paid = "paid",
  free = "free",
}
export interface IEventCard extends Pick<IEvent, "_id" | "name" | "image" | "tickets" | "type"> {}
export interface IEvent {
  _id: string;
  name: string;
  image: string;
  detail?: string; // html string
  timestamps: string[]; //utc
  location?: IEventLocation;
  type: EVENT_TYPE;
  ticketType: EVENT_TICKET_TYPE;
  owner: string;
  organizer: string;
  tickets?: IEventTicket[];
  tagIds?: string[];
  tags?: IEventTag[];
}

// end of extraction
export interface IEventOverview extends Pick<IEvent, "_id" | "name" | "timestamps" | "image" | "location"> {
  ticketRevenue: number | null;
  attendeeCount: number;
  totalTicketCount: number;
  totalTicketRevenue: number | null;
  ticketsSold: number;
}

export interface IDashboardOverview {
  upcomingEvent: IEventOverview;
  pastEvent: IEventOverview;
  plannedEvents: IEventOverview[];
}

export interface INotificationProps {
  notificationSections: NotificationSetting[];
}

export interface IAccountDetailsPageProps {
  user: IUser;
  profile: IProfile;
  profileType: "organizer" | "vendor" | "admin";
}

export interface SigninData {
  email: string;
  password: string;
}
export interface SignupData extends SigninData {
  firstName: string;
  lastName: string;
}

export type RouteData = RouteObject & {
  label?: string;
  icon?: React.ReactNode | React.ReactElement;
  isBottomSidebarItem?: boolean;
  isTopSidebarItem?: boolean;
  isInvisible?: boolean;
};

export type GetItemOptions = Pick<RouteData, "isBottomSidebarItem" | "isInvisible">;

export const evendySites = {
  ORGANIZER: "organizer",
  VENDOR: "vendor",
  ADMIN: "admin",
} as const;

export type EvendySiteTypesTypes = typeof evendySites;

export type EvendySiteOption = ValuesOf<EvendySiteTypesTypes>;

export interface IGetSiteRoutesParams {
  site: EvendySiteOption;
}

// from swagger docs
export type GetUserDto = {
  id: number;
  createdAt: ISODateString;
  updatedAt: ISODateString;
  firstName: string;
  lastName: string;
  email: string;
  userType: UserType;
  verified: boolean;
  organizer: object;
};

export type GetEventDto = {
  id: number;
  title: string;
  banner: string;
  organizerEmail: string;
  category: string;
  type: string;
  about: string;
  occurence: string;
  timezone: string;
  startDate: ISODateString;
  endDate: ISODateString;
  createdAt: ISODateString;
  updatedAt: ISODateString;
};
