import { Tabs, TabsProps } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { uiUtils } from "ui/utils";

type AppTabsProps = TabsProps;

const StyledTabs = styled(Tabs)``;

export const AppTabs: React.FC<AppTabsProps> = ({ defaultActiveKey, items, onChange, ...restOfTabProps }) => {
  const [activeKey, setActiveKey] = useState("");

  const handleTabChange = (val: string) => {
    setActiveKey(val);
    if (uiUtils.isFunction(onChange)) {
      onChange?.(val);
    }
  };

  useEffect(() => {
    if (defaultActiveKey) {
      setActiveKey(defaultActiveKey);
    } else if (items?.length) {
      setActiveKey(items[0].key);
    }
  }, []);
  return <StyledTabs {...restOfTabProps} activeKey={activeKey} items={items} onChange={handleTabChange} />;
};
