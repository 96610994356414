import React, { useState } from "react";
import styled from "styled-components";
import { ReactComponent as Caution } from "images/vendor/Caution.svg";
import { Link } from "react-router-dom";
import { routeNames } from "lib/routes";
import CreatedPackages from "./CreatedPackages";

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  padding: 16px;
  background-color: #f9faff;
  width: calc(31.3% - 30px);
  gap: 1rem;
`;

const Notice = styled.div`
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5rem;
  gap: 1rem;
  color: rgba(18, 18, 18, 1);
  padding: 8px 0px;
  margin-bottom: 10px;
  width: 100%;
  max-width: 550px;
`;

const Packages = () => {
  return (
    <>
      <Notice>
        {" "}
        <Caution />
        To add or manage your packages, please navigate to <Link to={routeNames.VendorMyPackages}>
          My packages
        </Link>{" "}
        page
      </Notice>
      <CreatedPackages />
    </>
  );
};

export default Packages;
