import { UseMutationResult, useMutation } from "@tanstack/react-query";
import { tqMutationKeys } from "lib/constants";
import { EvendyApi } from "lib/utils/api-utils";

// Define the payload type for editing an organizer
type EditOrganizerPayload = {
  email: string;
  data: {
    avatar?: string;
    country?: string;
    state?: string;
    city?: string;
    address?: string;
    workAddress?: string;
    role?: string;
    kycLevel?: string;
    accountStatus?: string;
    user?: {
      firstName: string;
      lastName: string;
      phone: string;
      email: string;
    };
  };
};

// Define the response type
interface EditOrganizerResponse {
  avatar: string;
  country: string;
  state: string;
  city: string;
  address: string;
  workAddress: string;
  role: string;
  kycLevel: string;
  accountStatus: string;
  user: {
    firstName: string;
    lastName: string;
    phone: string;
    email: string;
  };
}

// Define the mutation result type
type UseEditOrganizerResult = UseMutationResult<EditOrganizerResponse, Error, EditOrganizerPayload>;

// Define the useEditOrganizerById hook
export function useEditOrganizerByEmail(): UseEditOrganizerResult {
  return useMutation({
    mutationFn: async (payload: EditOrganizerPayload) => {
      const { email, data } = payload;
      const res = await EvendyApi.patch<Promise<EditOrganizerResponse>>(`organizer/update-by-email/${email}`, data);
      return res.data;
    },
    mutationKey: [tqMutationKeys.organizer.update],
    onError(editError: Error) {
      console.log({ editError });
    },
    onSuccess(data) {
      console.log("Organizer updated successfully", data);
    },
  });
}
