import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { Button, Form, Input, Select } from "antd";
import { AppModal } from "ui/components/base";
import { ReactComponent as Notice } from "images/vendorprofile/Vectornotice.svg";
import { ModalBodyContainer, NoticeDisplay, NoticeText, Text } from "app/pages/organizer/vendorsprofile/Packages";
import { useCreatePackage } from "lib/hooks/vendorpackage";
import SubmitButton from "ui/components/base/Buttons/SubmitButton";
import { jwtDecode, JwtPayload } from "jwt-decode";
import { sessionStorageKeys } from "lib/constants";
import { useGetVendorById } from "lib/hooks";
import { getSession } from "lib/utils/utils";

const { TextArea } = Input;

type AddPackageModalProps = {
  open: boolean;
  onCancel: () => void;
  onPackageAdded: () => void;
};

const AddPackageModal: React.FC<AddPackageModalProps> = ({ open, onCancel, onPackageAdded }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [vendorEmail, setVendorEmail] = useState<string | null>(null);

  const { mutate: createPackage, isError, error } = useCreatePackage();

  // Options for input in modal
  const options = [
    { value: "Select currency", label: "Select currency", disabled: true },
    { value: "NGN", label: "NGN" },
    { value: "USD", label: "USD" },
  ];

  useEffect(() => {
    const session = getSession();

    if (session) {
      setVendorEmail(session.email);
    }
  }, []);

  // Prevent onFinish from being called multiple times
  const onFinish = useCallback(
    (values: any) => {
      if (loading) return;

      setLoading(true);

      const payload = {
        title: values.packageTitle,
        description: values.description,
        currency: values.currency,
        vendorEmail: vendorEmail as string,
        price: parseFloat(values.unitprice),
        upfrontPayment: parseFloat(values.upfront),
        revenue: 0,
      };

      createPackage(payload, {
        onSuccess: () => {
          form.resetFields();
          setLoading(false);
          onCancel(); // Close modal on success
          onPackageAdded(); // Trigger refetch
        },
        onError: error => {
          console.error("Error creating package:", error);
          setLoading(false);
        },
      });
    },
    [createPackage, form, loading, onCancel, onPackageAdded, vendorEmail],
  );

  return (
    <AppModal open={open} onCancel={onCancel} header={<span>Add package</span>} size="md">
      <ModalBodyContainer>
        <NoticeDisplay>
          <Notice />
          <NoticeText>Evendy charges a 10% commission on each full payment processed to you</NoticeText>
        </NoticeDisplay>

        <Form layout="vertical" form={form} name="addPackage" onFinish={onFinish}>
          <Text>Package Title</Text>
          <Form.Item name="packageTitle" rules={[{ required: true, message: "Please input the package title!" }]}>
            <Input placeholder="e.g Home service, Food trays, Hire for a day, etc" />
          </Form.Item>
          <Text>Description of Package</Text>
          <Form.Item name="description" rules={[{ required: true, message: "Please input the package description!" }]}>
            <TextArea rows={4} placeholder="Briefly state details about your package" maxLength={200} />
          </Form.Item>
          <Text>Currency for Package</Text>
          <Form.Item name="currency" rules={[{ required: true, message: "Please select a currency!" }]}>
            <Select placeholder="Select currency" options={options} />
          </Form.Item>
          <Text>Unit Price</Text>
          <Form.Item name="unitprice" rules={[{ required: true, message: "" }]}>
            <Input placeholder="" prefix="NGN" />
          </Form.Item>
          <Text>Upfront Payment Percent</Text>
          <Form.Item name="upfront" rules={[{ required: true, message: "" }]}>
            <Input placeholder="" suffix="%" />
          </Form.Item>
          <Text>We recommend 10%. Value cannot be more than 20%</Text>
          {isError && <p style={{ color: "red" }}>{(error as Error).message}</p>} {/* Display error message */}
          <Form.Item>
            <SubmitButton form={form}>Add package</SubmitButton>
          </Form.Item>
        </Form>
      </ModalBodyContainer>
    </AppModal>
  );
};

export default AddPackageModal;
