import React from "react";
import { Table, TableColumnsType } from "antd";
import styled from "styled-components";
import { eventData } from "./createdevents/eventdata";
import { ReactComponent as TicketIcon } from "images/organizerevents/eventticket.svg";
import { ReactComponent as ConcludedIcon } from "images/organizerevents/concluded.svg";
import { ReactComponent as DraftIcon } from "images/organizerevents/draft.svg";
import { ReactComponent as UpcomingIcon } from "images/organizerevents/upcoming.svg";
import { AppTable } from "ui/components/base";
import { useGetOrganizerEvents } from "lib/hooks";

const StatusBadge = styled.div<{ status: string }>`
  display: flex;
  align-items: center;
  padding: 0.25rem 0.5rem;
  border-radius: 8px;
  background-color: ${props =>
    props.status === "Concluded" ? "#e8f5e9" : props.status === "Upcoming" ? "#e3f2fd" : "#fff8e1"};
  color: ${props =>
    props.status === "Concluded"
      ? "rgba(0, 94, 11, 1)"
      : props.status === "Upcoming"
        ? "rgba(28, 91, 135, 1)"
        : "rgba(94, 83, 0, 1)"};
  text-align: center;
  font-size: 0.85rem;
  width: fit-content;

  svg {
    margin-right: 8px;
  }
`;

const renderStatus = (status: string) => {
  let IconComponent;
  switch (status) {
    case "Concluded":
      IconComponent = ConcludedIcon;
      break;
    case "Upcoming":
      IconComponent = UpcomingIcon;
      break;
    case "Draft":
      IconComponent = DraftIcon;
      break;
    default:
      IconComponent = null;
  }
  return (
    <StatusBadge status={status}>
      {IconComponent && <IconComponent />}
      {status}
    </StatusBadge>
  );
};

const CustomTable = styled(AppTable)`
  .ant-table-thead > tr > th {
    color: rgba(18, 18, 18, 1);
    font-weight: 400;
    font-size: 1rem;
    background-color: rgba(249, 250, 255, 1);
  }
  .ant-table-thead > tr > th .anticon {
    margin-right: 8px;
    vertical-align: middle;
  }

  /* Responsive styles */
  @media (max-width: 768px) {
    .ant-table-thead > tr > th {
      font-size: 0.85rem;
    }
    .ant-table-thead > tr > th .anticon {
      width: 14px;
      height: 14px;
    }
    .ant-table {
      font-size: 0.75rem;
    }
  }

  @media (max-width: 480px) {
    .ant-table-thead {
      display: none;
    }
    .ant-table {
      font-size: 0.7rem;
    }
  }
`;

const columns: TableColumnsType = [
  {
    title: "#",
    dataIndex: "organizerEmail",
    key: "index",
    render(value, record, index) {
      return <span>{Number(index) + 1}</span>;
    },
    align: "center",
  },
  {
    title: "Event Name",
    dataIndex: "title",
    key: "name",
    render: (_: any, record: any) => (
      <div style={{ display: "flex", alignItems: "center", gap: "16px", fontWeight: 700 }}>
        <img src={record.banner} alt={record.title} style={{ width: "150px", height: "75px", borderRadius: "8px" }} />
        <div>
          <div style={{ fontWeight: 500, fontSize: "1rem", lineHeight: "1.5rem", color: "rgba(18, 18, 18, 1)" }}>
            {record.title}
          </div>
          <small style={{ color: "rgba(89, 89, 89, 1)", fontWeight: 400, fontSize: "0.8rem", lineHeight: "1rem" }}>
            {record.date}
          </small>
        </div>
      </div>
    ),
    align: "left",
  },
  {
    title: (
      <span>
        Tickets Sold <TicketIcon />
      </span>
    ),
    dataIndex: "ticketsSold",
    key: "ticketsSold",
    render: (_: any, record: any) => (
      <div>
        <div>{record.ticketsSold}</div>
        <small style={{ color: "rgba(150, 150, 150, 1)" }}>Expected: {record.expectedTickets}</small>
      </div>
    ),
    align: "left",
  },
  {
    title: "Attendees",
    dataIndex: "attendees",
    key: "attendees",
    align: "left",
  },
  {
    title: "Revenue Generated",
    dataIndex: "revenue",
    key: "revenue",
    align: "left",
  },
  {
    title: "Event Status",
    dataIndex: "status",
    key: "status",
    render: (status: string) => renderStatus(status),
    align: "center",
  },
  {
    title: "",
    key: "actions",
    render: () => <div>...</div>,
    align: "center",
  },
];

const EventTable = () => {
  const { data } = useGetOrganizerEvents();
  return <AppTable columns={columns} dataSource={data?.events} pagination={{ pageSize: 5 }} />;
};

export default EventTable;
