import React, { useEffect, useState } from "react";
import { Outlet, useNavigate, useLocation, Link } from "react-router-dom";
import styled from "styled-components";
import Sidebar, { SidebarItem } from "ui/components/Dashboard/Sidebar/Sidebar";
import { ReactComponent as HomeIcon } from "images/sidebar/Framehome.svg";
import { ReactComponent as EventIcon } from "images/sidebar/Frameevents.svg";
import { ReactComponent as VendorIcon } from "images/sidebar/Shop-Filledvendor.svg";
import { ReactComponent as MarketingIcon } from "images/sidebar/Shopmarketing.svg";
import { ReactComponent as LoanIcon } from "images/sidebar/Frameloan.svg";
import { ReactComponent as FinancesIcon } from "images/sidebar/Framefinances.svg";
import { ReactComponent as ReportsIcon } from "images/sidebar/Framereports.svg";
import { ReactComponent as HospitalityIcon } from "images/sidebar/Framehospitality.svg";
import { ReactComponent as HelpIcon } from "images/sidebar/Frame493help.svg";
import { ReactComponent as MyPackages } from "images/sidebar/mypackages.svg";
import { ReactComponent as Resources } from "images/sidebar/resources.svg";
import { ReactComponent as Requests } from "images/sidebar/vendorrequest.svg";
import Topbar from "ui/components/base/Topbar/Topbar";
import { getSession } from "lib/utils/utils";
import { routeNames } from "lib/routes";
import MobileTopBar from "ui/components/base/MobileTopBar/MobileTopBar";
import { StyledButton } from "app/pages/website/header/Header.style";
import { Button } from "antd";
import { useGetUser } from "lib/hooks";
import VerifyEmail from "ui/components/Dashboard/verifyEmail/VerifyEmail";
import { AppModal } from "ui/components/base";

const ContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const SidebarContainer = styled.div<{ isSidebarVisible: boolean }>`
  width: 18.5%;
  margin-top: 7rem;

  @media (max-width: 768px) {
    margin-top: 6rem;
    width: 100%;
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    background-color: white;
    transform: ${({ isSidebarVisible }) => (isSidebarVisible ? "translateX(0)" : "translateX(100%)")};
    transition: transform 0.3s ease-in-out;
    z-index: 2000;
  }
`;

const OutletContainer = styled.div`
  width: 81.5%;
  margin-top: 7rem;
  @media (max-width: 768px) {
    width: 100%;
    flex-grow: 1;
    margin-top: 0;
    padding-top: 3rem;
  }
`;

const TopbarWrapper = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  background-color: white;
`;

const MobileTopbarWrapper = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.05);
  @media (max-width: 768px) {
    display: flex;
  }
`;

const DesktopTopbarWrapper = styled.div`
  display: block;
  @media (max-width: 768px) {
    display: none;
  }
`;

const SidebarOverlay = styled.div<{ isSidebarVisible: boolean }>`
  display: none;
  @media (max-width: 768px) {
    display: ${({ isSidebarVisible }) => (isSidebarVisible ? "block" : "none")};
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000;
  }
`;

const DashboardContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const Notice = styled.button`
  background-color: #fffefa;
  color: #665000;
  border: 1px solid;
  border-color: #f1f1f1;
  border-radius: 8px;
  padding: 5px 10px;
  font-size: 1rem;
  margin-bottom: 2rem;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const Verify = styled(Button)``;

// Sidebar data for organizer user
const organizerSidebarData: SidebarItem[] = [
  { name: "Home", path: "/app/organizer/home", icon: HomeIcon },
  { name: "Events", path: "/app/organizer/events", icon: EventIcon },
  { name: "Vendor Marketplace", path: "/app/marketplace", icon: VendorIcon },
  { name: "Marketing", path: "/app/marketing", icon: MarketingIcon, comingSoon: true },
  { name: "Finances", path: "/app/finances", icon: FinancesIcon, comingSoon: true },
  { name: "Loan Applications", path: "/app/organizer/loan", icon: LoanIcon, comingSoon: true },
  { name: "Reports", path: "/app/organizer/reports", icon: ReportsIcon, comingSoon: true },
  { name: "Hospitality", path: "/app/hospitality", icon: HospitalityIcon, comingSoon: true },
  { name: "Help", path: "/app/help", icon: HelpIcon, comingSoon: true },
];

// Sidebar data for vendor user
const vendorSidebarData: SidebarItem[] = [
  { name: "Home", path: "/app/vendor/home", icon: HomeIcon },
  { name: "My Packages", path: "/app/vendor/mypackages", icon: MyPackages },
  { name: "Requests", path: "/app/vendor/requests", icon: Requests },
  { name: "Finances", path: "/app/finances", icon: FinancesIcon, comingSoon: true },
  { name: "Resources", path: "/app/vendor/resources", icon: Resources, comingSoon: true },
  { name: "Marketing", path: "/app/marketing", icon: MarketingIcon, comingSoon: true },
  { name: "Hospitality", path: "/app/hospitality", icon: HospitalityIcon, comingSoon: true },
  { name: "Help", path: "/app/help", icon: HelpIcon, comingSoon: true },
];

const Dashboard: React.FC = () => {
  const [usertype, setUsertype] = useState("");
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { data: user, isLoading, error } = useGetUser();
  const navigate = useNavigate();
  const location = useLocation();
  const [isModalVisible, setIsModalVisible] = useState(false);

  useEffect(() => {
    const session = getSession();
    if (session) {
      const user = session.userType;
      if (user === "vendor" || user === "organizer") {
        setUsertype(user);
      } else {
        console.error("Invalid userType in token");
      }
    } else {
      console.log("No session found");
    }
  }, []);

  let sidebarData: SidebarItem[] = [];
  switch (usertype) {
    case "vendor":
      sidebarData = vendorSidebarData;
      break;
    case "organizer":
      sidebarData = organizerSidebarData;
      break;
    default:
      sidebarData = [];
  }

  // Close the sidebar whenever the route changes on mobile view
  useEffect(() => {
    if (window.innerWidth <= 768 && isSidebarVisible) {
      setIsSidebarVisible(false);
      setIsSidebarOpen(false);
    }
  }, [location]);

  const toggleSidebar = () => {
    setIsSidebarVisible(!isSidebarVisible);
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleContinue = () => {
    setIsModalVisible(true);

    navigate(routeNames.VerifyEmail);
  };
  const handleModalOpen = () => {
    setIsModalVisible(true);
  };

  const handleModalClose = () => {
    setIsModalVisible(false);
  };
  return (
    <DashboardContainer>
      <TopbarWrapper>
        <DesktopTopbarWrapper>
          <Topbar />
        </DesktopTopbarWrapper>
        <MobileTopbarWrapper>
          <MobileTopBar toggleSidebar={toggleSidebar} isSidebarOpen={isSidebarOpen} />
        </MobileTopbarWrapper>
      </TopbarWrapper>

      {/* <SidebarOverlay isSidebarVisible={isSidebarVisible} onClick={toggleSidebar} /> */}
      <ContentContainer>
        <SidebarContainer isSidebarVisible={isSidebarVisible}>
          <Sidebar sidebarData={sidebarData} />
        </SidebarContainer>

        <OutletContainer>
          {!user?.verified && (
            <Notice>
              <p>Kindly verify your email within the next 30 days to avoid account suspension.</p>
              <Verify onClick={handleModalOpen}>Verify Here</Verify>
            </Notice>
          )}
          <AppModal open={isModalVisible} header="Verify Your Email" onCancel={handleModalClose}>
            <VerifyEmail />
          </AppModal>
          <Outlet />
        </OutletContainer>
      </ContentContainer>
    </DashboardContainer>
  );
};

export default Dashboard;
