import React from "react";
import styled from "styled-components";
import { routeNames } from "lib/routes";
import { Link } from "react-router-dom";

const AboutContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;

  gap: 2rem;
  @media (max-width: 768px) {
  }
`;

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: flex-start;
  margin-left: 56px;
  margin-right: 56px;
  @media (max-width: 768px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

const Middle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  margin-top: 5rem;
  margin-left: 56px;
  margin-right: 56px;
  @media (max-width: 768px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 56px;
  margin-right: 56px;
  margin-bottom: 3rem;
  @media (max-width: 768px) {
    margin-left: 15px;
    margin-right: 15px;
  }
`;

const Header = styled.p`
  font-size: 48px;
  font-weight: 600;
  line-height: 60.34px;
  letter-spacing: -0.02em;
  color: #2f2f2f;
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 768px) {
    font-size: 32px;
  }
`;

const Text = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;
  color: #595959;
  margin-top: 0;
  margin-bottom: 0;
  @media (max-width: 768px) {
  }
`;

const MediumHeader = styled.h5`
  font-size: 32px;
  font-weight: 500;
  line-height: 40px;

  font-size: 32px;
  font-weight: 500;
  line-height: 40px;
  text-align: left;

  margin-top: 0;
  margin-bottom: 0;
  z-index: 2;
`;

const SmallHeader = styled.h5`
  font-size: 24px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  z-index: 2;
`;

const SmallText = styled.p`
  font-size: 14px;
  font-weight: 400;
  line-height: 17.6px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  z-index: 2;

  color: #595959;
`;

const Aboutus = styled.button`
  background-color: #7ef88b33;
  color: #168f43;
  border: none;
  padding: 8px 24px 8px 24px;
  border-radius: 16px;
  font-size: 12px;
  font-weight: 600;
  line-height: 15.08px;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 0;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  margin-bottom: 4rem;
  @media (max-width: 768px) {
    justify-content: flex-start; /* Keep alignment left on mobile */
    margin-left: 1rem;
    margin-bottom: 0rem;
  }
`;

export const CreateAnEvent = styled(Link)`
  all: unset;
  height: 46px;
  border-radius: 8px;
  background-color: rgba(19, 144, 230, 1);
  color: #fff;
  width: 170px;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  /* Add transition for smooth shadow appearance */
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    background-color: #3897d8;
    /* Dropdown shadow effect */
    box-shadow: 0 8px 16px rgba(56, 151, 216, 0.3); /* Subtle shadow */
  }
`;

const ExploreEvents = styled(Link)`
  all: unset;
  height: 46px;
  border-radius: 8px;
  color: #121212;
  width: 170px;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;

  /* Add transition for smooth shadow appearance */
  transition:
    background-color 0.3s ease,
    box-shadow 0.3s ease;

  &:hover {
    color: #1c5b87;
  }
`;

const ImageContainer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;

const ImageContainerMobile = styled.div`
  display: none;
  @media (max-width: 768px) {
    display: block;
    /* margin-left: 15px;
    margin-right: 15px; */
  }
`;

const BottomCards = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 2rem;
  gap: 1rem;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const BottomLeft = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const BottomRigth = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const Card = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 1.5rem;
  padding-left: 1.5rem;
  padding-right: 3rem;
  padding-bottom: 3rem;
  background-color: #f6f6f6;
  border-radius: 8px;
`;

const About: React.FC = () => {
  return (
    <AboutContainer>
      <Top>
        <Aboutus>ABOUT US</Aboutus>
        <Header>
          EVENDY IS YOUR PARTNER IN CURATING EXPERIENCES
          <br />
          THAT LEAVE A LASTING IMPRESSION
        </Header>
        <ImageContainer>
          <img
            src="/Images/About/aboutus.jpeg"
            alt="Event financing"
            width="1328px"
            height="523px"
            style={{ borderRadius: "4.7px" }}
          />
        </ImageContainer>
        <ImageContainerMobile>
          <img
            src="/Images/About/aboutusmobile.jpeg"
            alt="Event financing"
            width="360px"
            style={{ borderRadius: "4.7px" }}
          />
        </ImageContainerMobile>
      </Top>
      <Middle>
        <MediumHeader>Welcome to Evendy, where we redefine how events:</MediumHeader>
        <Text>
          are conceived, executed, and celebrated. With a deep understanding of the intricacies that make each event
          unique.
          <br />
          At Evendy, our mission is to connect the event economy in one thriving ecosystem. We envision a world where
          every
          <br /> event, regardless of size or occasion, is a masterpiece.
        </Text>
        <Text>
          Our aim is to empower organizers, vendors, and general hospitality with inclusive platforms that simplify the
          <br /> journey from concept to reality.
        </Text>
        <ButtonWrapper>
          <CreateAnEvent to={routeNames.authSignup}>Create an event</CreateAnEvent>

          <ExploreEvents to={routeNames.event}>Explore Events</ExploreEvents>
        </ButtonWrapper>
      </Middle>
      <Bottom>
        <MediumHeader>Why Evendy?</MediumHeader>
        <BottomCards>
          <BottomLeft>
            <Card>
              <SmallHeader>Seamless Execution:</SmallHeader>
              <SmallText>
                {" "}
                Manage every aspect of your event on one intuitive platform, from registration and
                <br />
                ticketing to financing, promotions, marketing, and vendor management.
              </SmallText>
            </Card>
            <Card>
              <SmallHeader>Expert Network:</SmallHeader>
              <SmallText>
                Tap into our carefully curated network of trusted vendors and marketing partners,
                <br />
                ensuring top-notch quality and reliability.
              </SmallText>
            </Card>
          </BottomLeft>
          <BottomRigth>
            <Card>
              <SmallHeader>Tailored Experience:</SmallHeader>
              <SmallText>
                {" "}
                Craft unique, memorable events with our customizable platform, tailored to your
                <br /> specific needs and vision.
              </SmallText>
            </Card>
            <Card>
              <SmallHeader>Cutting-Edge Tech:</SmallHeader>
              <SmallText>
                {" "}
                Utilize our innovative platform for efficient event planning, real-time tracking, and data
                <br />
                -driven insights.
              </SmallText>
            </Card>
          </BottomRigth>
        </BottomCards>
      </Bottom>
    </AboutContainer>
  );
};
export default About;
