import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Form, Input, DatePicker, Upload } from "antd";
import { AppModal } from "ui/components/base";
import { useCreatePackage } from "lib/hooks/vendorpackage";
import SubmitButton from "ui/components/base/Buttons/SubmitButton";
import { sessionStorageKeys } from "lib/constants";
import { DownloadOutlined } from "@ant-design/icons";
import { UploadFile } from "antd/es/upload/interface";

const { TextArea } = Input;

type PastEventModalProps = {
  open: boolean;
  onCancel: () => void;
  onAddEvent: (event: any) => void;
  disabled?: boolean;
};

interface FileItem {
  uid: string;
  name: string;
  status?: string;
  url?: string;
}

const ModalBodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  padding: 1rem;
  width: 700px;
  max-width: 100%;
`;

const Text = styled.p`
  font-size: 14px;
`;

const PastEventModal: React.FC<PastEventModalProps> = ({ open, onCancel, onAddEvent }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [vendorEmail, setVendorEmail] = useState<string | null>(null);
  const [fileList, setFileList] = useState<UploadFile[]>([]);

  const { mutate: createPackage, isError, error } = useCreatePackage();

  useEffect(() => {
    const email = sessionStorage.getItem(sessionStorageKeys.email);
    setVendorEmail(email);
  }, []);

  const handleUploadChange = ({ fileList }: { fileList: UploadFile[] }) => {
    setFileList(fileList);
  };

  const onFinish = (values: any) => {
    setLoading(true);

    const newEvent = {
      title: values.titleName,
      organizers: values.eventOrganizers,
      date: values.eventDate.format("YYYY-MM-DD"),
      images: fileList,
      description: values.description,
      currency: values.currency,
    };

    createPackage(
      {
        ...newEvent,
        vendorEmail: vendorEmail as string,
        price: parseFloat(values.unitprice),
        upfrontPayment: parseFloat(values.upfront),
        revenue: 0,
      },
      {
        onSuccess: () => {
          console.log("Package created successfully");
          form.resetFields();
          setLoading(false);
          onAddEvent(newEvent);
          onCancel();
        },
        onError: error => {
          console.error("Error adding event:", error);
          setLoading(false);
        },
      },
    );
  };
  return (
    <AppModal open={open} onCancel={onCancel} header={<span>Add Event</span>} size="md">
      <ModalBodyContainer>
        <Form layout="vertical" form={form} name="addPackage" onFinish={onFinish}>
          <Text>Event Title/Name</Text>
          <Form.Item name="titleName" rules={[{ required: true, message: "Please input the event title!" }]}>
            <Input placeholder="e.g BBNaija Finale" />
          </Form.Item>

          <Text>Event Organizers</Text>
          <Form.Item name="eventOrganizers" rules={[{ required: true, message: "Please input the event organizers!" }]}>
            <Input />
          </Form.Item>

          <Text>Event Date</Text>
          <Form.Item name="eventDate" rules={[{ required: true, message: "Please select the event date!" }]}>
            <DatePicker format="YYYY-MM-DD" />
          </Form.Item>

          <Text>Please provide images from event</Text>
          <Form.Item name="eventImages" rules={[{ required: true, message: "Please upload event images!" }]}>
            <Upload
              action="/your-backend-endpoint-url"
              listType="picture"
              fileList={fileList}
              onChange={handleUploadChange}
            >
              <Button icon={<DownloadOutlined />}>Click to Upload</Button>
            </Upload>
          </Form.Item>

          {isError && <p style={{ color: "red" }}>{(error as Error).message}</p>}
          <Form.Item>
            <SubmitButton form={form}>
              <Button type="primary" htmlType="submit" disabled={true} style={{ border: "none" }}>
                Add Event
              </Button>
            </SubmitButton>
          </Form.Item>
        </Form>
      </ModalBodyContainer>
    </AppModal>
  );
};

export default PastEventModal;
